import { createSelector } from 'reselect';

import { selectProviders } from 'store/admin/providers/selectors';
import { RootState } from 'store/types';

import { transformProvidersToTableData } from './helpers';

export const createSelectProviderTableData = createSelector(
  selectProviders,
  transformProvidersToTableData
);

export const selectProvidersRequestStatus = (state: RootState) =>
  state.resources.admin.providers.providersRequestStatus;

export const selectTotalCount = (state: RootState) =>
  state.resources.admin.providers.totalCount;

export const selectCurrentPage = (state: RootState) =>
  state.resources.admin.providers.currentPage;
