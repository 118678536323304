import { CheckOutlined } from '@ant-design/icons';
import React from 'react';

import { Provider } from 'api/admin/providers/types';

import { ProvidersTableData } from './types';
import { PROVIDER_STATUS_TAG_PROPS } from './constants';
import { updateAdminApprovalAsync } from 'store/admin/providers/actions';

export const transformProvidersToTableData = (
  providers: Provider[]
): ProvidersTableData[] => {
  return providers.map((provider) => ({
    key: provider.id.toString(),
    provider: {
      text: provider.business_name,
      fontWeight: 'bold',
    },
    email: {
      rows: [
        {
          text: provider.email,
        },
      ],
    },
    phone: {
      text: provider.business_phone,
    },
    verification_point: {
      rows:
        provider['100_points'] >= 100
          ? [
              {
                isLinkable: false,
                icon: <CheckOutlined />,
                iconTo: '',
                textTo: '',
                color: 'green',
                text: '100',
              },
            ]
          : [],
    },
    status: {
      tags: [
        {
          name: PROVIDER_STATUS_TAG_PROPS[provider.provider_mode].name,
          color: PROVIDER_STATUS_TAG_PROPS[provider.provider_mode].color,
        },
      ],
    },
    vehicles: {
      text: provider.vehicles.toString(),
    },
    abn: {
      text: provider.abn,
    },
    action_btn: {
      useHook: () => {
        return updateAdminApprovalAsync({
          data: { provider_mode: 'ACTIVE' },
          id: provider.id,
        });
      },
      buttons:
        provider.provider_mode === 'PENDING' &&
        provider.is_onboarding_completed === true
          ? [
              {
                label: 'Approve',
                onClick: () => {},
              },
            ]
          : [],
    },
  }));
};
