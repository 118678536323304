import { Column } from 'components/tables/IXTable/types';

export const COLUMNS: Column[] = [
  {
    name: 'Ticket No.',
    key: 'ticket_no',
    type: 'image-and-text-cell',
  },
  {
    name: 'Vehicle',
    key: 'vehicle',
    type: 'text',
  },
  {
    name: 'Reg No.',
    key: 'reg_no',
    type: 'text',
  },
  {
    name: 'Violation',
    key: 'violation',
    type: 'text',
  },
  {
    name: 'Driver',
    key: 'driver',
    type: 'text',
  },
  {
    name: 'Date',
    key: 'date',
    type: 'text',
  },
  {
    name: 'Status',
    key: 'status',
    type: 'health',
  },
  {
    name: 'Fine Amount',
    key: 'fine_amount',
    type: 'text',
  },
];
