import { MenuOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Spin } from 'antd';
import classNames from 'classnames';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from 'components/Button';
import { useIsMobile } from 'shared/hooks';
import { ROUTES } from 'shared/routes/constants';
import { useAppSelector } from 'store/hooks';
import {
  selectLoginData,
  selectVerifyTokenRequestStatus,
} from 'store/shared/auth/selectors';
import { isCustomer, isProvider } from 'uicontainers/_shared/helpers/auth';

import Profile from '../../Profile';
import styles from './Navigation.module.scss';
import { selectLoginStatus } from './selectors';
import { selectBusinessInfo } from 'uicontainers/Customer/Account/BusinessInfo/selectors';

export const ProfileMenu: FC = () => {
  const isCheckingToken =
    useAppSelector(selectVerifyTokenRequestStatus) === 'started';
  const isLoggedIn = useAppSelector(selectLoginStatus);
  const businessInfo = useAppSelector(selectBusinessInfo);
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const loginData = useAppSelector(selectLoginData);
  const hasCustomerRole = loginData && isCustomer(loginData.user_role);
  const hasProviderRole = loginData && isProvider(loginData.user_role);

  // @ts-ignore
  const handleClick = (info: MenuInfo) => {
    if (info.key === 'login') {
      navigate(ROUTES.PATHS.LOGIN);
    }
    if (info.key === 'signup') {
      navigate(ROUTES.PATHS.SIGN_UP);
    }
  };

  const menu = (
    <Menu onClick={handleClick}>
      {/* <Menu.Item key="learnMore">Learn More</Menu.Item>
      <Menu.Divider /> */}
      <Menu.Item key="login">Log in</Menu.Item>
      <Menu.Divider />
      <Menu.Item key="signup">Sign Up</Menu.Item>
    </Menu>
  );

  if (isCheckingToken) {
    return <Spin spinning={true} size="large"></Spin>;
  } else {
    return (
      <div
        className={classNames({
          [styles.container]: true,
          [styles['is-mobile']]: isMobile,
        })}
      >
        {!isMobile && !isLoggedIn && (
          <div
            className={classNames({
              [styles.btnContainer]: true,
            })}
          >
            {/* <div className={styles.learnMore}>
              <b>Learn More</b>
            </div> */}
            {!isLoggedIn && (
              <>
                <div
                  className={styles.login}
                  onClick={() => navigate(ROUTES.PATHS.LOGIN)}
                >
                  <b>Log in</b>
                </div>
                <div
                  className={styles.login}
                  onClick={() => navigate(ROUTES.PATHS.SIGN_UP)}
                >
                  <b>Sign up</b>
                </div>
              </>
            )}
          </div>
        )}

        {isMobile && !isLoggedIn && (
          <Dropdown overlay={menu} trigger={['click']}>
            <div
              className={classNames({
                [styles.container]: true,
                [styles['is-mobile']]: isMobile,
              })}
              onClick={() => {}}
            >
              <div className={styles.downIcon}>
                <MenuOutlined />
              </div>
            </div>
          </Dropdown>
        )}

        {isLoggedIn && (
          <>
            {hasCustomerRole && !hasProviderRole && !loginData?.is_onboarding_completed &&(
              <Button
                isBordered={true}
                className={styles.btnStyle}
                onClick={() =>
                  businessInfo.business_name
                    ? navigate(ROUTES.PATHS.USER.USER_ACCOUNT_POINT_DOWNLOAD)
                    : navigate(
                        ROUTES.PATHS.USER.USER_ACCOUNT_POINT_BUSINESS_INFO
                      )
                }
              >
                {businessInfo.business_name ? (
                  <b>Set Up Payments</b>
                ) : (
                  <b>Become a Host</b>
                )}
              </Button>
            )}
          </>
        )}
        {isLoggedIn && <Profile isLoggedIn={isLoggedIn} />}
      </div>
    );
  }
};

export default ProfileMenu;
