import { Rule } from "antd/lib/form";
import { NavigationMenuItem } from "components/SideNavigation/types";
import { ROUTES } from "shared/routes/constants";

import { stringValidator } from "./helpers";

export const VEHICLE_TYPES = [
  { name: "Sedan", value: 1 },
  { name: "Convertibles", value: 2 },
  { name: "Sports", value: 3 },
  { name: "Suv", value: 4 },
];

export const INSURANCE_COMPANY = [{ name: "X Company", value: 1 }];

export const COLORS = [
  { name: "Red", value: "red" },
  { name: "Blue", value: "blue" },
  { name: "White", value: "white" },
];

export const VEHICLE_TYPE_RULES: Rule[] = [
  {
    required: true,
    message: "Vehicle type is required",
    validator: stringValidator,
  },
];

export const getVehicleSettingsSidebarMenu = (
  vehicleId: string | ""
): NavigationMenuItem[] => {
  // @ts-ignore
  const isNotNumber = isNaN(vehicleId);
  return [
    {
      title: "Basic info",
      link: ROUTES.PROVIDER_VEHICLES_SETTINGS_BASIC_INFO_ID(vehicleId),
      routeName: "basic-info",
      isDisabled: false,
    },
    {
      title: "Car Details",
      link: ROUTES.PROVIDER_VEHICLES_SETTINGS_DETAILS_ID(vehicleId),
      routeName: "car-details",
      isDisabled: isNotNumber,
    },
    {
      title: "Pricing",
      link: ROUTES.PROVIDER_VEHICLES_SETTINGS_PRICING_ID(vehicleId),
      routeName: "pricing",
      isDisabled: isNotNumber,
    },
    {
      title: "Photos",
      link: ROUTES.PROVIDER_VEHICLES_SETTINGS_PHOTOS_ID(vehicleId),
      routeName: "photos",
      isDisabled: isNotNumber,
    },
    {
      title: "Documents",
      link: ROUTES.PROVIDER_VEHICLES_SETTINGS_DOCUMENTS_ID(vehicleId),
      routeName: "documents",
      isDisabled: isNotNumber,
    },
    {
      title: "Protection",
      link: ROUTES.PROVIDER_VEHICLES_SETTINGS_PROTECTION_ID(vehicleId),
      routeName: "protection",
      isDisabled: isNotNumber,
    },
  ];
};

export const TRANSMISSION_TYPE = {
  MANUAL: "MANUAL",
  AUTOMATIC: "AUTOMATIC",
};

export const DISCOUNT_TYPE = {
  PERCENTAGE: "PERCENTAGE",
  FIXED: "FIXED_AMOUNT",
};

export const INSURANCE_TYPE = {
  OWNER: "OWNER_PROVIDED",
  ONE_GO: "ONE_GO_OFFERED",
};
