import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { RightOutlined } from '@ant-design/icons';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';

import { Spin, Form, Title, FormItem, useForm } from 'components/misc/helpers';
import Input from 'components/Input';
import SelectInput from 'components/SelectInput';
import RadioButton from 'components/RadioButton';
import Button from 'components/Button';
import { useUnwrapAsyncThunk } from 'store/hooks';
import {
  getVehicleTypesAsync,
  getVehicleMakeAsync,
  getVehicleModelAsync,
} from 'store/provider/vehicles/actions';

import {
  useVehicleBasicInfoCreate,
  useVehicleInfoSelectors,
  useUpdateVehicleBasicInfo,
  useBasicInfoActions,
} from './hooks';
import { useGetVehicleDetails } from '../hooks';

import TopSection from '../TopSection';
import LeftSection from '../LeftSection';

import { VEHICLE_TYPE_RULES, TRANSMISSION_TYPE } from '../constants';
import styles from '../vehicles.module.scss';
import { SelectOption } from 'components/SelectInput/types';

const BasicInfo: FC = () => {
  const { vehicleId } = useParams();
  const [form] = useForm();
  const unwrap = useUnwrapAsyncThunk();

  const createVehicle = useVehicleBasicInfoCreate();
  const updateVehicle = useUpdateVehicleBasicInfo();
  const { resetBasicInfo } = useBasicInfoActions();

  const {
    basicInfo,
    postBasicInfoRequestStatus,
    getVehicleDetailsRequestStatus,
    vehicleTypes,
    vehicleModels,
    vehicleMakes,
  } = useVehicleInfoSelectors();

  const getVehicleDetails = useGetVehicleDetails();

  const [makeId, setMakeId] = useState('');
  const [makes, setMakes] = useState<SelectOption[]>([]);
  const [models, setModels] = useState<SelectOption[]>([]);

  const isLoading =
    postBasicInfoRequestStatus === 'started' ||
    getVehicleDetailsRequestStatus === 'started';

  const handleMakeSearch = (searchText: string) => {
    if (isEmpty(searchText)) {
      setMakes(vehicleMakes);
    } else {
      setMakes(
        filter(vehicleMakes, (make) =>
          make.name.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    }
  };

  const handleModelSearch = (searchText: string) => {
    if (isEmpty(searchText)) {
      setModels(vehicleModels);
    } else {
      setModels(
        filter(vehicleModels, (model) =>
          model.name.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    }
  };

  useEffect(() => {
    unwrap(getVehicleTypesAsync());
    unwrap(getVehicleMakeAsync());

    if (vehicleId && vehicleId !== 'new') {
      getVehicleDetails(Number(vehicleId));
    } else {
      resetBasicInfo();
    }
  }, []);

  useEffect(() => {
    form.resetFields();
    const {
      vehicle_type_id,
      vehicle_make_id,
      vehicle_model_id,
      ...others
    } = basicInfo;
    form.setFieldsValue({
      ...others,
      vehicle_type: vehicle_type_id,
      vehicle_make: vehicle_make_id,
      vehicle_model: vehicle_model_id,
    });
    setMakeId(vehicle_make_id?.toString() || '');
  }, [basicInfo]);

  useEffect(() => {
    if (makeId) {
      unwrap(getVehicleModelAsync({ makeId }));
    }
  }, [makeId]);

  useEffect(() => {
    setModels(vehicleModels);
  }, [vehicleModels]);

  useEffect(() => {
    setMakes(vehicleMakes);
  }, [vehicleMakes]);

  const isDisable = basicInfo.status !== 'INACTIVE';

  return (
    <div
      className={classNames({
        [styles.container]: true,
      })}
    >
      <TopSection />
      <div className={styles.vehicle}>
        <LeftSection />
        <div
          className={classNames({
            [styles.formContainer]: true,
          })}
        >
          <Spin spinning={isLoading}>
            <Title level={2}>Basic Information</Title>
            <Form
              form={form}
              className={styles.form}
              initialValues={basicInfo}
              onFinish={(values) => {
                values.drop_off_address = values.pickup_address;
                if (vehicleId && vehicleId === 'new') {
                  createVehicle(values);
                } else {
                  updateVehicle(values, Number(vehicleId));
                }
              }}
            >
              <FormItem name={'vehicle_type'} rules={[...VEHICLE_TYPE_RULES]}>
                <SelectInput
                  disabled={isDisable}
                  title="Vehicle type"
                  options={vehicleTypes}
                  value={basicInfo.vehicle_type_id?.toString()}
                />
              </FormItem>
              <FormItem
                name={'vehicle_make'}
                rules={[{ required: true, message: 'Make is required' }]}
              >
                <SelectInput
                  disabled={isDisable}
                  title="Make"
                  options={makes}
                  value={basicInfo.vehicle_make_id?.toString()}
                  showSearch
                  filterOption={false}
                  onSearch={handleMakeSearch}
                  onSelect={(value) => {
                    setMakeId(value);
                  }}
                />
              </FormItem>
              <FormItem
                name={'vehicle_model'}
                rules={[{ required: true, message: 'Model is required' }]}
              >
                <SelectInput
                  disabled={isDisable}
                  title="Model"
                  options={models}
                  value={basicInfo.vehicle_model?.toString()}
                  showSearch
                  filterOption={false}
                  onSearch={handleModelSearch}
                />
              </FormItem>
              <FormItem
                name={'vehicle_year'}
                rules={[{ required: true, message: 'Year is required' }]}
              >
                <Input label="Year" disabled={isDisable} />
              </FormItem>
              <FormItem
                name={'pickup_address'}
                rules={[
                  {
                    required: true,
                    message: 'Pickup and Drop off address is required',
                  },
                ]}
              >
                <Input
                  label="Pickup And Drop off Address"
                  disabled={isDisable}
                />
              </FormItem>
              <FormItem name={'vehicle_transmission'}>
                <RadioButton
                  disabled={isDisable}
                  title="Transmission"
                  options={[
                    { label: 'Automatic', value: TRANSMISSION_TYPE.AUTOMATIC },
                    { label: 'Manual', value: TRANSMISSION_TYPE.MANUAL },
                  ]}
                  defaultValue={basicInfo.vehicle_transmission}
                />
              </FormItem>
              <FormItem
                name={'license_no'}
                rules={[{ required: true, message: 'License is required' }]}
              >
                <Input label="License Plate No." disabled={isDisable} />
              </FormItem>
              <FormItem
                name={'color'}
                rules={[{ required: true, message: 'Color is required' }]}
              >
                <Input label="Color" disabled={isDisable} />
              </FormItem>
              {!isDisable && (
                <Button
                  className={styles.button}
                  type="default"
                  isBordered={true}
                  htmlType="submit"
                  disabled={isDisable}
                >
                  <div className={styles.textContainer}>
                    <span className={styles.buttonText}>Save and Continue</span>
                    <RightOutlined className={styles.rightIcon} />
                  </div>
                </Button>
              )}
            </Form>
          </Spin>
        </div>
      </div>
    </div>
  );
};

export default BasicInfo;
