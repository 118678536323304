import React, { FC, useState } from 'react';

import IXTable from 'components/tables/IXTable';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getTicketsAsync, setCurrentPage } from 'store/admin/tickets/actions';
import TableHeader from 'uicontainers/_shared/TableHeader';

import { COLUMNS } from './constants';
import {
  createSelectTicketTableData,
  selectGetTicketsRequestStatus,
  selectCurrentPage,
  selectTotalCount,
} from './selectors';
import { Spin } from 'antd';

const Tickets: FC = () => {
  const [searchValue, setSearchValue] = useState('');
  const [selectedValue, setSelectedValue] = useState('');
  const totalCount = useAppSelector(selectTotalCount);
  const currentPage = useAppSelector(selectCurrentPage);
  const ticketsTableData = useAppSelector(createSelectTicketTableData);
  const isTicketsLoading = useAppSelector(selectGetTicketsRequestStatus) === 'started';

  const dispatch = useAppDispatch();
  const options = [
    { name: 'Paid', value: 'PAID' },
    { name: 'Unpaid', value: 'UNPAID' },
    { name: 'Contested', value: 'CONTESTED' },
  ];

  React.useEffect(() => {
    let searchParams = {};
    if (searchValue) {
      searchParams = { ...searchParams, search: searchValue };
    }
    if (selectedValue) {
      searchParams = { ...searchParams, status: selectedValue };
    }
    if (currentPage) {
      searchParams = { ...searchParams, page: currentPage };
    }
    dispatch(getTicketsAsync(searchParams));
  }, [searchValue, selectedValue, currentPage, getTicketsAsync]);

  const onChange = (page: number) => {
    dispatch(setCurrentPage(page));
  };

  const handleSearch = (value: string) => {
    setSearchValue(value);
  };

  const handleSelect = (value: string) => {
    setSelectedValue(value);
  };

  return (
    <Spin spinning={isTicketsLoading}>
      <TableHeader
        title=""
        onChange={() => {}}
        onSearch={handleSearch}
        onSelectChange={handleSelect}
        options={options}
        dropdownTitle="Status"
        // extraPlaceholder="Vehicle"
      />
      <IXTable
        data={ticketsTableData}
        columns={COLUMNS}
        pagination={{
          currentPage: currentPage,
          count: totalCount,
          onChange: onChange,
        }}
      />
    </Spin>
  );
};

export default Tickets;
