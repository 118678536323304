import React from 'react';
import { Paragraph, Title } from 'components/misc/helpers';


function ManageCar() {
  return (
    <div>
      <Title level={3}>A. Getting started as an owner</Title>
      <ol>
        <li>
          <b>1. How to set up your car for car sharing</b>
        </li>
      </ol>
      <Paragraph>Your Instant Keys kit:</Paragraph>
      <Paragraph>
        Your Instant Keys kit contains everything you need to get your car set
        up for sharing. Setting up the kit is simple and should take less than
        an hour.
      </Paragraph>
      <Paragraph>Your Instant Keys kit contains:</Paragraph>
      <ul>
        <li>Stickers</li>
        <li>A GPS device</li>
        <li>An extension cable</li>
        <li>A toll tag in a bag</li>
        <li>A lockbox and shackle</li>
        <li>
          A window bracket for the lockbox (if the lockbox will be kept on the
          car window)
        </li>
      </ul>
      <Paragraph>Let's get started:</Paragraph>
      <Paragraph>
        If you’re setting up the kit, it’s best to follow our step-by-step guide
        in your car’s profile. Go to Cars {'>'}Manage this car {'>'} Listing{' '}
        {'>'}
        Instant Keys.
      </Paragraph>
      <Paragraph>
        If{' '}
        <a
          href="https://support.carnextdoor.com.au/hc/en-gb/articles/5012421969305"
          target="_blank"
          rel="noreferrer"
        >
          someone else is setting up your kit
        </a>{' '}
        or if you’d like to print out the instructions instead, you can{' '}
        <a
          href="https://support.carnextdoor.com.au/hc/en-gb/article_attachments/5020704122137/Your_Instant_Keys_kit_instructions.pdf"
          target="_blank"
          rel="noreferrer"
        >
          download a copy
        </a>{' '}
        of the instructions.
      </Paragraph>

      <Title level={3} id="discount">
        B. Peak rates and multi-day discounts
      </Title>
      <ol>
        <li>
          <b>1. Peak rates and days:</b>
        </li>
      </ol>
      <Paragraph>
        Your car has base rates and peak rates. Borrowers will be charged peak
        rates for any days you set as peak days, and base rates for all other
        days.
      </Paragraph>
      <Paragraph>
        You can select the days of the week you want to charge your peak rates.
        We recommend selecting Saturday and Sunday, but there may be other days
        of the week you’d like your peak rates to apply.
      </Paragraph>
      <Paragraph>
        You can also select to charge peak rates for holiday periods. Holiday
        periods are days set by Jaunt as holidays in your state, based on long
        weekends and peak hiring periods.
      </Paragraph>
      <Paragraph>
        If you only want peak rates for holiday periods, enable and set your
        peak rates, deselect all of the days of the week, and select 'apply to
        holiday periods'.
      </Paragraph>
      <Paragraph>Multi-day discounts:</Paragraph>
      <Paragraph>
        To encourage longer bookings, offer a discount for bookings lasting 3
        days or more, or 12 days or more. The discount will be applied to the
        total time charge the Borrower pays.
      </Paragraph>
      <Paragraph>
        A Multi-day booking will generally be more valuable than a couple of
        shorter bookings in the same period, and usually require less effort to
        service as only one person is using the car.
      </Paragraph>
      <Paragraph>Choosing your rates and discounts:</Paragraph>
      <Paragraph>
        Use our recommended rates to set or guide your car’s base and peak
        rates. Our recommendations are based on an algorithm that looks at
        income data for cars similar to yours, taking into account a range of
        factors including your car’s body type, location, age, supply, and
        demand. The algorithm is designed to help you maximize your income, but
        they’re just a guide and you may like to experiment to find the best
        rates for your car and the types of bookings you’d like to get.
      </Paragraph>
      <Paragraph>
        Our recommended multi-day discounts are designed to make multi-day
        bookings more attractive without reducing your income too much. You can
        experiment with your discount to find the right balance for your car.
      </Paragraph>
      <Paragraph>
        Both peak rates and multi-day discounts can be applied at the same time.
        The full cost of the time for a trip is calculated first (including any
        peak days or hours) and then the multi-day discount is applied to the
        total.
      </Paragraph>
      <Paragraph>
        Peak rates and multi-day discounts only apply to your car’s time rates.
        Distance rates remain the same for every booking.
      </Paragraph>

      <Title level={3}>C. Troubleshooting</Title>
      <ol>
        <li>
          <b>
            1. What happens if a Borrower has a problem during their booking
          </b>
        </li>
      </ol>
      <Paragraph>
        If a Borrower has a small question that's specific to your car, they
        will contact you (within reasonable hours).
      </Paragraph>
      <Paragraph>
        For major things - like if the car breaks down, or if they are having
        trouble using the system, or if it's too late at night to contact you -
        they'll call our 24-hour member support hotline.
      </Paragraph>
    </div>
  );
}

export default ManageCar;
