import { BaseResponse } from 'api/base/types';

export interface ForgotPasswordPostData {
  email: string;
}
export interface LoginPostData {
  email: string;
  password: string;
}
export interface SignupPostData {
  first_name: string;
  last_name: string;
  email: string;
  phone_no: string;
  password: string;
  country: string;
  city: string;
  state: string[];
  zip: string;
  user_role: number[];
}

export enum ROLE {
  ADMIN = 'ADMIN',
  PROVIDER = 'PROVIDER',
  CUSTOMER = 'CUSTOMER',
}

export interface UserRole {
  id: number;
  user_role: ROLE;
}

export interface AuthenticationData {
  refresh: string;
  access: string;
  email: string;
  id: string;
  first_name: string;
  last_name: string;
  user_role: UserRole[];
  is_onboarding_completed: boolean;
}
export interface LoginResponse extends BaseResponse<AuthenticationData> {}
export interface PostLogin {
  (loginPostData: LoginPostData | SignupPostData): Promise<LoginResponse>;
}

export interface PasswordResetResponse {}
export interface PostPasswordReset {
  (email: string): Promise<PasswordResetResponse>;
}
export interface AccountActivationData {
  securityCode: string | null;
  token: string | null;
  password?: string;
}

export interface ActivateAccountResponse {}
export interface ActivateAccount {
  (accountActivationData: AccountActivationData): Promise<
    ActivateAccountResponse
  >;
}

export interface TokenVerifyResponse extends BaseResponse<AuthenticationData> {}
export interface PostTokenVerify {
  (token: string): Promise<TokenVerifyResponse>;
}

export enum USER_ROLE {
  ADMIN = 1,
  PROVIDER = 2,
  CUSTOMER = 3,
}

export interface StripeAccountLink {
  url: string;
}

export interface StripeAccountLinkResponse extends BaseResponse<StripeAccountLink> {}

export interface GetStripeOnboardingLink {
  (): Promise<StripeAccountLinkResponse>;
}

export interface AcknowledgeOnboard {
  (): Promise<void>;
}
