import { Col, Row, Space } from "antd";
import Text from "antd/lib/typography/Text";
import Title from "antd/lib/typography/Title";
import classNames from "classnames";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import Button from "components/Button";
import { useIsMobile } from "shared/hooks";
import { ROUTES } from "shared/routes/constants";

import styles from "./confirmation.module.scss";

const Home = () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { subscriptionId } = useParams();
 
  React.useEffect(() => {
    // if (!searchParams && !bookingInfo) {
    //   navigate(ROUTES.PATHS.ROOT);
    // }
  }, []);

  const handleConfirm = () => {
    navigate(ROUTES.PATHS.USER.USER_PROFILE);
    localStorage.removeItem("search_params");
    localStorage.removeItem("booking_info");
  };

  return (
    <div
      className={classNames({
        [styles.checkout]: true,
        [styles["is-mobile"]]: isMobile,
      })}
    >
      <Row>
        <Col lg={16} xs={24}>
          <Space direction="vertical" size={50}>
            <div>
              <Title className={styles.headerText} level={2}>
                Congratulations! your booking has been confirmed
              </Title>
              <Text className={styles.text}>
                You should get a confirmation email with the booking details.
              </Text>
              <Title level={4}>Trip Reservation Code: {subscriptionId}</Title>
              {/* <TextLink className={styles.linkWrapper} onClick={() => {}}>
                <span className={styles.link}>View Receipt</span>
              </TextLink> */}

              <Text className={styles.text}>
                You can find the pickup/return instructions and other
                information related to your trip including the contract details
                of the host from trip details page in your account.
              </Text>
            </div>
            <Col className="gutter-row" lg={10} xs={24}>
              <Button
                type="ghost"
                isBordered={true}
                style={{ width: "100%" }}
                htmlType="submit"
                onClick={handleConfirm}
              >
                Go to My Account
              </Button>
            </Col>
          </Space>
        </Col>
        <Col lg={8} xs={24}>
          {/* <Card
            className={classNames({
              [styles.card]: true,
              [styles["is-mobile"]]: isMobile,
            })}
            image={bookingInfo?.featured_image}
            title={title}
            description={description}
          /> */}
        </Col>
      </Row>
    </div>
  );
};

export default Home;
