import { EventInput } from '@fullcalendar/react';
import { Calendar } from 'api/provider/calendars/types';

export const transformCalendarToEvents = (calendars: Calendar[]): EventInput[] => {
  return calendars.map((calendar) => {
    return {
      id: calendar.id.toString(),
      title: calendar.vehicle_name,
      start: calendar.start_time,
      end: calendar.end_time,
      extendedProps: {
        renter: '',
        price: calendar.daily_price,
        image: calendar.image,
        unit: 'day',
        //currency: '$',
      },
    };
  });
};
