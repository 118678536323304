import React, { FC, useState } from 'react';

import IXTable from 'components/tables/IXTable';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getCustomersAsync, setCurrentPage } from 'store/admin/customers/actions';
import TableHeader from 'uicontainers/_shared/TableHeader';

import { COLUMNS } from './constants';
import styles from './customer.module.scss';
import {
  createSelectCustomerTableData,
  selectCustomersRequestStatus,
  selectCurrentPage,
  selectTotalCount,
} from './selectors';
import { Spin } from 'antd';

const Customers: FC = () => {
  const [searchValue, setSearchValue] = useState('');
  const [selectedValue, setSelectedValue] = useState('');

  const vehiclesTableData = useAppSelector(createSelectCustomerTableData);
  const isCustomersLoading = useAppSelector(selectCustomersRequestStatus) === 'started';
  const totalCount = useAppSelector(selectTotalCount);
  const currentPage = useAppSelector(selectCurrentPage);
  const dispatch = useAppDispatch();

  const options = [
    { name: 'Active', value: 'True' },
    { name: 'Inactive', value: 'False' },
  ];

  React.useEffect(() => {
    let searchParams = {};
    if (searchValue) {
      searchParams = { ...searchParams, search: searchValue };
    }
    if (selectedValue) {
      searchParams = { ...searchParams, status: selectedValue };
    }
    if (currentPage) {
      searchParams = { ...searchParams, page: currentPage };
    }
    dispatch(getCustomersAsync(searchParams));
  }, [searchValue, selectedValue, currentPage, getCustomersAsync]);

  const onChange = (page: number) => {
    dispatch(setCurrentPage(page));
  };
  const handleSearch = (value: string) => {
    setSearchValue(value);
    dispatch(setCurrentPage(1));
  };
  const handleSelect = (value: string) => {
    setSelectedValue(value);
    dispatch(setCurrentPage(1));
  };

  return (
    <div className={styles.root}>
      <Spin spinning={isCustomersLoading}>
        <TableHeader
          title="Customers"
          onSearch={handleSearch}
          onSelectChange={handleSelect}
          dropdownTitle="Status"
          options={options}
        />
        <IXTable
          data={vehiclesTableData}
          columns={COLUMNS}
          pagination={{ currentPage: currentPage, count: totalCount, onChange }}
        />
      </Spin>
    </div>
  );
};

export default Customers;
