// noinspection DuplicatedCode

import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';

import { Row, Col } from 'antd';
import classNames from 'classnames';

import SideNavigation from 'components/SideNavigation';
import { Title } from 'components/misc/helpers';
import { useAppSelector, useUnwrapAsyncThunk } from 'store/hooks';
import { getProfileAsync } from 'store/customer/account/actions';

import styles from './account.module.scss';
import { sidebarMenu } from './constants';
import { selectLoginData } from 'store/shared/auth/selectors';
import { hasRole } from 'pages/_shared/helpers';
import { selectBusinessInfo } from '../BusinessInfo/selectors';
import { NavigationMenuItem } from 'components/SideNavigation/types';

const Account = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userData = useAppSelector(selectLoginData);
  const businessInfo = useAppSelector(selectBusinessInfo);
  const [allowableMenuItems, setAllowableMenuItems] = useState<
    NavigationMenuItem[] | undefined
  >([]);

  const unwrap = useUnwrapAsyncThunk();

  const lastItem = location.pathname.substring(
    location.pathname.lastIndexOf('/') + 1
  );

  const found = sidebarMenu.find((item) => item.routeName === lastItem);
  const [activeTab, setActiveTab] = useState<string | undefined>('');

  const onClick = (link: string) => {
    navigate(link);
  };

  useEffect(() => {
    setActiveTab(found?.title);
  }, [found]);

  useEffect(() => {
    if (businessInfo.business_name) {
      setAllowableMenuItems(
        userData &&
          sidebarMenu.filter(
            (item) => item.roles && hasRole(item.roles, userData.user_role)
          )
      );
    } else {
      setAllowableMenuItems(
        userData &&
          sidebarMenu.filter(
            (item) =>
              item.roles &&
              hasRole(item.roles, userData.user_role) &&
              item.title !== 'Payment'
          )
      );
    }
  }, [businessInfo]);

  useEffect(() => {
    unwrap(getProfileAsync());
  }, []);

  return (
    <>
      <div
        className={classNames({
          [styles.container]: true,
        })}
      >
        <div className={styles.titleContainer}>
          <Title className={styles.title} level={3}>
            Account
          </Title>
        </div>
        <div>
          <Row gutter={[0, 16]} align="top">
            <Col lg={6}>
              <SideNavigation
                className={styles.sideBar}
                onClick={onClick}
                menuItems={allowableMenuItems}
                selectedTab={activeTab}
              />
            </Col>
            <Col lg={18}>
              <div
                className={classNames({
                  [styles.formContainer]: true,
                })}
              >
                <Outlet />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Account;
