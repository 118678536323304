import { createAsyncThunk } from '@reduxjs/toolkit';

import { getBillings } from 'api/admin/billing';
import { BillingRequestParams } from 'api/admin/billing/types';

export const getBillingsAsync = createAsyncThunk(
  'admin/billing/get_billings',
  async (params?: BillingRequestParams) => {
    const response = await getBillings(params);
    return response;
  },
);
