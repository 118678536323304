import { createSlice } from '@reduxjs/toolkit';

import {
  updateAccountAsync,
  getProfileAsync,
  uploadDataAsync,
} from 'store/customer/account/actions';
import { isPendingActionMatcher, isFulfilledActionMatcher } from 'store/matchers';
import { PointIdentificationState } from './types';
import { CUSTOMER_ACCOUNT_POINT_IDENTIFICATION } from './constants';

const initialState: PointIdentificationState = {
  status: 'initial',
  user_documents: null,
};

export const pointIdentificationSlice = createSlice({
  name: CUSTOMER_ACCOUNT_POINT_IDENTIFICATION,
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(updateAccountAsync.fulfilled, (state, action) => {
        state.user_documents = action.payload.user_documents;
      })

      .addCase(updateAccountAsync.rejected, (state) => {
        state.status = 'failed';
      })

      .addCase(getProfileAsync.fulfilled, (state, action) => {
        state.user_documents = action.payload.results[0].user_documents;
      })

      .addCase(getProfileAsync.rejected, (state) => {
        state.status = 'failed';
      })

      .addCase(uploadDataAsync.fulfilled, (state, action) => {
        state.user_documents = action.payload.user_documents;
      })

      .addCase(uploadDataAsync.rejected, (state) => {
        state.status = 'failed';
      })

      .addMatcher(isPendingActionMatcher, (state) => {
        state.status = 'started';
      })
      .addMatcher(isFulfilledActionMatcher, (state) => {
        state.status = 'done';
      }),
});

export default pointIdentificationSlice.reducer;
