import { createAsyncThunk } from '@reduxjs/toolkit';
import { getTripHistories } from 'api/customer/tripHistory';
import { TripHistoryRequestParams } from 'api/customer/tripHistory/types';

export const getTripHistoryAsync = createAsyncThunk(
  'customer/bookings',
  async (params?: TripHistoryRequestParams) => {
    const response = await getTripHistories(params);
    return response;
  },
);
