// Public routes
const ROOT = '/';
const LOGIN = '/login';
const SIGN_UP = '/sign-up';
const ACTIVATE_ACCOUNT = '/activate-account';
const FORGOT_PASSWORD = '/forgot-password';
const RESET_PASSWORD = '/reset-password';
const SEARCH = '/search';
const VEHICLE_DETAILS = '/vehicles/:vehicleId';
const PROVIDER_INFO = '/provider/:providerId';
const FAQ_DETAILS = '/faq-details/tabs/:key';
const PRIVACY_POLICY = 'privacy-policy';
const TERMS_AND_CONDITIONS = 'terms-and-conditions';

// Private routes

// Provider routes
const PROVIDER_HOME = `/provider`;
const PROVIDER_DASHBOARD = `${PROVIDER_HOME}/dashboard`;
const PROVIDER_CALENDAR = `${PROVIDER_HOME}/calendar`;
const PROVIDER_VEHICLES = `${PROVIDER_HOME}/vehicles`;
const PROVIDER_VEHICLES_SETTINGS = `${PROVIDER_HOME}/vehicles/:vehicleId`;
const PROVIDER_VEHICLES_SETTINGS_BASIC_INFO = `${PROVIDER_VEHICLES_SETTINGS}/basic-info`;
const PROVIDER_VEHICLES_SETTINGS_DETAILS = `${PROVIDER_VEHICLES_SETTINGS}/car-details`;
const PROVIDER_VEHICLES_SETTINGS_PRICING = `${PROVIDER_VEHICLES_SETTINGS}/pricing`;
const PROVIDER_VEHICLES_SETTINGS_PHOTOS = `${PROVIDER_VEHICLES_SETTINGS}/photos`;
const PROVIDER_VEHICLES_SETTINGS_DOCUMENTS = `${PROVIDER_VEHICLES_SETTINGS}/documents`;
const PROVIDER_VEHICLES_SETTINGS_PROTECTION = `${PROVIDER_VEHICLES_SETTINGS}/protection`;
const PROVIDER_CUSTOMERS = `${PROVIDER_HOME}/customers`;
const PROVIDER_TRIP_HISTORY = `${PROVIDER_HOME}/trip-history`;
const PROVIDER_BILLING = `${PROVIDER_HOME}/billing`;
const PROVIDER_TOLL_AND_TICKETS = `${PROVIDER_HOME}/toll-and-tickets`;
const PROVIDER_RENTAL_AGREEMENT = `${PROVIDER_HOME}/rental-agreement`;
const PROVIDER_RENTAL_AGREEMENT_TERMS_AND_CONDITIONS = `${PROVIDER_RENTAL_AGREEMENT}/terms-and-conditions`;
const PROVIDER_RENTAL_AGREEMENT_INSTRUCTIONS = `${PROVIDER_RENTAL_AGREEMENT}/instructions`;
const PROVIDER_RENTAL_AGREEMENT_RENTAL_CONTRACT = `${PROVIDER_RENTAL_AGREEMENT}/rental-contract`;

// Customer routes
const CUSTOMER_HOME = `/customer`;
const CUSTOMER_BOOKINGS = `${CUSTOMER_HOME}/bookings`;
const CUSTOMER_BOOKING_DETAILS = `${CUSTOMER_HOME}/bookings/:bookingId`;

const CUSTOMER_CREATE_BOOKING = `${CUSTOMER_HOME}/booking/vehicle`;
const CUSTOMER_CREATE_BOOKING_VEHICLE = `${CUSTOMER_HOME}/booking/vehicle/:vehicleId`;
const CUSTOMER_CREATE_BOOKING_VEHICLE_CHECKOUT = `${CUSTOMER_CREATE_BOOKING_VEHICLE}/checkout/:tripStartDate/:tripEndDate`;
const CUSTOMER_CREATE_BOOKING_VEHICLE_CONFIRMATION = `${CUSTOMER_CREATE_BOOKING_VEHICLE}/confirmation/:subscriptionId`;

const CUSTOMER_TRIP_HISTORY = `${CUSTOMER_HOME}/trip-history`;
const CUSTOMER_TOLL_AND_TICKETS = `${CUSTOMER_HOME}/toll-and-tickets`;

// Super Admin routes
const ADMIN_HOME = '/admin';
const ADMIN_DASHBOARD = `${ADMIN_HOME}/dashboard`;
const ADMIN_VEHICLES = `${ADMIN_HOME}/vehicles`;
const ADMIN_VEHICLE_DETAILS = `${ADMIN_HOME}/vehicles/:vehicleId`;
const ADMIN_PROVIDERS = `${ADMIN_HOME}/providers`;
const ADMIN_CUSTOMERS = `${ADMIN_HOME}/customers`;
const ADMIN_TRIP_HISTORY = `${ADMIN_HOME}/trip-history`;
const ADMIN_BILLING = `${ADMIN_HOME}/billing`;
const ADMIN_TOLL_AND_TICKETS = `${ADMIN_HOME}/toll-and-tickets`;
const ADMIN_RENTAL_AGREEMENT = `${ADMIN_HOME}/rental-agreement`;
const ADMIN_RENTAL_AGREEMENT_TERMS_AND_CONDITIONS = `${ADMIN_RENTAL_AGREEMENT}/terms-and-conditions`;
const ADMIN_RENTAL_AGREEMENT_INSTRUCTIONS = `${ADMIN_RENTAL_AGREEMENT}/instructions`;

// User routes
const USER_HOME = `/user`;
const USER_PROFILE = `${USER_HOME}/profile`;
const USER_ACCOUNT = `${USER_HOME}/account`;
const USER_ACCOUNT_PERSONAL_INFO = `${USER_ACCOUNT}/personal-info`;
const USER_ACCOUNT_CHANGE_PASSWORD = `${USER_ACCOUNT}/change-password`;
const USER_ACCOUNT_POINT_IDENTIFICATION = `${USER_ACCOUNT}/point-identification`;
const USER_ACCOUNT_POINT_BUSINESS_INFO = `${USER_ACCOUNT}/business-info`;
const USER_ACCOUNT_POINT_DOWNLOAD = `${USER_ACCOUNT}/payment`;
const USER_SETUP_PAYMENT = `${USER_ACCOUNT}/setup-payment`;
const USER_STRIPE_RE_AUTH = `${USER_ACCOUNT}/stripe-re-auth`;
const USER_STRIPE_RETURN = `${USER_ACCOUNT}/stripe-return`;

export const ROUTES = {
  PATHS: {
    ROOT,
    LOGIN,
    SIGN_UP,
    ACTIVATE_ACCOUNT,
    FORGOT_PASSWORD,
    RESET_PASSWORD,
    FAQ_DETAILS,
    PRIVACY_POLICY,
    TERMS_AND_CONDITIONS,
    SEARCH,
    VEHICLE_DETAILS,
    PROVIDER_INFO,
    PROVIDER: {
      PROVIDER_HOME,
      PROVIDER_DASHBOARD,
      PROVIDER_CALENDAR,
      PROVIDER_VEHICLES,
      PROVIDER_VEHICLES_SETTINGS,
      PROVIDER_VEHICLES_SETTINGS_BASIC_INFO,
      PROVIDER_VEHICLES_SETTINGS_DETAILS,
      PROVIDER_VEHICLES_SETTINGS_PRICING,
      PROVIDER_VEHICLES_SETTINGS_PHOTOS,
      PROVIDER_VEHICLES_SETTINGS_DOCUMENTS,
      PROVIDER_VEHICLES_SETTINGS_PROTECTION,
      PROVIDER_CUSTOMERS,
      PROVIDER_TRIP_HISTORY,
      PROVIDER_BILLING,
      PROVIDER_TOLL_AND_TICKETS,
      PROVIDER_RENTAL_AGREEMENT,
      PROVIDER_RENTAL_AGREEMENT_TERMS_AND_CONDITIONS,
      PROVIDER_RENTAL_AGREEMENT_INSTRUCTIONS,
      PROVIDER_RENTAL_AGREEMENT_RENTAL_CONTRACT,
    },
    CUSTOMER: {
      CUSTOMER_HOME,
      CUSTOMER_BOOKINGS,
      CUSTOMER_BOOKING_DETAILS,
      CUSTOMER_CREATE_BOOKING,
      CUSTOMER_CREATE_BOOKING_VEHICLE,
      CUSTOMER_CREATE_BOOKING_VEHICLE_CHECKOUT,
      CUSTOMER_CREATE_BOOKING_VEHICLE_CONFIRMATION,
      CUSTOMER_TRIP_HISTORY,
      CUSTOMER_TOLL_AND_TICKETS,
    },
    ADMIN: {
      ADMIN_HOME,
      ADMIN_DASHBOARD,
      ADMIN_VEHICLES,
      ADMIN_VEHICLE_DETAILS,
      ADMIN_PROVIDERS,
      ADMIN_CUSTOMERS,
      ADMIN_TRIP_HISTORY,
      ADMIN_BILLING,
      ADMIN_TOLL_AND_TICKETS,
      ADMIN_RENTAL_AGREEMENT,
      ADMIN_RENTAL_AGREEMENT_TERMS_AND_CONDITIONS,
      ADMIN_RENTAL_AGREEMENT_INSTRUCTIONS,
    },
    USER: {
      USER_HOME,
      USER_PROFILE,
      USER_ACCOUNT,
      USER_ACCOUNT_PERSONAL_INFO,
      USER_ACCOUNT_CHANGE_PASSWORD,
      USER_ACCOUNT_POINT_IDENTIFICATION,
      USER_ACCOUNT_POINT_BUSINESS_INFO,
      USER_ACCOUNT_POINT_DOWNLOAD,
      USER_SETUP_PAYMENT,
      USER_STRIPE_RE_AUTH,
      USER_STRIPE_RETURN,
    },
  },

  VEHICLE_DETAILS_ID: (vehicleId: string) =>
    VEHICLE_DETAILS.replace(':vehicleId', vehicleId),
  PROVIDER_INFO_ID: (providerId: string) =>
    PROVIDER_INFO.replace(':providerId', providerId),

  PROVIDER_VEHICLES_SETTINGS_ID: (vehicleId: string) =>
    PROVIDER_VEHICLES_SETTINGS.replace(':vehicleId', vehicleId),
  PROVIDER_VEHICLES_SETTINGS_BASIC_INFO_ID: (vehicleId: string) =>
    PROVIDER_VEHICLES_SETTINGS_BASIC_INFO.replace(':vehicleId', vehicleId),
  PROVIDER_VEHICLES_SETTINGS_DETAILS_ID: (vehicleId: string) =>
    PROVIDER_VEHICLES_SETTINGS_DETAILS.replace(':vehicleId', vehicleId),
  PROVIDER_VEHICLES_SETTINGS_PRICING_ID: (vehicleId: string) =>
    PROVIDER_VEHICLES_SETTINGS_PRICING.replace(':vehicleId', vehicleId),
  PROVIDER_VEHICLES_SETTINGS_PHOTOS_ID: (vehicleId: string) =>
    PROVIDER_VEHICLES_SETTINGS_PHOTOS.replace(':vehicleId', vehicleId),
  PROVIDER_VEHICLES_SETTINGS_DOCUMENTS_ID: (vehicleId: string) =>
    PROVIDER_VEHICLES_SETTINGS_DOCUMENTS.replace(':vehicleId', vehicleId),
  PROVIDER_VEHICLES_SETTINGS_PROTECTION_ID: (vehicleId: string) =>
    PROVIDER_VEHICLES_SETTINGS_PROTECTION.replace(':vehicleId', vehicleId),

  CUSTOMER_CREATE_BOOKING_VEHICLE_ID: (vehicleId: string) =>
    CUSTOMER_CREATE_BOOKING_VEHICLE.replace(':vehicleId', vehicleId),
  CUSTOMER_CREATE_BOOKING_VEHICLE_CHECKOUT_ID: (vehicleId: string) =>
    CUSTOMER_CREATE_BOOKING_VEHICLE_CHECKOUT.replace(':vehicleId', vehicleId),
  CUSTOMER_CREATE_BOOKING_VEHICLE_CONFIRMATION_ID: (vehicleId: string) =>
    CUSTOMER_CREATE_BOOKING_VEHICLE_CONFIRMATION.replace(':vehicleId', vehicleId),

  CUSTOMER_BOOKING_DETAILS_ID: (bookingId: string) =>
    CUSTOMER_BOOKING_DETAILS.replace(':bookingId', bookingId),

  ADMIN_VEHICLE_DETAILS_ID: (vehicleId: string) =>
    ADMIN_VEHICLE_DETAILS.replace(':vehicleId', vehicleId),

  FAQ_DETAILS_KEY: (key: string) =>
    FAQ_DETAILS.replace(':key', key),
};


