import { createSlice } from '@reduxjs/toolkit';

import { getTollsAsync } from './thunks';
import { TollState } from './types';

const initialState: TollState = {
  tolls: [],
  getTollsStatus: 'initial',
  totalCount: 0,
};

export const customerTripHistoriesSlice = createSlice({
  name: 'bookings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTollsAsync.pending, (state, _) => {
      state.getTollsStatus = 'started';
    });
    builder.addCase(getTollsAsync.fulfilled, (state, action) => {
      state.tolls = action.payload.results;
      state.getTollsStatus = 'done';
    });
    builder.addCase(getTollsAsync.rejected, (state, _) => {
      state.getTollsStatus = 'failed';
    });
  },
});

export default customerTripHistoriesSlice;
