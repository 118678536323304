import React, { FC, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { nanoid } from '@reduxjs/toolkit';

import { RightOutlined, InboxOutlined } from '@ant-design/icons';
import { UploadFile } from 'antd/lib/upload/interface';
import classNames from 'classnames';

import { Text, Title, FormItem, Form, Spin } from 'components/misc/helpers';
import Button from 'components/Button';
import FileUploader from 'components/FileUploader';

import LeftSection from '../LeftSection';

import TopSection from '../TopSection';
import {
  useUpdateCarPhotos,
  useCarPhotosSelectors,
  useNextRedirect,
  useDeleteUploadedImage,
} from './hooks';
import styles from '../vehicles.module.scss';
import { getFileName } from '../helpers';
import { useGetVehicleDetails } from '../hooks';

const Photos: FC = () => {
  const { vehicleId } = useParams();

  const nextHandler = useNextRedirect();
  const {
    photos,
    id,
    featurePhoto,
    getVehicleDetailsRequestStatus,
    status,
  } = useCarPhotosSelectors();
  const updateCarPhotos = useUpdateCarPhotos(id);
  const deleteImage = useDeleteUploadedImage();

  const [uploadedFiles, setUploadedFiles] = useState<UploadFile[]>([]);
  const [featurePhotoFile, setFeaturePhotoFile] = useState<UploadFile[]>([]);
  const isLoading = getVehicleDetailsRequestStatus === 'started';

  const getVehicleDetails = useGetVehicleDetails();

  useEffect(() => {
    if (vehicleId) {
      getVehicleDetails(Number(vehicleId));
    }
  }, [vehicleId]);

  useEffect(() => {
    if (photos.length > 0) {
      const tempPhotos: UploadFile[] = [];
      photos.map((photo) => {
        tempPhotos.push({
          uid: photo.id.toString(),
          name: getFileName(photo.images),
          status: 'done',
          url: photo.images,
        });
        return photo;
      });
      setUploadedFiles(tempPhotos);
    } else {
      setUploadedFiles([]);
    }
  }, [photos]);

  useEffect(() => {
    if (featurePhoto && !featurePhoto.includes('profile_pic/avatar.jpg')) {
      setFeaturePhotoFile([
        {
          uid: nanoid(5),
          name: getFileName(featurePhoto),
          status: 'done',
          url: featurePhoto,
        },
      ]);
    } else {
      setFeaturePhotoFile([]);
    }
  }, [featurePhoto]);

  const isDisable = status !== 'INACTIVE';

  return (
    <div
      className={classNames({
        [styles.container]: true,
      })}
    >
      <TopSection />
      <div className={styles.vehicle}>
        <LeftSection />
        <div
          className={classNames({
            [styles.formContainer]: true,
          })}
        >
          <Spin spinning={isLoading}>
            <Title level={2}>Upload Photos</Title>
            <Form className={styles.form}>
              <div>
                <Text strong>Feature Photo</Text>
              </div>
              <FormItem>
                <FileUploader
                  disabled={isDisable}
                  name="featured_image"
                  fileType="image"
                  onUpload={updateCarPhotos}
                  listType="picture-card"
                  maxCount={1}
                  isDragger={false}
                  showUploadList={{
                    showRemoveIcon: false,
                  }}
                  fileList={featurePhotoFile}
                >
                  Upload
                </FileUploader>
              </FormItem>
              <div className={styles.upperTitleContainer}>
                <Text strong>Vehicle Photos</Text>
              </div>
              <FormItem>
                <FileUploader
                  disabled={isDisable}
                  name="vehicle_image[0]images"
                  fileType="image"
                  onUpload={updateCarPhotos}
                  isDragger
                  listType="picture-card"
                  fileList={uploadedFiles}
                  onRemove={(file) => deleteImage(file.uid)}
                  maxCount={5}
                  method="put"
                  multiple
                >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                  <p className="ant-upload-hint">
                    Support for a single or bulk upload. Strictly prohibit from
                    uploading company data or other band files
                  </p>
                </FileUploader>
              </FormItem>
              {!isDisable && (
                <Button
                  className={styles.button}
                  type="default"
                  isBordered={true}
                  onClick={nextHandler}
                  disabled={isDisable}
                >
                  <div>
                    <span>Save and Continue</span>
                    <RightOutlined className={styles.rightIcon} />
                  </div>
                </Button>
              )}
            </Form>
          </Spin>
        </div>
      </div>
    </div>
  );
};

export default Photos;
