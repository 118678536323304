import { publicAPI } from 'api/base';
import { GetVehicleMake, GetVehicleModel } from './types';

export const getVehicleMake: GetVehicleMake = () => {
  return publicAPI.get(`/vehicle-makes/`);
};

export const getVehicleModel: GetVehicleModel = (makeId) => {
  return publicAPI.get(`/vehicle-models/?make_id=${makeId}`);
};
