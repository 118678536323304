import { VehicleStatus } from 'api/admin/vehicles/types';
import { HealthTagProps } from 'components/tables/cells/HealthCell/types';
import { Column } from 'components/tables/IXTable/types';

export const COLUMNS: Column[] = [
  {
    name: 'Vehicle',
    key: 'car_info',
    type: 'image-and-text-cell',
    width: 160,
  },
  {
    name: 'Provider',
    key: 'provider',
    type: 'text',
  },
  {
    name: 'Registration No.',
    key: 'reg_no',
    type: 'text',
  },
  {
    name: 'Rego Paper',
    key: 'rego_file',
    type: 'icon-link-and-text-cell',
  },
  {
    name: 'Trips',
    key: 'trips',
    type: 'text',
  },
  {
    name: 'Pick up',
    key: 'pickup_address',
    type: 'text',
  },
  {
    name: 'Drop off',
    key: 'drop_off_address',
    type: 'text',
  },
  {
    name: 'Status',
    key: 'status',
    type: 'health',
  },
  {
    name: 'Price per day',
    key: 'price_per_day',
    type: 'text',
    align: 'right',
  },
  {
    name: '',
    key: 'three_dot_btn',
    type: 'action-button-cell',
  },
];

export const VEHICLE_STATUS_TAG_PROPS: Record<VehicleStatus, HealthTagProps> = {
  ACTIVE: {
    name: 'Active',
    color: 'green',
  },
  INACTIVE: {
    name: 'Inactive',
    color: 'red',
  },
  REQUEST_FOR_APPROVAL: {
    name: 'Request for approval',
    color: 'yellow',
  },
};
