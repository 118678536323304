import { InfoCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import {
  Card,
  Checkbox,
  Col,
  Divider,
  message,
  Row,
  Tag,
  Typography,
} from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Button from 'components/Button';
import {
  DatePicker,
  Form,
  FormItem,
  Tooltip,
  useForm,
} from 'components/misc/helpers';
import { ROUTES } from 'shared/routes/constants';
import {
  useAppDispatch,
  useAppSelector,
  useUnwrapAsyncThunk,
} from 'store/hooks';
import { handleCheckoutAsync } from 'store/tripBooking/payment/actions';
import { bookCarAsync } from 'store/tripBooking/vehicleDetails/actions';
import { resetCarBookingDetails } from 'store/tripBooking/vehicleDetails/actions';
import {
  selectCarBookingDetails,
  selectVehicleDetails,
} from 'store/tripBooking/vehicleDetails/selectors';
import { selectBooking } from 'store/customer/booking/selectors';
import { setTripBooking } from 'uicontainers/tripBooking/actions';
import { changeBookingDetailsAsync } from 'store/customer/booking/thunks';
import { notification } from 'shared/helpers/notifications';

import styles from '../book.module.scss';

const { Title, Text } = Typography;

const BookCard = () => {
  const navigate = useNavigate();
  const { vehicleId, bookingId } = useParams();
  const unwrap = useUnwrapAsyncThunk();
  const dispatch = useAppDispatch();
  const vehicleDetails = useAppSelector(selectVehicleDetails);
  const bookingDetails = useAppSelector(selectBooking);
  const carBookingDetails = useAppSelector(selectCarBookingDetails);

  const [tripStartDate, setTripStartDate] = useState('');
  const [tripEndDate, setTripEndDate] = useState('');
  const [isVerified, setIsVerified] = useState(true);
  const [vehicleID, setvehicleID] = useState<string>('');
  const [pickupAddress, setPickupAddress] = useState<string | undefined>('');
  const [dropoffAddress, setDropoffAddress] = useState<string | undefined>('');
  const [perDayPrice, setPerDayPrice] = useState<number>(0);

  const [form] = useForm();

  useEffect(() => {
    if (bookingDetails && bookingId) {
      setvehicleID(bookingDetails?.vehicle?.id.toString());
      setPickupAddress(bookingDetails?.vehicle.pickup_address);
      setDropoffAddress(bookingDetails?.vehicle.drop_off_address);
      setPerDayPrice(bookingDetails?.vehicle.daily_price);
      setTripStartDate(moment(bookingDetails?.start_time).format('YYYY-MM-DD'));
      setTripEndDate(moment(bookingDetails?.end_time).format('YYYY-MM-DD'));
    }
    if (vehicleDetails.id && vehicleId) {
      setvehicleID(vehicleId.toString());
      setPickupAddress(vehicleDetails?.pickup_address);
      setDropoffAddress(vehicleDetails?.drop_off_address);
      setPerDayPrice(vehicleDetails?.daily_price);
      setTripStartDate(moment().format('YYYY-MM-DD'));
    }
  }, [bookingDetails, vehicleDetails.id]);

  useEffect(() => {
    form.setFieldsValue({
      start_time: moment(),
      end_time: '',
    });
    dispatch(resetCarBookingDetails());
  }, [vehicleId]);

  useEffect(() => {
    if (tripStartDate && tripEndDate) {
      form.setFieldsValue({
        start_time: tripStartDate ? moment(tripStartDate) : '',
        end_time: tripEndDate ? moment(tripEndDate) : '',
      });
    }

    if (
      tripStartDate &&
      tripEndDate &&
      moment(tripEndDate).diff(tripStartDate, 'days') > 0
    ) {
      unwrap(
        bookCarAsync({
          vehicle_id: vehicleID as string,
          start_date: tripStartDate,
          end_date: tripEndDate,
        })
      )
        .then(() => {})
        .catch((e) => {});
      dispatch(
        setTripBooking({
          end_time: tripEndDate,
          start_time: tripStartDate,
        })
      );
    } else {
      dispatch(resetCarBookingDetails());
    }
  }, [tripStartDate, tripEndDate]);

  const existingBookingsTooltip = vehicleDetails?.booking_dates
    ? vehicleDetails?.booking_dates.map((item, index) => {
        return (
          <div key={index}>
            {moment(item?.start_date).format('YYYY-MM-DD')} to{' '}
            {moment(item?.end_date).format('YYYY-MM-DD')}
          </div>
        );
      })
    : bookingDetails &&
      bookingDetails?.vehicle &&
      bookingDetails?.vehicle?.booking_dates &&
      bookingDetails?.vehicle?.booking_dates
        .filter((item) => {
          return (
            tripStartDate !== moment(item?.start_date).format('YYYY-MM-DD')
          );
        })
        .map((item, index) => {
          return (
            <div key={index}>
              {moment(item?.start_date).format('YYYY-MM-DD')} to{' '}
              {moment(item?.end_date).format('YYYY-MM-DD')}
            </div>
          );
        });

  return (
    <Card>
      <Form
        layout="vertical"
        form={form}
        onValuesChange={(value) => {
          //change start time
          if (value.start_time !== undefined) {
            if (value.start_time) {
              setTripStartDate(value.start_time.format('YYYY-MM-DD'));
            } else {
              setTripStartDate('');
            }
          }

          //change send time
          if (value.end_time !== undefined) {
            if (value.end_time) {
              setTripEndDate(value.end_time.format('YYYY-MM-DD'));
            } else {
              setTripEndDate('');
            }
          }
        }}
        onFinish={(values) => {
          if (values.hasReadTerms && vehicleId) {
            unwrap(
              handleCheckoutAsync({
                vehicle_id: vehicleID,
                start_date: tripStartDate,
                end_date: tripEndDate,
              })
            )
              .then((response) => {
                if (response) {
                  navigate(ROUTES.PATHS.CUSTOMER.CUSTOMER_BOOKINGS);
                }
              })
              .catch((e) => {
                if (
                  e.error_data ===
                  'Can not book the vehicle, You are not 100 points verified'
                ) {
                  setIsVerified(false);
                }
              });
          } else if (values.hasReadTerms && bookingId) {
            unwrap(
              changeBookingDetailsAsync({
                data: {
                  start_date: tripStartDate,
                  end_date: tripEndDate,
                },
                id: parseInt(bookingId),
              })
            )
              .then((response) => {
                if (response) {
                  navigate(`/customer/bookings`);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            message.error(
              'Please read and agree with the terms and conditions'
            );
          }
        }}
      >
        <div className={styles.header}>
          <Title level={4}>{`$${perDayPrice * 1}/day`}</Title>
          {existingBookingsTooltip && existingBookingsTooltip.length > 0 && (
            <Tooltip title={existingBookingsTooltip}>
              <Tag color="warning">
                <QuestionCircleOutlined /> Existing bookings
              </Tag>
            </Tooltip>
          )}
        </div>
        <Divider />

        <div className={styles.time_range}>
          <FormItem
            label="Trip Start"
            name="start_time"
            validateTrigger={['onSubmit', 'onChange']}
            rules={[
              {
                required: true,
                message: 'Please select a start date',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (
                    value &&
                    getFieldValue('end_time') &&
                    moment(value).isSameOrAfter(
                      moment(getFieldValue('end_time')),
                      'day'
                    )
                  ) {
                    return Promise.reject('Start date must be before end date');
                  }

                  return Promise.resolve();
                },
              }),
            ]}
          >
            <DatePicker
              style={{ width: '100%' }}
              disabledDate={(current) =>
                current.isBefore(moment().subtract(1, 'day'))
              }
            />
          </FormItem>

          <FormItem
            label="Trip End"
            name="end_time"
            validateTrigger={['onSubmit', 'onChange']}
            rules={[
              {
                required: true,
                message: 'Please select a end date ',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (
                    value &&
                    getFieldValue('start_time') &&
                    moment(value).isSameOrBefore(
                      moment(getFieldValue('start_time')),
                      'day'
                    )
                  ) {
                    return Promise.reject('End date must be after start date');
                  }

                  return Promise.resolve();
                },
              }),
            ]}
          >
            <DatePicker
              style={{ width: '100%' }}
              disabledDate={(current) =>
                current.isBefore(moment().subtract(1, 'day'))
              }
            />
          </FormItem>
          {!isVerified && (
            <p>
              You are not hundred points verified.{' '}
              <Button
                type="ghost"
                isBordered={true}
                onClick={() =>
                  navigate(ROUTES.PATHS.USER.USER_ACCOUNT_POINT_IDENTIFICATION)
                }
              >
                Verification
              </Button>
            </p>
          )}
          <div>
            <Text strong className={styles.title}>
              Pickup And Drop Off Location
            </Text>
            <div className={styles.padding}>
              <div className={styles.input_value_text}>
                <div>
                  <Text>{`${pickupAddress}`}</Text>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.gap} />
          <Divider />
        </div>

        <div>
          <Title level={5}>Payment Details</Title>
          <Row gutter={[0, 8]}>
            <Col span={24}>
              <Row className={styles.border}>
                <Col span={12}>
                  <Text>
                    {vehicleId || bookingId
                      ? `$${perDayPrice * 1} x ${
                          carBookingDetails.total_units
                        } day(s)`
                      : ''}
                  </Text>
                </Col>
                <Col span={12}>
                  <Text>{carBookingDetails.total_amount.toFixed(2)}</Text>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row className={styles.border}>
                <Col span={12}>
                  <Text>
                    Discount
                    <InfoCircleOutlined />
                  </Text>
                </Col>
                <Col span={12}>
                  <Text>{carBookingDetails.total_discount.toFixed(2)}</Text>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row className={styles.border}>
                <Col span={12}>
                  <Text>
                    Security Money
                    <InfoCircleOutlined />
                  </Text>
                </Col>
                <Col span={12}>
                  <Text>{carBookingDetails.security_money?.toFixed(2)}</Text>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row className={styles.border}>
                <Col span={12}>
                  <Text>
                    Surcharge
                    <InfoCircleOutlined />
                  </Text>
                </Col>
                <Col span={12}>
                  <Text>{carBookingDetails.surcharge.toFixed(2)}</Text>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row className={styles.border}>
                <Col span={12}>
                  <Text strong>
                    Total Payment
                    <InfoCircleOutlined />
                  </Text>
                </Col>
                <Col span={12}>
                  <Text strong>{`${
                    carBookingDetails.net_payable.toFixed(2) || 0.0
                  }`}</Text>
                </Col>
              </Row>
            </Col>
            {carBookingDetails.security_money !== 0 && (
              <Col span={24}>
                <i className={styles.securityMoney}>
                  * Security deposit $
                  {carBookingDetails?.security_money?.toFixed(2)} will be added
                  with first installment.
                </i>
              </Col>
            )}
            <Col span={24}>
              <Row className={styles.border}>
                <Col span={12}>
                  <Text>
                    No. Of Installments
                    <InfoCircleOutlined />
                  </Text>
                </Col>
                <Col span={12}>
                  <Text>{carBookingDetails.no_of_installments}</Text>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>

        <div className={styles.terms_condition}>
          <FormItem
            name="hasReadTerms"
            valuePropName="checked"
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error(
                          'Please read and agree with the terms and conditions'
                        )
                      ),
              },
            ]}
          >
            <Checkbox className={styles.checkboxContent}>
              I have read and agree with the host's &nbsp;
              <a
                href="/terms-and-conditions"
                className={styles.text_style}
                target="_blank"
              >
                Terms and Conditions
              </a>
            </Checkbox>
          </FormItem>
        </div>

        <div className={styles.btn_container}>
          <Button type="ghost" isBordered={true} htmlType="submit">
            {vehicleId ? 'Book Vehicle' : 'Update Booking'}
          </Button>
        </div>
      </Form>
    </Card>
  );
};

export default BookCard;
