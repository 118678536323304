import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { UploadFile } from 'antd/lib/upload/interface';
import { UploadOutlined } from '@ant-design/icons';

import { transformToUploadFile } from 'uicontainers/provider/vehicles/details/helpers';
import Input from 'components/Input';
import Button from 'components/Button';
import FileUploader from 'components/FileUploader';
import { BusinessInfo as BusinessInfoType } from './types';

import styles from '../Home/account.module.scss';
import {
  useUpdateBusinessInfo,
  useBusinessInfoSelectors,
  useUploadData,
} from './hooks';
import {
  Form,
  FormItem,
  Spin,
  Title,
  Text,
  Button as AntButton,
  useForm,
  Alert,
} from 'components/misc/helpers';
import { selectCustomerAccount } from 'store/customer/account/selectors';
import { useAppSelector } from 'store/hooks';
import { Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'shared/routes/constants';
import { selectLoginData } from 'store/shared/auth/selectors';

const BusinessInfo = () => {
  const updateBusinessInfo = useUpdateBusinessInfo();
  const upload = useUploadData();
  const navigate = useNavigate();
  const { provider_mode } = useAppSelector(selectCustomerAccount);
  const loginData = useAppSelector(selectLoginData);
  const isOnboardingCompleted = loginData && loginData.is_onboarding_completed;

  const { businessInfo, isLoading } = useBusinessInfoSelectors();

  const [businessLicense, setBusinessLicense] = useState<UploadFile[]>([]);
  const [changedValues, setChangedValues] = useState<Partial<BusinessInfoType>>(
    {}
  );
  const [form] = useForm();

  useEffect(() => {
    form.resetFields();
    const {
      business_name,
      abn,
      business_phone,
      business_address,
      business_email,
    } = businessInfo;
    form.setFieldsValue({
      business_name: changedValues.business_name || business_name,
      abn: changedValues.abn || abn,
      business_phone: changedValues.business_phone || business_phone,
      business_address: changedValues.business_address || business_address,
      business_email: changedValues.business_email || business_email,
    });
    setBusinessLicense(transformToUploadFile(businessInfo.business_license));
  }, [businessInfo]);

  return (
    <div>
      {!isOnboardingCompleted && businessInfo?.business_name && (
        <Alert
          message="To become a host you need to connect your account to stripe as well."
          type="info"
          action={
            <Space>
              <Button
                className={styles.button}
                type="ghost"
                isBordered={true}
                onClick={() =>
                  navigate(ROUTES.PATHS.USER.USER_ACCOUNT_POINT_DOWNLOAD)
                }
              >
                Continue
              </Button>
            </Space>
          }
        />
      )}
      <div className={styles.titleContainer}>
        <Title level={2}>Business Information</Title>
      </div>
      <Form
        className={styles.form}
        initialValues={businessInfo}
        form={form}
        onFinish={updateBusinessInfo}
        onValuesChange={(changedValues) => {
          setChangedValues((prevState) => {
            return { ...prevState, ...changedValues };
          });
        }}
      >
        <Spin spinning={isLoading}>
          <FormItem
            name="business_name"
            rules={[
              {
                required: true,
                message: 'Please input your business name!',
              },
            ]}
          >
            <Input
              label="Business Name"
              disabled={provider_mode === 'ACTIVE'}
            />
          </FormItem>
          <FormItem
            name="abn"
            rules={[
              {
                required: true,
                message: 'Please input your ABN!',
              },
            ]}
          >
            <Input label="ABN" disabled={provider_mode === 'ACTIVE'} />
          </FormItem>
          <FormItem
            name="business_phone"
            rules={[
              {
                required: true,
                message: 'Please input your phone number!',
              },
            ]}
          >
            <Input label="Phone" disabled={provider_mode === 'ACTIVE'} />
          </FormItem>
          <FormItem
            name="business_address"
            rules={[
              {
                required: true,
                message: 'Please input your address!',
              },
            ]}
          >
            <Input label="Address" disabled={provider_mode === 'ACTIVE'} />
          </FormItem>
          <FormItem
            name="business_email"
            rules={[
              {
                required: true,
                message: 'Please input your email!',
              },
            ]}
          >
            <Input
              label="Email"
              type="email"
              disabled={provider_mode === 'ACTIVE'}
            />
          </FormItem>
          <div
            className={classNames({
              [styles.businessLicense]: true,
              [styles.disableEdit]: provider_mode === 'ACTIVE',
            })}
          >
            <div className={styles.upperTitleContainer}>
              <Text strong>Business License</Text>
              <br />
              <Text className={styles.info}>
                Upload a photo or pdf copy of your business license
              </Text>
            </div>
            <FormItem
              rules={[
                {
                  required: true,
                  message: 'license is required!',
                },
              ]}
            >
              <FileUploader
                name="business_license"
                multiple={false}
                maxCount={1}
                onUpload={upload}
                isDragger={false}
                fileList={businessLicense}
                onRemove={() => {
                  updateBusinessInfo({
                    business_license: null,
                  });
                }}
              >
                <AntButton
                  className={styles.uploadButton}
                  icon={<UploadOutlined />}
                  disabled={provider_mode === 'ACTIVE'}
                >
                  Click to Upload
                </AntButton>
              </FileUploader>
            </FormItem>
            <span className={styles.info}>
              After submission, your documents will be verified by Jaunt and
              after that your verification status will be changed.
            </span>
          </div>
          <Button
            className={styles.button}
            type="ghost"
            isBordered={true}
            htmlType="submit"
            disabled={provider_mode === 'ACTIVE'}
          >
            <div className={styles.textContainer}>
              <span className={styles.buttonTextGhost}>Save</span>
            </div>
          </Button>
        </Spin>
      </Form>
    </div>
  );
};

export default BusinessInfo;
