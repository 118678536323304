import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ACCESS_TOKEN_NAME, USER_ID } from 'api/base/constants';
import { AuthenticationData } from 'api/auth/types';

import {
  activateAccountAsync,
  loginAsync,
  passwordResetRequestAsync,
  resetPasswordAsync,
  signupAsync,
  verifyTokenAsync,
  getStripeOnboardingLinkAsync,
} from './thunks';
import { AuthState, UserMode } from './types';
import { isProvider } from 'uicontainers/_shared/helpers/auth';

const initialState: AuthState = {
  loginData: undefined,
  signupData: undefined,
  loginStatus: 'initial',
  isAuthenticated: false,
  signupStatus: 'initial',
  forgotPasswordStatus: 'initial',
  resetPasswordStatus: 'initial',
  activateAccountStatus: 'initial',
  verifyTokenStatus: 'started',
  stripeOnboardingLink: '',
  getStripeOnboardingLinkStatus: 'initial',
  userMode: undefined,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setAccessToken(_, action: PayloadAction<string>) {
      localStorage.setItem(ACCESS_TOKEN_NAME, action.payload);
    },
    setUserData(state, action: PayloadAction<AuthenticationData | undefined>) {
      state.loginData = action.payload;
    },
    setIsAuthenticated(state, action: PayloadAction<boolean>) {
      state.isAuthenticated = action.payload;
    },
    setUserMode(state, action: PayloadAction<UserMode>) {
      state.userMode = action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.pending, (state) => {
        state.loginStatus = 'started';
        state.isAuthenticated = false;
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        state.loginStatus = 'done';
        state.loginData = action.payload.results;
        state.isAuthenticated = true;
        state.userMode = isProvider(action.payload.results.user_role) ? 'PROVIDER' : 'CUSTOMER'
        localStorage.setItem(ACCESS_TOKEN_NAME, action.payload.results.access);
        localStorage.setItem(USER_ID, action.payload.results.id);
      })
      .addCase(loginAsync.rejected, (state) => {
        state.loginStatus = 'failed';
        state.loginData = undefined;
        state.isAuthenticated = false;
      })
      .addCase(signupAsync.pending, (state) => {
        state.signupStatus = 'started';
      })
      .addCase(signupAsync.fulfilled, (state, action) => {
        state.signupStatus = 'done';
        state.signupData = action.payload.results;
      })
      .addCase(signupAsync.rejected, (state) => {
        state.signupStatus = 'failed';
      })
      .addCase(passwordResetRequestAsync.pending, (state) => {
        state.forgotPasswordStatus = 'started';
      })
      .addCase(passwordResetRequestAsync.fulfilled, (state) => {
        state.forgotPasswordStatus = 'done';
      })
      .addCase(passwordResetRequestAsync.rejected, (state) => {
        state.forgotPasswordStatus = 'failed';
      })
      .addCase(resetPasswordAsync.pending, (state) => {
        state.resetPasswordStatus = 'started';
      })
      .addCase(resetPasswordAsync.fulfilled, (state) => {
        state.resetPasswordStatus = 'done';
      })
      .addCase(resetPasswordAsync.rejected, (state) => {
        state.resetPasswordStatus = 'failed';
      })
      .addCase(activateAccountAsync.pending, (state) => {
        state.activateAccountStatus = 'started';
      })
      .addCase(activateAccountAsync.fulfilled, (state) => {
        state.activateAccountStatus = 'done';
      })
      .addCase(activateAccountAsync.rejected, (state) => {
        state.activateAccountStatus = 'failed';
      })
      .addCase(verifyTokenAsync.fulfilled, (state, action) => {
        if (action.payload.results) {
          state.loginData = action.payload.results;
          state.isAuthenticated = true;
        } else {
          state.loginData = undefined;
          state.isAuthenticated = false;
        }
        state.verifyTokenStatus = 'done';
      })
      .addCase(verifyTokenAsync.rejected, (state) => {
        state.loginData = undefined;
        state.isAuthenticated = false;
        state.verifyTokenStatus = 'failed';
      })
      .addCase(getStripeOnboardingLinkAsync.pending, (state) => {
        state.getStripeOnboardingLinkStatus = 'started';
      })
      .addCase(getStripeOnboardingLinkAsync.fulfilled, (state, action) => {
        state.getStripeOnboardingLinkStatus = 'done';
        state.stripeOnboardingLink = action.payload.results.url;
      })
      .addCase(getStripeOnboardingLinkAsync.rejected, (state) => {
        state.getStripeOnboardingLinkStatus = 'failed';
      });
  },
});

export default authSlice;
