import React from 'react';
import { Outlet, Route } from 'react-router-dom';

import { ROUTES } from 'shared/routes/constants';
import Footer from 'uicontainers/_shared/Footer/Footer';
import Content from 'uicontainers/_shared/layout/Content';
import AccountActivation from 'uicontainers/AccountActivation';
import ForgotPassword from 'uicontainers/ForgotPassword';
import Login from 'uicontainers/Login';
import ResetPassword from 'uicontainers/ResetPassword';
import SignUp from 'uicontainers/SignUp';
import TripBooking from 'uicontainers/tripBooking/Home';
import ProviderInfo from 'uicontainers/tripBooking/ProviderInfo';
import SearchedVehicles from 'uicontainers/tripBooking/SearchedVehicles/SearchedVehicles';
import PreBook from 'uicontainers/tripBooking/PreBook';
import PrivacyPolicy from 'uicontainers/privacyPolicy';
import TermsAndConditions from 'uicontainers/termsAndConditions';
import FaqDetails from 'uicontainers/tripBooking/Home/FaqSection/FaqDetails/FaqDetails';

const PublicLayout = () => {
  return (
    <Content footer={<Footer />}>
      <Outlet />
    </Content>
  );
};

const Public = () => {
  return (
    <Route path={ROUTES.PATHS.ROOT} element={<PublicLayout />}>
      <Route index element={<TripBooking />} />
      <Route path={ROUTES.PATHS.SEARCH} element={<SearchedVehicles />} />
      <Route path={ROUTES.PATHS.VEHICLE_DETAILS} element={<PreBook />} />
      <Route path={ROUTES.PATHS.PROVIDER_INFO} element={<ProviderInfo />} />     
      <Route path={ROUTES.PATHS.LOGIN} element={<Login />} />
      <Route path={ROUTES.PATHS.SIGN_UP} element={<SignUp />} />
      <Route path={ROUTES.PATHS.FORGOT_PASSWORD} element={<ForgotPassword />} />
      <Route path={ROUTES.PATHS.RESET_PASSWORD} element={<ResetPassword />} />
      <Route path={ROUTES.PATHS.ACTIVATE_ACCOUNT} element={<AccountActivation />} />
      <Route path={ROUTES.PATHS.FAQ_DETAILS} element={<FaqDetails />} />
      <Route path={ROUTES.PATHS.PRIVACY_POLICY} element={<PrivacyPolicy />} />
      <Route path={ROUTES.PATHS.TERMS_AND_CONDITIONS} element={<TermsAndConditions />} />
    </Route>
  );
};

export default Public;
