import { createSlice } from '@reduxjs/toolkit';

import { getVehicleMakeAsync, getVehicleModelAsync } from './thunks';
import { TripBookingVehicleCategoryState } from './types';

const initialState: TripBookingVehicleCategoryState = {
  vehicle_makes: [],
  vehicle_models: [],
  getVehicleCategoryRequestStatus: 'initial',
};

export const tripBookingVehicleCategorySlice = createSlice({
  name: 'vehicleFilter',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getVehicleMakeAsync.pending, (state) => {
        state.getVehicleCategoryRequestStatus = 'started';
      })
      .addCase(getVehicleMakeAsync.fulfilled, (state, action) => {
        const { results } = action.payload;
        state.vehicle_makes = results.map((item) => ({
          name: item.name,
          value: item.id,
        }));
        state.getVehicleCategoryRequestStatus = 'done';
      })
      .addCase(getVehicleMakeAsync.rejected, (state) => {
        state.getVehicleCategoryRequestStatus = 'failed';
      })
      .addCase(getVehicleModelAsync.pending, (state) => {
        state.getVehicleCategoryRequestStatus = 'started';
      })
      .addCase(getVehicleModelAsync.fulfilled, (state, action) => {
        const { results } = action.payload;
        state.vehicle_models = results.map((item) => ({
          name: item.name,
          value: item.id,
        }));
        state.getVehicleCategoryRequestStatus = 'done';
      })
      .addCase(getVehicleModelAsync.rejected, (state) => {
        state.getVehicleCategoryRequestStatus = 'failed';
      });
  },
});

export default tripBookingVehicleCategorySlice;
