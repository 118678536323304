import React from 'react';
import { Paragraph, Title } from 'components/misc/helpers';
import { Badge } from 'antd';
import styles from './faqDetails.module.scss';

function EligibilityCriteria() {
  return (
    <div>
      <Title level={3}>
        A. How we're different from other car-sharing services
      </Title>

      <Paragraph>
        <Badge color="black" text="Free membership and no joining fee:" />
      </Paragraph>
      <Paragraph>
        If you're already a member of another car-sharing service, you won't
        want to pay twice. The good news is that joining Jaunt is free and there
        are no monthly fees.
      </Paragraph>
      <Paragraph>
        {' '}
        We have no tricky contracts or minimum contract terms. If it’s not for
        you, just let us know and we will go our separate ways.
      </Paragraph>
      <Paragraph>
        <Badge color="black" text="Low-cost local mobility:" />
      </Paragraph>
      <Paragraph>
        {' '}
        We’re way better value than the other guys. That’s because by not having
        to maintain our own expensive fleet, in many cases renting our cars is
        around 20-40% cheaper than other carsharing services. Our prices start
        at $5/hr or $25/day+ distance charges. So you can get your errands done
        around town for around $40 or $50, rather than shelling out closer to
        $100 to hire a traditional car share car for the day.
      </Paragraph>
      <Paragraph>
        Jaunt cars live where you live; down the road, across the street, maybe
        even in your apartment building. Jaunt cars are real people's cars, and
        so they come with real-people parking spots; convenient!
      </Paragraph>
      <Paragraph>
        <Badge color="black" text="Helping your community:" />
      </Paragraph>
      <Paragraph>
        {' '}
        Why not help your community instead of a company? Instead of passing
        your money off to a corporation, when you pay for a Jaunt car, the
        lion's share of that money goes to one of your neighbors. So you’re
        investing in your community and helping out local people while saving
        money AND being green by helping keep extra cars off the road.
      </Paragraph>

      <Title level={3}>B. Joining as an international driver</Title>
      <Paragraph>
        <Badge color="black" text="Short answer:" />
      </Paragraph>
      <Paragraph>
        International drivers are very welcome to join! You will need to hold a
        full unrestricted driver’s license from your home country, with a formal
        English translation if it is in another language.
      </Paragraph>
      <Paragraph>
        You don't need an International Driving Permit (international license)
        to join Jaunt, provided the details on your international license and
        the English translation are clear. We'll be in touch if we need any more
        information to process your application.
      </Paragraph>
      <Paragraph>
        <Badge color="black" text="Tips for driving in Australia:" />
      </Paragraph>
      <Paragraph>When you're driving in Australia, remember:</Paragraph>
      <Paragraph>
        <ol className={styles.alpha}>
          <li>Drive on the left side of the road.</li>
          <li>
            Park on the left side of the road - you will get a fine if you park
            against the direction of traffic.
          </li>
          <li>
            Always check the parking signs carefully before you leave the car -
            you will be responsible if you leave the car in a place and it gets
            a fine (no matter how long after you leave it).
          </li>
          <li>
            Speed limits are strictly enforced in Australia. Some roads and
            streets don't have speed limit signs, but speed limits still apply.
            Slow down, drive safely and avoid heavy fines.
          </li>
          <li>
            Mobile phone use while driving is illegal and also strictly
            enforced.
          </li>
          <li>
            If you are driving outside of the cities, kangaroos and other
            wildlife may be on the roads - avoid driving around dawn and dusk if
            possible and drive carefully at all times
          </li>
        </ol>
      </Paragraph>
    </div>
  );
}

export default EligibilityCriteria;
