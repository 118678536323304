import { adminAPI } from 'api/base';

import { UpdateAdminApproval, GetProviders } from './types';

export const getProviders: GetProviders = (params) => {
  return adminAPI.get('/providers/', { params });
};

export const updateAdminApproval: UpdateAdminApproval = (data, id) => {
  return adminAPI.patch(`/provider-list/${id}/`, data);
};
