import React from 'react';
import { Outlet, Route } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { ROLE } from 'api/auth/types';
import PrivateRoute from 'pages/PrivateRoute';
import { ROUTES } from 'shared/routes/constants';
import Content from 'uicontainers/_shared/layout/Content';
import SetupPayment from 'uicontainers/_shared/SetupPayment';
import StripeReAuth from 'uicontainers/_shared/StripeReAuth';
import StripeReturn from 'uicontainers/_shared/StripeReturn';
import UserAccount from 'uicontainers/Customer/Account/Home';
import BusinessInfo from 'uicontainers/Customer/Account/BusinessInfo';
import ChangePassword from 'uicontainers/Customer/Account/ChangePassword';
import Download from 'uicontainers/Customer/Account/Download';
import PersonalInfo from 'uicontainers/Customer/Account/PersonalInfo';
import PointIdentification from 'uicontainers/Customer/Account/PointIdentification';
import UserProfile from 'uicontainers/Customer/profile/Home';

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_API_KEY as string
);

const UserLayout = () => {
  return (
    <Content>
      <Outlet />
    </Content>
  );
};

const User = () => {
  return [
    <Route
      path={ROUTES.PATHS.USER.USER_HOME}
      element={
        <PrivateRoute roles={[ROLE.PROVIDER, ROLE.CUSTOMER, ROLE.ADMIN]}>
          <UserLayout />
        </PrivateRoute>
      }
    >
      <Route index element={<UserProfile />} />
      <Route path={ROUTES.PATHS.USER.USER_SETUP_PAYMENT} element={<SetupPayment />} />
      <Route path={ROUTES.PATHS.USER.USER_STRIPE_RE_AUTH} element={<StripeReAuth />} />
      <Route path={ROUTES.PATHS.USER.USER_STRIPE_RETURN} element={<StripeReturn />} />
      <Route path={ROUTES.PATHS.USER.USER_PROFILE} element={<UserProfile />} />
      <Route path={ROUTES.PATHS.USER.USER_ACCOUNT} element={<UserAccount />}>
        <Route path={ROUTES.PATHS.USER.USER_ACCOUNT_PERSONAL_INFO} element={<PersonalInfo />} />
        <Route path={ROUTES.PATHS.USER.USER_ACCOUNT_CHANGE_PASSWORD} element={<ChangePassword />} />
        <Route
          path={ROUTES.PATHS.USER.USER_ACCOUNT_POINT_IDENTIFICATION}
          element={<Elements stripe={stripePromise}><PointIdentification /></Elements>}
        />
        <Route
          path={ROUTES.PATHS.USER.USER_ACCOUNT_POINT_BUSINESS_INFO}
          element={<BusinessInfo />}
        />
        <Route path={ROUTES.PATHS.USER.USER_ACCOUNT_POINT_DOWNLOAD} element={<Download />} />        
        <Route index element={<PersonalInfo />} />
      </Route>
    </Route>,
  ];
};

export default User;
