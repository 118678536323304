import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useActions, useAppSelector } from 'store/hooks';
import {
  selectCarPhotos,
  selectCarFeaturePhotos,
  selectGetVehicleDetailsStatus,
  selectStatus,
} from './selectors';
import { getVehicleSettingsSidebarMenu } from '../constants';
import { selectVehicleId } from 'store/provider/vehicles/selectors';
import { upload, deleteVehicleImageAsync } from 'store/provider/vehicles/actions';

export const useCarPhotosActions = () => {
  return useActions({
    upload,
    deleteVehicleImageAsync,
  });
};

export const useCarPhotosSelectors = () => {
  return useAppSelector((state) => {
    return {
      id: selectVehicleId(state),
      photos: selectCarPhotos(state),
      featurePhoto: selectCarFeaturePhotos(state),
      getVehicleDetailsRequestStatus: selectGetVehicleDetailsStatus(state),
      status: selectStatus(state),
    };
  });
};

export const useUpdateCarPhotos = (id?: number) => {

  const { upload } = useCarPhotosActions();

  return useCallback(
    async (formData: FormData) => {
      if (id) {
        await upload({ data: formData, vehicleId: id });
      }
    },
    [upload],
  );
};

export const useDeleteUploadedImage = () => {
  const { deleteVehicleImageAsync } = useCarPhotosActions();

  return useCallback(
    async (id: string) => {
      await deleteVehicleImageAsync(id);
    },
    [deleteVehicleImageAsync],
  );
};

export const usePreviousRedirect = () => {
  const navigate = useNavigate();
  const { id } = useCarPhotosSelectors();

  return useEffect(() => {
    if (id) {
      navigate(getVehicleSettingsSidebarMenu(id.toString())[2].link);
    }
  }, [id]);
};

export const useNextRedirect = () => {
  const navigate = useNavigate();
  const { id } = useCarPhotosSelectors();

  return useCallback(() => {
    if (id) {
      navigate(getVehicleSettingsSidebarMenu(id.toString())[4].link);
    }
  }, [id]);
};
