import { createAsyncThunk } from '@reduxjs/toolkit';

import { getCustomers } from 'api/admin/customers';
import { CustomerRequestParams } from 'api/admin/customers/types';

export const getCustomersAsync = createAsyncThunk(
  'admin/customer/get_customers',
  async (params?: CustomerRequestParams) => {
    const response = await getCustomers(params);
    return response;
  },
);
