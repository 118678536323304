import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getTollsAsync } from './thunks';
import { AdminTollsState } from './types';

const initialState: AdminTollsState = {
  tolls: [],
  getTollsRequestStatus: 'initial',
  totalCount: 0,
  currentPage: 1,
};

export const adminTollsSlice = createSlice({
  name: 'admin-tolls',
  initialState,
  reducers: {
    setCurrentPage(state, action: PayloadAction<number>) {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTollsAsync.pending, (state) => {
      state.getTollsRequestStatus = 'started';
    });
    builder.addCase(getTollsAsync.fulfilled, (state, action) => {
      state.tolls = action.payload.results;
      state.getTollsRequestStatus = 'done';
    });
    builder.addCase(getTollsAsync.rejected, (state) => {
      state.getTollsRequestStatus = 'failed';
    });
  },
});

export default adminTollsSlice;
