import { CarOutlined } from '@ant-design/icons';
import React from 'react';

import { ROUTES } from 'shared/routes/constants';

export const getSideBarMenu = () => [
  {
    title: 'Trip History',
    icon: <CarOutlined />,
    link: ROUTES.PATHS.CUSTOMER.CUSTOMER_BOOKINGS,
  },
  // {
  //   title: 'Trip History',
  //   icon: <HistoryOutlined />,
  //   link: ROUTES.PATHS.CUSTOMER.CUSTOMER_TRIP_HISTORY,
  // },
  // {
  //   title: 'Toll and Tickets',
  //   icon: <FileDoneOutlined />,
  //   link: ROUTES.PATHS.CUSTOMER.CUSTOMER_TOLL_AND_TICKETS,
  // },
];
