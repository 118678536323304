import React from 'react';
import { Navigate, Outlet, Route } from 'react-router-dom';

import { ROLE } from 'api/auth/types';
import Sidebar from 'components/Sidebar';
import PrivateRoute from 'pages/PrivateRoute';
import { ROUTES } from 'shared/routes/constants';
import Content from 'uicontainers/_shared/layout/Content';
import Dashboard from 'uicontainers/admin/dashboard/Home';
import Vehicles from 'uicontainers/admin/Vehicles';
import Customers from 'uicontainers/admin/Customers';
import Providers from 'uicontainers/admin/Providers';
import TripHistory from 'uicontainers/admin/TripHistory';
import Billings from 'uicontainers/admin/Billings';
import TollAndTickets from 'uicontainers/admin/tollAndTickets/Home';
import Instructions from 'uicontainers/admin/RentalAgreement/Instructions';
import TermsAndConditions from 'uicontainers/admin/RentalAgreement/TermsAndConditions';

import { getSideBarMenu } from './helpers';
import BookingDetails from 'uicontainers/Customer/BookingDetails/BookingDetails';

const RentalAgreementDetails = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

const AdminLayout = () => {
  return (
    <Content leftSection={<Sidebar menuItems={getSideBarMenu()} />}>
      <Outlet />
    </Content>
  );
};

const VehicleDetailsLayout = () => {
  return (
    <Content>
      <Outlet />
    </Content>
  );
};

const Admin = () => {
  return [
    <>
    <Route
      path={ROUTES.PATHS.ADMIN.ADMIN_HOME}
      element={
        <PrivateRoute roles={[ROLE.ADMIN]}>
          <AdminLayout />
        </PrivateRoute>
      }
    >
      <Route index element={<Navigate replace to={ROUTES.PATHS.ADMIN.ADMIN_DASHBOARD} />} />
      <Route path={ROUTES.PATHS.ADMIN.ADMIN_DASHBOARD} element={<Dashboard />} />
      <Route path={ROUTES.PATHS.ADMIN.ADMIN_VEHICLES} element={<Vehicles />} />
      <Route path={ROUTES.PATHS.ADMIN.ADMIN_PROVIDERS} element={<Providers />} />
      <Route path={ROUTES.PATHS.ADMIN.ADMIN_CUSTOMERS} element={<Customers />} />
      <Route path={ROUTES.PATHS.ADMIN.ADMIN_TRIP_HISTORY} element={<TripHistory />} />
      <Route path={ROUTES.PATHS.ADMIN.ADMIN_BILLING} element={<Billings />} />
      <Route path={ROUTES.PATHS.ADMIN.ADMIN_TOLL_AND_TICKETS} element={<TollAndTickets />} />
      <Route path={ROUTES.PATHS.ADMIN.ADMIN_RENTAL_AGREEMENT} element={<RentalAgreementDetails />}>
        <Route
          index
          element={
            <Navigate replace to={ROUTES.PATHS.ADMIN.ADMIN_RENTAL_AGREEMENT_TERMS_AND_CONDITIONS} />
          }
        />
        <Route
          path={ROUTES.PATHS.ADMIN.ADMIN_RENTAL_AGREEMENT_TERMS_AND_CONDITIONS}
          element={<TermsAndConditions />}
        />
        <Route
          path={ROUTES.PATHS.ADMIN.ADMIN_RENTAL_AGREEMENT_INSTRUCTIONS}
          element={<Instructions />}
        />
      </Route>
    </Route>
    <Route
      path={ROUTES.PATHS.ADMIN.ADMIN_VEHICLES}
      element={
        <PrivateRoute roles={[ROLE.ADMIN]}>
          <VehicleDetailsLayout />
        </PrivateRoute>
      }>
      <Route path={ROUTES.PATHS.ADMIN.ADMIN_VEHICLE_DETAILS} element={<BookingDetails />} />
      
    </Route>
    </>
  ];
};

export default Admin;
