import React from 'react';
import { Navigate, Outlet, Route } from 'react-router-dom';

import { ROLE } from 'api/auth/types';
import Sidebar from 'components/Sidebar';
import PrivateRoute from 'pages/PrivateRoute';
import { ROUTES } from 'shared/routes/constants';
import Content from 'uicontainers/_shared/layout/Content';
import Bookings from 'uicontainers/Customer/Bookings';
import Confirmation from 'uicontainers/tripBooking/Confirmation';
import BookingVehicleDetail from 'uicontainers/tripBooking/vehicleDetails/Home';
import { getSideBarMenu } from './helpers';
import Payment from 'uicontainers/tripBooking/Payment/Payment';

const VehicleBookingLayout = () => {
  return (
    <Content>
      <Outlet />
    </Content>
  );
};

const CustomerLayout = () => {
  return (
    <Content leftSection={<Sidebar menuItems={getSideBarMenu()} />}>
      <Outlet />
    </Content>
  );
};

const Customer = () => {
  return [
    <>
      <Route
        path={ROUTES.PATHS.CUSTOMER.CUSTOMER_HOME}
        element={
          <PrivateRoute roles={[ROLE.CUSTOMER]}>
            <CustomerLayout />
          </PrivateRoute>
        }
      >
        <Route
          index
          element={
            <Navigate replace to={ROUTES.PATHS.CUSTOMER.CUSTOMER_BOOKINGS} />
          }
        />
        <Route
          path={ROUTES.PATHS.CUSTOMER.CUSTOMER_BOOKINGS}
          element={<Bookings />}
        />

        {/* <Route path={ROUTES.PATHS.CUSTOMER.CUSTOMER_TRIP_HISTORY} element={<TripHistory />} />
        <Route
          path={ROUTES.PATHS.CUSTOMER.CUSTOMER_TOLL_AND_TICKETS}
          element={<TollAndTickets />}
        />
        <Route
          path={ROUTES.PATHS.CUSTOMER.CUSTOMER_BOOKING_DETAILS}
          element={<BookingVehicleDetail />}

        />
        /> */}
      </Route>

      <Route
        path={ROUTES.PATHS.CUSTOMER.CUSTOMER_BOOKINGS}
        element={
          <PrivateRoute roles={[ROLE.CUSTOMER]}>
            <VehicleBookingLayout />
          </PrivateRoute>
        }
      >
        <Route
          path={ROUTES.PATHS.CUSTOMER.CUSTOMER_BOOKING_DETAILS}
          element={<BookingVehicleDetail />}
        />
      </Route>

      <Route
        path={ROUTES.PATHS.CUSTOMER.CUSTOMER_CREATE_BOOKING}
        element={
          <PrivateRoute roles={[ROLE.CUSTOMER]}>
            <VehicleBookingLayout />
          </PrivateRoute>
        }
      >
        <Route
          path={ROUTES.PATHS.CUSTOMER.CUSTOMER_CREATE_BOOKING_VEHICLE}
          element={<BookingVehicleDetail />}
        />
        <Route
          path={ROUTES.PATHS.CUSTOMER.CUSTOMER_CREATE_BOOKING_VEHICLE_CHECKOUT}
          element={<Payment />}
        />
        <Route
          path={
            ROUTES.PATHS.CUSTOMER.CUSTOMER_CREATE_BOOKING_VEHICLE_CONFIRMATION
          }
          element={<Confirmation />}
        />
      </Route>
    </>,
  ];
};

export default Customer;
