import React, { FC, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Form, Input as AntInput, Spin } from 'antd';
import classNames from 'classnames';
import { UploadOutlined } from '@ant-design/icons';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import { UploadFile } from 'antd/lib/upload/interface';

import Input from 'components/Input';
import SelectInput from 'components/SelectInput';
import RadioButton from 'components/RadioButton';
import Button from 'components/Button';
import { useUnwrapAsyncThunk } from 'store/hooks';
import { getInsuranceCompanyAsync } from 'store/provider/vehicles/actions';

import TopSection from '../TopSection';
import LeftSection from '../LeftSection';

import { INSURANCE_TYPE } from '../constants';
import styles from '../vehicles.module.scss';
import FileUploader from 'components/FileUploader';
import { SelectOption } from 'components/SelectInput/types';
import {
  useUpdateCarProtectionPlan,
  useCarProtectionSelectors,
  useUpdateCaInsurancePaper,
  useDeleteInsurancePaper,
} from './hooks';
import { transformToUploadFile } from '../helpers';
import { useGetVehicleDetails } from '../hooks';
import { Protection as ProtectionType } from './types';

const Title = Typography.Title;
const Text = Typography.Text;
const { TextArea } = AntInput;

const Protection: FC = () => {
  const [form] = Form.useForm();
  const { vehicleId } = useParams();
  const unwrap = useUnwrapAsyncThunk();

  const {
    protectionPlan,
    id,
    getVehicleRequestStatus,
    insuranceCompanies,
    getInsuranceRequestStatus,
  } = useCarProtectionSelectors();
  const updateProtectionPlan = useUpdateCarProtectionPlan(id);
  const updateDocument = useUpdateCaInsurancePaper(id);
  const deleteInsurancePaper = useDeleteInsurancePaper(id);

  const [insurancePaper, setInsurancePaper] = useState<UploadFile[]>([]);
  const [companies, setCompanies] = useState<SelectOption[]>([]);
  const [changedValues, setChangedValues] = useState<Partial<ProtectionType>>(
    {}
  );

  const isLoading =
    getVehicleRequestStatus === 'started' ||
    getInsuranceRequestStatus === 'started';

  const getVehicleDetails = useGetVehicleDetails();

  const handleSearch = (text: string) => {
    if (isEmpty(text)) {
      setCompanies(insuranceCompanies);
    } else {
      setCompanies(
        insuranceCompanies.filter((company) =>
          company.name.toLowerCase().includes(text.toLowerCase())
        )
      );
    }
  };

  useEffect(() => {
    unwrap(getInsuranceCompanyAsync());
    if (vehicleId) {
      getVehicleDetails(Number(vehicleId));
    }
  }, []);

  useEffect(() => {
    form.resetFields();
    const {
      insurance_by_id,
      insurance_number,
      coverage_overview,
      insurance_type,
    } = protectionPlan;
    form.setFieldsValue({
      insurance_by: changedValues.insurance_by
        ? changedValues.insurance_by
        : insurance_by_id
        ? insurance_by_id.toString()
        : '',
      insurance_type: changedValues.insurance_type || insurance_type,
      insurance_number: changedValues.insurance_number || insurance_number,
      coverage_overview: changedValues.coverage_overview || coverage_overview,
    });
    setInsurancePaper(transformToUploadFile(protectionPlan?.insurance_paper));
  }, [protectionPlan]);

  useEffect(() => {
    setCompanies(insuranceCompanies);
  }, [insuranceCompanies]);

  const isDisable = protectionPlan.status !== 'INACTIVE';

  return (
    <div
      className={classNames({
        [styles.container]: true,
      })}
    >
      <TopSection />
      <div className={styles.vehicle}>
        <LeftSection />
        <div
          className={classNames({
            [styles.formContainer]: true,
          })}
        >
          <Spin spinning={isLoading}>
            <Title level={2}>Vehicle Protection</Title>
            <Form
              form={form}
              className={styles.form}
              onFinish={({ ...others }) => {
                updateProtectionPlan({
                  ...others,
                });
              }}
              onValuesChange={(changedValues) => {
                setChangedValues((prevState) => {
                  return { ...prevState, ...changedValues };
                });
              }}
              layout="vertical"
            >
              <Form.Item name="insurance_type">
                <RadioButton
                  disabled={isDisable}
                  title="Insurance Type"
                  options={[
                    { label: 'Owner Provided', value: INSURANCE_TYPE.OWNER },
                    { label: 'Jaunt Offered', value: INSURANCE_TYPE.ONE_GO },
                  ]}
                  value={protectionPlan.insurance_type}
                />
              </Form.Item>
              <Form.Item name="insurance_by">
                <SelectInput
                  disabled={isDisable}
                  title="Insured by"
                  options={companies}
                  value={protectionPlan.insurance_by_id?.toString()}
                  showSearch
                  onSearch={handleSearch}
                  filterOption={false}
                />
              </Form.Item>
              <Form.Item name="insurance_number">
                <Input label="Insurance Number" disabled={isDisable} />
              </Form.Item>
              <div>
                <div className={styles.areaTitle}>
                  <Text strong>Coverage Overview</Text>
                </div>
                <Form.Item name="coverage_overview">
                  <TextArea rows={4} disabled={isDisable} />
                </Form.Item>
              </div>
              <div></div>

              <div>
                <div className={styles.areaTitle}>
                  <Text strong>Insurance papers</Text>
                  <br />
                  <Text>
                    Upload a photo or PDF copy of your insurance papers
                  </Text>
                </div>
                <Form.Item>
                  <FileUploader
                    disabled={isDisable}
                    name="insurance_paper"
                    onUpload={updateDocument}
                    multiple={false}
                    maxCount={1}
                    onRemove={() => {
                      deleteInsurancePaper({
                        insurance_paper: null,
                      });
                    }}
                    fileList={insurancePaper}
                  >
                    <Button
                      disabled={isDisable}
                      className={styles.uploadButton}
                      icon={<UploadOutlined />}
                      isBordered
                    >
                      Click to Upload
                    </Button>
                  </FileUploader>
                </Form.Item>
              </div>
              {!isDisable && (
                <Button
                  className={styles.button}
                  type="default"
                  isBordered={true}
                  htmlType="submit"
                  disabled={isDisable}
                >
                  <div className={styles.textContainer}>
                    <span className={styles.buttonText}>Save</span>
                  </div>
                </Button>
              )}
            </Form>
          </Spin>
        </div>
      </div>
    </div>
  );
};

export default Protection;
