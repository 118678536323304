import { TicketStatus } from 'api/provider/tickets/types';
import { HealthTagProps } from 'components/tables/cells/HealthCell/types';
import { Column } from 'components/tables/IXTable/types';

export const COLUMNS: Column[] = [
  {
    name: 'Notice No.',
    key: 'notice_no',
    type: 'image-and-text-cell',
  },
  {
    name: 'Vehicle',
    key: 'vehicle',
    type: 'text',
  },
  {
    name: 'Reg No.',
    key: 'reg_no',
    type: 'text',
  },
  {
    name: 'Location',
    key: 'location',
    type: 'text',
  },
  {
    name: 'Date',
    key: 'date',
    type: 'text',
  },
  {
    name: 'Status',
    key: 'status',
    type: 'health',
  },
  {
    name: 'Toll Amount',
    key: 'toll_amount',
    type: 'text',
  },
];

export const TOLL_TICKET_STATUS_TAG_PROPS: Record<TicketStatus, HealthTagProps> = {
  PAID: {
    name: 'Paid',
    color: 'green',
  },
  UNPAID: {
    name: 'Unpaid',
    color: 'red',
  },
  CONTESTED: {
    name: 'Contested',
    color: '#FA541C',
  },
};
