import { combineReducers as _ } from 'redux';

import earningReducer from './dashboard/Earning/reducer';

export const adminReducer = _({
  dashboard: _({
    earning: earningReducer,
  }),
});

export default adminReducer;
