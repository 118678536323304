import React from 'react';
import { Typography } from 'antd';
import { StarFilled } from '@ant-design/icons';

import classNames from 'classnames';

import styles from './tripRating.module.scss';
import { TripRatingProps } from './types';

const { Text } = Typography;

const TripRating = (props: TripRatingProps) => {
  const { rating, trips, className = '' } = props;

  return (
    <>
      <Text
        className={classNames({
          [styles.rating]: true,
          [className]: Boolean(className),
        })}
      >
        <StarFilled className={styles.starIcon} /> <Text strong>{rating}</Text>
        <span id="trips" className={styles.trips}>
          ({trips} trips)
        </span>
      </Text>
    </>
  );
};

export default TripRating;
