import { Empty, Spin } from 'antd';
import React from 'react';

import IXTable from 'components/tables/IXTable';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getTopEarningProvidersAsync } from 'store/admin/dashboard/actions';
import styles from './topEarningProviders.module.scss';
import { COLUMNS } from './constants';
import { getTopEarningProvidersTableData } from './helpers';
import { selectTopEarningProviders, selectTopEarningProvidersRequestStatus } from './selectors';
import NXCard from 'components/NXCard';

const TopEarningProviders = () => {
  const topEarningProvidersData = getTopEarningProvidersTableData(
    useAppSelector(selectTopEarningProviders),
  );
  const isTopEarningProvidersLoading =
    useAppSelector(selectTopEarningProvidersRequestStatus) === 'started';

  const dispatch = useAppDispatch();
  React.useEffect(() => {
    dispatch(getTopEarningProvidersAsync());
  }, []);

  return (
    <Spin spinning={isTopEarningProvidersLoading}>
      <NXCard
        headerTitle="Top Earning Providers"
        hasPadding={false}
        cssClassNames={styles.adminDashboardTopEarningProviders}
      >
        {
          topEarningProvidersData?.length > 0
            ? <IXTable data={topEarningProvidersData} columns={COLUMNS} />
            : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        }
      </NXCard>
    </Spin>
  );
};

export default TopEarningProviders;
