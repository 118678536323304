
import { Column } from 'components/tables/IXTable/types';

export const COLUMNS: Column[] = [
  {
    name: 'Trip duration',
    key: 'trip_duration',
    type: 'text',
  },
  {
    name: 'Provider',
    key: 'provider',
    type: 'text',
  },
  {
    name: 'Vehicle',
    key: 'vehicle',
    type: 'text',
  },
  {
    name: 'Booked',
    key: 'booked',
    type: 'text',
  },
  {
    name: 'Trip status',
    key: 'trip_status',
    type: 'health',
  },
  {
    name: 'Total Price',
    key: 'total_amount',
    type: 'text',
    align: 'right',
  },
  {
    name: 'Receipt',
    key: 'receipt',
    type: 'text',
  },
  {
    name: '',
    key: 'three_dot_btn',
    type: 'action-button-cell',
  },
  {
    name: '',
    key: 'cancel_button',
    type: 'action-button-cell',
  },
];