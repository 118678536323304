import { Spin } from 'antd';
import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import IXTable from 'components/tables/IXTable';
import {
  getVehiclesAsync,
  setCurrentPage,
  setQueryParams,
} from 'store/admin/vehicles/actions';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import TableHeader from 'uicontainers/_shared/TableHeader';

import { COLUMNS } from './constants';
import {
  createSelectVehicleTableData,
  selectCurrentPage,
  selectTotalCount,
  selectVehiclesRequestStatus,
} from './selectors';
import styles from './vehicles.module.scss';
import { ROUTES } from 'shared/routes/constants';
import { VehiclesTableData } from './types';

const Vehicles: FC = () => {
  const [searchValue, setSearchValue] = useState('');
  const [selectedValue, setSelectedValue] = useState('');
  const navigate = useNavigate();

  const vehiclesTableData = useAppSelector(createSelectVehicleTableData);
  const totalCount = useAppSelector(selectTotalCount);
  const currentPage = useAppSelector(selectCurrentPage);

  const isVehiclesLoading =
    useAppSelector(selectVehiclesRequestStatus) === 'started';
  const dispatch = useAppDispatch();

  const options = [
    { name: 'Active', value: 'ACTIVE' },
    { name: 'Inactive', value: 'INACTIVE' },
    { name: 'Request for approval', value: 'REQUEST_FOR_APPROVAL' },
  ];

  React.useEffect(() => {
    let searchParams = {};
    if (searchValue) {
      searchParams = { ...searchParams, search: searchValue };
    }
    if (selectedValue) {
      searchParams = { ...searchParams, status: selectedValue };
    }
    if (currentPage) {
      searchParams = { ...searchParams, page: currentPage };
    }
    dispatch(setQueryParams(searchParams));
    dispatch(getVehiclesAsync(searchParams));
  }, [searchValue, selectedValue, currentPage, getVehiclesAsync]);

  const onChange = (page: number) => {
    dispatch(setCurrentPage(page));
  };

  const handleSearch = (value: string) => {
    setSearchValue(value);
    dispatch(setCurrentPage(1));
  };
  const handleSelect = (value: string) => {
    setSelectedValue(value);
    dispatch(setCurrentPage(1));
  };

  const goToVehicleDetails = (record: VehiclesTableData) => {
    navigate(ROUTES.ADMIN_VEHICLE_DETAILS_ID(record.key));
  };

  return (
    <div className={styles.root}>
      <Spin spinning={isVehiclesLoading}>
        <TableHeader
          title="Vehicles"
          onSearch={handleSearch}
          onSelectChange={handleSelect}
          dropdownTitle="Status"
          options={options}
        />
        <IXTable
          onRowClick={goToVehicleDetails}
          data={vehiclesTableData}
          columns={COLUMNS}
          pagination={{ currentPage: currentPage, count: totalCount, onChange }}
        />
      </Spin>
    </div>
  );
};

export default Vehicles;
