import { combineReducers as _, configureStore } from '@reduxjs/toolkit';

import provider from 'uicontainers/provider/providerReducer';
import admin from 'uicontainers/admin/providerReducer';
import customer from 'uicontainers/Customer/customerReducer';
import tripBookingReducer from 'uicontainers/tripBooking/slice';

import providerBillingsReducer from './provider/billings/reducer';
import providerCustomersReducer from './provider/customers/reducer';
import providerDashboardReducer from './provider/dashboard/reducer';
import providerTicketsReducer from './provider/tickets/reducer';
import providerTollsReducer from './provider/tolls/reducer';
import providerTripsReducer from './provider/tripHistory/reducer';
import providerVehiclesReducer from './provider/vehicles/reducer';
import providerCalendarsReducer from './provider/calendars/reducer';
import providerRentalAgreementReducer from './provider/rentalAgreement/reducer';

import adminBillingsReducer from './admin/billings/reducer';
import adminCustomersReducer from './admin/customers/reducer';
import adminProvidersReducer from './admin/providers/reducer';
import adminDashboardReducer from './admin/dashboard/reducer';
import adminTicketsReducer from './admin/tickets/reducer';
import adminTollsReducer from './admin/tolls/reducer';
import adminTripsReducer from './admin/tripHistory/reducer';
import adminVehiclesReducer from './admin/vehicles/reducer';
import adminRentalAgreementReducer from './admin/rentalAgreement/reducer';

import customerBookingsReducer from './customer/bookings/reducer';
import CustomerBookingReducer from './customer/booking/reducer';
import tripBookingSearchedVehicleReducer from './tripBooking/home/reducer';
import tripBookingVehicleDetailsReducer from './tripBooking/vehicleDetails/reducer';
import tripBookingProviderDetailsReducer from './tripBooking/providerDetails/reducer';
import tripBookingPaymentReducer from './tripBooking/payment/reducer';
import tripBookingVehicleCategoryReducer from './tripBooking/vehicleCategory/reducer';

import authReducer from './shared/auth/reducer';
import customerAccountReducer from './customer/account/reducer';
import tripHistoryReducer from './customer/tripHistory/reducer';
import customerTollsReducer from './customer/tolls/reducer';
import customerTicketsReducer from './customer/tickets/reducer';

const rootReducer = _({
  resources: _({
    provider: _({
      dashboard: providerDashboardReducer,
      vehicles: providerVehiclesReducer,
      customers: providerCustomersReducer,
      trips: providerTripsReducer,
      billings: providerBillingsReducer,
      tickets: providerTicketsReducer,
      tolls: providerTollsReducer,
      calendars: providerCalendarsReducer,
      rentalAgreement: providerRentalAgreementReducer,
    }),
    customer: _({
      account: customerAccountReducer,
      bookings: customerBookingsReducer,
      booking: CustomerBookingReducer,
      tripHistories: tripHistoryReducer,
      tolls: customerTollsReducer,
      tickets: customerTicketsReducer,
    }),
    tripBooking: _({
      searchedVehicles: tripBookingSearchedVehicleReducer,
      vehicledetails: tripBookingVehicleDetailsReducer,
      providerDetails: tripBookingProviderDetailsReducer,
      paymentResponse: tripBookingPaymentReducer,
      vehicleCategory: tripBookingVehicleCategoryReducer,
    }),
    admin: _({
      dashboard: adminDashboardReducer,
      vehicles: adminVehiclesReducer,
      customers: adminCustomersReducer,
      providers: adminProvidersReducer,
      trips: adminTripsReducer,
      billings: adminBillingsReducer,
      tickets: adminTicketsReducer,
      tolls: adminTollsReducer,
      rentalAgreement: adminRentalAgreementReducer,
    }),
  }),
  ui: _({
    provider,
    admin,
    customer,
    auth: authReducer,
    tripBooking: tripBookingReducer,
  }),
});

export const store = configureStore({
  reducer: rootReducer,
});
