import { createAction } from '@reduxjs/toolkit';

import { BasicInfo } from './types';
import uiSlice from './slice';

const { resetBasicInfo } = uiSlice.actions;

export const updateBasicInfo = createAction<BasicInfo>('form/vehicle/update_basic_info');

export { resetBasicInfo };
