import {
  formatHTTPErrorMessage,
  notification,
} from 'shared/helpers/notifications';
import { setIsAuthenticated, setUserData } from 'store/shared/auth/actions';
import { ACCESS_TOKEN_NAME } from './constants';
import { store } from 'store';

import { axiosCreate } from './helpers';
import {
  InterceptErrorResponse,
  InterceptSuccessRequest,
  InterceptSuccessResponse,
} from './types';

export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export const interceptSuccessRequest: InterceptSuccessRequest = (request) => {
  const accessToken = localStorage.getItem(ACCESS_TOKEN_NAME);

  if (accessToken && request.headers) {
    request.headers.Authorization = `Bearer ${accessToken}`;
    request.headers[
      'X-Timezone'
    ] = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  return request;
};

export const interceptSuccessResponse: InterceptSuccessResponse = (
  response
) => {
  const txtMatch = new RegExp('/agreements/[0-9]?');
  if (response.config.url) {
    if (txtMatch.test(response.config.url)) {
      return response.data;
    }
  }
  if (response?.data.status) {
    return response.data.success_data;
  }
  return {};
};

export const interceptErrorResponse: InterceptErrorResponse = (error) => {
  if (
    error?.response?.status === 401 &&
    error?.response?.data?.error_data?.code === 'token_not_valid'
  ) {
    localStorage.removeItem(ACCESS_TOKEN_NAME);
    store.dispatch(setIsAuthenticated(false));
    store.dispatch(setUserData(undefined));
  } else {
    notification({
      type: 'error',
      title: 'Error occurred',
      description: formatHTTPErrorMessage(error),
    });
  }

  return Promise.reject(error);
};

const authAPI = axiosCreate({
  baseURL: `${API_ENDPOINT}/users`,
})(interceptSuccessResponse, interceptErrorResponse);

const customerAPI = axiosCreate({
  baseURL: `${API_ENDPOINT}/customer`,
})(interceptSuccessResponse, interceptErrorResponse, interceptSuccessRequest);

const userAPI = axiosCreate({
  baseURL: `${API_ENDPOINT}/users`,
})(interceptSuccessResponse, interceptErrorResponse, interceptSuccessRequest);

const providerAPI = axiosCreate({
  baseURL: `${API_ENDPOINT}/provider`,
})(interceptSuccessResponse, interceptErrorResponse, interceptSuccessRequest);

const adminAPI = axiosCreate({
  baseURL: `${API_ENDPOINT}/admin`,
})(interceptSuccessResponse, interceptErrorResponse, interceptSuccessRequest);

const publicAPI = axiosCreate({
  baseURL: `${API_ENDPOINT}/public`,
})(interceptSuccessResponse, interceptErrorResponse, interceptSuccessRequest);

const pdfGeneratorAPI = axiosCreate({
  baseURL: '',
})(interceptSuccessResponse, interceptErrorResponse, interceptSuccessRequest);

const silentAPI = axiosCreate({
  baseURL: `${API_ENDPOINT}/users`,
})(interceptSuccessResponse);

const paymentAPI = axiosCreate({
  baseURL: `${API_ENDPOINT}/payment`,
})(interceptSuccessResponse, interceptErrorResponse, interceptSuccessRequest);

export {
  providerAPI,
  adminAPI,
  authAPI,
  userAPI,
  publicAPI,
  pdfGeneratorAPI,
  silentAPI,
  paymentAPI,
};

export default customerAPI;
