import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  activateAccount,
  login,
  passwordResetRequest,
  resetPassword,
  signup,
  verifyToken,
  getStripeOnboardingLink,
  acknowledgeOnboarding,
} from 'api/auth/index';
import {
  AccountActivationData,
  LoginPostData,
  SignupPostData,
} from 'api/auth/types';

export const loginAsync = createAsyncThunk(
  'login',
  async (loginData: LoginPostData) => {
    const response = await login(loginData);
    return response;
  }
);

export const signupAsync = createAsyncThunk(
  'signup',
  async (loginData: SignupPostData) => {
    const response = await signup(loginData);
    return response;
  }
);

export const passwordResetRequestAsync = createAsyncThunk(
  'password-reset-request',
  async (email: string) => {
    const response = await passwordResetRequest(email);
    return response;
  }
);

export const resetPasswordAsync = createAsyncThunk(
  'reset-password',
  async (activationData: AccountActivationData) => {
    const response = await resetPassword(activationData);
    return response;
  }
);

export const activateAccountAsync = createAsyncThunk(
  'activate-account',
  async (activationData: AccountActivationData) => {
    const response = await activateAccount(activationData);
    return response;
  }
);

export const verifyTokenAsync = createAsyncThunk(
  'token-verify',
  async (token: string) => {
    const response = await verifyToken(token);
    return response;
  }
);

export const getStripeOnboardingLinkAsync = createAsyncThunk(
  'get-stripe-onboarding-link',
  async () => {
    const response = await getStripeOnboardingLink();
    return response;
  }
);

export const acknowledgeOnboardingAsync = createAsyncThunk(
  'acknowledge-onboarding',
  async () => {
    const response = await acknowledgeOnboarding();
    return response;
  }
);
