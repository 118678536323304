import React, { FC, useState, useEffect } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';

import classNames from 'classnames';
import { RightOutlined, UploadOutlined } from '@ant-design/icons';

import { UploadFile } from 'antd/lib/upload/interface';

import Button from 'components/Button';
import FileUploader from 'components/FileUploader';
import {
  Title,
  Text,
  Form,
  Spin,
  FormItem,
  useForm,
  DatePicker,
} from 'components/misc/helpers';

import TopSection from '../TopSection';
import LeftSection from '../LeftSection';
import styles from '../vehicles.module.scss';

import {
  useCarDocumentsUpdate,
  useCarDocumentSelectors,
  useNextRedirect,
  useUpdateVehicle,
} from './hooks';
import { transformToUploadFile } from '../helpers';
import { useGetVehicleDetails } from '../hooks';
import { CarDocuments as CarDocumentsType } from './types';

const Documents: FC = () => {
  const { vehicleId } = useParams();
  const [form] = useForm();

  const {
    id,
    carDocument,
    getVehicleDetailsRequestStatus,
  } = useCarDocumentSelectors();
  const updateDocument = useCarDocumentsUpdate(id);
  const updateVehicle = useUpdateVehicle();
  const nextHandler = useNextRedirect();

  const [regoPaper, setRegoPaper] = useState<UploadFile[]>([]);
  const [pinkSlip, setPinkSlip] = useState<UploadFile[]>([]);
  const [greenSlip, setGreenSlip] = useState<UploadFile[]>([]);
  const [expirationDate, setExpirationDate] = useState<string | undefined>('');

  const [changedValues, setChangedValues] = useState<Partial<CarDocumentsType>>(
    {}
  );

  const isLoading = getVehicleDetailsRequestStatus === 'started';

  const getVehicleDetails = useGetVehicleDetails();

  useEffect(() => {
    if (vehicleId) {
      getVehicleDetails(Number(vehicleId));
    }
  }, [vehicleId]);

  useEffect(() => {
    setRegoPaper(transformToUploadFile(carDocument.rego_paper));
  }, [carDocument.rego_paper]);

  useEffect(() => {
    setPinkSlip(transformToUploadFile(carDocument.pink_slip));
  }, [carDocument.pink_slip]);

  useEffect(() => {
    setGreenSlip(transformToUploadFile(carDocument.green_slip));
  }, [carDocument.green_slip]);

  useEffect(() => {
    setExpirationDate(carDocument.expiration_date);
  }, [carDocument.expiration_date]);

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({
      expiration_date: changedValues.expiration_date
        ? moment(changedValues.expiration_date)
        : expirationDate
        ? moment(expirationDate)
        : '',
    });
  }, [expirationDate]);

  const isDisable = carDocument.status !== 'INACTIVE';

  return (
    <div
      className={classNames({
        [styles.container]: true,
      })}
    >
      <TopSection />
      <div className={styles.vehicle}>
        <LeftSection />
        <div
          className={classNames({
            [styles.formContainer]: true,
          })}
        >
          <Spin spinning={isLoading}>
            <Title level={2}>Upload Documents</Title>
            <Form
              form={form}
              className={styles.form}
              onFinish={({ expiration_date, ...others }) => {
                updateDocument({
                  ...others,
                  expiration_date: moment(expiration_date).format(),
                });
                nextHandler();
              }}
              onValuesChange={(changedValues) => {
                setChangedValues((prevState) => {
                  return { ...prevState, ...changedValues };
                });
              }}
            >
              <div className={styles.upperTitleContainer}>
                <Text strong>Rego paper</Text>
                <br />
                <Text>Upload a photo or PDF copy of your rego paper</Text>
              </div>
              <FormItem>
                <FileUploader
                  disabled={isDisable}
                  name="rego_paper"
                  onUpload={updateDocument}
                  multiple={false}
                  maxCount={1}
                  fileList={regoPaper}
                  onRemove={() => {
                    updateVehicle({
                      rego_paper: null,
                    });
                  }}
                >
                  <Button
                    disabled={isDisable}
                    className={styles.uploadButton}
                    icon={<UploadOutlined />}
                    isBordered
                  >
                    Click to Upload
                  </Button>
                </FileUploader>
                <div>
                  <div className={styles.areaTitle}>
                    <Text strong>Expiration date</Text>
                  </div>
                  <FormItem
                    name="expiration_date"
                    rules={[
                      {
                        required: true,
                        message: 'Expiration date is required!',
                      },
                    ]}
                  >
                    <DatePicker disabled={isDisable} />
                  </FormItem>
                </div>
              </FormItem>
              <div className={styles.lowerTitle}>
                <Text>
                  Need to be verified before car can be listed for rental
                </Text>
              </div>

              <br />
              <div className={styles.divider} />
              <br />
              <br />

              <div className={styles.upperTitleContainer}>
                <Text strong>Vehicle Inspection Document</Text>
                <br />
                <Text>
                  Upload a photo or PDF copy of your vehicle inspection document
                </Text>
              </div>
              <FormItem>
                <FileUploader
                  disabled={isDisable}
                  name="pink_slip"
                  onUpload={updateDocument}
                  multiple={false}
                  maxCount={1}
                  fileList={pinkSlip}
                  onRemove={() => {
                    updateVehicle({
                      pink_slip: null,
                    });
                  }}
                >
                  <Button
                    disabled={isDisable}
                    className={styles.uploadButton}
                    icon={<UploadOutlined />}
                    isBordered
                  >
                    Click to Upload
                  </Button>
                </FileUploader>
              </FormItem>
              <div className={styles.lowerTitle}>
                <Text>
                  Need to be verified before car can be listed for rental
                </Text>
              </div>

              <br />
              <div className={styles.divider} />
              <br />
              <br />

              <div className={styles.upperTitleContainer}>
                <Text strong>Green slip</Text>
                <br />
                <Text>Upload a photo or PDF copy of your green slip</Text>
              </div>
              <FormItem>
                <FileUploader
                  disabled={isDisable}
                  name="green_slip"
                  onUpload={updateDocument}
                  multiple={false}
                  fileList={greenSlip}
                  maxCount={1}
                  onRemove={() => {
                    updateVehicle({
                      green_slip: null,
                    });
                  }}
                >
                  <Button
                    className={styles.uploadButton}
                    icon={<UploadOutlined />}
                    isBordered
                    disabled={isDisable}
                  >
                    Click to Upload
                  </Button>
                </FileUploader>
              </FormItem>
              <div className={styles.lowerTitle}>
                <Text>
                  Need to be verified before car can be listed for rental
                </Text>
              </div>

              <br />
              <div className={styles.divider} />
              <br />
              <br />

              {!isDisable && (
                <Button
                  className={styles.button}
                  type="default"
                  isBordered={true}
                  disabled={isDisable}
                  htmlType="submit"
                >
                  <div className={styles.textContainer}>
                    <span className={styles.buttonText}>Save and Continue</span>
                    <RightOutlined className={styles.rightIcon} />
                  </div>
                </Button>
              )}
            </Form>
          </Spin>
        </div>
      </div>
    </div>
  );
};

export default Documents;
