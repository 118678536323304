import { createAsyncThunk } from '@reduxjs/toolkit';

import { getTolls } from 'api/provider/tolls';
import { TollsRequestParams } from 'api/provider/tolls/types';

export const getTollsAsync = createAsyncThunk(
  'provider/toll/get_tolls',
  async (params?: TollsRequestParams) => {
    const response = await getTolls(params);
    return response;
  },
);
