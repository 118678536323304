import React from 'react';

import { Vehicle } from 'api/admin/vehicles/types';

import { VEHICLE_STATUS_TAG_PROPS } from './constants';
import { VehiclesTableData } from './types';
import FileIcon from './FileIcon';
import ActionButton from './ActionButton';

export const transformVehiclesToTableData = (
  vehicles: Vehicle[]
): VehiclesTableData[] => {
  return vehicles.map((vehicle) => ({
    key: vehicle.id.toString(),
    car_info: {
      rows: [
        {
          text: `${vehicle?.vehicle_make ?? ''} ${
            vehicle?.vehicle_model ?? ''
          } ${vehicle?.vehicle_year ?? ''}`,
        },
      ],
    },
    provider: {
      text: `${vehicle?.provider ?? ''}`,
    },
    reg_no: {
      text: `${vehicle?.license_no ?? '-'}`,
    },
    trips: {
      text: `${vehicle.trips} Trips`,
    },
    pickup_address: {
      text: `${vehicle?.pickup_address ?? ''}`,
    },
    drop_off_address: {
      text: `${vehicle?.drop_off_address ?? ''}`,
    },
    rego_file: {
      rows: [
        {
          isLinkable: true,
          icon: vehicle?.rego_paper ? (
            <FileIcon regoLink={vehicle?.rego_paper} />
          ) : null,
          iconTo: '',
          textTo: '',
          color: 'pink',
        },
      ],
    },
    status: {
      tags: [
        {
          name: VEHICLE_STATUS_TAG_PROPS[vehicle.status].name,
          color: VEHICLE_STATUS_TAG_PROPS[vehicle.status].color,
        },
      ],
    },
    price_per_day: {
      text: `${vehicle.daily_price}`,
    },
    three_dot_btn: {
      buttons: vehicle.status !== 'REQUEST_FOR_APPROVAL'
        ? []
        : [
            {
              icon: <ActionButton vehicle={vehicle} />,
              onClick: () => {},
            },
          ],
    },
  }));
};
