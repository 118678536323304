import { createSlice } from '@reduxjs/toolkit';

import { handlePaymentAsync } from './thunks';
import { TripBookingPaymentState } from './types';

const initialState: TripBookingPaymentState = {
  paymentResponse: null,
  paymentRequestStatus: 'initial',
};

export const tripBookingPaymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(handlePaymentAsync.pending, (state) => {
        state.paymentRequestStatus = 'started';
      })
      .addCase(handlePaymentAsync.fulfilled, (state, action) => {
        state.paymentRequestStatus = 'done';
        state.paymentResponse = action.payload;
      })
      .addCase(handlePaymentAsync.rejected, (state) => {
        state.paymentRequestStatus = 'failed';
      });
  },
});

export default tripBookingPaymentSlice;
