import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getSearchedVehicles,
  getVehicleTypes,
  getPopularProviders,
} from "api/tripBooking/home";
import { searchedVehicleRequestParams } from "api/tripBooking/home/types";

export const getSearchedVehiclesAsync = createAsyncThunk(
  "customer/trip_booking/searched_vehicles",
  async (params?: searchedVehicleRequestParams) => {
    const response = await getSearchedVehicles(params);
    return response;
  }
);

export const getMOreVehiclesAsync = createAsyncThunk(
  "customer/trip_booking/load_more_vehicles",
  async (params?: searchedVehicleRequestParams) => {
    const response = await getSearchedVehicles(params);
    return response;
  }
);

export const getVehicleTypesAsync = createAsyncThunk(
  "trip_booking/vehicles/get_vehicle_types",
  async () => {
    const response = await getVehicleTypes();
    return response;
  }
);

export const getPopularProviderAsync = createAsyncThunk(
  "trip_booking/home/get_popular_provider",
  async () => {
    return await getPopularProviders();
  }
);
