import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getProvidersAsync } from './thunks';
import { AdminProvidersState } from './types';

const initialState: AdminProvidersState = {
  providers: [],
  providersRequestStatus: 'initial',
  totalCount: 0,
  currentPage: 1,
};

export const adminProviderSlice = createSlice({
  name: 'admin-providers',
  initialState,
  reducers: {
    setCurrentPage(state, action: PayloadAction<number>) {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProvidersAsync.pending, (state) => {
      state.providersRequestStatus = 'started';
    });
    builder.addCase(getProvidersAsync.fulfilled, (state, action) => {
      state.providers = action.payload.results;
      state.providersRequestStatus = 'done';
      state.totalCount = action.payload.count;
    });
    builder.addCase(getProvidersAsync.rejected, (state) => {
      state.providersRequestStatus = 'failed';
    });
  },
});

export default adminProviderSlice;
