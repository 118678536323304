import { saveAs } from 'file-saver';
import React, { useEffect } from 'react';

import { useAppDispatch, useAppSelector, useUnwrapAsyncThunk } from 'store/hooks';
import { selectRentalAgreementStatus } from 'store/provider/rentalAgreement/selectors';
import {
  getRentalContractAsync,
  getRentalPdfLinkAsync,
} from 'store/provider/rentalAgreement/thunks';

import RentalAgreement from './RentalAgreement';

function RentalContract() {
  const providerRentalInfo = useAppSelector(selectRentalAgreementStatus);
  const dispatch = useAppDispatch();
  const unwrap = useUnwrapAsyncThunk();
  useEffect(() => {
    dispatch(getRentalContractAsync());
  }, []);

  const handleRentalContractPrint = () => {
    unwrap(getRentalPdfLinkAsync())
      .then((res) => {
        const blob = new Blob([res], { type: 'octet/stream' });
        saveAs(blob, 'agreement_pdf.pdf');
      })
      .catch((e) => {
        console.log(e);
      });
  };
  return (
    <RentalAgreement
      secondaryTitle={'Rental Contract'}
      richTextEditorLabel={'Agreement Contract'}
      footerText={'Please be sure to save your changes before printing.'}
      template={providerRentalInfo?.rental_contacts_template_type}
      agreementDetail={providerRentalInfo?.rental_contacts_agreement}
      id={providerRentalInfo?.id}
      handleRentalContractPrint={handleRentalContractPrint}
    />
  );
}

export default RentalContract;
