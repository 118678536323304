import { Tabs } from 'antd';
import React, { FC } from 'react';

import styles from './home.module.scss';
import Tickets from '../Tickets';
import Tolls from '../Tolls';

const { TabPane } = Tabs;

const Home: FC = () => {
  return (
    <div className={styles.root}>
      <h1>Toll and Tickets</h1>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Toll" key="1">
          <div className={styles.content}>
            <Tolls></Tolls>
          </div>
        </TabPane>
        <TabPane tab="Tickets" key="2">
          <div className={styles.content}>
            <Tickets></Tickets>
          </div>
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Home;
