import React, { FC } from 'react';
import {
  Col,
  Divider,
  Form,
  Result,
  Row,
  Spin,
  Typography,
  Tooltip,
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import Button from 'components/Button';
import Input from 'components/Input';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { resetPasswordAsync } from 'store/shared/auth/thunks';
import { selectResetPasswordRequestStatus } from 'store/shared/auth/selectors';
import { useQuery } from 'uicontainers/_shared/hooks';

import styles from './resetPassword.module.scss';
import { resetPasswordValues } from './types';
import PrivacyAndTermsFooter from 'uicontainers/_shared/PrivacyAndTermsFooter';

const { Title } = Typography;

const ResetPassword: FC = () => {
  const query = useQuery();
  const securityCode = query.get('securityCode');
  const token = query.get('token');
  const dispatch = useAppDispatch();
  const isResetPasswordLoading =
    useAppSelector(selectResetPasswordRequestStatus) === 'started';
  const isResetPasswordSuccess =
    useAppSelector(selectResetPasswordRequestStatus) === 'done';

  const onFinish = (values: resetPasswordValues) => {
    dispatch(
      resetPasswordAsync({
        securityCode,
        token,
        password: values.password,
      })
    );
  };

  return (
    <Row align="middle">
      <Col xs={20} sm={20} md={20} lg={7} className={styles.container}>
        {isResetPasswordSuccess && (
          <Result
            status="success"
            title="You successfully changed your password!"
            subTitle="You can now login using the new password"
          />
        )}
        {!isResetPasswordSuccess && (
          <Spin spinning={isResetPasswordLoading}>
            <Title level={3} className={styles.title}>
              Reset Password
              <div className="ant-result-subtitle">
                {' '}
                Please enter your new password.
              </div>
            </Title>
            <Form onFinish={onFinish}>
              <Row gutter={[16, 8]}>
                <Col className="gutter-row" span={24}>
                  <Form.Item
                    name="password"
                    rules={[
                      { required: true, message: 'Please input your password' },
                      {
                        min: 8,
                        max: 32,
                        message: 'Password must be 8-32 characters long',
                      },
                    ]}
                  >
                    <Input
                      label="Password"
                      inputType="password"
                      prefix={
                        <div>
                          <Tooltip title="Password must contain 8 characters, at least one Uppercase letter, one lower case letter, one number, one special character">
                            <InfoCircleOutlined
                              style={{
                                color: 'rgba(0,0,0,.45)',
                                marginRight: '.5rem',
                              }}
                            />
                          </Tooltip>
                        </div>
                      }
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={24}>
                  <Form.Item
                    name="confirm"
                    dependencies={['password']}
                    rules={[
                      {
                        required: true,
                        message: 'Please confirm your password!',
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              'The two passwords that you entered do not match!'
                            )
                          );
                        },
                      }),
                    ]}
                  >
                    <Input label="Confirm Password" inputType="password" />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={24}>
                  <Button
                    type="ghost"
                    isBordered={true}
                    style={{ width: '100%' }}
                    htmlType="submit"
                  >
                    Submit
                  </Button>
                </Col>
                <Col className="gutter-row" span={24}>
                  <Divider plain />
                </Col>
                <Col className="gutter-row" span={24}>
                  <PrivacyAndTermsFooter />
                </Col>
              </Row>
            </Form>
          </Spin>
        )}
      </Col>
    </Row>
  );
};

export default ResetPassword;
