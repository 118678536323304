import { createAsyncThunk } from '@reduxjs/toolkit';
import { getVehicleMake, getVehicleModel } from 'api/tripBooking/vehicleCategory';

export const getVehicleMakeAsync = createAsyncThunk(
  'public/vehicles/get_vehicle_make',
  async () => {
    const response = await getVehicleMake();
    return response;
  },
);

export const getVehicleModelAsync = createAsyncThunk(
  'public/vehicles/get_vehicle_model',
  async ({ makeId }: { makeId: string }) => {
    const response = await getVehicleModel(makeId);
    return response;
  },
);
