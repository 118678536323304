import { createAsyncThunk } from '@reduxjs/toolkit';

import { PostRentalContractData } from 'api/admin/rentalAgreement/types';
import {
  handleRentalContract,
  getRentalContract,
  updateRentalContract,
} from 'api/admin/rentalAgreement/index';

export const handleRentalContractAsync = createAsyncThunk(
  'admin/rental-agreement/create-contract',
  async (data: PostRentalContractData) => {
    const response = await handleRentalContract(data);
    return response;
  },
);

export const getAdminRentalInfoAsync = createAsyncThunk(
  'admin/rental-agreement/get-contract',
  async () => {
    const response = await getRentalContract();
    return response;
  },
);

export const updateRentalContractAsync = createAsyncThunk(
  'admin/rental-agreement/update-contract',
  async ({ data, id }: { data: PostRentalContractData; id: number }) => {
    const response = await updateRentalContract(data, id);
    return response;
  },
);
