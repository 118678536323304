import { useSelector } from 'react-redux';

import {
  selectCustomerAccount,
  selectCustomerAccountStatus,
} from 'store/customer/account/selectors';
import { RootState } from 'store/types';

export const useBusinessInfoSelectors = () => {
  return useSelector((state: RootState) => {
    const {
      business_name,
      business_email,
      business_phone,
      business_address,
      abn = '',
    } = selectCustomerAccount(state);

    const status = selectCustomerAccountStatus(state);

    return {
      businessInfo: {
        business_name,
        business_email,
        business_phone,
        business_address,
        abn,
      },
      status,
      hasBusinessInfo: business_name || business_email || business_phone || business_address || abn,
    };
  });
};
