import { Column } from 'components/tables/IXTable/types';

export const COLUMNS: Column[] = [
  {
    name: 'Trip ID',
    key: 'trip_id',
    type: 'text',
  },
  {
    name: 'Customer',
    key: 'customer',
    type: 'text',
  },
  {
    name: 'Trip duration',
    key: 'trip_duration',
    type: 'text',
  },
  {
    name: 'Vehicle',
    key: 'vehicle',
    type: 'text',
  },
  {
    name: 'Booked',
    key: 'booked',
    type: 'text',
  },
  {
    name: 'Trip status',
    key: 'trip_status',
    type: 'health',
  },
  {
    name: '',
    key: 'action_btn',
    type: 'action-button-cell',
  },
];