import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  getBillingsAsync,
  getCurrentBalanceAsync,
  handlePayOutAsync,
} from './thunks';
import { ProviderBillingsState } from './types';

const initialState: ProviderBillingsState = {
  billings: [],
  CurrentBalance: {
    object: '',
    available: [
      {
        amount: 0,
        currency: '',
        source_types: {
          card: 0,
        },
      },
    ],
    livemode: false,
    pending: [],
  },
  getBillingsRequestStatus: 'initial',
  getPayOutRequestStatus: 'initial',
  totalCount: 0,
  currentPage: 1,
};

export const providerBillingsSlice = createSlice({
  name: 'billings',
  initialState,
  reducers: {
    setCurrentPage(state, action: PayloadAction<number>) {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBillingsAsync.pending, (state) => {
      state.getBillingsRequestStatus = 'started';
    });
    builder.addCase(getBillingsAsync.fulfilled, (state, action) => {
      state.billings = action.payload.results;
      state.totalCount = action.payload.count;
      state.getBillingsRequestStatus = 'done';
    });
    builder.addCase(getBillingsAsync.rejected, (state) => {
      state.getBillingsRequestStatus = 'failed';
    });

    builder.addCase(handlePayOutAsync.pending, (state) => {
      state.getPayOutRequestStatus = 'started';
    });
    builder.addCase(handlePayOutAsync.fulfilled, (state, action) => {
      state.getPayOutRequestStatus = 'done';
    });
    builder.addCase(handlePayOutAsync.rejected, (state) => {
      state.getPayOutRequestStatus = 'failed';
    });
    builder.addCase(getCurrentBalanceAsync.pending, (state) => {
      state.getBillingsRequestStatus = 'started';
    });
    builder.addCase(getCurrentBalanceAsync.fulfilled, (state, action) => {
      state.CurrentBalance = action.payload.results;
      state.getBillingsRequestStatus = 'done';
    });
    builder.addCase(getCurrentBalanceAsync.rejected, (state) => {
      state.getBillingsRequestStatus = 'failed';
    });
  },
});

export default providerBillingsSlice;
