import { TopEarningProviders } from 'api/admin/dashboard/types';

import { TopEarningProvidersTableData } from './types';

export const getTopEarningProvidersTableData = (
  topEarningProviders: TopEarningProviders[],
): TopEarningProvidersTableData[] => {
  return topEarningProviders.map((topEarningProvider) => ({
    key: topEarningProvider.id,
    provider: {
      text: topEarningProvider.name,
    },
    earnings: {
      text: `$ ${topEarningProvider.revenue.toString()}`,
    },
  }));
};
