import React, { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import moment from 'moment';
import { Elements } from '@stripe/react-stripe-js';
import {
  useAppDispatch,
  useAppSelector,
  useUnwrapAsyncThunk,
} from 'store/hooks';

import {
  selectVehicleDetails,
  selectCarBookingDetails,
} from 'store/tripBooking/vehicleDetails/selectors';
import { Row, Col, Space } from 'antd';
import Title from 'antd/lib/typography/Title';
import Card from 'components/NXCard';
import CheckoutForm from '../CheckoutForm';
import {
  Appearance,
  loadStripe,
  StripeElementsOptions,
} from '@stripe/stripe-js';
import styles from './payment.module.scss';
import { useIsMobile } from 'shared/hooks';
import { SwapRightOutlined } from '@ant-design/icons';
import {
  bookCarAsync,
  getVehicleDetailsAsync,
} from 'store/tripBooking/vehicleDetails/actions';
import { selectBooking } from 'store/customer/booking/selectors';
import { getBookingDetailsAsync } from 'store/customer/booking/thunks';
import { resetVehicleDetails } from 'store/tripBooking/vehicleDetails/actions';
import { selectPaymentRequestStatus } from 'store/tripBooking/payment/selectors';
import { Spin } from 'components/misc/helpers';

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISHABLE_API_KEY as string
);

function Payment() {
  const unwrap = useUnwrapAsyncThunk();
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();
  const vehicleDetails = useAppSelector(selectVehicleDetails);
  const carBookingDetails = useAppSelector(selectCarBookingDetails);
  const { vehicleId = '', tripStartDate = '', tripEndDate = '' } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const bookId = searchParams.get('bookId');
  const bookingDetails = useAppSelector(selectBooking);
  const isLoading = useAppSelector(selectPaymentRequestStatus) === 'started';

  useEffect(() => {
    if (bookId) {
      unwrap(getBookingDetailsAsync(parseInt(bookId)));
    }

    if (tripStartDate && tripEndDate) {
      unwrap(
        bookCarAsync({
          vehicle_id: vehicleId as string,
          start_date: tripStartDate,
          end_date: tripEndDate,
        })
      );
    }
    dispatch(getVehicleDetailsAsync(vehicleId));
  }, []);

  const appearance: Appearance = {
    theme: 'stripe',
  };
  const options: StripeElementsOptions = {
    appearance,
  };

  const title = (
    <Title className={styles.title} level={4}>
      {`${vehicleDetails?.vehicle_make ?? ''} ${
        vehicleDetails?.vehicle_model ?? ''
      } ${vehicleDetails?.vehicle_year ?? ''}`}
    </Title>
  );

  const description = (
    <div className={styles.description}>
      <div className={styles.middle}>
        <div className={styles.trip}>
          <Space direction="vertical" size={2}>
            <Title level={5}>Trip start</Title>
            <span className={styles.time}>
              {moment(carBookingDetails?.start_date).format('YYYY-MM-DD')}
            </span>
            <p>{carBookingDetails?.pickup_address}</p>
          </Space>
        </div>
        <div className={styles.icon}>
          <SwapRightOutlined twoToneColor="grey" />
        </div>
        <div className={styles.trip}>
          <Space direction="vertical" size={2}>
            <Title level={5}>Trip end</Title>
            <span className={styles.startTime}>
              {moment(carBookingDetails?.end_date).format('YYYY-MM-DD')}
            </span>
            <p>{carBookingDetails?.drop_off_address}</p>
          </Space>
        </div>
      </div>
      <div className={styles.bottomFirst}>
        <Space direction="vertical">
          <Row>
            <Col span={18}>
              <span>daily Deposit</span>
            </Col>
            <Col span={6}>{`${carBookingDetails?.unit_price}`}</Col>
          </Row>
        </Space>
      </div>
      <div className={styles.bottomLast}>
        <Space direction="vertical">
          <Row>
            <Col span={18}>
              <Title level={5}>Total Payment </Title>
            </Col>
            <Title level={5}>{`${carBookingDetails?.net_payable}`} </Title>
          </Row>
        </Space>
      </div>
    </div>
  );

  return (
    <div
      className={classNames({
        [styles.checkout]: true,
        [styles['is-mobile']]: isMobile,
      })}
    >
      <Spin spinning={isLoading || !bookingDetails ? true : false}>
        <Row>
          <Col lg={16} xs={24}>
            {!bookingDetails?.subscription_id ? (
              <div>
                <Title level={2}>Checkout and Payment</Title>
                <Elements options={options} stripe={stripePromise}>
                  <CheckoutForm
                    vehicleId={vehicleId}
                    startDate={tripStartDate}
                    endDate={tripEndDate}
                  />
                </Elements>
              </div>
            ) : (
              <Title level={2}>Payment is already made!</Title>
            )}
          </Col>
          <Col lg={8} xs={24}>
            <Card
              cssClassNames={classNames({
                [styles.card]: true,
                [styles['is-mobile']]: isMobile,
              })}
              image={vehicleDetails?.featured_image}
              metaTitle={title}
              metaDescription={description}
            />
          </Col>
        </Row>
      </Spin>
    </div>
  );
}

export default Payment;
