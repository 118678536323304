import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';

import { RootState } from 'store/types';
import { selectUserDocuments, selectStatus } from './selectors';
import { useUnwrapAsyncThunk } from 'store/hooks';
import { USER_ID } from 'api/base/constants';
import { updateAccountAsync, uploadDataAsync } from 'store/customer/account/actions';
import { UserDocument } from './types';
import { RecursivePartial } from 'api/customer/account/types';
import { message } from 'antd';

export const usePointIdentificationSelectors = () => {
  return useSelector((state: RootState) => ({
    documents: selectUserDocuments(state),
    isLoading: isEqual(selectStatus(state), 'started'),
  }));
};

export const useUpdateUserDocuments = () => {
  const unwrap = useUnwrapAsyncThunk();

  return useCallback((userDocuments: RecursivePartial<UserDocument>) => {
    const userId = localStorage.getItem(USER_ID);

    if (userId) {
      unwrap(updateAccountAsync({ data: userDocuments, id: parseInt(userId, 10) }))
      .then(() => {
        message.success('successfully saved');
      })
      .catch((e) => {
        message.error('update failed');
      });
    }
  }, []);
};

export const useUploadData = () => {
  const unwrap = useUnwrapAsyncThunk();

  return useCallback(async (data: FormData) => {
    const userId = localStorage.getItem(USER_ID);

    if (userId) {
      unwrap(uploadDataAsync({ data, id: parseInt(userId, 10) }));
    }
  }, []);
};
