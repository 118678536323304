import { createAsyncThunk } from '@reduxjs/toolkit';

import { PostRentalContractData } from 'api/provider/rentalAgreement/types';
import {
  handleRentalContract,
  getRentalContract,
  updateRentalContract,
  getRentalPdfLink,
  getRentalPdfDownload,
} from 'api/provider/rentalAgreement/index';

export const handleRentalContractAsync = createAsyncThunk(
  'provider/rental-agreement/create-contract',
  async (data: PostRentalContractData) => {
    const response = await handleRentalContract(data);
    return response;
  },
);

export const getRentalContractAsync = createAsyncThunk(
  'provider/rental-agreement/get-contract',
  async () => {
    const response = await getRentalContract();
    return response;
  },
);

export const updateRentalContractAsync = createAsyncThunk(
  'provider/rental-agreement/update-contract',
  async ({ data, id }: { data: PostRentalContractData; id: number }) => {
    const response = await updateRentalContract(data, id);
    return response;
  },
);

export const getRentalPdfLinkAsync = createAsyncThunk(
  'provider/rental-agreement/pdf-link',
  async () => {
    const response = await getRentalPdfLink();
    return response;
  },
);

export const getRentalPdfDownloadAsync = createAsyncThunk(
  'provider/rental-agreement/pdf-download',
  async (pdfLink: string) => {
    const response = await getRentalPdfDownload(pdfLink);
    return response;
  },
);
