import React, { FC } from 'react';
import { FileTextOutlined } from '@ant-design/icons';
import saveAs from 'file-saver';
import { FileIconTypes } from './types';

const FileIcon: FC<FileIconTypes> = (props) => {
  const { regoLink } = props;

  const handleDownloadFile = async () => {
    try {
      const fileName = regoLink.substring(regoLink.lastIndexOf('/') + 1);
      saveAs(regoLink, fileName);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <FileTextOutlined
        onClick={() => {
          handleDownloadFile();
        }}
      />
    </div>
  );
};

export default FileIcon;
