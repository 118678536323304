import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Form, Row, Col, Result, Spin, Tooltip } from 'antd';
import { validatePassword } from 'shared/helpers/helperFunctions';
import {
  InfoCircleOutlined,
  EyeInvisibleOutlined,
  EyeTwoTone,
} from '@ant-design/icons';

import Input from 'components/Input';
import Checkbox from 'components/CheckBox';
import Button from 'components/Button';
import { signupAsync } from 'store/shared/auth/thunks';
import { ROUTES } from 'shared/routes/constants';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { SignupPostData, USER_ROLE } from 'api/auth/types';
import { useForm } from 'components/misc/helpers';

import styles from './signUp.module.scss';
import { selectSignUpRequestStatus } from 'store/shared/auth/selectors';

const { Title } = Typography;

const SignUp: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [signedUp, setSignedUp] = useState(false);
  const isSignUpLoading =
    useAppSelector(selectSignUpRequestStatus) === 'started';
  const isSignUpSuccess = useAppSelector(selectSignUpRequestStatus) === 'done';

  useEffect(() => {
    if (isSignUpSuccess) {
      setSignedUp(true);
    }
  }, [isSignUpSuccess]);
  useEffect(() => {
    setSignedUp(false);
  }, []);

  const onFinish = (values: SignupPostData) => {
    // by default user will get customer role
    dispatch(signupAsync({ ...values, user_role: [USER_ROLE.CUSTOMER] }));
  };
  return (
    <Row align="middle">
      <Col xs={20} sm={20} md={20} lg={7} className={styles.container}>
        {signedUp && (
          <Result
            status="success"
            title="Successfully Signed Up!"
            subTitle="Please check your email and activate your account"
          />
        )}

        {!signedUp && (
          <Spin spinning={isSignUpLoading}>
            <Title level={3} className={styles.title}>
              Welcome to Jaunt
            </Title>
            <Form onFinish={onFinish} size="large" layout="vertical">
              <Row gutter={[16, 0]}>
                <Col className="gutter-row" span={12}>
                  <Form.Item
                    name="first_name"
                    extra="As in driving license"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your first name!',
                      },
                    ]}
                  >
                    <Input label="First Name" />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={12}>
                  <Form.Item
                    extra="As in driving license"
                    name="last_name"
                    rules={[
                      {
                        required: true,
                        message: 'Please input your last name!',
                      },
                    ]}
                  >
                    <Input label="Last Name" type="text" />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={24}>
                  <Form.Item
                    name="email"
                    rules={[
                      { required: true, message: 'Please input your email!' },
                    ]}
                  >
                    <Input label="Email" type="email" />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={24}>
                  <Form.Item
                    name="password"
                    validateTrigger={['onSubmit', 'onChange']}
                    rules={[
                      {
                        required: true,
                        message: 'Please input your password!',
                      },
                      {
                        min: 8,
                        max: 32,
                        message: 'Password must be 8-32 characters long',
                      },
                    ]}
                  >
                    <Input
                      label="Password"
                      inputType="password"
                      prefix={
                        <div>
                          <Tooltip title="Password must contain 8 characters, at least one Uppercase letter, one lower case letter, one number, one special character">
                            <InfoCircleOutlined
                              style={{
                                color: 'rgba(0,0,0,.45)',
                                marginRight: '.5rem',
                              }}
                            />
                          </Tooltip>
                        </div>
                      }
                      iconRender={(visible: boolean) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={24}>
                  <Form.Item
                    name="confirm"
                    dependencies={['password']}
                    rules={[
                      {
                        required: true,
                        message: 'Please confirm your password!',
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              'The two passwords that you entered do not match!'
                            )
                          );
                        },
                      }),
                    ]}
                  >
                    <Input
                      label="Confirm Password"
                      inputType="password"
                      iconRender={(visible: boolean) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={24}>
                  <Form.Item
                    name="agreement"
                    valuePropName="checked"
                    rules={[
                      {
                        validator: (_, value) =>
                          value
                            ? Promise.resolve()
                            : Promise.reject(
                                new Error(
                                  'Should accept terms of service and privacy policy'
                                )
                              ),
                      },
                    ]}
                  >
                    <Checkbox className={styles.checkboxContent}>
                      I agree to the{' '}
                      <a
                        href="/terms-and-conditions"
                        target="_blank"
                        className={styles.checkoboxMarkedTxt}
                      >
                        terms of service
                      </a>{' '}
                      and{' '}
                      <a
                        href="/privacy-policy"
                        target="_blank"
                        className={styles.checkoboxMarkedTxt}
                      >
                        privacy policy
                      </a>
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={24}>
                  <Button
                    type="ghost"
                    isBordered={true}
                    style={{ width: '100%' }}
                    htmlType="submit"
                  >
                    Sign up
                  </Button>
                </Col>
                <Col className="gutter-row" span={24}>
                  <div className={styles.loginBtnContainer}>
                    <span>Already have an account?</span>
                    <Button
                      isBordered={true}
                      className={styles.loginBtn}
                      onClick={() => navigate(ROUTES.PATHS.LOGIN)}
                    >
                      Log in
                    </Button>
                  </div>
                </Col>
                {/* <Col className="gutter-row" span={24}>
                  <Divider plain />
                </Col> */}
                {/* <Col className="gutter-row" span={24}>
                  <PrivacyAndTermsFooter/>
                </Col> */}
              </Row>
            </Form>
          </Spin>
        )}
      </Col>
    </Row>
  );
};

export default SignUp;
