import React, { FC } from 'react';

import SetupPayment from '../SetupPayment';

const StripeReAuth: FC = () => {
  return (
    <SetupPayment isRedirected={true} />
  );
};

export default StripeReAuth;
