import {
  getProvidersAsync,
  updateAdminApprovalAsync,
} from 'store/admin/providers/thunks';

import slice from './slice';

const { setCurrentPage } = slice.actions;

export { getProvidersAsync, setCurrentPage, updateAdminApprovalAsync };
