import { Column } from 'components/tables/IXTable/types';

export const COLUMNS: Column[] = [
  {
    name: 'Customer',
    key: 'customer',
    type: 'text',
  },
  {
    name: 'Trip duration',
    key: 'trip_duration',
    type: 'text',
  },
];
