import { Booking } from 'api/customer/bookings/types';
import { dateGenerator } from 'shared/helpers/helperFunctions';
import React from 'react';

import { BookingsTableData } from './types';

import { TRIP_STATUS_TAG_PROPS } from 'uicontainers/_shared/constants';
import moment from 'moment';
import ActionButton from './ActionButton';

export const transformBookingsToTableData = (
  books: Booking[]
): BookingsTableData[] => {
  return books.map((book) => ({
    key: book.id.toString(),
    book_id: {
      text: book.id.toString(),
    },
    provider: {
      text: book.provider,
    },
    trip_duration: {
      text: `${dateGenerator(book?.start_time)}---${dateGenerator(
        book?.end_time
      )}`,
    },
    vehicle: {
      text: book.vehicle,
    },
    vehicleId: {
      text: book.vehicle_id,
    },
    booked: {
      text: dateGenerator(book?.created_at),
    },
    trip_status: {
      tags: [
        {
          name: TRIP_STATUS_TAG_PROPS[book.request_status].name,
          color: TRIP_STATUS_TAG_PROPS[book.request_status].color,
        },
      ],
    },
    total_amount: {
      text: book.total_amount ? `${book.total_amount}` : `0.00`,
    },
    receipt: {
      text: book.subscription_id,
    },
    three_dot_btn: {
      buttons:
        (book.request_status === 'BOOKED' && !book.subscription_id) ||
        book.request_status === 'PICKED_UP'
          ? [
              {
                icon: <ActionButton booking={book} />,
                onClick: () => {},
              },
            ]
          : [],
    },
  }));
};
