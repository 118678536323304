import { Col, Row, Typography } from 'antd';
import React, { FC } from 'react';

import Button from 'components/Button';
import { Spin } from 'components/misc/helpers';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getStripeOnboardingLinkAsync } from 'store/shared/auth/actions';

import {
  selectGetStripeOnboardingLinkStatus,
  selectStripeOnboardingLink,
} from './selectors';
import styles from './stripeReturn.module.scss';
import { Props } from './types';
import { selectLoginData } from 'store/shared/auth/selectors';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'shared/routes/constants';
import { acknowledgeOnboardingAsync } from 'store/shared/auth/thunks';
import { getProfileAsync } from 'store/customer/account/actions';

const { Title } = Typography;

const StripeReturn: FC<Props> = (props) => {
  const navigate = useNavigate();
  const isLoading =
    useAppSelector(selectGetStripeOnboardingLinkStatus) === 'started';
  const stripeOnboardingLink = useAppSelector(selectStripeOnboardingLink);
  const dispatch = useAppDispatch();
  const userData = useAppSelector(selectLoginData);
  const isOnboardingCompleted = userData && userData.is_onboarding_completed;

  React.useEffect(() => {
    dispatch(getStripeOnboardingLinkAsync());
    dispatch(acknowledgeOnboardingAsync());
    dispatch(getProfileAsync());
  }, []);

  const handleOnClick = () => {
    window.location.href = stripeOnboardingLink;
  };

  return (
    <Spin spinning={isLoading}>
      <Row align="middle">
        <Col xs={20} sm={20} md={20} lg={7} className={styles.container}>
          <Title level={3} className={styles.title}>
            Thanks for completing the onboarding flow
          </Title>
          {!isOnboardingCompleted && (
            <Col className="gutter-row" span={24}>
              <p className={styles.paragraph}>
                You are almost done. There are few missing information. Please
                click the button below and complete your payment settings.
              </p>
            </Col>
          )}
          {isOnboardingCompleted && (
            <Row gutter={[16, 8]}>
              <Col className="gutter-row" span={24}>
                <Button
                  onClick={() => navigate(ROUTES.PATHS.ROOT)}
                  type="ghost"
                  isBordered={true}
                  style={{ width: '100%' }}
                >
                  RETURN TO HOME PAGE
                </Button>
              </Col>
            </Row>
          )}

          {!isOnboardingCompleted && (
            <Row gutter={[16, 8]}>
              <Col className="gutter-row" span={24}>
                <Button
                  onClick={handleOnClick}
                  type="ghost"
                  isBordered={true}
                  style={{ width: '100%' }}
                >
                  SET UP PAYMENTS
                </Button>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </Spin>
  );
};

export default StripeReturn;
