import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Menu, Modal } from 'antd';
import moment from 'moment';
import React, { FC } from 'react';

import { useChangeBookingStatus } from './hooks';
import { MenuActionsProps } from './types';

const MenuActions: FC<MenuActionsProps> = (props) => {
  const { booking, setVisible } = props;
  const changeBookingStatus = useChangeBookingStatus();

  const { confirm } = Modal;

  const showDeleteConfirm = () => {
    confirm({
      title: 'Are you sure cancel the booking for?',
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          <div>{booking.vehicle}</div>
          <div>
            {moment(booking.start_time).format('YYYY-MM-DD')} to{' '}
            {moment(booking.end_time).format('YYYY-MM-DD')}
          </div>
        </>
      ),
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        changeBookingStatus({ request_status: 'CANCELED' }, booking.id);
      },
      onCancel() {
        return;
      },
    });
  };

  // @ts-ignore
  const handleStatusChange = (info: MenuInfo) => {
    setVisible(false);
    if (info.key === 'cancel_booking') {
      showDeleteConfirm();
    }
    if (info.key === 'request_for_pickup') {
      window.location.href = `/customer/booking/vehicle/${
        booking.vehicle_id
      }/checkout/${moment(booking.start_time).format('YYYY-MM-DD')}/${moment(
        booking.end_time
      ).format('YYYY-MM-DD')}?bookId=${booking.id}`;
    }
    if (info.key === 'request_for_drop_off') {
      changeBookingStatus({ request_status: 'DROP_OFF_REQUESTED' }, booking.id);
    }
  };

  const getMenuItems = () => {
    if (booking) {
      if (booking.request_status === 'BOOKED') {
        if (
          !booking.subscription_id &&
          moment().isBetween(
            moment(booking.start_time),
            moment(booking.end_time).add(1, 'd')
          )
        ) {
          return (
            <>
              <Menu.Item key="request_for_pickup">Request for pickup</Menu.Item>
              <Menu.Item key="cancel_booking">Cancel</Menu.Item>
            </>
          );
        } else {
          return <Menu.Item key="cancel_booking">Cancel</Menu.Item>;
        }
      } else if (booking.request_status === 'PICKED_UP') {
        return (
          <Menu.Item key="request_for_drop_off">Request for drop off</Menu.Item>
        );
      }
    } else {
      return null;
    }
  };

  return <Menu onClick={handleStatusChange}>{getMenuItems()}</Menu>;
};

export default MenuActions;
