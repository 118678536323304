import React, { FC, useState } from 'react';
import { Row } from 'antd';
import classNames from 'classnames';

import SearchInput from 'components/SearchInput/SearchInput';

import { useAppDispatch } from 'store/hooks';

import styles from './tableHeader.module.scss';
import DateRangePicker from 'components/DateRangePicker';

import { getBookingsAsync, setCurrentPage } from 'store/customer/bookings/actions';

import { Typography } from 'antd';
import SelectInput from 'components/SelectInput';
import { BookingsRequestParams } from 'api/customer/bookings/types';
import { TripStatusOptions } from 'uicontainers/_shared/constants';
import { Props } from './types';

const TableHeader: FC<Props> = (props) => {
  const { currentPage } = props;
  const Title = Typography.Title;
  const dispatch = useAppDispatch();

  const [searchValue, setSearchValue] = useState('');
  const [status, setStatus] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');

  React.useEffect(() => {
    const params: BookingsRequestParams = {};
    if (searchValue) {
      params.search = searchValue;
    }

    if (startTime && endTime) {
      params.start_time = startTime;
      params.end_time = endTime;
    }

    if (status) {
      params.status = status;
    }

    if (currentPage) {
      params.page = currentPage;
    }

    dispatch(getBookingsAsync(params));
  }, [searchValue, startTime, endTime, status, currentPage]);

  const handleSearch = (value: string) => {
    setSearchValue(value);
    dispatch(setCurrentPage(1));
  };

  const handleTripStatus = (value: string) => {
    setStatus(value);
    dispatch(setCurrentPage(1));
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleRangeChange = (_: any, dateStr: any) => {
    setStartTime(dateStr[0]);
    setEndTime(dateStr[1]);
    dispatch(setCurrentPage(1));
  };

  const tripStatusOptions = TripStatusOptions;

  return (
    <div
      className={classNames({
        [styles.tableTopContainer]: true,
      })}
    >
      <Row className={styles.titleContainer}>
        <Title level={3}>Bookings</Title>
      </Row>
      <Row>
        <SearchInput onSearch={handleSearch} />
        <DateRangePicker onChange={handleRangeChange} />
        <SelectInput
          options={tripStatusOptions}
          placeholder="Trip status"
          onChange={handleTripStatus}
        />{' '}
      </Row>
    </div>
  );
};
export default TableHeader;
