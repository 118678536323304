import React from 'react';
import { Paragraph, Title } from 'components/misc/helpers';


function FuelTollsFines() {
  return (
    <div>
      <Title level={3}>A. Fuel</Title>
      <ol>
        <li>
          <b>1. Fuel price changes and your income</b>
        </li>
      </ol>
      <Paragraph>
        Fuel prices can fluctuate a lot - and when the pump prices sit at $1.50
        and higher it can feel like fuel costs are eating into your car's
        income.
      </Paragraph>
      <Paragraph>
        But when you do the sums, you might be surprised at how little these
        fuel price increases actually affect your earnings.
      </Paragraph>
      <Paragraph>How much fuel costs per kilometer:</Paragraph>
      <Paragraph>
        When the fuel price rises from $1.30 to $1.60, the cost per kilometer
        for the average medium- sized car goes up by about 2 cents - from 12
        cents/km to 14 cents/km.
      </Paragraph>
      <Paragraph>
        Small or fuel-efficient cars will use less fuel, and large cars or vans
        will use more. To work out how much fuel your car takes, check out the
        <a
          href="https://carnextdoor.typeform.com/to/NtoYMH"
          target="_blank"
          rel="noreferrer"
        >
          fuel cost explainer.
        </a>
      </Paragraph>
      <Paragraph>Making sure you're compensated for your fuel costs:</Paragraph>
      <Paragraph>
        You're reimbursed for the fuel Borrowers use through your car's distance
        income. You choose{' '}
        <a
          href="https://support.carnextdoor.com.au/hc/en-gb/articles/360022882131"
          target="_blank"
          rel="noreferrer"
        >
          your own distance income,
        </a>{' '}
        from 13c, 25c, 31c, 37c or 45c per kilometre. You should choose a
        distance rate that is enough to cover fuel at an average rate over a
        year, and also cover tyres and servicing.
      </Paragraph>
      <Paragraph>
        Most trips with Jaunt are under 100 kilometres per day, so the overall
        impact on your earnings of fuel price fluctuations is likely to be
        small. However, if you’re concerned about fuel prices, you could raise
        your car’s distance rate, or raise your daily rate by a few dollars to
        cover higher costs.
      </Paragraph>
      <Paragraph>
        For example, if you wanted to cover the extra cost of fuel for the
        average day trip of 100 km, you would need to earn an extra $2 per day
        ($0.02 x 100).
      </Paragraph>
      <Paragraph>Minimising your fuel costs:</Paragraph>
      <Paragraph>
        To make the system as convenient as possible for Borrowers, they can
        fill up at any service station. If there's a particular service station
        in your area that's usually cheaper than others, you could:
      </Paragraph>
      <ul>
        <li>
          Keep your car filled up yourself before it goes out on bookings to
          reduce the chance that Borrowers need to fill up
        </li>
        <li>
          Add a note to your special instructions asking Borrowers to use your
          preferred petrol station if possible
        </li>
        <li>
          Add a note to your special instructions asking Borrowers to only
          return the car with 1/4 tank if petrol is particularly expensive.
        </li>
      </ul>

      <Title level={3}>B. Tolls</Title>
      <ol>
        <li>
          <b>
            1. Setting up a toll account for your car (Key Handover owners only)
          </b>
        </li>
      </ol>
      <Paragraph>
        If you don't already have a toll account for your car, make sure you get
        one. Borrowers need to be able to drive on toll roads, and if you don't
        have a valid toll account you'll be charged an administration fee
        (usually $10-$20 per toll) by the toll road provider. You'll be liable
        to pay this fee yourself.
      </Paragraph>
      <Paragraph>
        We recommend you choose a toll plan with a toll device rather than a
        licence plate matching system. This will save your Borrowers having to
        pay the extra fee for not having a toll tag (usually around $1 per
        toll), and you can advertise this in your car's profile.
      </Paragraph>
      <Paragraph>Borrowers will pay for:</Paragraph>
      <ul>
        <li>the cost of the tolls they incur</li>
        <li>
          any billing or admin fees for licence plate matching (if you don't
          have a toll tag)
        </li>
      </ul>
      <Paragraph>You will pay for:</Paragraph>
      <ul>
        <li>admin fees for not having a toll account for your car.</li>
      </ul>
      <Title level={3}>C. Fines</Title>
      <ol>
        <li>
          <b>1. What happens if you get a fine during a trip</b>
        </li>
      </ol>
      <Paragraph>If you get a fine during your booking:</Paragraph>
      <ul>
        <li>
          If you are issued a fine during your trip, pay it straight away and
          email a photo of the fine to members@carnextdoor.com.au with proof of
          payment.
        </li>
        <li>
          This will prevent the Owner receiving a copy of the fine from the
          authorities, and you’ll avoid having to pay an additional fee under
          the{' '}
          <a
            href="https://support.carnextdoor.com.au/hc/en-gb/articles/360001149986"
            target="_blank"
            rel="noreferrer"
          >
            Fee Schedule
          </a>{' '}
          to cover the Owner’s and Jaunt’s costs of transferring the fine to
          you.
        </li>
      </ul>
      <Paragraph>If the Owner is sent a fine after your trip:</Paragraph>
      <ul>
        <li>
          We will check the booking records to see who had the car at the time.
        </li>
        <li>
          If the records show that you are responsible for the fine, Jaunt will
          provide your name, address, date of birth, driver licence number and
          state or country of issue to the Owner or their authorised
          representative.
        </li>
        <li>
          The Owner or their authorised representative will tell the issuing
          authority that you are responsible for the fine.
        </li>
        <li>The agency will re-issue the fine directly to you.</li>
        <li>
          We will charge a $40 administration fee to cover the time it takes for
          the Owner and Jaunt to transfer the fine.
        </li>
      </ul>
      <Paragraph>
        If you think you have been assigned a fine incorrectly
      </Paragraph>
      <Paragraph>
        You can contest the fine with the relevant authority once the fine is in
        your name.
      </Paragraph>
      <Paragraph>
        If you were{' '}
        <a
          href="https://support.carnextdoor.com.au/hc/en-gb/articles/204824239-Can-I-share-the-driving-Can-other-people-drive-a-car-booked-under-my-account-"
          target="_blank"
          rel="noreferrer"
        >
          sharing the driving with another member
        </a>{' '}
        and the fine was incurred while they were driving, you will be able to
        reassign the fine to them once the fine is in your name. Just follow the
        instructions on the fine notice you'll receive in the mail.
      </Paragraph>
      <Paragraph>
        Remember: if the car gets a parking fine in the place you left it,
        you're responsible - no matter how long it's been since your trip ended.
      </Paragraph>
    </div>
  );
}

export default FuelTollsFines;
