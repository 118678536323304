import { Col, Row, Spin } from 'antd';
import classNames from 'classnames';
import React from 'react';

import StatisticsCard from 'components/StatisticsCard';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getSummaryAsync } from 'store/admin/dashboard/actions';

import { selectSummary, selectSummaryRequestStatus } from './selectors';
import styles from './summary.module.scss';

const Summary = () => {
  const {
    total_income,
    vehicle_rented,
    payment_gateway_fee,
    booking_count,
  } = useAppSelector(selectSummary);
  const isSummaryLoading =
    useAppSelector(selectSummaryRequestStatus) === 'started';

  const dispatch = useAppDispatch();
  React.useEffect(() => {
    dispatch(getSummaryAsync());
  }, []);

  return (
    <Row>
      <Col span={24}>
        <Row gutter={[{ xs: 8, sm: 8, md: 16, lg: 32 }, 0]}>
          <Col lg={6} md={12} sm={24} xs={24}>
            <Spin spinning={isSummaryLoading}>
              <StatisticsCard title="Total Income This Month" isActive={true}>
                <h1
                  className={classNames(
                    styles.statValue,
                    styles.total_income_value
                  )}
                >
                  {total_income ? total_income?.toFixed(2) : 0}
                </h1>
              </StatisticsCard>
            </Spin>
          </Col>
          <Col lg={6} md={12} sm={24} xs={24}>
            <Spin spinning={isSummaryLoading}>
              <StatisticsCard
                title="Payment Gateway Fee This Month"
                isActive={false}
              >
                <h1
                  className={classNames(styles.statValue, styles.booking_value)}
                >
                  {payment_gateway_fee ? payment_gateway_fee?.toFixed(2) : 0}
                </h1>
              </StatisticsCard>
            </Spin>
          </Col>
          <Col lg={6} md={12} sm={24} xs={24}>
            <Spin spinning={isSummaryLoading}>
              <StatisticsCard title="Bookings This Month" isActive={false}>
                <h1
                  className={classNames(styles.statValue, styles.booking_value)}
                >
                  {booking_count ? booking_count : 0}
                </h1>
              </StatisticsCard>
            </Spin>
          </Col>
          <Col lg={6} md={12} sm={24} xs={24}>
            <Spin spinning={isSummaryLoading}>
              <StatisticsCard
                title="Vehicle Rented This Month"
                isActive={false}
              >
                <h1
                  className={classNames(
                    styles.statValue,
                    styles.onego_fee_value
                  )}
                >
                  {vehicle_rented ? vehicle_rented : 0}
                </h1>
              </StatisticsCard>
            </Spin>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Summary;
