import { adminAPI } from 'api/base';

import { GetSummary, GetEarning, GetTopEarningProviders, GetVehicleStatus } from './types';

export const getSummary: GetSummary = () => {
  return adminAPI.get('/dashboard/summary/');
};

export const getEarning: GetEarning = () => {
  return adminAPI.get('/dashboard/earnings/');
};

export const getTopEarningProviders: GetTopEarningProviders = () => {
  return adminAPI.get('/dashboard/top-earning-providers/');
};

export const getVehicleStatus: GetVehicleStatus = () => {
  return adminAPI.get('/dashboard/vehicle-status/');
};
