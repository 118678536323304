import { Toll } from 'api/provider/tolls/types';
import { dateGenerator } from 'shared/helpers/helperFunctions';

import { TOLL_TICKET_STATUS_TAG_PROPS } from './constants';
import { TollTableData } from './types';

export const transformTollsToTableData = (tolls: Toll[]): TollTableData[] => {
  return tolls.map((toll) => ({
    key: toll.id.toString(),
    notice_no: {
      rows: [
        {
          text: `${toll.id}`,
        },
      ],
    },
    vehicle: {
      text: toll.vehicle,
    },
    reg_no: {
      text: toll.license_no,
    },
    location: {
      text: toll.location,
    },
    date: {
      text: dateGenerator(toll.toll_date),
    },
    status: {
      tags: [
        {
          name: TOLL_TICKET_STATUS_TAG_PROPS[toll.status].name,
          color: TOLL_TICKET_STATUS_TAG_PROPS[toll.status].color,
        },
      ],
    },
    toll_amount: {
      text: `$${toll.toll_amount}`,
    },
  }));
};
