import React from 'react';
import RentalAgreement from './RentalAgreement';

function Instructions() {
  return (
    <RentalAgreement
      secondaryTitle={'Instructions'}
      paragraph1={'This information is only shown to customers after their trip is booked.'}
      richTextEditorLabel={'Pick up and return instructions'}
    />
  );
}

export default Instructions;
