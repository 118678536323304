import React, { Fragment, FC } from 'react';
import { Carousel, Image } from 'antd';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import { Props } from './types';

const Images: FC<Props> = (props) => {
  const { vehicleImages } = props;
  return (
    <Carousel arrows nextArrow={<RightOutlined />} prevArrow={<LeftOutlined />}>
      {vehicleImages.map((imgUrl, i) => (
        <Fragment key={i}>
          <Image src={imgUrl} alt="" preview={false} />
        </Fragment>
      ))}
    </Carousel>
  );
};

export default Images;
