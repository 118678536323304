import { createSlice } from '@reduxjs/toolkit';

import { getTicketsAsync } from './thunks';
import { TicketState } from './types';

const initialState: TicketState = {
  tickets: [],
  getTicketsStatus: 'initial',
  totalCount: 0,
};

export const customerTicketsSlice = createSlice({
  name: 'tickets',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTicketsAsync.pending, (state, _) => {
      state.getTicketsStatus = 'started';
    });
    builder.addCase(getTicketsAsync.fulfilled, (state, action) => {
      state.tickets = action.payload.results;
      state.getTicketsStatus = 'done';
    });
    builder.addCase(getTicketsAsync.rejected, (state, _) => {
      state.getTicketsStatus = 'failed';
    });
  },
});

export default customerTicketsSlice;
