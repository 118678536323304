import React, { useCallback } from 'react';
import { Collapse, Row, Col } from 'antd';
import { useNavigate } from 'react-router-dom';
import styles from './faqSection.module.scss';
import { ROUTES } from 'shared/routes/constants';

function FaqSection() {
  const navigate = useNavigate();
  const { Panel } = Collapse;
  const text = '';

  const handleOnClick = useCallback(
    (link) => {
      navigate(link, { replace: true });
      window.scrollTo(0, 0);
    },
    [navigate]
  );

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        <b>Frequently asked questions</b>
      </div>
      <div className={styles.divider} />
      <Row>
        <Col className="gutter-row" xs={24} sm={24} md={11} lg={11}>
          <Collapse accordion expandIconPosition="right">
            <Panel header="What do I need to book a car on Jaunt?" key="1">
              <p>
                We found that not many trips were starting or ending between
                these times, and owners may be disturbed by borrowers wanting to
                get in touch when picking up and returning their car. To save
                our owners' sleep and make sure borrowers are always able to get
                help when they need it, we've restricted our exchange hours (the
                times a trip can start or end) to between 6am and 11pm.{' '}
                <small
                  className={styles.link}
                  onClick={() => navigate(ROUTES.FAQ_DETAILS_KEY('2#booking'))}
                >
                  see more
                </small>
              </p>
            </Panel>
            <Panel header="Do I need my own insurance?" key="2">
              <p>{text}</p>
            </Panel>
            <Panel header="Can other people drive a car that I booked?" key="3">
              <p>{text}</p>
            </Panel>
            <Panel header="What is the cancellation policy?" key="4">
              <p>
                {' '}
                If you cancel before your trip starts, then the cancellation
                fees (if there are any) will be deducted from the pre-payment.{' '}
                <small
                  className={styles.link}
                  onClick={() => navigate(ROUTES.FAQ_DETAILS_KEY('3#cancellation'))}
                >
                  see more
                </small>
              </p>
            </Panel>
          </Collapse>
        </Col>
        <Col className="gutter-row" xs={24} sm={24} md={2} lg={2}></Col>
        <Col className="gutter-row" xs={24} sm={24} md={11} lg={11}>
          <Collapse accordion expandIconPosition="right">
            <Panel header="What happens if I have an accident?" key="1">
              <p>
                {' '}
                You’re responsible for any damage or loss to the car that
                happens from the time you pick it up until the time you drop it
                (and the keys) back - even if it’s not your fault, for example,
                a tree branch falling on the car, a hail storm, or damage caused
                by a hit-and-run driver.{' '}
                <small
                  className={styles.link}
                  onClick={() => navigate(ROUTES.FAQ_DETAILS_KEY('6#damage'))}
                >
                  see more
                </small>
              </p>
            </Panel>
            <Panel header="Can I get my car delivered to me?" key="2">
              <p>{text}</p>
            </Panel>
            <Panel header="How do I get discounts when booking a car?" key="3">
              <p>
                {' '}
                Our recommended multi-day discounts are designed to make
                multi-day bookings more attractive without reducing your income
                too much. You can experiment with your discount to find the
                right balance for your car.{' '}
                <small
                  className={styles.link}
                  onClick={() => navigate(ROUTES.FAQ_DETAILS_KEY('5#discount'))}
                >
                  see more
                </small>
              </p>
            </Panel>
            <Panel header="What are the cleaning and safety policies?" key="4">
              <p>
                {' '}
                When your car is not in use by a Borrower, you will be able to
                pinpoint its location on a map by logging in to the website or
                app. This makes it easy for you to find where your car is parked
                after a Borrower drops it back.{' '}
                <small
                  className={styles.link}
                  onClick={() => navigate(ROUTES.FAQ_DETAILS_KEY('4#safety'))}
                >
                  see more
                </small>
              </p>
            </Panel>
          </Collapse>
        </Col>
      </Row>
      <div
        className={styles.viewAllText}
        onClick={() => {
          handleOnClick('/faq-details/tabs/1');
        }}
      >
        View All FAQs
      </div>
    </div>
  );
}

export default FaqSection;
