import { useCallback } from 'react';
import isEqual from 'lodash/isEqual';
import { useUnwrapAsyncThunk, useAppSelector } from 'store/hooks';
import { USER_ID } from 'api/base/constants';
import {
  updateAccountAsync,
  uploadDataAsync,
} from 'store/customer/account/actions';
import { selectBusinessInfo, selectStatus } from './selectors';
import { BusinessInfo } from './types';
import { message } from 'antd';

export const useBusinessInfoSelectors = () => {
  return useAppSelector((state) => {
    return {
      businessInfo: selectBusinessInfo(state),
      isLoading: isEqual(selectStatus(state), 'started'),
    };
  });
};

export const useUpdateBusinessInfo = () => {
  const unwrap = useUnwrapAsyncThunk();

  return useCallback((businessInfo: Partial<BusinessInfo>) => {
    const userId = localStorage.getItem(USER_ID);

    if (userId) {
      unwrap(
        updateAccountAsync({ data: businessInfo, id: parseInt(userId, 10) })
      )
        .then(() => {
          message.success('Business info saved successfully');
          window.scrollTo(0, 0);
        })
        .catch((e) => {
          message.error('update failed');
        });
      //window.location.reload();
    }
  }, []);
};

export const useUploadData = () => {
  const unwrap = useUnwrapAsyncThunk();

  return useCallback(async (data: FormData) => {
    const userId = localStorage.getItem(USER_ID);

    if (userId) {
      unwrap(uploadDataAsync({ data, id: parseInt(userId, 10) }));
    }
  }, []);
};
