import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getBookingsAsync, changeBookingStatusAsync } from './thunks';
import { BookingsState } from './types';

const initialState: BookingsState = {
  bookings: [],
  totalCount: 0,
  currentPage: 1,
  payment_method_id: '',
  getBookingsRequestStatus: 'initial',
  patchBookingsRequestStatus: 'initial',
};

export const customerBookingsSlice = createSlice({
  name: 'bookings',
  initialState,
  reducers: {
    setCurrentPage(state, action: PayloadAction<number>) {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(getBookingsAsync.pending, (state, action) => {
      state.getBookingsRequestStatus = 'started';
    })
    .addCase(getBookingsAsync.fulfilled, (state, action) => {
      state.bookings = action.payload.results;
      state.totalCount = action.payload.count;
      state.getBookingsRequestStatus = 'done';
    })
    .addCase(getBookingsAsync.rejected, (state, action) => {
      state.getBookingsRequestStatus = 'failed';
    })
    .addCase(changeBookingStatusAsync.pending, (state) => {
      state.patchBookingsRequestStatus = 'started';
    })
    .addCase(changeBookingStatusAsync.fulfilled, (state) => {
      state.patchBookingsRequestStatus = 'done';
    })
    .addCase(changeBookingStatusAsync.rejected, (state) => {
      state.patchBookingsRequestStatus = 'failed';
    })
  },
});

export default customerBookingsSlice;
