import React, { FC } from "react";

import styles from "./privacyAndTermsFooter.module.scss";

const PrivacyAndTermsFooter: FC = () => {
  return (
    <p className={styles.footer}>
      By accessing and using our services,you consent to Jaunt{" "}
      <a
        href="/privacy-policy"
        className={styles.markedTxt}
        target="_blank"
      > Privacy Policy </a>
      and{" "}
      <a
        href="/terms-and-conditions"
        className={styles.markedTxt}
        target="_blank"
      > Terms and Conditions </a>
    </p>
  );
};

export default PrivacyAndTermsFooter;
