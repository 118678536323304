import React from 'react';
import RentalAgreement from './RentalAgreement';

function TermsAndConditions() {
  return (
    <RentalAgreement
      secondaryTitle={'Terms of Use'}
      paragraph1={
        'The terms and conditions are presented to customers prior to booking, so they can read and agree with the terms before commitment.'
      }
      richTextEditorLabel={'Terms and Conditions'}
    />
  );
}

export default TermsAndConditions;
