import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getAdminRentalInfoAsync } from 'store/admin/rentalAgreement/thunks';
import { selectAdminRentalInfoStatus } from 'store/admin/rentalAgreement/selectors';

import RentalAgreement from './RentalAgreement';

function TermsAndConditions() {
  const dispatch = useAppDispatch();
  const adminRentalInfo = useAppSelector(selectAdminRentalInfoStatus);

  useEffect(() => {
    dispatch(getAdminRentalInfoAsync());
  }, []);
  return (
    <RentalAgreement
      secondaryTitle={'Terms of Use'}
      paragraph1={
        'The terms and conditions are presented to customers prior to booking, so they can read and agree with the terms before commitment.'
      }
      richTextEditorLabel={'Terms and Conditions'}
      isReadOnly={true}
      agreementDetail={adminRentalInfo?.terms_conditions}
    />
  );
}

export default TermsAndConditions;
