import React, { FC, useState } from 'react';

import IXTable from 'components/tables/IXTable';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  getBillingsAsync,
  setCurrentPage,
} from 'store/provider/billings/actions';
import TableHeader from 'uicontainers/_shared/TableHeader';

import styles from './billing.module.scss';
import { COLUMNS } from './constants';
import {
  createSelectBillingTableData,
  selectGetBillingsRequestStatus,
  selectCurrentPage,
  selectTotalCount,
} from './selectors';
import { Spin } from 'antd';
import { BillingStatusOptions } from 'uicontainers/_shared/constants';

const Billing: FC = () => {
  const [searchValue, setSearchValue] = useState('');
  const [selectedValue, setSelectedValue] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const totalCount = useAppSelector(selectTotalCount);
  const currentPage = useAppSelector(selectCurrentPage);

  const isBillingsLoading =
    useAppSelector(selectGetBillingsRequestStatus) === 'started';
  const billingsTableData = useAppSelector(createSelectBillingTableData);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    let searchParams = {};
    if (searchValue) {
      searchParams = { ...searchParams, search: searchValue };
    }
    if (selectedValue) {
      searchParams = { ...searchParams, status: selectedValue };
    }
    if (startDate) {
      searchParams = { ...searchParams, start_time: startDate };
    }
    if (endDate) {
      searchParams = { ...searchParams, end_time: endDate };
    }
    if (currentPage) {
      searchParams = { ...searchParams, page: currentPage };
    }
    dispatch(getBillingsAsync(searchParams));
  }, [searchValue, selectedValue, currentPage, getBillingsAsync, endDate]);

  const onChange = (page: number) => {
    dispatch(setCurrentPage(page));
  };
  const handleSearch = (value: string) => {
    setSearchValue(value);
    dispatch(setCurrentPage(1));
  };
  const handleDateRangeChange = (date: string, dateString: string) => {
    setStartDate(dateString[0]);
    setEndDate(dateString[1]);
    dispatch(setCurrentPage(1));
  };

  const handleSelect = (value: string) => {
    setSelectedValue(value);
    dispatch(setCurrentPage(1));
  };

  const options = BillingStatusOptions;

  return (
    <div className={styles.root}>
      <Spin spinning={isBillingsLoading}>
        <TableHeader
          title="Billing"
          onChange={handleDateRangeChange}
          onSearch={handleSearch}
          onSelectChange={handleSelect}
          dropdownTitle="Status"
          placeholder="Trip status"
          options={options}
        />
        <IXTable
          data={billingsTableData}
          columns={COLUMNS}
          pagination={{ currentPage: currentPage, count: totalCount, onChange }}
        />
      </Spin>
    </div>
  );
};

export default Billing;
