import React from 'react';
import { Paragraph, Title } from 'components/misc/helpers';

function DamageCover() {
  return (
    <div>
      <Title level={3}>A. Damage and loss cover for borrowing cars</Title>
      <ol>
        <li>1. Your responsibility for damage and loss:</li>
      </ol>
      <Paragraph>
        You’re responsible for any damage or loss to the car that happens from
        the time you pick it up until the time you drop it (and the keys) back -
        even if it’s not your fault, for example, a tree branch falling on the
        car, a hail storm, or damage caused by a hit-and-run driver.
      </Paragraph>
      <Paragraph>Excess options:</Paragraph>
      <Paragraph>
        How much you’ll pay if you’re responsible for damage costs depends on
        the type of cover you purchase from Mobility Mutual when you book your
        trip:
      </Paragraph>
      <ul>
        <li>Basic Cover - $2000 excess (required for all trips)</li>
        <li>
          Premium Cover - $500 excess (optional extra $1.50/hour or $18/day)
        </li>
      </ul>
      <Paragraph>How to protect yourself from damage costs:</Paragraph>
      <Paragraph>
        Accidents happen, even to the most careful drivers. There are a few ways
        you can protect yourself from unexpected damage costs:
      </Paragraph>
      <ul>
        <li>
          Reduce your excess by adding Premium Cover to your trip when you book,
          or anytime before you start your trip
        </li>
        <li>
          Upload clear photos of the entire car before and after your trip
        </li>
        <li>
          Read the exclusions to cover below - you may be liable for the full
          costs of any damage or loss if any of the exclusions apply
        </li>
      </ul>
      <Paragraph>What’s covered:</Paragraph>
      <Paragraph>
        When you book a trip with Jaunt, you’re responsible for returning the
        car to the Owner in the same condition you borrowed it in. You’re
        responsible for any loss or damage to the car during your trip - even if
        it was not your fault.
      </Paragraph>
      <Paragraph>
        Trip Cover offers protection for loss or damage that you are responsible
        for under the Jaunt Member Agreement. This includes:
      </Paragraph>
      <ul>
        <li>Damage to the car you’ve booked</li>
        <li>Loss of the car due to theft or fire</li>
        <li>Towing, storage and recovery charges</li>
        <li>Third party damage and loss</li>
      </ul>
      <Paragraph>What’s not covered:</Paragraph>
      <Paragraph>
        There are some exclusions to the cover. You may be responsible for the
        full cost of any loss or damage if you:
      </Paragraph>
      <ul>
        <li>Use the car to tow anything</li>
        <li>Drive off-road or on an unsealed surface</li>
        <li>Drive through water or on the beach</li>
        <li>Drive above the snow line during snow season</li>
        <li>Put the wrong fuel in the car</li>
        <li>
          Damage the underbody or the roof of the car by driving over or under
          something or putting things on the roof
        </li>
        <li>Carry more passengers or cargo than the car is designed for</li>
        <li>
          Drive for rideshare or courier services or competitive driving, or
          pick up hitch-hikers
        </li>
        <li>
          Carry anything dangerous or illegal, or use the car in connection with
          any illegal activity
        </li>
        <li>
          Use the car in a reckless or dangerous way or contravene traffic
          regulations
        </li>
        <li>
          Drive while using a mobile phone or similar device (e.g. texting)
        </li>
        <li>
          Drive with a warning light on or after becoming aware of a mechanical
          fault, or when the car is in an unsafe or unroadworthy condition
        </li>
        <li>
          Drive under the influence of drugs or alcohol, or refuse to submit to
          a test
        </li>
        <li>
          Leave the car unlocked or fail to secure the key in the Lockbox when
          returning the car
        </li>
        <li>Jump start the car, or use it to jump start another car</li>
        <li>
          Commit fraud or fail to provide information that is relevant to a
          damage claim
        </li>
        <li>Flee the scene of an accident</li>
        <li>Let anyone other than an approved Jaunt member drive the car</li>
        <li>
          Don’t return the car within two hours after your trip’s end time.
        </li>
      </ul>
      <Paragraph>To be eligible for cover you also need to:</Paragraph>
      <ul>
        <li>report any damage or theft to us promptly;</li>
        <li>
          get the details of any other parties in an accident and (if relevant)
          wait at the scene until police arrive; and
        </li>
        <li>provide information and documents in relation to a claim.</li>
      </ul>
      <Paragraph>
        Trip Cover doesn’t cover any loss or damage to your personal belongings
        in the car.
      </Paragraph>
      <Paragraph>
        Full details of the exclusions are listed in the Trip Cover Protection
        Wording. If there is any difference between this summary and the full
        terms set out in the Trip Cover Protection Wording, then the Trip Cover
        Protection Wording will prevail.
      </Paragraph>
      <Paragraph>Who’s covered:</Paragraph>
      <Paragraph>
        You’re covered when you, or any other approved Jaunt member, is driving
        the car during a valid booking. If you let someone who’s not an approved
        member drive, then you won’t be covered.
      </Paragraph>
      <Paragraph>
        It’s free to share the driving with another Jaunt member. You don’t need
        to nominate them or add them to your booking, as long as they are
        approved as a member before they drive.
      </Paragraph>
      <Paragraph>
        If the car has a manual transmission, all drivers must have at least two
        years’ manual driving experience.
      </Paragraph>
      <Paragraph>When you’re covered:</Paragraph>
      <Paragraph>
        Your Trip Cover applies for the booking period set out in this
        Protection Schedule, starting at the time you pick up the car and
        finishing when you end your trip.
      </Paragraph>

      <Paragraph>
        If you don’t return the car on time, you may not be covered. Make sure
        to extend your trip in the app if you need more time.
      </Paragraph>
      <Paragraph>What costs you may be liable for:</Paragraph>
      <Paragraph id="damage">
        If you’re responsible for damage or loss, you’ll be liable for the costs
        of:
      </Paragraph>
      <ul>
        <li>
          damage to the vehicle that requires repair or replacement (excluding
          normal wear and tear)
        </li>
        <li>
          loss arising from theft of the vehicle or fire damage to the vehicle
        </li>
        <li>towing, storage and recovery charges</li>
        <li>
          fees and charges relating to the assessment of the loss or damage
        </li>
        <li>third party loss</li>
        <li>
          a loss of use payment to the Owner for each day their car is
          unavailable
        </li>
      </ul>
      <Paragraph>
        The most you’ll pay for these costs is your excess ($2000 with Basic
        Cover or $500 with Premium Cover), subject to your claim being accepted
        by Mobility Mutual. If any of the exclusions to cover (listed above)
        apply, you won’t be covered and will be liable for the full cost of
        repairs or replacement.
      </Paragraph>
      <Paragraph>
        If you weren’t at fault in a third-party accident, you’ll pay your
        excess upfront and we’ll refund any costs we recover from the at-fault
        party. Be sure to get the contact details of any other drivers involved
        in an accident.
      </Paragraph>
      <Paragraph>How the damage cover works:</Paragraph>
      <Paragraph>
        Damage cover for Jaunt trips is a financial risk product offered by One
        Go on behalf of{' '}
        <a
          href="https://www.mobilitymutual.com.au/"
          target="_blank"
          rel="noreferrer"
        >
          Mobility Mutual
        </a>
        .
      </Paragraph>
      <Paragraph>
        When you book a trip with Jaunt, you also join Mobility Mutual. Your
        trip cost includes a contribution to the mutual for your cover and this
        is pooled with contributions from other Jaunt members.
      </Paragraph>

      <Title level={3}>B. Responsibility for damage</Title>
      <ol>
        <li>
          <b>1. Determining responsibility for damage</b>
        </li>
      </ol>
      <Paragraph>
        With thousands of real people’s cars shared by tens of thousands of
        borrowers each week in the Jaunt community, it is crucial that we have
        clear, firm and consistent rules to establish liability for damage.
      </Paragraph>
      <Paragraph>
        Damage liability is determined under the rules set out in the{' '}
        <a
          href="https://www.carnextdoor.com.au/terms-of-use/member-agreement"
          target="_blank"
          rel="noreferrer"
        >
          Member Agreement
        </a>{' '}
        and{' '}
        <a
          href="https://www.carnextdoor.com.au/terms-of-use/damage-policy"
          target="_blank"
          rel="noreferrer"
        >
          Damage Policy.
        </a>{' '}
        Any disputes between members, or between a member and Jaunt, are managed
        under the{' '}
        <a
          href="https://support.carnextdoor.com.au/hc/en-gb/articles/115003829523-Escalating-Your-Issue-our-Dispute-Resolution-Policy"
          target="_blank"
          rel="noreferrer"
        >
          Dispute Resolution Policy.
        </a>
      </Paragraph>
      <Paragraph>
        Also see the other articles in this section (listed below) for more
        detailed information.
      </Paragraph>
    </div>
  );
}

export default DamageCover;
