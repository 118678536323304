import { createSelector } from 'reselect';

import { selectTickets } from 'store/admin/tickets/selectors';
import { RootState } from 'store/types';

import { transformTicketsToTableData } from './helpers';

export const createSelectTicketTableData = createSelector(
  selectTickets,
  transformTicketsToTableData,
);

export const selectGetTicketsRequestStatus = (state: RootState) =>
  state.resources.admin.tickets.getTicketsRequestStatus;

export const selectTotalCount = (state: RootState) => state.resources.admin.tickets.totalCount;

export const selectCurrentPage = (state: RootState) => state.resources.admin.tickets.currentPage;
