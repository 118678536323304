import { Row, Typography } from 'antd';
import classNames from 'classnames';
import React, { FC } from 'react';

import DateRangePicker from 'components/DateRangePicker';
import SearchInput from 'components/SearchInput/SearchInput';
import SelectInput from 'components/SelectInput';

import styles from './tableHeader.module.scss';
import { Props } from './types';

const TableHeader: FC<Props> = (props) => {
  const {
    title,
    button,
    onSearch,
    onChange,
    onSelectChange,
    onSecondSelectChange,
    className = '',
    options,
    extraOptions = [],
    placeholder = 'Status',
    extraPlaceholder = '',
  } = props;
  const Title = Typography.Title;

  return (
    <div
      className={classNames({
        [styles.tableTopContainer]: true,
        [className]: Boolean(className),
      })}
    >
      <Row className={styles.titleContainer}>
        <Title level={3}>{title}</Title>
        {button}
      </Row>
      <Row>
        <SearchInput onSearch={onSearch} />

        {onChange && <DateRangePicker onChange={onChange} />}
        <SelectInput options={options} placeholder={placeholder} onChange={onSelectChange} />
        {extraPlaceholder && (
          <SelectInput
            options={extraOptions}
            placeholder={extraPlaceholder}
            onChange={onSecondSelectChange}
          />
        )}
      </Row>
    </div>
  );
};
export default TableHeader;
