import { createSlice } from '@reduxjs/toolkit';

import { getProviderInfoAsync } from './thunks';
import { TripBookingVehicleProviderDetailState } from './types';

const initialState: TripBookingVehicleProviderDetailState = {
  providerDetails: {
    first_name: '',
    last_name: '',
    rating: 0,
    trips: 0,
    joined_date: '',
    address: null,
    email: '',
    profile_picture: '',
    '100_points': null,
    user_documents: null,
    approve_to_drive: false,
    is_active: false,
    id: 0,
  },
  getVehicleProviderDetailsRequestStatus: 'initial',
};

export const tripBookingVehicleProviderDetailsSlice = createSlice({
  name: 'vehicleProvider',
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder
      .addCase(getProviderInfoAsync.pending, (state) => {
        state.getVehicleProviderDetailsRequestStatus = 'started';
      })
      .addCase(getProviderInfoAsync.fulfilled, (state, action) => {
        state.providerDetails = action.payload;
        state.getVehicleProviderDetailsRequestStatus = 'done';
      })
      .addCase(getProviderInfoAsync.rejected, (state) => {
        state.getVehicleProviderDetailsRequestStatus = 'failed';
      });
  },
});

export default tripBookingVehicleProviderDetailsSlice;
