import React from 'react';
import { useStripe } from '@stripe/react-stripe-js';

import { Button as AntButton, Divider, Spin } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import styles from '../Home/account.module.scss';

import { Text, Title } from 'components/misc/helpers';

import { usePointIdentificationSelectors } from './hooks';
import { createVerificationAsync } from 'store/customer/account/thunks';
import { useAppSelector, useUnwrapAsyncThunk } from 'store/hooks';
import { selectCustomerAccount } from 'store/customer/account/selectors';
import { getProfileAsync } from "store/customer/account/actions";

const PointIdentification = () => {
  const unwrap = useUnwrapAsyncThunk();
  const stripe = useStripe();
  const { user_documents } = useAppSelector(selectCustomerAccount);

  const { isLoading } = usePointIdentificationSelectors();

  // @ts-ignore
  const handleVerification = async (event, documentType) => {
    // Block native event handling.
    event.preventDefault();

    if (!stripe) {
      // Stripe.js has not loaded yet. Make sure to disable
      // the button until Stripe.js has loaded.
      return;
    }

    // Call your backend to create the VerificationSession.
    unwrap(createVerificationAsync({ document_type: documentType }))
      .then(async (res) => {
        // Show the verification modal.
        const { error } = await stripe.verifyIdentity(res.client_secret);

        if (error) {
          console.log('[error]', error);
        } else {
          unwrap(getProfileAsync());
          console.log('Verification submitted!');
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div className={styles.pointIdentification}>
      <Title level={2}>100 Point Identification</Title>

      <Spin spinning={isLoading}>
        <div>
          <div className={styles.upperTitleContainer}>
            <Text strong>Passport (70 points) </Text>
          </div>

          <div className={styles.upperTitleContainer}>
            <Text>Upload a photo or PDF copy of your passport</Text>
          </div>
          <AntButton
            className={styles.uploadButton}
            icon={<UploadOutlined />}
            id="verify-button"
            onClick={(e) => handleVerification(e, 'passport')}
            disabled={user_documents?.is_passport_verified}
          >
            Upload and Verify
          </AntButton>
        </div>

        <Divider />

        <div>
          <div className={styles.upperTitleContainer}>
            <Text strong>National ID (70 points) </Text>
          </div>

          <div className={styles.upperTitleContainer}>
            <Text>Upload a photo or PDF copy of your National ID</Text>
          </div>
          <AntButton
            className={styles.uploadButton}
            icon={<UploadOutlined />}
            id="verify-button"
            onClick={(e) => handleVerification(e, 'id_card')}
            disabled={user_documents?.is_nid_verified}
          >
            Upload and Verify
          </AntButton>
        </div>

        <Divider />

        <div>
          <div className={styles.upperTitleContainer}>
            <Text strong>Driving License (60 points) </Text>
          </div>

          <div>
            <div className={styles.upperTitleContainer}>
              <Text>Upload a photo or PDF copy of your driving license</Text>
            </div>
          </div>

          <div>
            <AntButton
              className={styles.uploadButton}
              icon={<UploadOutlined />}
              id="verify-button"
              onClick={(e) => handleVerification(e, 'driving_license')}
              disabled={user_documents?.is_driving_license_verified}
            >
              Upload and Verify
            </AntButton>
          </div>
        </div>

        <Divider />
      </Spin>
    </div>
  );
};

export default PointIdentification;
