import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'shared/routes/constants';

import { usePersonalInfoSelectors } from './hooks';

import { ContentType } from '../_shared/types';
import InfoCard from '../_shared';

const PersonalInfo = () => {
  const navigate = useNavigate();

  const { personalInfo, status } = usePersonalInfoSelectors();

  const [contents, setContents] = useState<ContentType[]>([]);

  useEffect(() => {
    setContents([
      {
        text: 'Name',
        value: `${personalInfo.first_name} ${personalInfo.last_name}`,
      },
      {
        text: 'Date of birth',
        value: personalInfo.date_of_birth,
      },
      {
        text: 'Email Address',
        value: personalInfo.email,
      },
      {
        text: 'Phone',
        value: personalInfo.phone_no,
      },
      {
        text: 'Address',
        value: personalInfo.address,
      },
    ]);
  }, [status]);

  const editClickHandler = () => {
    navigate(ROUTES.PATHS.USER.USER_ACCOUNT_PERSONAL_INFO);
  };

  return (
    <>
      <InfoCard
        titleText="Personal Information"
        contents={contents}
        onEditClick={editClickHandler}
        loading={status === 'started'}
      />
    </>
  );
};

export default PersonalInfo;
