import { CheckOutlined } from '@ant-design/icons';
import React from 'react';

import { Customer } from 'api/provider/customers/types';
import { dateGenerator } from 'shared/helpers/helperFunctions';

import { CustomersTableData } from './types';

export const transformCustomersToTableData = (customers: Customer[]): CustomersTableData[] => {
  return customers.map((customer) => ({
    key: customer.customer_id.toString(),
    customer: {
      text: `${customer.customer_first_name} ${customer.customer_last_name}`,
      fontWeight: 'bold',
    },
    email: {
      rows: [
        {
          text: customer.customer_email,
        },
      ],
    },
    phone: {
      text: customer.customer_phone_no,
    },
    verification_point: {
      rows: customer.customer__hundred_points >= 100
        ? [
            {
              isLinkable: false,
              icon: <CheckOutlined />,
              iconTo: '',
              textTo: '',
              color: 'green',
              text: '100',
            },
          ]
        : [],
    },
    status: {
      tags: [
        {
          name: customer.customer_is_active ? 'Active' : 'Inactive',
          color: customer.customer_is_active ? 'Green' : 'Red',
        },
      ],
    },
    modified: {
      text: dateGenerator(customer.customer_updated_at),
    },
  }));
};
