import { RootState } from 'store/types';

export const selectBasicInfo = (state: RootState) => state.ui.provider.vehicle.basicInfo.data;

export const selectStatus = (state: RootState) => state.ui.provider.vehicle.basicInfo.status;

export const selectVehicleTypes = (state: RootState) =>
  state.ui.provider.vehicle.basicInfo.vehicle_types;

export const selectVehicleMake = (state: RootState) =>
  state.ui.provider.vehicle.basicInfo.vehicle_makes;

export const selectVehicleModel = (state: RootState) =>
  state.ui.provider.vehicle.basicInfo.vehicle_models;

export const selectPostRequestStatus = (state: RootState) =>
  state.resources.provider.vehicles.postVehicleRequestStatus;

export const selectGetVehicleDetailsStatus = (state: RootState) =>
  state.resources.provider.vehicles.getVehicleDetailsRequestStatus;
