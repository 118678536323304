import { createSlice } from '@reduxjs/toolkit';

import {
  createVehicleAsync,
  getVehicleDetailsAsync,
  getVehicleTypesAsync,
  getVehicleMakeAsync,
  getVehicleModelAsync,
} from 'store/provider/vehicles/actions';
import { BasicInfoState } from './types';
import { TRANSMISSION_TYPE } from '../constants';

const initialState: BasicInfoState = {
  data: {
    vehicle_type: '',
    vehicle_make: '',
    vehicle_model: '',
    vehicle_year: undefined,
    vehicle_transmission: TRANSMISSION_TYPE.AUTOMATIC,
    license_no: '',
    color: '',
    vehicle_name: '',
    status: 'INACTIVE',
  },
  vehicle_types: [],
  vehicle_makes: [],
  vehicle_models: [],
  status: 'initial',
};

export const uiSlice = createSlice({
  name: 'vehicle',
  initialState,
  reducers: {
    resetBasicInfo: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(createVehicleAsync.pending, (state) => {
        state.status = 'started';
      })
      .addCase(createVehicleAsync.fulfilled, (state, action) => {
        state.status = 'done';

        const {
          color,
          license_no,
          vehicle_make,
          vehicle_model,
          vehicle_transmission,
          vehicle_type,
          vehicle_year,
          id,
        } = action.payload;
        state.data.vehicle_model = vehicle_model;
        state.data.color = color;
        state.data.license_no = license_no;
        state.data.vehicle_make = vehicle_make;
        state.data.vehicle_transmission = vehicle_transmission;
        state.data.vehicle_type = vehicle_type;
        state.data.vehicle_year = vehicle_year;
        state.data.id = id;
      })
      .addCase(createVehicleAsync.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(getVehicleDetailsAsync.fulfilled, (state, action) => {
        state.data = {
          ...state.data,
          ...action.payload,
        };
      })
      .addCase(getVehicleTypesAsync.fulfilled, (state, action) => {
        const { results } = action.payload;
        state.vehicle_types = results.map((item) => ({
          name: item.name,
          value: item.id,
        }));
      })
      .addCase(getVehicleMakeAsync.fulfilled, (state, action) => {
        const { results } = action.payload;
        state.vehicle_makes = results.map((item) => ({
          name: item.name,
          value: item.id,
        }));
      })
      .addCase(getVehicleModelAsync.fulfilled, (state, action) => {
        const { results } = action.payload;
        state.vehicle_models = results.map((item) => ({
          name: item.name,
          value: item.id,
        }));
      });
  },
});

export default uiSlice;
