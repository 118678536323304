import React from 'react';
import {
  CalendarOutlined,
  CarOutlined,
  DashboardOutlined,
  DollarOutlined,
  HistoryOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import { ROUTES } from 'shared/routes/constants';

export const getSideBarMenu = () => [
  {
    title: 'Dashboard',
    icon: <DashboardOutlined />,
    link: ROUTES.PATHS.PROVIDER.PROVIDER_DASHBOARD,
  },
  {
    title: 'Calendar',
    icon: <CalendarOutlined />,
    link: ROUTES.PATHS.PROVIDER.PROVIDER_CALENDAR,
  },
  {
    title: 'Vehicles',
    icon: <CarOutlined />,
    link: ROUTES.PATHS.PROVIDER.PROVIDER_VEHICLES,
  },
  {
    title: 'Customers',
    icon: <TeamOutlined />,
    link: ROUTES.PATHS.PROVIDER.PROVIDER_CUSTOMERS,
  },
  {
    title: 'Trip History',
    icon: <HistoryOutlined />,
    link: ROUTES.PATHS.PROVIDER.PROVIDER_TRIP_HISTORY,
  },
  {
    title: 'Billing',
    icon: <DollarOutlined />,
    link: ROUTES.PATHS.PROVIDER.PROVIDER_BILLING,
  },
  // {
  //   title: 'Toll and Tickets',
  //   icon: <FileDoneOutlined />,
  //   link: ROUTES.PATHS.PROVIDER.PROVIDER_TOLL_AND_TICKETS,
  // },
  // {
  //   title: 'Rental Agreement',
  //   icon: <AuditOutlined />,
  //   link: ROUTES.PATHS.PROVIDER.PROVIDER_RENTAL_AGREEMENT,
  // },
];
