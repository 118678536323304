import React from 'react';

import { useUnwrapAsyncThunk, useAppSelector } from 'store/hooks';
import {
  selectSearchedVehicles,
  selectPopularProviders,
} from 'store/tripBooking/home/selectors';
import {
  getSearchedVehiclesAsync,
  getPopularProviderAsync,
} from 'store/tripBooking/home/actions';

import TopSection from './TopSection';
import FeaturedVehicles from './FeaturedVehicles';
import BrowseCar from './BrowseCar';
import PopularRides from './PopularRides';
import BottomLargeCard from './BottomLargeCard';
import FaqSection from './FaqSection';

const Home = () => {
  const unwrap = useUnwrapAsyncThunk();
  const vehicles = useAppSelector(selectSearchedVehicles);
  const providers = useAppSelector(selectPopularProviders);

  React.useEffect(() => {
    unwrap(getSearchedVehiclesAsync({ featured: 'True' }));
    unwrap(getPopularProviderAsync());
  }, []);
  return (
    <div>
      <TopSection />
      <FeaturedVehicles vehicles={vehicles} />
      {/*<VehicleTypes />*/}
      <BrowseCar />
      <PopularRides providers={providers} />
      {/*<PopularDestinations />*/}
      <BottomLargeCard />
      <FaqSection />
    </div>
  );
};

export default Home;
