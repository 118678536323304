import { createAsyncThunk } from '@reduxjs/toolkit';
import { getBookingDetails, updateBookingDetails} from 'api/customer/booking';
import { BookingsUpdateParams } from "api/customer/booking/types";
import { getBookingsAsync } from 'store/customer/bookings/actions';


export const getBookingDetailsAsync = createAsyncThunk(
  'customer/booking',
  async (id: number) => {
    const response = await getBookingDetails(id);
    return response;
  }
);

export const changeBookingDetailsAsync = createAsyncThunk(
  'customer/booking/change_booking',
  async ({ data, id }: { data: BookingsUpdateParams; id: number },
    { dispatch }) => {
    try {
      const response = await updateBookingDetails(data,id);
      dispatch(getBookingsAsync());
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);