import React, { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Col, Row, Typography } from "antd";

import isEqual from "lodash/isEqual";

import styles from "./vehicles.module.scss";

const Title = Typography.Title;

const NewVehicle: FC = () => {
  const navigate = useNavigate();
  const { vehicleId } = useParams();

  return (
    <>
      <div className={styles.top_section}>
        <Row>
          <Col span={24}>
            <div className={styles.title_container}>
              <ArrowLeftOutlined
                onClick={() => navigate(-1)}
                className={styles.icon}
              />
              <Title className={styles.title} level={3}>
                {isEqual(vehicleId, "new")
                  ? "Add New Vehicle"
                  : "Edit Vehicle Information"}
              </Title>
            </div>
          </Col>
        </Row>
      </div>
      <div className={styles.divider} />
    </>
  );
};

export default NewVehicle;
