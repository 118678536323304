import React, { FC, useState } from 'react';

import IXTable from 'components/tables/IXTable';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  getProvidersAsync,
  setCurrentPage,
} from 'store/admin/providers/actions';
import TableHeader from 'uicontainers/_shared/TableHeader';

import { COLUMNS } from './constants';
import styles from './customer.module.scss';
import {
  createSelectProviderTableData,
  selectProvidersRequestStatus,
  selectCurrentPage,
  selectTotalCount,
} from './selectors';
import { Spin } from 'antd';

const Providers: FC = () => {
  const [searchValue, setSearchValue] = useState('');
  const [selectedValue, setSelectedValue] = useState('');

  const providersTableData = useAppSelector(createSelectProviderTableData);
  const isLoading = useAppSelector(selectProvidersRequestStatus) === 'started';
  const totalCount = useAppSelector(selectTotalCount);
  const currentPage = useAppSelector(selectCurrentPage);
  const dispatch = useAppDispatch();

  const options = [
    { name: 'Active', value: 'ACTIVE' },
    { name: 'Inactive', value: 'INACTIVE' },
    { name: 'Pending', value: 'PENDING' },
  ];

  React.useEffect(() => {
    let searchParams = {};
    if (searchValue) {
      searchParams = { ...searchParams, search: searchValue };
    }
    if (selectedValue) {
      searchParams = { ...searchParams, status: selectedValue };
    }
    if (currentPage) {
      searchParams = { ...searchParams, page: currentPage };
    }
    dispatch(getProvidersAsync(searchParams));
  }, [searchValue, selectedValue, currentPage, getProvidersAsync]);

  const onChange = (page: number) => {
    dispatch(setCurrentPage(page));
  };
  const handleSearch = (value: string) => {
    setSearchValue(value);
    dispatch(setCurrentPage(1));
  };
  const handleSelect = (value: string) => {
    setSelectedValue(value);
    dispatch(setCurrentPage(1));
  };

  return (
    <div className={styles.root}>
      <Spin spinning={isLoading}>
        <TableHeader
          title="Providers"
          onSearch={handleSearch}
          onSelectChange={handleSelect}
          dropdownTitle="Status"
          options={options}
        />
        <IXTable
          data={providersTableData}
          columns={COLUMNS}
          pagination={{ currentPage: currentPage, count: totalCount, onChange }}
        />
      </Spin>
    </div>
  );
};

export default Providers;
