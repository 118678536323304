import { DownOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Dropdown, Menu } from 'antd';
import classNames from 'classnames';
import React, { FC, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getProfileAsync } from 'store/customer/account/actions';

import { ACCESS_TOKEN_NAME } from 'api/base/constants';
import { useIsMobile } from 'shared/hooks';
import { ROUTES } from 'shared/routes/constants';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  setIsAuthenticated,
  setUserData,
  setUserMode,
} from 'store/shared/auth/actions';
import Image from 'components/Image';
import { selectLoginData, selectUserMode } from 'store/shared/auth/selectors';
import {
  isAdmin,
  isProvider,
  isCustomer,
} from 'uicontainers/_shared/helpers/auth';
import { usePersonalInfoSelectors } from 'uicontainers/Customer/Account/PersonalInfo/hooks';

import styles from './profile.module.scss';
import { Props } from './types';

export const ProfileMenu: FC<Props> = (props) => {
  const { isLoggedIn } = props;
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const location = useLocation();
  const loginData = useAppSelector(selectLoginData);
  const { personalInfo, isLoading } = usePersonalInfoSelectors();
  const userMode = useAppSelector(selectUserMode);
  const userName = `${loginData?.first_name}`;

  const hasAdminRole = loginData && isAdmin(loginData.user_role);
  const hasProviderRole = loginData && isProvider(loginData.user_role);
  const hasCustomerRole = loginData && isCustomer(loginData.user_role);

  const isProviderMode =
    hasProviderRole &&
    (userMode === 'PROVIDER' || location.pathname.includes('provider'));
  const isCustomerMode =
    hasCustomerRole &&
    (userMode === 'CUSTOMER' || location.pathname.includes('customer'));

  useEffect(() => {
    dispatch(getProfileAsync());
  }, []);

  // @ts-ignore
  const handleClick = (info: MenuInfo) => {
    if (info.key === 'profile') {
      navigate(ROUTES.PATHS.USER.USER_PROFILE);
    }
    if (info.key === 'account') {
      navigate(ROUTES.PATHS.USER.USER_ACCOUNT_PERSONAL_INFO);
    }
    if (info.key === 'logOut') {
      localStorage.removeItem(ACCESS_TOKEN_NAME);
      dispatch(setIsAuthenticated(false));
      dispatch(setUserData(undefined));
      window.location.href = '/login';
    }
    if (info.key === 'userMode') {
      if (isProviderMode) {
        navigate(ROUTES.PATHS.CUSTOMER.CUSTOMER_BOOKINGS);
        dispatch(setUserMode('CUSTOMER'));
      } else {
        navigate(ROUTES.PATHS.PROVIDER.PROVIDER_DASHBOARD);
        dispatch(setUserMode('PROVIDER'));
      }
    }
    if (info.key === 'home') {
      if (isProviderMode) {
        navigate(ROUTES.PATHS.PROVIDER.PROVIDER_DASHBOARD);
      } else if (isCustomerMode) {
        navigate(ROUTES.PATHS.CUSTOMER.CUSTOMER_BOOKINGS);
      } else if (hasAdminRole) {
        navigate(ROUTES.PATHS.ADMIN.ADMIN_DASHBOARD);
      } else {
        navigate(ROUTES.PATHS.CUSTOMER.CUSTOMER_BOOKINGS);
      }
    }
  };

  const menu = (
    <Menu onClick={handleClick}>
      {!hasAdminRole && <Menu.Item key="profile">Profile</Menu.Item>}
      <Menu.Item key="account">Account</Menu.Item>
      {hasProviderRole && (
        <Menu.Item key="userMode">
          {isProviderMode ? 'Customer mode' : 'Provider mode'}
        </Menu.Item>
      )}
      <Menu.Divider />
      <Menu.Item key="home">Home</Menu.Item>
      <Menu.Divider />
      <Menu.Item key="logOut">Log out</Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <div
        className={classNames({
          [styles.container]: true,
          [styles['is-mobile']]: isMobile,
        })}
        onClick={() => {}}
      >
        <div className={styles.avatar}>
          {personalInfo?.profile_picture ? (
            <Image
              src={personalInfo.profile_picture}
              className={styles.imageStyle}
            />
          ) : (
            <Avatar size={32} icon={<UserOutlined />} />
          )}
        </div>
        {!isMobile && isLoggedIn ? (
          <div className={`${styles.userName}`}>{userName}</div>
        ) : (
          !isMobile && <div className={styles.userName}>Sign up</div>
        )}
        <div className={styles.downIcon}>
          <DownOutlined />
        </div>
      </div>
    </Dropdown>
  );
};

export default ProfileMenu;
