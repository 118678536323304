import React, { useState, FC, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { Row, Col } from 'antd';
import Button from 'components/Button';
import { ROUTES } from 'shared/routes/constants';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  handleRentalContractAsync,
  getAdminRentalInfoAsync,
  updateRentalContractAsync,
} from 'store/admin/rentalAgreement/actions';
import { selectAdminRentalInfoStatus } from 'store/admin/rentalAgreement/selectors';
import styles from './rentalAgreement.module.scss';
import SidebarNavigation from 'components/SideNavigation';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { Props } from './types';

const sidebarMenu = [
  {
    title: 'Terms and Conditions',
    link: `${ROUTES.PATHS.ADMIN.ADMIN_RENTAL_AGREEMENT_TERMS_AND_CONDITIONS}`,
    routeName: 'terms-and-conditions',
  },
  {
    title: 'Instructions',
    link: `${ROUTES.PATHS.ADMIN.ADMIN_RENTAL_AGREEMENT_INSTRUCTIONS}`,
    routeName: 'instructions',
  },
];

const RentalAgreement: FC<Props> = (props) => {
  const { secondaryTitle, paragraph1, richTextEditorLabel, footerText } = props;
  const adminRentalInfo = useAppSelector(selectAdminRentalInfoStatus);
  const [agreementData, setAgreementData] = useState('');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const lastItem = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
  const found = sidebarMenu.find((item) => item.routeName === lastItem);
  const [activeTab] = useState(found?.title);

  useEffect(() => {
    dispatch(getAdminRentalInfoAsync());
  }, []);
  useEffect(() => {
    if (activeTab === 'Terms and Conditions') {
      adminRentalInfo && setAgreementData(adminRentalInfo?.terms_conditions);
    }
    if (activeTab === 'Instructions') {
      adminRentalInfo && setAgreementData(adminRentalInfo?.instructions);
    }
  }, [adminRentalInfo]);

  const onClick = (link: string) => {
    navigate(link);
  };

  const handleSave = () => {
    if (activeTab === 'Terms and Conditions') {
      if (adminRentalInfo) {
        dispatch(
          updateRentalContractAsync({
            data: { terms_conditions: agreementData },
            id: adminRentalInfo.id,
          }),
        );
      } else {
        dispatch(
          handleRentalContractAsync({
            terms_conditions: agreementData,
          }),
        );
      }
    }

    if (activeTab === 'Instructions') {
      if (adminRentalInfo) {
        dispatch(
          updateRentalContractAsync({
            data: { instructions: agreementData },
            id: adminRentalInfo.id,
          }),
        );
      } else {
        dispatch(
          handleRentalContractAsync({
            instructions: agreementData,
          }),
        );
      }
    }
  };

  const toolbarOptions = [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline'],
    [{ color: [] }, { background: [] }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ align: [] }],
  ];

  return (
    <div className={styles.root}>
      <h1 className={styles.containerTitle}>
        {' '}
        <b>Rental Agreement</b>
      </h1>
      <Row>
        <Col sm={6}>
          <SidebarNavigation onClick={onClick} menuItems={sidebarMenu} selectedTab={activeTab} />{' '}
        </Col>

        <Col sm={18}>
          <div className={styles.container}>
            <h1>
              <b>{secondaryTitle}</b>
            </h1>

            <span className={styles.agreementParagraph}>{paragraph1}</span>

            <p className={styles.textAreaTitle}>
              <b>{richTextEditorLabel}</b>
            </p>
            <ReactQuill
              modules={{ toolbar: toolbarOptions }}
              theme="snow"
              value={agreementData || ''}
              onChange={setAgreementData}
              className={styles.richTextEditor}
            />

            <span className={styles.agreementParagraph}>{footerText}</span>

            <Button
              type="ghost"
              isBordered={true}
              className={classNames({
                [styles.buttonContainer]: true,
              })}
              onClick={handleSave}
            >
              <span className={styles.buttonText}>Save</span>
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default RentalAgreement;
