import { CheckOutlined } from '@ant-design/icons';
import React from 'react';

import { Customer } from 'api/admin/customers/types';
import { dateGenerator } from 'shared/helpers/helperFunctions';

import { CustomersTableData } from './types';

export const transformCustomersToTableData = (
  customers: Customer[]
): CustomersTableData[] => {
  return customers.map((customer) => ({
    key: customer.id.toString(),
    customer: {
      text: `${customer.first_name} ${customer.last_name}`,
      fontWeight: 'bold',
    },
    email: {
      rows: [
        {
          text: customer.email,
        },
      ],
    },
    phone: {
      text: customer.phone_no,
    },
    verification_point: {
      rows:
        customer['100_points'] >= 100
          ? [
              {
                isLinkable: false,
                icon: <CheckOutlined />,
                iconTo: '',
                textTo: '',
                color: 'green',
                text: '100',
              },
            ]
          : [],
    },
    status: {
      tags: [
        {
          name: customer.is_active ? 'Active' : 'Inactive',
          color: customer.is_active ? 'Green' : 'Red',
        },
      ],
    },
    modified: {
      text: dateGenerator(customer.updated_at),
    },
  }));
};
