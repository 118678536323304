import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  createVehicle,
  updateVehicle,
  uploadImage,
  getVehicles,
  deleteUploadedImage,
  getVehicleDetails,
  getInsuranceCompany,
  getVehicleTypes,
  getVehicleModel,
  getVehicleMake,
} from 'api/provider/vehicles';
import {
  UpdateVehicleData,
  CreateVehicleData,
  VehicleRequestParams,
} from 'api/provider/vehicles/types';

export const getVehiclesAsync = createAsyncThunk(
  'provider/vehicle/get_vehicles',
  async (params?: VehicleRequestParams) => {
    const response = await getVehicles(params);
    return response;
  },
);

export const createVehicleAsync = createAsyncThunk(
  'provider/vehicles/create_vehicle',
  async (data: Partial<CreateVehicleData>) => {
    const response = await createVehicle(data);
    return response;
  },
);

export const updateVehicleAsync = createAsyncThunk(
  'provider/vehicles/update_vehicle',
  async (data: UpdateVehicleData) => {
    const response = await updateVehicle(data, data.vehicleId);
    return response;
  },
);

export const upload = createAsyncThunk(
  'provider/vehicles/upload',
  async ({ data, vehicleId }: { data: FormData; vehicleId: number }) => {
    const response = await uploadImage(data, vehicleId);
    return response;
  },
);

export const deleteVehicleImageAsync = createAsyncThunk(
  'provider/vehicles/delete-vehicle-image',
  async (image_id: string) => {
    await deleteUploadedImage(image_id);
    return { image_id };
  },
);

export const getVehicleDetailsAsync = createAsyncThunk(
  'provider/vehicles/get_vehicle_details',
  async (id: number) => {
    const response = await getVehicleDetails(id);
    return response;
  },
);

export const getInsuranceCompanyAsync = createAsyncThunk(
  'provider/vehicles/get_insurance_company',
  async () => {
    const response = await getInsuranceCompany();
    return response;
  },
);

export const getVehicleTypesAsync = createAsyncThunk(
  'provider/vehicles/get_vehicle_types',
  async () => {
    const response = await getVehicleTypes();
    return response;
  },
);

export const getVehicleModelAsync = createAsyncThunk(
  'provider/vehicles/get_vehicle_model',
  async ({ makeId }: { makeId: string }) => {
    const response = await getVehicleModel(makeId);
    return response;
  },
);

export const getVehicleMakeAsync = createAsyncThunk(
  'provider/vehicles/get_vehicle_make',
  async () => {
    const response = await getVehicleMake();
    return response;
  },
);
