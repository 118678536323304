import { createSlice } from '@reduxjs/toolkit';

import { getCalendarsAsync } from './thunks';
import { ProviderCalendarsState } from './types';

const initialState: ProviderCalendarsState = {
  calendars: [],
  calendarsRequestStatus: 'initial',
};

export const providerCalendarsSlice = createSlice({
  name: 'calendars',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCalendarsAsync.pending, (state) => {
      state.calendarsRequestStatus = 'started';
    });
    builder.addCase(getCalendarsAsync.fulfilled, (state, action) => {
      state.calendars = action.payload.results;
      state.calendarsRequestStatus = 'done';
    });
    builder.addCase(getCalendarsAsync.rejected, (state) => {
      state.calendarsRequestStatus = 'failed';
    });
  },
});

export default providerCalendarsSlice;
