import { providerAPI } from 'api/base';

import { GetBillings, GetCurrentBalance, PayOut } from './types';

export const getBillings: GetBillings = (params) => {
  return providerAPI.get('/billings/', { params });
};

export const getCurrentBalance: GetCurrentBalance = () => {
  return providerAPI.get('/payout/');
};

export const payOut: PayOut = (data) => {
  return providerAPI.post('/payout/', data);
};
