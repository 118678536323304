import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  PaymentData,
  PaymentIntent,
  SubscriptionData,
} from 'api/tripBooking/payment/types';
import {
  createPaymentIntent,
  handlePayment,
  handleCheckout,
} from 'api/tripBooking/payment/index';

export const handlePaymentAsync = createAsyncThunk(
  'customer/trip_booking/handle_payment',
  async (data: PaymentData) => {
    const response = await handlePayment(data);
    return response;
  }
);

export const handlePaymentIntentAsync = createAsyncThunk(
  'customer/trip_booking/handle_payment_intent',
  async (data: PaymentIntent) => {
    const response = await createPaymentIntent(data);
    return response;
  }
);

//payment
export const handleCheckoutAsync = createAsyncThunk(
  'customer/trip_booking/checkout',
  async (data: SubscriptionData, { rejectWithValue }) => {
    try {
      const response = await handleCheckout(data);
      return response;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e:any) {
      return rejectWithValue(e.response.data);
    }
  }
);
