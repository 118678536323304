import {
  getSearchedVehiclesAsync,
  getVehicleTypesAsync,
  getPopularProviderAsync,
} from "store/tripBooking/home/thunks";
import slice from "./slice";

const { setCurrentPage } = slice.actions;

export {
  getSearchedVehiclesAsync,
  setCurrentPage,
  getVehicleTypesAsync,
  getPopularProviderAsync,
};
