import { createSelector } from 'reselect';

import { selectBillings } from 'store/provider/billings/selectors';
import { RootState } from 'store/types';

import { transformBillingsToTableData } from './helpers';

export const createSelectBillingTableData = createSelector(
  selectBillings,
  transformBillingsToTableData,
);

export const selectGetBillingsRequestStatus = (state: RootState) =>
  state.resources.provider.billings.getBillingsRequestStatus;

export const selectTotalCount = (state: RootState) => state.resources.provider.billings.totalCount;

export const selectCurrentPage = (state: RootState) =>
  state.resources.provider.billings.currentPage;
