import { Col, Divider, Form, Result, Row, Spin, Typography } from 'antd';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from 'components/Button';
import Input from 'components/Input';
import { ROUTES } from 'shared/routes/constants';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { passwordResetRequestAsync } from 'store/shared/auth/thunks';
import { selectForgotPasswordRequestStatus } from 'store/shared/auth/selectors';

import styles from './forgotPassword.module.scss';
import PrivacyAndTermsFooter from 'uicontainers/_shared/PrivacyAndTermsFooter';

const { Title } = Typography;

const ForgotPassword: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isForgotPasswordLoading = useAppSelector(selectForgotPasswordRequestStatus) === 'started';
  const isForgotPasswordSuccess = useAppSelector(selectForgotPasswordRequestStatus) === 'done';

  React.useEffect(() => { }, []);

  const onFinish = (email: string) => {
    dispatch(passwordResetRequestAsync(email));
  };

  const onGoBackToLogin = () => {
    navigate(ROUTES.PATHS.LOGIN);
  };

  return (
    <Row align="middle">
      <Col xs={20} sm={20} md={20} lg={7} className={styles.container}>
        {isForgotPasswordSuccess && (
          <Result
            status="success"
            title="Successfully sent reset link!"
            subTitle="Please check your email and click the reset link to reset your password"
          />
        )}
        {!isForgotPasswordSuccess && (
          <Spin spinning={isForgotPasswordLoading}>
            <Title level={3} className={styles.title}>
              Forgot Password?
              <div className="ant-result-subtitle">
                {' '}
                Enter your email and will send you a reset link to reset your password.
              </div>
            </Title>
            <Form onFinish={onFinish}>
              <Row gutter={[16, 8]}>
                <Col className="gutter-row" span={24}>
                  <Form.Item
                    name="email"
                    rules={[{ required: true, message: 'Please input your email!' }]}
                  >
                    <Input label="Email" type="email" size="large" />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" span={24}>
                  <Button
                    type="ghost"
                    isBordered={true}
                    style={{ width: '100%' }}
                    htmlType="submit"
                  >
                    Submit
                  </Button>
                </Col>
                <Col className="gutter-row" span={24}>
                  <Button
                    onClick={onGoBackToLogin}
                    isBordered={true}
                    style={{ width: '100%' }}
                    htmlType="submit"
                  >
                    Go back to Login
                  </Button>
                </Col>
                <Col className="gutter-row" span={24}>
                  <Divider plain />
                </Col>
                <Col className="gutter-row" span={24}>
                  <PrivacyAndTermsFooter />
                </Col>
              </Row>
            </Form>
          </Spin>
        )}
      </Col>
    </Row>
  );
};

export default ForgotPassword;
