import IconLinkAndTextCell from '../cells/IconLinkAndTextCell/IconLinkAndTextCell';
import TextCell from '../cells/TextCell';
import HealthCell from '../cells/HealthCell';
import DateCell from '../cells/DateCell';
import DateTimeCell from '../cells/DateTimeCell';
import StarRatingCell from '../cells/StarRatingCell';
import LinkCell from '../cells/LinkCell';
import ActionButtonCell from '../cells/ActionButtonCell';
import ImageAndTextCell from '../cells/ImageAndTextCell';

import { Cells } from './types';


export const CELLS: Cells = {
  text: TextCell,
  health: HealthCell,
  'image-and-text-cell': ImageAndTextCell,
  'icon-link-and-text-cell': IconLinkAndTextCell,
  link: LinkCell,
  'action-button-cell': ActionButtonCell,
  date: DateCell,
  datetime: DateTimeCell,
  'star-rating': StarRatingCell,
};

export const DEFAULT_PAGE_SIZE = 10;
