import { providerAPI, pdfGeneratorAPI } from 'api/base';

import {
  PostRentalContract,
  GetRentalContract,
  UpdateRentalContract,
  GetRentalPdfLink,
  GetRentalPdfDownLoad,
} from './types';

export const handleRentalContract: PostRentalContract = (data) => {
  return providerAPI.post(`/rental-agreements/`, data);
};

export const getRentalContract: GetRentalContract = () => {
  return providerAPI.get(`/rental-agreements/`);
};

export const updateRentalContract: UpdateRentalContract = (data, id) => {
  return providerAPI.patch(`/rental-agreements/${id}/`, data);
};

export const getRentalPdfLink: GetRentalPdfLink = () => {
  return providerAPI.get(`/agreements/`, { responseType: 'blob' });
};

export const getRentalPdfDownload: GetRentalPdfDownLoad = (pdfLink: string) => {
  return pdfGeneratorAPI.get(pdfLink);
};
