import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  getSearchedVehiclesAsync,
  getVehicleTypesAsync,
  getPopularProviderAsync,
  getMOreVehiclesAsync,
} from './thunks';
import { TripBookingSearchedVehiclesState } from './types';

const initialState: TripBookingSearchedVehiclesState = {
  searchedVehicles: [],
  vehicle_types: [],
  searchParams: undefined,
  getVehiclesRequestStatus: 'initial',
  currentPage: 1,
  totalCount: 0,
  next: null,
  popularProviders: [],
};

export const tripBookingSearchedVehicleSlice = createSlice({
  name: 'searchedVehicles',
  initialState,
  reducers: {
    setCurrentPage(state, action: PayloadAction<number>) {
      state.currentPage = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getSearchedVehiclesAsync.pending, (state) => {
        state.getVehiclesRequestStatus = 'started';
      })
      .addCase(getSearchedVehiclesAsync.fulfilled, (state, action) => {
        if (action.meta.arg !== undefined) {
          state.searchParams = action.meta.arg;
        }
        state.next = action.payload.next;
        state.searchedVehicles = action.payload.results;
        state.getVehiclesRequestStatus = 'done';
      })
      .addCase(getSearchedVehiclesAsync.rejected, (state) => {
        state.getVehiclesRequestStatus = 'failed';
      })

      .addCase(getMOreVehiclesAsync.pending, (state) => {
        state.getVehiclesRequestStatus = 'started';
      })
      .addCase(getMOreVehiclesAsync.fulfilled, (state, action) => {
        state.totalCount = action.payload.count;
        state.next = action.payload.next;
        state.searchedVehicles = [
          ...state.searchedVehicles,
          ...action.payload.results,
        ];
        state.getVehiclesRequestStatus = 'done';
      })
      .addCase(getMOreVehiclesAsync.rejected, (state) => {
        state.getVehiclesRequestStatus = 'failed';
      })

      .addCase(getVehicleTypesAsync.pending, (state) => {
        state.getVehiclesRequestStatus = 'started';
      })
      .addCase(getVehicleTypesAsync.fulfilled, (state, action) => {
        const { results } = action.payload;
        state.vehicle_types = results.map((item) => ({
          name: item.name,
          value: item.id,
        }));
        state.getVehiclesRequestStatus = 'done';
      })
      .addCase(getVehicleTypesAsync.rejected, (state) => {
        state.getVehiclesRequestStatus = 'failed';
      })
      .addCase(getPopularProviderAsync.fulfilled, (state, action) => {
        const { results } = action.payload;
        state.popularProviders = results;
      });
  },
});

export default tripBookingSearchedVehicleSlice;
