import { createSlice } from '@reduxjs/toolkit';

import { getTripHistoryAsync } from './thunks';
import { TripHistoryState } from './types';

const initialState: TripHistoryState = {
  tripHistories: [],
  totalCount: 0,
};

export const customerTripHistoriesSlice = createSlice({
  name: 'bookings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTripHistoryAsync.fulfilled, (state, action) => {
      state.tripHistories = action.payload.results;
    });
  },
});

export default customerTripHistoriesSlice;
