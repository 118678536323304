import { Spin } from 'antd';
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { ROLE } from 'api/auth/types';
import { ROUTES } from 'shared/routes/constants';
import { useAppSelector } from 'store/hooks';
import { selectIsAuthenticated, selectLoginData, selectVerifyTokenRequestStatus } from 'store/shared/auth/selectors';
import AccessDenied from 'uicontainers/AccessDenied';
import { hasRole } from 'pages/_shared/helpers';

const PrivateRoute = ({ children, roles }: { children: JSX.Element; roles: ROLE[] }) => {
  const location = useLocation();
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const isCheckingToken = useAppSelector(selectVerifyTokenRequestStatus) === 'started';
  const userData = useAppSelector(selectLoginData);

  const userHasRequiredRole = userData && hasRole(roles, userData.user_role) ? true : false;

  if (isCheckingToken) {
    return <Spin spinning={true} size="large"></Spin>;
  } else {
    if (!isAuthenticated) {
      return <Navigate to={ROUTES.PATHS.LOGIN} state={{ from: location }} />;
    }
    
    if (isAuthenticated && !userHasRequiredRole) {
      return <AccessDenied />;
    }
    return children;
  }
};

export default PrivateRoute;
