import { createAsyncThunk } from '@reduxjs/toolkit';

import { getTripHistory } from 'api/admin/tripHistory';
import { TripHistoryRequestParams } from 'api/admin/tripHistory/types';

export const getTripsAsync = createAsyncThunk(
  'admin/tripHistory/get_trips',
  async (params?: TripHistoryRequestParams) => {
    const response = await getTripHistory(params);
    return response;
  },
);
