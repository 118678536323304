import React from 'react';
import { Paragraph, Title } from 'components/misc/helpers';

function TakingTrip() {
  return (
    <div>
      <Title level={3}>A. Accidents, breakdowns and troubleshooting</Title>
      <ul>
        <li>What to do if the app doesn't work during your trip:</li>
      </ul>
      <Paragraph>
        If you can't view your trip details, generate a lockbox code or the app
        crashes during your trip, here are some quick things you can do.
      </Paragraph>
      <ul>
        <li>
          See if you have the latest version of the app. Visit the App Store or
          Google Play to see if there's an update available.
        </li>
        <li>
          Log out and close down the app. Clearing your cache often resolves
          bugs.
        </li>
        <li>Check your internet connection is stable.</li>
      </ul>
      <Paragraph>
        If all else fails, please{' '}
        <a
          href="https://www.carnextdoor.com.au/help"
          target="_blank"
          rel="noreferrer"
        >
          get in touch
        </a>
        , and we can help you with your trip.
      </Paragraph>
      <Title level={3}>B. Payment</Title>
      <ul>
        <li>Paying for the cars you borrow</li>
      </ul>
      <Paragraph>
        Before you can book a trip, you'll need to enter the details of a valid
        Visa or Mastercard. We don't accept Amex or cash payments.
      </Paragraph>
      <Paragraph>
        When you book a trip, the cost is broken down into hire costs which you
        pay upfront and driving costs (which you pay later).
      </Paragraph>
      <Paragraph>Hire costs:</Paragraph>
      <Paragraph>
        When you review the cost and confirm your booking, your card will be
        charged the 'Due now' amount. This covers:
      </Paragraph>
      <ul>
        <li>time charge</li>
        <li>booking fee</li>
        <li>Basic Cover</li>
        <li>Premium Cover upgrade (if selected)</li>
        <li>deposit (for your first trip only)</li>
      </ul>
      <Paragraph>Driving costs:</Paragraph>
      <Paragraph>Your driving costs are:</Paragraph>
      <ul>
        <li>the per-kilometre distance fee</li>
        <li>toll charges (if you drive on any toll roads)</li>
      </ul>
      <Paragraph>Distance fee:</Paragraph>
      <Paragraph>
        For most trips, the distance will be charged the night after you have
        finished your trip, but on occasion it may take up to a week for the
        billing to be finalised. If you have driving credit, an available
        balance in your Jaunt account, or a deposit (if it's your first trip),
        we will take the payment from there first, and only charge the balance
        to your card.
      </Paragraph>
      <Paragraph>
        If you are driving over 550km, we may ask you to pay for distance before
        your trip ends. We will send you an email and SMS to inform you if this
        applies. Read more about
      </Paragraph>
      <Paragraph>progressive payments for long trips.</Paragraph>
      <Paragraph>Pro tip</Paragraph>
      <Paragraph>
        You can view your current estimated distance from 24 hours after your
        trip has started, by going to Trips and checking the Trip cost summary
        for your current booking. If you have push notifications turned on,
        you’ll also receive regular updates. Note that the distance is not
        updated in real-time - a note underneath the Driving costs heading will
        tell you when it last updated.
      </Paragraph>
      <Paragraph>
        Fuel is included in the distance charge, and if there are any
        outstanding refunds pending the distance will not be billed until they
        have been processed. See{' '}
        <a
          href="https://support.carnextdoor.com.au/hc/en-gb/articles/204824079-Buying-fuel-during-a-trip#submitting-a-receipt-for-reimbursement"
          target="_blank"
          rel="noreferrer"
        >
          Submitting a receipt for reimbursement
        </a>
        .
      </Paragraph>
      <Paragraph>Tolls:</Paragraph>
      <Paragraph>
        Tolls are billed as they're charged to us or the car's owner, which is
        generally within 48 hours. For Key Handover trips, and in cases where a
        toll provider sends toll information to us later, billing can occur up
        to a month afterwards. This means you may be charged for tolls in the
        month following your trip.
      </Paragraph>
      <Paragraph id="cancellation">If you cancel your trip:</Paragraph>
      <Paragraph>
        If you cancel before your trip starts, then the cancellation fees (if
        there are any) will be deducted from the pre-payment.
      </Paragraph>
      <Paragraph>
        If your trip ends up being shorter or longer than what you booked:
      </Paragraph>
      <Paragraph>
        If you return the car early, you may still be charged for 'unused time':
        see{' '}
        <a
          href="https://support.carnextdoor.com.au/hc/en-gb/articles/115005755583"
          target="_blank"
          rel="noreferrer"
        >
          "Cancellation policy and cancelled time or unused time charges"
        </a>
        .
      </Paragraph>
      <Paragraph>
        If you extend your trip, you will be charged an extension fee as well as
        the cost of the extra time - see{' '}
        <a
          href="https://support.carnextdoor.com.au/hc/en-gb/articles/204823999-I-m-running-late-how-do-I-extend-my-booking"
          target="_blank"
          rel="noreferrer"
        >
          "I'm running late - how do I extend my booking?'
        </a>
      </Paragraph>
      <Paragraph>Viewing your trip cost breakdown:</Paragraph>
      <Paragraph>
        To view a breakdown of your trip charge, go to the 'Trips' tab of the
        website or app and select the relevant trip.
      </Paragraph>
      <Paragraph>What if I’ve paid more than the trip cost:</Paragraph>
      <Paragraph>
        You may end up paying more upfront than the final trip cost, due to the
        deposit on your first booking, any fuel refunds, driving credit, or a
        trip cancellation. Once the trip cost is finalised, these extra funds
        will be marked as Available in your Jaunt account.
      </Paragraph>
      <Paragraph>
        If you make another booking, these Available funds will be used for the
        upfront payment first, and only any remaining balance charged to your
        card. If any other charges come through to your account soon after the
        funds become available, they will also be covered. For example,
        membership plan fees (if applicable) or any delayed toll charges.
      </Paragraph>
      <Paragraph>
        Otherwise, the remaining funds will be automatically refunded to you
        within a few days. You can speed this up by making a{' '}
        <a
          href="https://support.carnextdoor.com.au/hc/en-gb/articles/360024930131"
          target="_blank"
          rel="noreferrer"
        >
          withdrawal
        </a>{' '}
        via the website or the app.
      </Paragraph>
    </div>
  );
}

export default TakingTrip;
