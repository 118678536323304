import React, { FC, useState } from 'react';
import SideNavigation from 'components/SideNavigation';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { getVehicleSettingsSidebarMenu } from './constants';

const NewVehicle: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { vehicleId = '' } = useParams();

  const lastItem = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
  const found = getVehicleSettingsSidebarMenu(vehicleId).find(
    (item) => item.routeName === lastItem,
  );
  const [activeTab] = useState(found?.title);

  const onClick = (link: string) => {
    navigate(link);
  };

  return (
    <SideNavigation
      onClick={onClick}
      menuItems={getVehicleSettingsSidebarMenu(vehicleId)}
      selectedTab={activeTab}
    />
  );
};

export default NewVehicle;
