import { createSlice } from '@reduxjs/toolkit';

import {
  getEarningAsync,
  getSummaryAsync,
  getTopEarningProvidersAsync,
  getVehicleStatusAsync,
} from './thunks';
import { AdminDashboardState } from './types';

const initialState: AdminDashboardState = {
  summary: {
    booking_count: 0,
    vehicle_rented: 0,
    payment_gateway_fee: 0,
    total_income: 0,
  },
  summaryRequestStatus: 'initial',
  earning: {
    all_year: [],
    this_month: [],
    last_month: [],
  },
  earningRequestStatus: 'initial',
  topEarningProviders: [],
  topEarningProvidersRequestStatus: 'initial',
  vehicleStatus: {
    active: 0,
    standby: 0,
    inactive: 0,
  },
  vehicleStatusRequestStatus: 'initial',
};

export const adminDashboardSlice = createSlice({
  name: 'admin-dashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSummaryAsync.pending, (state) => {
        state.summaryRequestStatus = 'started';
      })
      .addCase(getSummaryAsync.fulfilled, (state, action) => {
        state.summaryRequestStatus = 'done';
        state.summary = action.payload.results;
      })
      .addCase(getSummaryAsync.rejected, (state) => {
        state.summaryRequestStatus = 'failed';
      })
      .addCase(getEarningAsync.pending, (state) => {
        state.earningRequestStatus = 'started';
      })
      .addCase(getEarningAsync.fulfilled, (state, action) => {
        state.earningRequestStatus = 'done';
        state.earning = action.payload.results;
      })
      .addCase(getEarningAsync.rejected, (state) => {
        state.earningRequestStatus = 'failed';
      })
      .addCase(getTopEarningProvidersAsync.pending, (state) => {
        state.topEarningProvidersRequestStatus = 'started';
      })
      .addCase(getTopEarningProvidersAsync.fulfilled, (state, action) => {
        state.topEarningProvidersRequestStatus = 'done';
        state.topEarningProviders = action.payload.results;
      })
      .addCase(getTopEarningProvidersAsync.rejected, (state) => {
        state.topEarningProvidersRequestStatus = 'failed';
      })
      .addCase(getVehicleStatusAsync.pending, (state) => {
        state.vehicleStatusRequestStatus = 'started';
      })
      .addCase(getVehicleStatusAsync.fulfilled, (state, action) => {
        state.vehicleStatusRequestStatus = 'done';
        state.vehicleStatus = action.payload.results;
      })
      .addCase(getVehicleStatusAsync.rejected, (state) => {
        state.vehicleStatusRequestStatus = 'failed';
      });
  },
});

export default adminDashboardSlice;
