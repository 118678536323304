import { createSelector } from 'reselect';

import { selectCustomers } from 'store/provider/customers/selectors';
import { RootState } from 'store/types';

import { transformCustomersToTableData } from './helpers';

export const createSelectCustomerTableData = createSelector(
  selectCustomers,
  transformCustomersToTableData,
);

export const selectCustomersRequestStatus = (state: RootState) =>
  state.resources.provider.customers.customersRequestStatus;

export const selectTotalCount = (state: RootState) => state.resources.provider.customers.totalCount;

export const selectCurrentPage = (state: RootState) =>
  state.resources.provider.customers.currentPage;
