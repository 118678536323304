import { Earning } from 'api/admin/dashboard/types';
import { IncomeType } from 'uicontainers/_shared/constants';
import { EarningReportCategory } from './types';

export const prepareChartData = (
  earning: Earning,
  category: EarningReportCategory
) => {
  if (category === 'all_year') {
    const earnings = earning.all_year.map((item) => {
      return {
        month: item.month,
        earning: item.revenue,
        type: IncomeType.REVENUE,
      };
    });
    const expectedIncomes = earning.all_year
      .filter((item) => item.expected_income > 0)
      .map((item) => {
        return {
          month: item.month,
          earning: item.expected_income,
          type: IncomeType.EXPECTED_INCOME,
        };
      });

    const items = [...expectedIncomes, ...earnings];
    return items;
  } else if (category === 'this_month' && earning.this_month?.length > 0) {
    return earning.this_month.map((item) => {
      return {
        date: item.day,
        earning: item.revenue,
        type: IncomeType.REVENUE,
      };
    });
  } else if (category === 'last_month' && earning.last_month?.length > 0) {
    return earning.last_month.map((item) => {
      return {
        date: item.day,
        earning: item.revenue,
        type: IncomeType.REVENUE,
      };
    });
  }

  return [];
};
