import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { CAR_DETAILS_SLICE } from './constants';
import { State } from './types';
import { updateVehicleAsync, getVehicleDetailsAsync } from 'store/provider/vehicles/actions';

const initialState: State = {
  carDetails: {
    baggage: 0,
    vehicle_details: '',
    doors: 0,
    fuel_type: '',
    mileage: 0,
    passengers: 0,
  },
};

export const carDetailSlice = createSlice({
  name: CAR_DETAILS_SLICE,
  initialState,
  reducers: {
    resetCarDetails: () => initialState,
  },
  extraReducers: (builder) =>
    builder.addMatcher(
      isAnyOf(updateVehicleAsync.fulfilled, getVehicleDetailsAsync.fulfilled),
      (state, action) => {
        state.carDetails = {
          ...state.carDetails,
          ...action.payload,
        };
      },
    ),
});

export default carDetailSlice;
