import React, { FC } from "react";
import { useNavigate } from "react-router-dom";

import NXCarousel from "components/NXCarousel";
import ProviderCard from "components/ProviderCard";
import { ROUTES } from "shared/routes/constants";

import Section from "../Section";
import { Props } from "../types";
import styles from "./popularRides.module.scss";

const PopularRides: FC<Props> = (props) => {
  const { providers } = props;
  const navigate = useNavigate();

  return (
    <Section title="Featured Providers">
      <NXCarousel totalCount={providers.length}>
        {providers.map((provider) => (
          <div key={provider.id} className={styles.carouselItem}>
            <ProviderCard
              primaryTitle={`${provider.first_name} ${provider.last_name}`}
              imgSrc={provider.profile_picture}
              rating={provider.rating}
              tripCount={provider.trips}
              onClick={() => provider?.id && navigate(ROUTES.PROVIDER_INFO_ID(provider.id.toString()))}
            />
          </div>
        ))}
      </NXCarousel>
    </Section>
  );
};

export default PopularRides;
