import React, { FC } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Typography, Form, Divider, Row, Col } from "antd";

import Input from "components/Input";
import Checkbox from "components/CheckBox";
import Button from "components/Button";
import { loginAsync } from "store/shared/auth/thunks";
import { LoginPostData } from "api/auth/types";
import { ROUTES } from "shared/routes/constants";
import { useAppSelector, useUnwrapAsyncThunk } from "store/hooks";
import {
  selectIsAuthenticated,
  selectLoginData,
  selectLoginStatus,
} from "store/shared/auth/selectors";

import styles from "./login.module.scss";
import { USER_NAME } from "./constants";
import {
  isAdmin,
  isCustomer,
  isProvider,
} from "uicontainers/_shared/helpers/auth";
import PrivacyAndTermsFooter from "uicontainers/_shared/PrivacyAndTermsFooter";
import { Spin } from "components/misc/helpers";

const { Title } = Typography;

const Login: FC = () => {
  const navigate = useNavigate();
  const userName = localStorage.getItem(USER_NAME);
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const isLoading = useAppSelector(selectLoginStatus) === 'started';
  const unwrap = useUnwrapAsyncThunk();
  const [isRemembered, setIsRemembered] = React.useState(
    userName ? true : false
  );
  const loginData = useAppSelector(selectLoginData);
  const location = useLocation();
  // @ts-ignore
  const from = location.state?.from?.pathname;

  React.useEffect(() => {
    if (isAuthenticated && loginData) {
      if (from) {
        navigate(from, { replace: true });
      } else if (isProvider(loginData.user_role)) {
        navigate(ROUTES.PATHS.PROVIDER.PROVIDER_DASHBOARD);
      } else if (isCustomer(loginData.user_role)) {
        navigate(ROUTES.PATHS.CUSTOMER.CUSTOMER_BOOKINGS);
      } else if (isAdmin(loginData.user_role)) {
        navigate(ROUTES.PATHS.ADMIN.ADMIN_DASHBOARD);
      }
    }
  }, [isAuthenticated, loginData]);

  const onFinish = (values: LoginPostData) => {
    unwrap(loginAsync(values))
      .then(() => {
        isRemembered
          ? localStorage.setItem(USER_NAME, values.email)
          : localStorage.setItem(USER_NAME, "");
      })
      .catch((e) => { });
  };

  return (
    <Spin spinning={isLoading}>
      <Row align="middle">
        <Col xs={20} sm={20} md={20} lg={7} className={styles.container}>
          <Title level={3} className={styles.title}>
            Welcome to Jaunt
          </Title>
          <Form onFinish={onFinish} initialValues={{ email: userName }}>
            <Row gutter={[16, 8]}>
              <Col className="gutter-row" span={24}>
                <Form.Item
                  name="email"
                  rules={[
                    { required: true, message: "Please input your email!" },
                  ]}
                >
                  <Input label="Email" type="email" size="large" />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={24}>
                <Form.Item
                  name="password"
                  rules={[
                    { required: true, message: "Please input your password" },
                  ]}
                >
                  <Input label="Password" inputType="password" size="large" />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={24}>
                <div className={styles.checkboxContent}>
                  <Checkbox
                    onChange={() => setIsRemembered(!isRemembered)}
                    checked={isRemembered}
                  >
                    Remember me
                  </Checkbox>
                  <Link to={"/forgot-password"} className={styles.markedTxt}>
                    Forgot your password?
                  </Link>
                </div>
              </Col>
              <Col className="gutter-row" span={24}>
                <Button
                  type="ghost"
                  isBordered={true}
                  style={{ width: "100%" }}
                  htmlType="submit"
                >
                  Log in
                </Button>
              </Col>
              <Col className="gutter-row" span={24}>
                <div className={styles.signupBtnContainer}>
                  <span>Don't have an account?</span>
                  <Button
                    isBordered={true}
                    onClick={() => navigate(ROUTES.PATHS.SIGN_UP)}
                  >
                    Sign Up
                  </Button>
                </div>
              </Col>
              <Col className="gutter-row" span={24}>
                <Divider plain />
              </Col>
              <Col className="gutter-row" span={24}>
                <PrivacyAndTermsFooter />
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Spin>
  );
};

export default Login;
