import { Empty, Spin } from 'antd';
import React from 'react';

import PieChart from 'components/Piechart';
import { getVehicleStatusAsync } from 'store/admin/dashboard/actions';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import {
  selectVehicleStatus,
  selectVehicleStatusRequestStatus,
} from './selectors';
import NXCard from 'components/NXCard';

const VehicleStatus = () => {
  const vehicleStatus = useAppSelector(selectVehicleStatus);
  const isVehicleStatusLoading =
    useAppSelector(selectVehicleStatusRequestStatus) === 'started';

  const vehicleStatusData = vehicleStatus
    ? [
        {
          status: 'Active Vehicles',
          count: vehicleStatus.active,
        },
        {
          status: 'Aproval Requested',
          count: vehicleStatus.standby,
        },
        {
          status: 'Inactive Vehicles',
          count: vehicleStatus.inactive,
        },
      ]
    : [];

  const dispatch = useAppDispatch();
  React.useEffect(() => {
    dispatch(getVehicleStatusAsync());
  }, []);

  return (
    <Spin spinning={isVehicleStatusLoading}>
      <NXCard headerTitle="Vehicle Status">
        {vehicleStatusData?.length > 0 ? (
          <PieChart
            data={vehicleStatusData}
            angleField="count"
            colorField="status"
          />
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </NXCard>
    </Spin>
  );
};

export default VehicleStatus;
