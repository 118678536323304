import { ROLE } from 'api/auth/types';
import { NavigationMenuItem } from 'components/SideNavigation/types';
import { ROUTES } from 'shared/routes/constants';

export const sidebarMenu: NavigationMenuItem[] = [
  {
    title: 'Personal Info',
    link: ROUTES.PATHS.USER.USER_ACCOUNT_PERSONAL_INFO,
    routeName: 'personal-info',
    roles: [ROLE.ADMIN, ROLE.CUSTOMER, ROLE.PROVIDER],
  },
  {
    title: 'Change Password',
    link: ROUTES.PATHS.USER.USER_ACCOUNT_CHANGE_PASSWORD,
    routeName: 'change-password',
    roles: [ROLE.ADMIN, ROLE.CUSTOMER, ROLE.PROVIDER],
  },
  {
    title: '100 Point Identification',
    link: ROUTES.PATHS.USER.USER_ACCOUNT_POINT_IDENTIFICATION,
    routeName: 'point-identification',
    roles: [ROLE.CUSTOMER, ROLE.PROVIDER],
  },
  {
    title: 'Business Info',
    link: ROUTES.PATHS.USER.USER_ACCOUNT_POINT_BUSINESS_INFO,
    routeName: 'business-info',
    roles: [ROLE.CUSTOMER, ROLE.PROVIDER],
  },
  {
    title: 'Payment',
    link: ROUTES.PATHS.USER.USER_ACCOUNT_POINT_DOWNLOAD,
    routeName: 'payment',
    roles: [ROLE.CUSTOMER, ROLE.PROVIDER],
  },
];

export const PRIMARY_DOCUMENT = [
  {
    name: 'Australian Passport',
    value: 'passport',
  },
  {
    name: 'National ID',
    value: 'id_card',
  },
];

export const SECONDARY_DOCUMENT_MORTGAGE = [
  {
    name: 'Mortgage Certificate',
    value: 'mortgage_certificate',
  },
];

export const SECONDARY_DOCUMENT_Bank = [
  {
    name: 'Bank Statement',
    value: 'bank_statement',
  },
];
