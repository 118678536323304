import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { useUnwrapAsyncThunk, useAppSelector } from 'store/hooks';
import { updateVehicleAsync } from 'store/provider/vehicles/actions';
import {
  selectCarDetail,
  selectPatchRequestStatus,
  selectGetVehicleDetailsStatus,
} from './selectors';
import { CarDetail } from './types';
import { getVehicleSettingsSidebarMenu } from '../constants';
import { selectVehicleId } from 'store/provider/vehicles/selectors';
import { message } from 'antd';

export const useCarDetailsSelectors = () => {
  return useAppSelector((state) => {
    return {
      carDetails: selectCarDetail(state).carDetails,
      id: selectVehicleId(state),
      patchStatus: selectPatchRequestStatus(state),
      getVehicleStatus: selectGetVehicleDetailsStatus(state),
    };
  });
};

export const useUpdateCarDetails = (id?: number) => {
  const unwrap = useUnwrapAsyncThunk();
  const navigate = useNavigate();

  return useCallback(
    async (carDetails: CarDetail) => {
      if (id) {
        try {
          await unwrap(updateVehicleAsync({ ...carDetails, vehicleId: id }));
          message.success('Vehicle information saved successfully', 0.5);
          navigate(getVehicleSettingsSidebarMenu(id.toString())[2].link);
        } catch (e) {
          message.error(e.message);
        }
      }
    },
    [id]
  );
};
