import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getCustomersAsync } from './thunks';
import { ProviderCustomersState } from './types';

const initialState: ProviderCustomersState = {
  customers: [],
  customersRequestStatus: 'initial',
  totalCount: 0,
  currentPage: 1,
};

export const providerCustomerSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    setCurrentPage(state, action: PayloadAction<number>) {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCustomersAsync.pending, (state) => {
      state.customersRequestStatus = 'started';
    });
    builder.addCase(getCustomersAsync.fulfilled, (state, action) => {
      state.customers = action.payload.results;
      state.customersRequestStatus = 'done';
      state.totalCount = action.payload.count;
    });
    builder.addCase(getCustomersAsync.rejected, (state) => {
      state.customersRequestStatus = 'failed';
    });
  },
});

export default providerCustomerSlice;
