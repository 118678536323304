import { BillingStatus, TripStatus } from 'api/shared/types';
import { HealthTagProps } from 'components/tables/cells/HealthCell/types';

export const FUEL_TYPE = [
  { name: 'Petrol', value: 'PETROL' },
  { name: 'Diesel', value: 'DIESEL' },
  { name: 'Electric', value: 'ELECTRIC' },
  { name: 'Gas', value: 'GAS' },
  { name: 'Octane', value: 'OCTANE' },
  { name: 'Hybrid', value: 'HYBRID' },
];

export enum IncomeType {
  REVENUE = 'Earnings',
  EXPECTED_INCOME = 'Upcoming Earnings',
}

export const TRIP_STATUS_TAG_PROPS: Record<TripStatus, HealthTagProps> = {
  BOOKED: {
    name: 'Booked',
    color: 'cyan',
  },
  PICKUP_REQUESTED: {
    name: 'Pickup requested',
    color: 'lime',
  },
  PICKED_UP: {
    name: 'Picked up',
    color: 'green',
  },
  DROP_OFF_REQUESTED: {
    name: 'Drop off requested',
    color: 'volcano',
  },
  DROPPED_OFF: {
    name: 'Dropped off',
    color: 'magenta',
  },
  ASK_FOR_COMPENSATION: {
    name: 'Ask for compensation',
    color: 'primaryThemeColor',
  },
  PENDING: {
    name: 'Pending',
    color: 'yellow',
  },
  CANCELED: {
    name: 'Cancelled',
    color: '#f00',
  },
};

export const BILLING_STATUS_TAG_PROPS: Record<BillingStatus, HealthTagProps> = {
  PAID: {
    name: 'Paid',
    color: 'green',
  },
  UNPAID: {
    name: 'Unpaid',
    color: 'blue',
  },
  FAILED: {
    name: 'Failed',
    color: 'red',
  },
  PENDING: {
    name: 'Pending',
    color: 'yellow',
  },
};

export const TripStatusOptions = [
  { name: 'Booked', value: 'BOOKED' },
  { name: 'Pickup requested', value: 'PICKUP_REQUESTED' },
  { name: 'Picked up', value: 'PICKED_UP' },
  { name: 'Drop off requested', value: 'DROP_OFF_REQUESTED' },
  { name: 'Dropped off', value: 'DROPPED_OFF' },
  { name: 'Ask for compensation', value: 'ASK_FOR_COMPENSATION' },
  { name: 'Pending', value: 'PENDING' },
  { name: 'Canceled', value: 'CANCELED' },
];

export const BillingStatusOptions = [
  { name: 'Paid', value: 'PAID' },
  // { name: 'Unpaid', value: 'UNPAID' },
  // { name: 'Failed', value: 'FAILED' },
  { name: 'Pending', value: 'PENDING' },
];
