import { Trip } from 'api/provider/tripHistory/types';
import { dateGenerator } from 'shared/helpers/helperFunctions';
import { changeVehicleStatusAsync } from 'store/provider/tripHistory/thunks';
import { TRIP_STATUS_TAG_PROPS } from 'uicontainers/_shared/constants';

import { TripHistoryTableData } from './types';

const generatePayloadStatus = (status: string) => {
  if (status === 'PICKUP_REQUESTED') {
    return 'PICKED_UP';
  }
  if (status === 'DROP_OFF_REQUESTED') {
    return 'DROPPED_OFF';
  }
};

export const transformTripsToTableData = (
  trips: Trip[]
): TripHistoryTableData[] => {
  return trips.map((trip) => ({
    key: trip.id.toString(),
    trip_id: {
      text: trip.id,
    },
    customer: {
      text: trip.customer,
    },
    trip_duration: {
      text: `${dateGenerator(trip?.start_time)}---${dateGenerator(
        trip?.end_time
      )}`,
    },
    vehicle: {
      text: trip.vehicle,
    },
    booked: {
      text: dateGenerator(trip?.created_at),
    },
    trip_status: {
      tags: [
        {
          name: TRIP_STATUS_TAG_PROPS[trip.request_status].name,
          color: TRIP_STATUS_TAG_PROPS[trip.request_status].color,
        },
      ],
    },
    action_btn: {
      useHook: () => {
        return changeVehicleStatusAsync({
          id: parseInt(trip.id),
          status: generatePayloadStatus(trip.request_status),
        });
      },
      buttons:
        trip.request_status === 'PICKUP_REQUESTED' ||
        trip.request_status === 'DROP_OFF_REQUESTED'
          ? [
              {
                label: 'Confirm',
                onClick: () => {},
              },
            ]
          : [],
    },
  }));
};
