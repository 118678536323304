import { createSlice } from '@reduxjs/toolkit';
import { CUSTOMER_ACCOUNT } from './constants';
import { getProfileAsync } from './thunks';
import { AccountStateType } from 'api/customer/account/types';
import {
  isPendingActionMatcher,
  isFulfilledActionMatcher,
} from 'store/matchers';

const initialState: AccountStateType = {
  status: 'initial',
  account: {
    address: null,
    date_of_birth: '',
    email: '',
    first_name: '',
    id: '',
    last_name: '',
    phone_no: null,
    profile_picture: null,
    abn: null,
    business_address: null,
    business_email: null,
    business_license: null,
    business_name: null,
    business_phone: null,
    user_documents: null,
    '100_points': null,
    approve_to_drive: false,
    date_joined: '',
    rating: 0,
    trips: 0,
    is_active: false,
    bank_account: null,
    provider_mode: null,
  },
};

export const accountSlice = createSlice({
  name: CUSTOMER_ACCOUNT,
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(getProfileAsync.fulfilled, (state, action) => {
        state.account = action.payload.results[0];
      })

      .addCase(getProfileAsync.rejected, (state) => {
        state.status = 'failed';
      })

      .addMatcher(isPendingActionMatcher, (state) => {
        state.status = 'started';
      })

      .addMatcher(isFulfilledActionMatcher, (state) => {
        state.status = 'done';
      }),
});

export default accountSlice;
