import { RootState } from 'store/types';

export const selectCarPhotos = (state: RootState) => state.ui.provider.vehicle.photos.vehicleImages;

export const selectStatus = (state: RootState) => state.ui.provider.vehicle.photos.status;

export const selectCarFeaturePhotos = (state: RootState) =>
  state.ui.provider.vehicle.photos.featuredImage;

export const selectGetVehicleDetailsStatus = (state: RootState) =>
  state.resources.provider.vehicles.getVehicleDetailsRequestStatus;
