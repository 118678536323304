import { CustomerStatus } from 'api/admin/customers/types';
import { HealthTagProps } from 'components/tables/cells/HealthCell/types';
import { Column } from 'components/tables/IXTable/types';

export const COLUMNS: Column[] = [
  {
    name: 'Customer',
    key: 'customer',
    type: 'text',
  },
  {
    name: 'Email',
    key: 'email',
    type: 'image-and-text-cell',
    width: 300,
  },
  {
    name: 'Phone',
    key: 'phone',
    type: 'text',
  },
  {
    name: '100 Points Verification',
    key: 'verification_point',
    type: 'icon-link-and-text-cell',
  },
  {
    name: 'Status',
    key: 'status',
    type: 'health',
  },
  {
    name: 'Modified',
    key: 'modified',
    type: 'text',
  },
];

export const CUSTOMER_STATUS_TAG_PROPS: Record<CustomerStatus, HealthTagProps> = {
  active: {
    name: 'Active',
    color: 'green',
  },
  inactive: {
    name: 'Inactive',
    color: 'red',
  },
};
