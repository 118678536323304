import React, { FC } from 'react';
import moment from 'moment';

import { StarFilled, InfoCircleOutlined, CheckOutlined } from '@ant-design/icons';
import { Card, Row, Col, Typography } from 'antd';
import Image from 'components/Image'; 
import styles from './profileInfo.module.scss';
import { Props } from './types';
import NXCard from 'components/NXCard';

const { Title, Text } = Typography;

const VerifyInfo = ({ title, status }: { title: string; status: React.ReactNode }) => (
  <Row>
    <Col span={12}>
      <div>
        <Text className={styles.title}>{title}</Text>
        <InfoCircleOutlined className={styles.infoIcon} />
      </div>
    </Col>
    <Col span={12}>{status}</Col>
  </Row>
);

const ProfileInfo: FC<Props> = (props) => {
  const { profileInfo, status } = props;

  return (
    <NXCard isLoading={status === 'started'} hasPadding={true}>
      <Row>
        <Col xs={24} lg={12}>
          <Row align="middle" justify="start">
            <Col md={6}>
              <div className={styles.imageContainer}>
                <Image
                  src={
                    profileInfo.profile_picture
                      ? profileInfo.profile_picture
                      : 'https://www.gravatar.com/avatar/?d=mp'
                  }
                  alt="Profile"
                  className={styles.imageStyle}
                />
              </div>
            </Col>
            <Col md={18}>
              <div className={styles.infoContainer}>
                <Title level={3}>
                  {profileInfo.first_name} {profileInfo.last_name}
                </Title>

                <Text className={styles.location}>
                  {profileInfo.address ? profileInfo.address : 'Location unknown'}
                </Text>

                <Text className={styles.rating}>
                  <StarFilled className={styles.starIcon} />{' '}
                  <Text strong>{profileInfo.rating}</Text>
                  <span className={styles.trips}>({profileInfo.trips} trips)</span>
                </Text>

                <Text className={styles.footer}>
                  Joined {moment(profileInfo.date_joined).format('MMM YYYY')}
                </Text>
              </div>
            </Col>
          </Row>
        </Col>
        <Col xs={24} lg={12}>
          <Row style={{ paddingBottom: 5 }}>
            <Col span={24}>
              <Text className={styles.verifiedInfo}>Verified info</Text>
            </Col>
          </Row>
          <VerifyInfo
            title="Approved to drive"
            status={
              profileInfo?.user_documents?.is_driving_license_verified ? (
                <Text className={styles.verified}>
                  <CheckOutlined />
                  <span className={styles.text}>Approved</span>
                </Text>
              ) : (
                <Text className={styles.notApproved}>Not approved</Text>
              )
            }
          />
          <VerifyInfo
            title="100 Points Identification"
            status={
              profileInfo['100_points'] && profileInfo['100_points'] >= 100 ? (
                <Text className={styles.verified}>
                  <CheckOutlined />
                  <span className={styles.text}>Verified</span>
                </Text>
              ) : (
                <Text className={styles.status}>Verify 100 Points</Text>
              )
            }
          />
          <VerifyInfo
            title=" Email"
            status={
              profileInfo.is_active ? (
                <Text className={styles.verified}>
                  <CheckOutlined />
                  <span className={styles.text}>Verified</span>
                </Text>
              ) : (
                <Text className={styles.status}>Verify Email</Text>
              )
            }
          />
        </Col>
      </Row>
    </NXCard>
  );
};

export default ProfileInfo;
