import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { CAR_DOCUMENT_SLICE } from './constants';
import { State } from './types';
import {
  upload,
  updateVehicleAsync,
  getVehicleDetailsAsync,
} from 'store/provider/vehicles/thunks';

const initialState: State = {
  carDocuments: {
    green_slip: null,
    rego_paper: null,
    pink_slip: null,
    expiration_date: '',
    status: 'INACTIVE',
  },
};

export const carDocumentSlice = createSlice({
  name: CAR_DOCUMENT_SLICE,
  initialState,
  reducers: {
    resetCarDocuments: () => initialState,
  },
  extraReducers: (builder) =>
    builder
      .addCase(upload.fulfilled, (state, action) => {
        state.carDocuments.green_slip = action.payload.green_slip || null;
        state.carDocuments.pink_slip = action.payload.pink_slip || null;
        state.carDocuments.rego_paper = action.payload.rego_paper || null;
        state.carDocuments.expiration_date =
          action.payload.expiration_date || '';
      })
      .addMatcher(
        isAnyOf(updateVehicleAsync.fulfilled, getVehicleDetailsAsync.fulfilled),
        (state, action) => {
          const {
            green_slip,
            pink_slip,
            rego_paper,
            expiration_date,
            ...others
          } = action.payload;

          state.carDocuments = {
            ...state.carDocuments,
            ...others,
            expiration_date: expiration_date ? expiration_date : '',
            pink_slip: pink_slip ? pink_slip : null,
            rego_paper: rego_paper ? rego_paper : null,
            green_slip: green_slip ? green_slip : null,
          };
        }
      ),
});

export default carDocumentSlice;
