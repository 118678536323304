import React, { useEffect, FC } from "react";
import { Typography, Row, Col, Divider } from "antd";

import moment from "moment";
import TripRating from "uicontainers/_shared/TripRating";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getProviderInfoAsync } from "store/tripBooking/providerDetails/thunks";
import { selectProviderDetails } from "store/tripBooking/providerDetails/selectors";
import Image from 'components/Image'; 
import styles from "./hostInfo.module.scss";
import { Props } from "./types";

const { Title, Text } = Typography;

const HostInfo: FC<Props> = (props) => {
  const { providerId, onClick } = props;
  const providerInfo = useAppSelector(selectProviderDetails);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (providerId) {
      dispatch(getProviderInfoAsync(providerId));
    }
  }, [providerId]);
  return (
    <>
      <Title level={4}>Hosted By</Title>
      <Row>
        <Col className={styles.hostedImage}>
          <div className={styles.imageContainer}>
            <Image
              src={providerInfo && providerInfo.profile_picture}
              alt="Profile"
              className={styles.imageStyle}
            />
          </div>
        </Col>
        <Col>
          <div className={styles.infoContainer}>
            <Title
              level={5}
              onClick={onClick}
              style={{ cursor: "pointer" }}
            >{`${providerInfo?.first_name ?? ""} ${
              providerInfo?.last_name ?? ""
            }`}</Title>

            <TripRating
              className={styles.rating}
              rating={providerInfo?.rating}
              trips={providerInfo?.trips}
            />

            <Text className={styles.footer}>
              Joined {moment(providerInfo?.joined_date).format("MMM YYYY")}
            </Text>
          </div>
        </Col>
      </Row>
      <Divider style={{ margin: "10px 0" }} />
    </>
  );
};

export default HostInfo;
