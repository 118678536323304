import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useActions, useAppSelector } from 'store/hooks';
import { selectCarDocument, selectGetVehicleDetailsStatus } from './selectors';
import { getVehicleSettingsSidebarMenu } from '../constants';
import { selectVehicleId } from 'store/provider/vehicles/selectors';
import { upload, updateVehicleAsync } from 'store/provider/vehicles/actions';
import { useCarPhotosActions, useCarPhotosSelectors } from '../Photos/hooks';
import { CarDocuments } from './types';

export const useCarDocumentActions = () => {
  return useActions({
    upload,
    updateVehicleAsync,
  });
};

export const useCarDocumentSelectors = () => {
  return useAppSelector((state) => {
    return {
      carDocument: selectCarDocument(state),
      id: selectVehicleId(state),
      getVehicleDetailsRequestStatus: selectGetVehicleDetailsStatus(state),
    };
  });
};

export const useCarDocumentsUpdate = (id?: number) => {
  const { upload } = useCarPhotosActions();

  return useCallback(
    async (formData: FormData) => {
      if (id) {
        await upload({ data: formData, vehicleId: id });
      }
    },
    [upload],
  );
};

export const useUpdateVehicle = () => {
  const { updateVehicleAsync } = useCarDocumentActions();
  const { id } = useCarDocumentSelectors();

  return useCallback(
    async (data: Partial<CarDocuments>) => {
      if (id) {
        await updateVehicleAsync({ ...data, vehicleId: id });
      }
    },
    [updateVehicleAsync],
  );
};

export const useNextRedirect = () => {
  const navigate = useNavigate();
  const { id } = useCarPhotosSelectors();

  return useCallback(() => {
    if (id) {
      navigate(getVehicleSettingsSidebarMenu(id.toString())[5].link);
    }
  }, [id]);
};
