import React from 'react';
import { Paragraph, Title } from 'components/misc/helpers';
import { Badge } from 'antd';
import styles from './faqDetails.module.scss';

function BookingCar() {
  return (
    <div>
      <Title level={3}>A. How a trip works</Title>
      <Paragraph>How a trip works from start to finish</Paragraph>
      <ol className={styles.alpha}>
        <li>How to book a car, van or Ute</li>
        <Paragraph>
          To book, just visit the{' '}
          <a
            href="https://www.carnextdoor.com.au/search"
            target="_blank"
            rel="noreferrer"
          >
            search page
          </a>{' '}
          or{' '}
          <a
            href="https://support.carnextdoor.com.au/hc/en-gb/articles/207324513-Car-Next-Door-App-what-it-does-and-where-to-find-it"
            target="_blank"
            rel="noreferrer"
          >
            download the app
          </a>
          , search for available cars that match your needs, and book instantly.
          You don't need to wait for the owner to confirm.
        </Paragraph>
        <Paragraph>
          Your trip can be as short as an hour, and increase in increments of 15
          mins for up to 60 days.
        </Paragraph>
        <li>Trip instructions:</li>
        <Paragraph>
          Log in to the app 15 minutes before your trip starts. We’ll walk you
          step-by-step through your whole trip.
        </Paragraph>
        <li>Before you drive …</li>
        <Paragraph>
          Remember, you are a guest in a real person’s car. Please take care of
          it as you would for a friend.
        </Paragraph>

        <div>
          <div>
            <Badge
              color="black"
              text="Only you or other verified Jaunt members may drive the car. Other drivers will be
            covered by the damage and loss cover."
            />
          </div>
          <div>
            {' '}
            <Badge
              color="black"
              text="Don’t smoke in the car. If you or a passenger smokes, close all the doors and windows
              and go at least 10 metres away before lighting up"
            />
          </div>
          <div>
            {' '}
            <Badge
              color="black"
              text="Return the car clean and tidy, ready for the next driver."
            />
          </div>
          <div>
            <Badge
              color="black"
              text="If anything goes wrong, let the owner know - don’t wait for them to discover damage,
              spills or fines themselves."
            />
          </div>
        </div>
      </ol>
      <Title level={5}>Get the keys and find the car</Title>
      <Title level={5}>If you're borrowing an Instant Keys car:</Title>
      <Paragraph>
        To get the approximate location before your trip starts, select the trip
        from the Trips page in the <span>app</span>. The car's ideal parking
        address is shown so you can head to the right area.
      </Paragraph>
      <Paragraph>
        15 minutes before your trip starts, select 'Pickup instructions' on the
        trip page. You'll see step-by-step instructions on picking up the car,
        including the current location.
      </Paragraph>
      <Paragraph>
        The keys for most Instant Keys cars are secured in a lockbox. It may be
        on the car’s window, or attached to a fence or building nearby. You'll
        see instructions tailored to the car you have booked.
      </Paragraph>
      <Paragraph>
        If the lockbox is on the car's window, take it off and store it safely
        in the car while you drive.
      </Paragraph>
      <Title level={5}>If you're borrowing a Key Handover car:</Title>
      <Paragraph>
        You’ll need to meet the owner to pick up the keys. The app will give you
        instructions to get in touch with the owner and arrange to meet. The
        owner will be able to let you know where the car is currently parked.
      </Paragraph>

      <Title level={5}>Check the car and take photos</Title>
      <Paragraph>Before you drive, do a quick check of the car.</Paragraph>
      <Paragraph>
        If the car is not in good condition, is dirty or has less than ¼ tank of
        fuel, take a photo of it and{' '}
        <a
          href="https://www.carnextdoor.com.au/help"
          target="_blank"
          rel="noreferrer"
        >
          let us know
        </a>
        .
      </Paragraph>
      <Paragraph>
        Take photos of all surfaces of the car and the car's interior and upload
        them through the app. You will take photos again when you return the
        car. If you can't upload the photos as you take them, make sure you
        upload them within 48 hours of your trip ending.
      </Paragraph>
      <Paragraph>
        Your photos protect you. If damage is found later, and your before and
        after photos show that it was not caused during your trip, you won’t be
        held responsible.
      </Paragraph>

      <Title level={5}>Read the owner’s instructions</Title>
      <Paragraph>
        Each Jaunt car is unique. The car owner’s instructions will tell you any
        tips and tricks for using their car.
      </Paragraph>
      <Paragraph>
        You’ll get the owner’s contact details in case you need to ask them a
        question.
      </Paragraph>
      <Title level={5}>Enter the odometer reading (Key Handover only)</Title>
      <Paragraph>
        <b>If you're borrowing a Key Handover car:</b> enter the current
        odometer reading and upload a photo of the dash showing the odometer
        before you drive.
      </Paragraph>

      <Title level={5}>Refuel</Title>
      <Paragraph>
        You must return the car with the fuel tank at least ¼ full, so the next
        driver can get on their way. The car’s instructions tell you what type
        of fuel to use.
      </Paragraph>
      <Paragraph>
        You don’t pay for fuel yourself - it is included in the distance charge.
      </Paragraph>
      <Paragraph>
        If you need to fill up, pay for fuel using your own money and upload a
        photo of the receipt for reimbursement
      </Paragraph>

      <Title level={5}>Need more time?</Title>
      <Paragraph>
        It’s simple to extend your trip through the app, as long as no-one else
        has booked the car. Just follow the prompts to set a new end time.
      </Paragraph>
      <Paragraph>
        Make sure you extend before the trip is due to end, to avoid late fees.
      </Paragraph>
      <Paragraph>
        If you return the car early, you’ll still pay for the time you booked,
        unless someone else books it. This is because the owner may have made
        plans around your booking, and missed the chance to rent the car to
        someone else.
      </Paragraph>

      <Title level={5}>Return and park the car</Title>
      <Paragraph>
        When you're ready to return the car, use the return instructions steps
        in the app and follow the directions on where to park. Some cars have a
        dedicated parking spot, like a garage or driveway. Others are parked on
        the street.
      </Paragraph>
      <Paragraph>
        In very busy areas, you may need to plan extra time to find a park at
        the end of your trip.
      </Paragraph>
      <Paragraph>
        If the car is parked on the street, check the instructions and parking
        signs very carefully. If you leave a car in a place where it gets a
        parking fine - no matter how long after your trip ends - you’ll need to
        pay the fine.
      </Paragraph>

      <Title level={5}>Check, lock and leave</Title>
      <Paragraph>
        <b>If you're borrowing a Key Handover car:</b> Enter the final odometer
        reading and upload another photo of the dash showing the odometer (Key
        Handover cars only).
      </Paragraph>
      <Paragraph>
        <b>For all cars:</b> Take another set of photos clearly showing all
        surfaces of the car and the car's interior, and upload them through the
        app or website.
      </Paragraph>
      <Paragraph>
        Check that you've taken all your belongings with you and left the car
        clean and with at least ¼ tank of fuel.
      </Paragraph>
      <Paragraph>
        Check that all windows are closed, lights are off and the car is locked.
      </Paragraph>
      <Paragraph>
        Use the app to generate a lockbox code, put the key back inside and
        close the lockbox securely. If the lockbox was on the car's window, make
        sure you put it back in place
      </Paragraph>
      <Paragraph>
        Hit the 'ENd trip' button in the return steps to complete your booking,
        and you're all done!
      </Paragraph>

      <Title level={5}>Not a member yet? Here's how to join</Title>
      <Paragraph>
        You can join Jaunt and become a verified member for free. Once you're a
        verified member you'll be able to borrow from our fleet of over 3000
        cars, vans and utes across Australia.
      </Paragraph>
      <Paragraph>
        It only takes a few minutes to apply and you can be on the road today.
      </Paragraph>

      <Title level={3} id="booking">
        B. Booking a Trip:
      </Title>
      <Paragraph>Restricted booking exchange hours:</Paragraph>
      <Paragraph>
        We found that not many trips were starting or ending between these
        times, and owners may be disturbed by borrowers wanting to get in touch
        when picking up and returning their car. To save our owners' sleep and
        make sure borrowers are always able to get help when they need it, we've
        restricted our exchange hours (the times a trip can start or end) to
        between 6am and 11pm.
      </Paragraph>
      <ol className={styles.alpha}>
        <li>
          If you need to pick up or return a car outside of exchange hours
        </li>

        <Paragraph>
          You can still pick up and return a car outside of exchange hours, as
          long as your trip is scheduled to start and end within the exchange
          hours.
        </Paragraph>
        <Paragraph>For example (returning a car after 11pm):</Paragraph>
        <ul>
          <li>
            You need to borrow a car for most of the day on Tuesday and won't be
            returning it until about 11.30pm
          </li>
          <li>
            You book a trip to start at 8am on Tuesday and end at 6am on
            Wednesday
          </li>
          <li>
            You can still return the car at 11.30pm Tuesday - you're just
            returning the car earlier than your original trip was scheduled to
            end
          </li>
          <li>
            You won't pay any extra because you've already{' '}
            <a
              href="https://support.carnextdoor.com.au/hc/en-gb/articles/207507293-How-long-is-a-day-for-calculating-the-daily-rate"
              target="_blank"
              rel="noreferrer"
            >
              reached the daily rate{' '}
            </a>{' '}
            - the most you'll pay for the car for any 24 hour period
          </li>
        </ul>
        <Paragraph>
          If you need a car earlier than 6am, book the trip to start before 11pm
          the night before. You can pick the car up and park it at your house
          overnight, or go to the car early in the morning to pick it up.
        </Paragraph>
        <Paragraph>
          If your trip ended before 11pm and you're running late
        </Paragraph>
        <li>
          If you're late returning a car or need it for a bit longer,{' '}
          <a
            href="https://support.carnextdoor.com.au/hc/en-gb/articles/204823999"
            target="_blank"
            rel="noreferrer"
          >
            extend your trip
          </a>{' '}
          until 6am the following day. Now you can return the car anytime before
          6am, and if you've{' '}
          <a
            href="https://support.carnextdoor.com.au/hc/en-gb/articles/207507293-How-long-is-a-day-for-calculating-the-daily-rate"
            target="_blank"
            rel="noreferrer"
          >
            already reached the daily rate
          </a>{' '}
          for the car, you won't be charged any extra.
        </li>
        <Paragraph> Quick tip</Paragraph>
        <Paragraph>
          Remember: It's best to extend your trip before the end time of your
          original booking -{' '}
          <a
            href="https://support.carnextdoor.com.au/hc/en-gb/articles/360001149986-Inconvenience-and-admin-fees#punctuality"
            target="_blank"
            rel="noreferrer"
          >
            fees apply
          </a>{' '}
          for extending after your trip has already ended, so keep an eye on
          that clock!
        </Paragraph>
        <li>24/7 phone support</li>
        <Paragraph>
          Our support team is still available by phone to help with urgent
          issues 24 hours a day. If you need help with an in-progress trip
          anytime (including between 11pm and 6am){' '}
          <a
            href="https://www.carnextdoor.com.au/help"
            target="_blank"
            rel="noreferrer"
          >
            call us
          </a>{' '}
          and choose option 1 for urgent issues.
        </Paragraph>
      </ol>
    </div>
  );
}

export default BookingCar;
