import { Billing } from 'api/provider/billing/types';
import { dateGenerator } from 'shared/helpers/helperFunctions';
import { BILLING_STATUS_TAG_PROPS } from 'uicontainers/_shared/constants';

import { BillingTableData } from './types';

export const transformBillingsToTableData = (
  billings: Billing[]
): BillingTableData[] => {
  return billings.map((billing) => ({
    key: billing.invoice_id.toString(),
    customer: {
      text: `${billing.first_name}  ${billing.last_name}`,
    },
    invoice_duration: {
      text: `${dateGenerator(billing.invoice_start_time)} --- ${dateGenerator(
        billing.invoice_end_time
      )}`,
    },
    vehicle: {
      text: billing.vehicle_name,
    },
    booked: {
      text: dateGenerator(billing.booked_at),
    },
    trip_status: {
      tags: [
        {
          name: BILLING_STATUS_TAG_PROPS[billing.invoice_status].name,
          color: BILLING_STATUS_TAG_PROPS[billing.invoice_status].color,
        },
      ],
    },
    total_price: {
      text: `${billing.invoice_net_amount}`,
    },
    receipt: {
      rows: [
        {
          text: billing.payment_gateway_invoice ? `${billing.payment_gateway_invoice}` : '',
        },
      ],
    },
  }));
};
