import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EarningReportCategory, EarningState } from './types';

export const initialState: EarningState = {
  category: 'all_year',
};

const earningSlice = createSlice({
  name: 'provider_earning',
  initialState,
  reducers: {
    selectCategory(state, action: PayloadAction<EarningReportCategory>) {
      state.category = action.payload;
    },
  },
});

export default earningSlice;
