import { createAsyncThunk } from '@reduxjs/toolkit';
import { getTickets } from 'api/customer/tickets';
import { TicketRequestParams } from 'api/customer/tickets/types';

export const getTicketsAsync = createAsyncThunk(
  'customer/tickets',
  async (params?: TicketRequestParams) => {
    const response = await getTickets(params);
    return response;
  },
);
