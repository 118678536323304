import { createAsyncThunk } from '@reduxjs/toolkit';

import { updateAdminApproval, getProviders } from 'api/admin/providers';
import {
  ProviderRequestParams,
  PostAdminApprovalData,
} from 'api/admin/providers/types';

export const getProvidersAsync = createAsyncThunk(
  'admin/provider/get_providers',
  async (params?: ProviderRequestParams) => {
    const response = await getProviders(params);
    return response;
  }
);

export const updateAdminApprovalAsync = createAsyncThunk(
  'admin/provider/update_approval',
  async (
    { data, id }: { data: PostAdminApprovalData; id: number },
    { dispatch }
  ) => {
    const response = await updateAdminApproval(data, id);
    dispatch(getProvidersAsync());
    return response;
  }
);
