import { RecentBooking } from 'api/provider/dashboard/types';
import { dateGenerator } from 'shared/helpers/helperFunctions';

import { RecentBookingTableData } from './types';

export const getRecentBookingTableData = (
  recentBookings: RecentBooking[]
): RecentBookingTableData[] => {
  return recentBookings.map((booking) => ({
    key: booking.id.toString(),
    customer: {
      text: booking.customer,
    },
    trip_duration: {
      text: `${dateGenerator(booking?.start_time)}---${dateGenerator(
        booking?.end_time
      )}`,
    },
  }));
};
