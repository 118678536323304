import React from 'react';
import { Paragraph, Title } from 'components/misc/helpers';

function RentCar() {
  return (
    <div>
      <Title level={3}>A. How a trip works</Title>
      <Paragraph>Finding your car after a booking:</Paragraph>
      <Paragraph>
        Borrowers follow the parking instructions your set to return your car to
        the right place. If your car is parked on-street, then Borrowers will
        try to park it as close to its home location as possible at the end of
        their trip. This may not be exactly where they picked it up or where you
        last saw it.
      </Paragraph>
      <Paragraph>To find your car:</Paragraph>
      <ul>
        <li>Log in to the website or app</li>
        <li>Go to Cars and select your car</li>
        <li>Click 'Get location'</li>
        <li>You'll see your car's current location as a pin on a map</li>
      </ul>
      <Paragraph>
        Note that for privacy reasons you can't see where your car is while it's
        in a booking.
      </Paragraph>
      <Title level={3} id="safety">
        B. Trust and safety
      </Title>
      <Paragraph>Seeing your car's GPS location during a booking.</Paragraph>

      <Paragraph>
        Depending on your sharing plan, we may fit your car with GPS tracking
        devices as part of setting it up for sharing. This lets us calculate the
        per-kilometer charges that Borrowers pay. It also means that we can
        locate your car at any time, which gives you additional security and
        peace of mind.
      </Paragraph>

      <Paragraph>
        When your car is not in use by a Borrower, you will be able to pinpoint
        its location on a map by logging in to the website or app. This makes it
        easy for you to find where your car is parked after a Borrower drops it
        back.
      </Paragraph>
      <Paragraph>
        For privacy reasons, you will not be able to see your car's location
        when it is being driven by a Borrower.
      </Paragraph>
      <Title level={3}>C. Earnings and the income guarantee</Title>
      <Paragraph>The income guarantee</Paragraph>
      <Paragraph>
        To remain eligible for the income guarantee, car owners must ensure that
        their cars meet certain criteria throughout the Guarantee Period. If you
        meet all the following criteria for your first full year on Jaunt, we
        guarantee that you will earn $2,000 in your first 12 months or we'll pay
        you the difference.
      </Paragraph>
      <Paragraph>
        The $2,000 is your income after we take our commission. You'll still
        have costs for running your car, but many of these may be tax
        deductible.
      </Paragraph>
      <Paragraph>Guarantee Conditions</Paragraph>
      <Paragraph>
        The Guarantee Period starts on the first day of the calendar month after
        a car is first made available for bookings, and lasts for 12 months.
      </Paragraph>
      <Paragraph>
        To be eligible for the guarantee, a car must, for the duration of the
        Guarantee Period:
      </Paragraph>
      <Paragraph>
        Be 6 years old or less (based on the year of manufacture);
      </Paragraph>
      <Paragraph>Have fewer than 150,000 km on the odometer;</Paragraph>
      <Paragraph>
        Be located in one of our high-demand suburbs of Sydney, Melbourne or
        Brisbane;
      </Paragraph>
      <Paragraph>
        Be on our Instant Keys or Full-Time Fleet sharing plan (or the legacy
        $60/month or legacy Instant Keys Plus+ plan)
      </Paragraph>
      <Paragraph>
        Be available for members (not including you or your additional drivers)
        to book from midnight to midnight at least 5 weekend days and 13
        weekdays of each full calendar month during the Guarantee Period;
      </Paragraph>
      <Paragraph>Stay priced at the rate recommended by Jaunt;</Paragraph>
      <Paragraph>
        Be available for verified Borrowers above the age of 21 to book;
      </Paragraph>
      <Paragraph>
        Be made available (in the homezone, with the lockbox and key accessible)
        for every booking (except where this is not possible due to the actions
        of another borrower);
      </Paragraph>
      <Paragraph>
        Not receive negative (“thumbs down”) ratings from borrowers for more
        than five percent of bookings;
      </Paragraph>
      <Paragraph>
        Meet the car eligibility criteria as set out in clause 4 of the Owner
        Supplement and Have the full suite of Jaunt stickers on the car
      </Paragraph>
      <Paragraph>
        The Owner must also comply with their obligations under the Owner
        Supplement, act in good faith and not do anything that is intended to,
        or likely to have the effect of, reducing their car’s bookings (for
        example, but not limited to, asking borrowers to cancel a booking or
        intentionally making it difficult for borrowers to access or enjoy the
        use of the car).
      </Paragraph>
      <Paragraph>Applying for the guarantee</Paragraph>
      <Paragraph>
        There's no need to apply for the guarantee. As long as your car meets
        the conditions, it will automatically be offered to you when your car is
        listed on Jaunt. If you have any questions or would like to confirm
        whether you have been offered the guarantee, please get in touch.
      </Paragraph>
    </div>
  );
}

export default RentCar;
