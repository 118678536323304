import { RootState } from "store/types";

export const selectVehicleDetails = (state: RootState) =>
  state.resources.tripBooking.vehicledetails.vehicleDetails;

export const selectVehicleDetailsRequestStatus = (state: RootState) =>
state.resources.tripBooking.vehicledetails.getVehicleDetailsRequestStatus;

export const selectCarBookingDetails = (state: RootState) =>
  state.resources.tripBooking.vehicledetails.carBookingDetails;
