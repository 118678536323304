import { createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';

import { getTripHistory, changeBookingStatus } from 'api/provider/tripHistory';
import {
  TripHistoryRequestParams,
  StatusRequestParams,
} from 'api/provider/tripHistory/types';

export const getTripsAsync = createAsyncThunk(
  'provider/tripHistory/get_trips',
  async (params?: TripHistoryRequestParams) => {
    const response = await getTripHistory(params);
    return response;
  }
);

export const changeVehicleStatusAsync = createAsyncThunk(
  'provider/tripHistory/change_status',
  async (data: StatusRequestParams, { dispatch }) => {
    try {
      const response = await changeBookingStatus(data);
      dispatch(getTripsAsync()).then(() => {
        message.success('Successfully updated', 0.5);
      });
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);
