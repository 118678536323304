import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getAdminRentalInfoAsync } from 'store/admin/rentalAgreement/thunks';
import { selectAdminRentalInfoStatus } from 'store/admin/rentalAgreement/selectors';

import RentalAgreement from './RentalAgreement';

function Instructions() {
  const dispatch = useAppDispatch();
  const adminRentalInfo = useAppSelector(selectAdminRentalInfoStatus);

  useEffect(() => {
    dispatch(getAdminRentalInfoAsync());
  }, []);
  return (
    <RentalAgreement
      secondaryTitle={'Instructions'}
      paragraph1={'This information is only shown to customers after their trip is booked.'}
      richTextEditorLabel={'Pick up and return instructions'}
      isReadOnly={true}
      agreementDetail={adminRentalInfo?.instructions}
    />
  );
}

export default Instructions;
