import { createAsyncThunk } from '@reduxjs/toolkit';

import { getCustomers } from 'api/provider/customers';
import { CustomerRequestParams } from 'api/provider/customers/types';

export const getCustomersAsync = createAsyncThunk(
  'provider/customer/get_customers',
  async (params?: CustomerRequestParams) => {
    const response = await getCustomers(params);
    return response;
  },
);
