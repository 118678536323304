import { createAsyncThunk } from '@reduxjs/toolkit';
import { getTolls } from 'api/customer/tolls';
import { TollsRequestParams } from 'api/customer/tolls/types';

export const getTollsAsync = createAsyncThunk(
  'customer/tolls',
  async (params?: TollsRequestParams) => {
    const response = await getTolls(params);
    return response;
  },
);
