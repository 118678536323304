import { createAsyncThunk } from '@reduxjs/toolkit';

import { getRegoPaper, getVehicleDetails, getVehicles, updateVehicle } from 'api/admin/vehicles';
import { UpdateVehicleData, VehicleRequestParams } from 'api/admin/vehicles/types';

export const getVehiclesAsync = createAsyncThunk(
  'admin/vehicle/get_vehicles',
  async (params?: VehicleRequestParams) => {
    const response = await getVehicles(params);
    return response;
  },
);

export const getAdminVehicleDetailsAsync = createAsyncThunk(
  'admin/vehicles/get_vehicle_details',
  async (id: number) => {
    const response = await getVehicleDetails(id);
    return response;
  },
);

export const getRegoPaperAsync = createAsyncThunk(
  'admin/vehicle/get_rego_paper',
  async (id: number) => {
    const response = await getRegoPaper(id);
    return response;
  },
);

export const updateVehicleAsync = createAsyncThunk(
  'admin/vehicles/update_vehicle',
  async (data: UpdateVehicleData) => {
    const response = await updateVehicle(data, data.vehicleId);
    return response;
  },
);
