import { RootState } from 'store/types';

export const selectCarProtection = (state: RootState) =>
  state.ui.provider.vehicle.protection.carProtection;

export const selectGetVehicleDetailsStatus = (state: RootState) =>
  state.resources.provider.vehicles.getVehicleDetailsRequestStatus;

export const selectGetInsurancesStatus = (state: RootState) =>
  state.resources.provider.vehicles.getInsuranceRequestStatus;

export const selectInsuranceCompanyOptions = (state: RootState) =>
  state.ui.provider.vehicle.protection.insurance_company_options;
