import React from 'react';
import { Col, Typography } from 'antd';
import { InfoProps } from './types';
import Image from 'components/Image'; 
const { Text } = Typography;

const Info = (props: InfoProps) => {
  const { value, iconUrl } = props;

  const imgSpan = 3;
  const valueSpan = 24 - imgSpan;

  return (
    <>
      <Col span={imgSpan}>
        <Image src={iconUrl} alt="icon" />
      </Col>
      <Col span={valueSpan}>
        <Text>{value}</Text>
      </Col>
    </>
  );
};

export default Info;
