import { publicAPI } from "api/base";

import {
  GetSearchedVehicles,
  GetVehicleTypes,
  GetPopularProviders,
} from "./types";

export const getSearchedVehicles: GetSearchedVehicles = (params) => {
  return publicAPI.get("/vehicles/", { params });
};

export const getVehicleTypes: GetVehicleTypes = () => {
  return publicAPI.get(`/vehicle-types/`);
};

export const getPopularProviders: GetPopularProviders = () => {
  return publicAPI.get(`/user-details/`);
};
