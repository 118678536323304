// @ts-nocheck
import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Typography, Row, Col, Divider, Spin } from 'antd';
import isImageURL from 'image-url-validator';
import noImage from 'assets/images/NoImageAvailableIcon.png';

import { ROUTES } from 'shared/routes/constants';
import TripRating from 'uicontainers/_shared/TripRating';

import { Props } from './types';

import styles from './details.module.scss';
import Images from './Images';
import Overview from './Overview';
import HostInfo from './HostInfo';

const { Title, Text } = Typography;

const Details: FC<Props> = (props) => {
  const [isValidURL, setIsValidURL] = useState<boolean>(false);
  const { vehicleDetails } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const isAdmin = location.pathname.includes('/admin');
  let vehicleImages;

  if (isAdmin) {
    vehicleImages = [vehicleDetails?.featured_image];
    for (let i = 0; i < vehicleDetails?.vehicle_image?.length; i++) {
      vehicleImages = vehicleImages.concat(
        vehicleDetails?.vehicle_image[i].images
      );
    }
  } else {
    vehicleImages = [vehicleDetails?.featured_image]
      .concat(vehicleDetails?.vehicle_images)
      .filter((item) => item !== undefined);
  }

  return (
    <div className={styles.container}>
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <Title level={3}>{`${vehicleDetails?.vehicle_make ?? ''} ${
            vehicleDetails?.vehicle_model ?? ''
          } ${vehicleDetails?.vehicle_year ?? ''}`}</Title>
        </Col>

        <Col span={24}>
          <Text>Model: {vehicleDetails?.vehicle_model ?? ''}</Text>
        </Col>

        <Col span={24}>
          <TripRating
            rating={vehicleDetails?.rating}
            trips={vehicleDetails?.trips}
          />
        </Col>

        <Col className={styles.carouselWidth} xs={24} sm={12} md={16} lg={24}>
          <div
            className={
              vehicleImages[0] && vehicleImages[0].includes('avatar.jpg')
                ? `${styles.noImage}`
                : `${styles.images}`
            }
          >
            <Images
              vehicleImages={
                vehicleImages[0] !== undefined &&
                vehicleImages[0].includes('avatar.jpg')
                  ? [`${noImage}`]
                  : vehicleImages
              }
            />
          </div>
        </Col>

        <Col>
          <div className={styles.overview}>
            <Overview
              mileage={vehicleDetails?.mileage}
              passengers={vehicleDetails?.passengers}
              baggage={vehicleDetails?.baggage}
              fuelType={vehicleDetails?.fuel_type}
              doors={vehicleDetails?.doors}
              vehicleTransmission={vehicleDetails?.vehicle_transmission}
            />
          </div>
        </Col>

        <Col xs={24}>
          <HostInfo
            providerId={vehicleDetails?.provider_id}
            onClick={() =>
              navigate(
                ROUTES.PROVIDER_INFO_ID(`${vehicleDetails?.provider_id}`)
              )
            }
          />
        </Col>

        <Col className={styles.fixWidth} sm={12} md={16} lg={24}>
          <Text strong>Description</Text>
          <div>
            <Text>{vehicleDetails?.vehicle_details}</Text>
          </div>
          <Divider className={styles.divider_margin} />
        </Col>
      </Row>
    </div>
  );
};

export default Details;
