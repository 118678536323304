import { createAction } from '@reduxjs/toolkit';
import { Protection } from './types';
import carProtectionSlice from './slice';

const { resetCarProtection } = carProtectionSlice.actions;

export const updateProtectionPlan = createAction<Protection>(
  'form/vehicle/update_car_protection_plan',
);

export { resetCarProtection };
