import React from 'react';
import { Row, Col } from 'antd';
import { useNavigate } from 'react-router-dom';

import { ROUTES } from 'shared/routes/constants';
import BgImageCard from 'components/BgImageCard';

import styles from './browseCar.module.scss';

const BrowseCar = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.root}>
      <Row gutter={[16, 0]}>
        <Col className="gutter-row" xs={24} sm={24} md={24} lg={12}>
          <BgImageCard
            title={'Find the car to conquer the great outdoors'}
            handleClick={() => navigate(ROUTES.PATHS.SEARCH)}
          />
        </Col>
        <Col className="gutter-row" xs={24} sm={24} md={24} lg={12}>
          <BgImageCard
            title={'Arrive in style for your next event'}
            className={styles.secondCardImg}
            handleClick={() => navigate(ROUTES.PATHS.SEARCH)}
          />
        </Col>
      </Row>
    </div>
  );
};

export default BrowseCar;
