import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  getDashboard,
  getEarning,
  getRecentBooking,
  getVehicleWiseEarning,
} from 'api/provider/dashboard';

export const getDashboardAsync = createAsyncThunk('provider/dashboard/get_dashboard', async () => {
  const response = await getDashboard();
  return response;
});

export const getEarningAsync = createAsyncThunk('provider/dashboard/get_earning', async () => {
  const response = await getEarning();
  return response;
});

export const getRecentBookingAsync = createAsyncThunk(
  'provider/dashboard/get_recent_booking',
  async () => {
    const response = await getRecentBooking();
    return response;
  },
);

export const getVehicleWiseEarningAsync = createAsyncThunk(
  'provider/dashboard/get_vehicle_wise_earning',
  async () => {
    const response = await getVehicleWiseEarning();
    return response;
  },
);
