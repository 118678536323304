import { Column } from 'components/tables/IXTable/types';

export const COLUMNS: Column[] = [
  {
    name: 'Provider',
    key: 'provider',
    type: 'text',
  },
  {
    name: 'Vehicle',
    key: 'vehicle',
    type: 'text',
  },
  {
    name: 'Invoice duration',
    key: 'invoice_duration',
    type: 'text',
  },
  {
    name: 'Customer',
    key: 'customer',
    type: 'text',
  },
  {
    name: 'Trip status',
    key: 'trip_status',
    type: 'health',
  },
  {
    name: 'Total Price',
    key: 'total_price',
    type: 'text',
    align: 'right',
  },
  {
    name: 'Jaunt Fee',
    key: 'onego_fees',
    type: 'text',
    align: 'right',
  },
  {
    name: 'Receipt',
    key: 'receipt',
    type: 'image-and-text-cell',
  },
];
