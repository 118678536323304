import React, { FC } from 'react';
import { Props } from './types';
import styles from './section.module.scss';

const Section: FC<Props> = (props) => {
  const { children, title } = props;

  return (
    <div className={styles.root}>
      <div className={styles.title}>
        <b>{title}</b>
      </div>
      <div className={styles.divider} />
      <div>
        
      {children}
      </div>
    </div>
  );
}

export default Section;
