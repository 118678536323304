import React from 'react';
import { Paragraph, Title } from 'components/misc/helpers';

function YourAccount() {
  return (
    <div>
      <Title level={3}>A. Changing or cancelling your membership</Title>
      <ol>
        <li>
          <b>1. What to do if your account is restricted</b>{' '}
        </li>
      </ol>
      <Paragraph>
        If you get a message that your account has been restricted, this could
        mean a number of things:
      </Paragraph>
      <ul>
        <li>Your account has not yet been verified</li>
        <li>
          You clicked the 'Deactivate' button to deactivate your account or
          requested that we deactivate your account
        </li>
        <li>
          We have restricted your account, either permanently, or temporarily
          while a matter is sorted out
        </li>
      </ul>
      <Paragraph>
        <b>If you deactivated your account,</b> you can reactivate it instantly
        from the Account page. You'll be able to book cars again right away.
      </Paragraph>
      <Paragraph>
        <b>If your account has been restricted,</b> you may be able to request a
        review of this restriction from the Account page. We'll review your
        restriction and get in touch as soon as possible. If you don't see an
        option to request a review, you can contact us to find out more.
      </Paragraph>
      <Title level={3}>B. Invoices and payments</Title>
      <ol>
        <li>
          <b>1. Understanding your final trip charge</b>
        </li>
      </ol>
      <Paragraph>Breakdown of trip charges:</Paragraph>
      <Paragraph>The charges for your trip will consist of:</Paragraph>
      <ul>
        <li>a charge for the time you had the car</li>
        <li>a charge for the distance you drove</li>
        <li>a booking fee</li>
        <li>a charge for Basic Cover</li>
      </ul>
      <Paragraph>It may also consist of:</Paragraph>
      <ul>
        <li>a charge to add Premium Cover</li>
        <li>a cancelled time or unused time charge</li>
        <li>toll charges and processing fees</li>
        <li>fees to extend your trip</li>
      </ul>
      <Paragraph>
        Time, booking, Basic Cover and Premium Cover fees are charged at the
        time you book your trip, with the other fees charged during or after
        your trip.
      </Paragraph>
      <Paragraph>Quick tip</Paragraph>
      <Paragraph>
        To see a full breakdown of your trip costs, log in and select the
        relevant trip under the 'Trips' tab.
      </Paragraph>
      <Paragraph>Time charges:</Paragraph>
      <Paragraph>
        The car's hourly and daily rates are set by the Owner. The car's hourly
        rate
        <a
          href="https://support.carnextdoor.com.au/hc/en-gb/articles/207507293"
          target="_blank"
          rel="noreferrer"
        >
          applies for each hour you have the car, until you reach the daily
          rate.
        </a>{' '}
        The daily rate is the most you will pay for the car for any 24 hour
        period.
      </Paragraph>
      <Paragraph>Booking fee:</Paragraph>
      <Paragraph>
        To help run our platform we charge a booking fee for each trip.
      </Paragraph>
      <Paragraph>
        Borrowers on our legacy Heavyweight plan don't pay booking fees.
      </Paragraph>
      <Paragraph>Basic Cover and Premium Cover:</Paragraph>
      <Paragraph>
        These are charges for your excess reduction. Basic Cover is included
        with every trip. You can upgrade to Premium Cover for an additional fee.
      </Paragraph>
      <Paragraph>
        Borrowers on our legacy Heavyweight plan have Premium Cover included
        with every trip.
      </Paragraph>
      <Paragraph>Distance charges:</Paragraph>
      <Paragraph>
        You pay a{' '}
        <a
          href="https://support.carnextdoor.com.au/hc/en-gb/articles/115003618486-How-you-pay-for-the-distance-you-drive"
          target="_blank"
          rel="noreferrer"
        >
          distance charge
        </a>{' '}
        for each kilometre you drive. This includes fuel as well as other
        running costs associated with your driving. This distance charge is 21c,
        33c, 39c, 45c or 53c per kilometre, as chosen by the car's Owner.
      </Paragraph>
      <Paragraph>Tolls:</Paragraph>
      <Paragraph>
        Any{' '}
        <a
          href="https://support.carnextdoor.com.au/hc/en-gb/articles/205083309"
          target="_blank"
          rel="noreferrer"
        >
          tolls
        </a>{' '}
        you incur are charged to you. Most tolls are charged within 48 hours of
        them being incurred but some tolls may take up to a month.
      </Paragraph>
      <Paragraph>
        We charge a processing fee of $0.86 on each toll, which will be listed
        separately.
      </Paragraph>
      <Paragraph>Unused time or cancelled time:</Paragraph>
      <Paragraph>
        If you cancel or change your trip, or return the car early, you may see
        a charge for unused or cancelled time.{' '}
        <a
          href="https://support.carnextdoor.com.au/hc/en-gb/articles/115005755583"
          target="_blank"
          rel="noreferrer"
        >
          See a full explanation of these charges
        </a>
        .
      </Paragraph>
      <Paragraph>Extension fee:</Paragraph>
      <Paragraph>
        You pay an extension fee of $0.50 each time you extend your trip past
        its end time. If your trip's end time has already passed, you'll pay a
        late extension fee of $10.
      </Paragraph>
      <Paragraph>$150 deposit:</Paragraph>
      <Paragraph>
        For your first trip, we'll charge a{' '}
        <a
          href="https://support.carnextdoor.com.au/hc/en-gb/articles/205194173"
          target="_blank"
          rel="noreferrer"
        >
          $150 deposit
        </a>{' '}
        at the time you book your trip. Your distance charge will be deducted
        from this amount and the{' '}
        <a
          href="https://support.carnextdoor.com.au/hc/en-gb/articles/360024930131"
          target="_blank"
          rel="noreferrer"
        >
          balance returned to your Jaunt account.
        </a>
      </Paragraph>
      <Title level={3}>C. How we calculate how far you</Title>
      <Paragraph>
        If you borrow a car with a device fitted, you don't need to record the
        odometer as our device will track how far you drive automatically. If
        you need to enter the odometer reading, the trip instructions in the app
        will tell you when you pick up and return the car.
      </Paragraph>
      <Paragraph>
        If you would like to double-check that you have been billed for the
        right distance, you might like to plot your trip on Google Maps and use
        that as a secondary source of comparison. If you're still concerned that
        you've been charged incorrectly, please get in touch so we can
        investigate.
      </Paragraph>
    </div>
  );
}

export default YourAccount;
