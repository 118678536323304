import customerAPI from 'api/base';

import { GetBookings, GetCustomerBookingPdf } from './types';

export const getBookings: GetBookings = (params) => {
  return customerAPI.get(`/bookings/`, { params });
};

export const getCustomerBookingPdf: GetCustomerBookingPdf = async (
  id: number
) => {
  return customerAPI.get(`/agreements/${id}/`, { responseType: 'blob' });
};
