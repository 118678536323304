import { DownOutlined } from '@ant-design/icons';
import {
  Checkbox as AntCheckbox,
  Col,
  Empty,
  Form,
  InputNumber,
  Modal,
  Row,
  Space,
  Spin,
  Typography,
} from 'antd';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { SearchFormValues } from 'api/tripBooking/home/types';
import Button from 'components/Button';
import IxInput from 'components/Input';
import SelectInput from 'components/SelectInput';
import VehicleCard from 'components/VehicleCard';
import { useIsMobile, useIsTab } from 'shared/hooks';
import {
  useAppDispatch,
  useAppSelector,
  useUnwrapAsyncThunk,
} from 'store/hooks';
import {
  getVehicleTypesAsync,
  setCurrentPage,
} from 'store/tripBooking/home/actions';
import {
  selectCurrentPage,
  selectNextPage,
  selectRequest,
  selectSearchedVehicles,
  selectVehicleTypes,
} from 'store/tripBooking/home/selectors';
import { useForm } from 'components/misc/helpers';
import {
  getMOreVehiclesAsync,
  getSearchedVehiclesAsync,
} from 'store/tripBooking/home/thunks';
import {
  getVehicleMakeAsync,
  getVehicleModelAsync,
} from 'store/tripBooking/vehicleCategory/actions';
import {
  selectVehicleMakeStatus,
  selectVehicleModelStatus,
} from 'store/tripBooking/vehicleCategory/selectors';
import { FUEL_TYPE } from 'uicontainers/_shared/constants';
import { TRANSMISSION_TYPE } from 'uicontainers/provider/vehicles/details/constants';
import type { CheckboxValueType } from 'antd/es/checkbox/Group';

import { setTripBooking } from '../actions';
import SearchBar from '../SearchBar';
import { selectSearchItems } from '../selectors';
import styles from './searchedVehicles.module.scss';
import { ROUTES } from 'shared/routes/constants';

const SearchedVehicles = () => {
  const [form] = useForm();
  const searchedVehicles = useAppSelector(selectSearchedVehicles);
  const searchItems = useAppSelector(selectSearchItems);
  const isLoading = useAppSelector(selectRequest) === 'started';
  let currentPage = useAppSelector(selectCurrentPage);
  const nextPage = useAppSelector(selectNextPage);
  const vehicleTypes = useAppSelector(selectVehicleTypes);
  const vehicleMakes = useAppSelector(selectVehicleMakeStatus);
  const vehicleModels = useAppSelector(selectVehicleModelStatus);
  const dispatch = useAppDispatch();

  const [searchedPlace, setSearchedPlace] = useState(searchItems.location);
  const [startPrice, setStartPrice] = useState(0);
  const [endPrice, setEndPrice] = useState(0);
  const [vehicleType, setVehicleType] = useState<string | undefined>(undefined);
  const [isPriceModalVisible, setIsPriceModalVisible] = useState(false);
  const [isVehicleTypeModalVisible, setIsVehicleTypeModalVisible] = useState(
    false
  );
  const [isMoreFilterModalVisible, setIsMoreFilterModalVisible] = useState(
    false
  );
  const [vehicleMake, setvehicleMake] = useState<string | undefined>(undefined);
  const [vehicleModel, setvehicleModel] = useState<string | undefined>(
    undefined
  );
  const [vehicleColor, setVehicleColor] = useState<string | undefined>(
    undefined
  );
  const [vehicleTransmission, setVehicleTransmission] = useState<
    CheckboxValueType[] | undefined
  >(undefined);
  const [vehicleFuel, setVehicleFuel] = useState<
    CheckboxValueType[] | undefined
  >(undefined);

  const isMobile = useIsMobile();
  const isTab = useIsTab();
  const navigate = useNavigate();
  const unwrap = useUnwrapAsyncThunk();

  const { Text } = Typography;
  let searchParams = {};

  useEffect(() => {
    dispatch(setCurrentPage(1));
    unwrap(getVehicleMakeAsync());
    unwrap(getVehicleTypesAsync());
    unwrap(getSearchedVehiclesAsync({ ...searchParams, page: 1 }));
    window.scrollTo(0, 0);
  }, []);

  if (searchedPlace || searchedPlace === '') {
    searchParams = {
      ...searchParams,
      search: searchedPlace ? searchedPlace : undefined,
    };
  }

  searchParams = {
    ...searchParams,
    vehicle_type_id: vehicleType ? vehicleType : undefined,
  };

  if (endPrice) {
    if (!startPrice) {
      searchParams = {
        ...searchParams,
        daily_price_end: endPrice,
        daily_price_start: 0,
      };
    } else {
      searchParams = {
        ...searchParams,
        daily_price_end: endPrice,
        daily_price_start: startPrice,
      };
    }
  }

  if (currentPage) {
    searchParams = { ...searchParams, page: currentPage };
  }

  const handleSetTripSearch = () => {
    dispatch(
      setTripBooking({
        location: searchedPlace,
        vehicle_type_id: vehicleType,
      })
    );
  };

  const handleLocationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchedPlace(e.target.value);
  };

  const showPriceModal = () => {
    setIsPriceModalVisible(true);
  };

  const handlePricePerDay = (values: {
    daily_price_start: number;
    daily_price_end: number;
  }) => {
    if (values.daily_price_start > 0) {
      searchParams = {
        ...searchParams,
        daily_price_start: values.daily_price_start,
      };
    }
    if (values.daily_price_end > 0) {
      searchParams = {
        ...searchParams,
        daily_price_end: values.daily_price_end,
      };
    }
    dispatch(setCurrentPage(1));
    dispatch(getSearchedVehiclesAsync({ ...searchParams, page: 1 }));
    setIsPriceModalVisible(false);
  };

  const handleVehicleTypeFilter = () => {
    dispatch(setCurrentPage(1));
    dispatch(getSearchedVehiclesAsync({ ...searchParams, page: 1 }));
    setIsVehicleTypeModalVisible(false);
  };

  const handleSearch = () => {
    const formValues = {
      color: vehicleColor ?? undefined,
      daily_price_start:
        endPrice && !startPrice ? 0 : startPrice === 0 ? undefined : startPrice,
      daily_price_end: endPrice === 0 ? undefined : endPrice,
      vehicle_type_id: vehicleType ?? undefined,
      fuel_type:
        vehicleFuel && vehicleFuel.length > 0
          ? vehicleFuel.join(',')
          : undefined,
      vehicle_transmission:
        vehicleTransmission && vehicleTransmission.length > 0
          ? vehicleTransmission.join(',')
          : undefined,
      vehicle_make: vehicleMake ?? undefined,
      vehicle_model: vehicleModel ?? undefined,
    };
    handleSetTripSearch();
    dispatch(setCurrentPage(1));
    dispatch(
      getSearchedVehiclesAsync({ ...searchParams, ...formValues, page: 1 })
    );
  };

  const handleMoreFilters = (values: SearchFormValues) => {
    const {
      fuel_type,
      vehicle_transmission,
      vehicle_type_id,
      color,
      ...others
    } = values;

    const formValues = {
      ...others,
      color: color ?? undefined,
      daily_price_start:
        endPrice && !startPrice ? 0 : startPrice === 0 ? undefined : startPrice,
      daily_price_end: endPrice === 0 ? undefined : endPrice,
      vehicle_type_id: vehicle_type_id ? vehicle_type_id : undefined,
      fuel_type:
        fuel_type && fuel_type.length > 0 ? fuel_type.join(',') : undefined,
      vehicle_transmission:
        vehicle_transmission && vehicle_transmission.length > 0
          ? vehicle_transmission.join(',')
          : undefined,
    };
    dispatch(setCurrentPage(1));
    dispatch(
      getSearchedVehiclesAsync({ ...searchParams, ...formValues, page: 1 })
    );
    setIsMoreFilterModalVisible(false);
  };
  const handleVehicleMakeChange = (value: string) => {
    setvehicleMake(value);
    value && dispatch(getVehicleModelAsync({ makeId: value }));
  };
  const handleCancel = () => {
    setIsPriceModalVisible(false);
    setIsVehicleTypeModalVisible(false);
    setIsMoreFilterModalVisible(false);
  };

  const handleLoadMore = () => {
    if (nextPage) {
      currentPage = currentPage + 1;
      dispatch(setCurrentPage(currentPage));
      dispatch(getMOreVehiclesAsync({ ...searchParams, page: currentPage }));
    }
  };

  return (
    <div className={styles.root}>
      <Spin spinning={isLoading}>
        <Row>
          <Col
            xs={24}
            sm={24}
            md={{ span: 16, offset: 4 }}
            lg={{ span: 16, offset: 4 }}
          >
            <div className={styles.searchContainer}>
              <SearchBar
                onLocationChange={handleLocationChange}
                onVehicleTypeChange={(value: string) => {
                  setVehicleType(value);
                  form.setFieldsValue({ vehicle_type_id: value });
                }}
                vehicleTypesOptions={vehicleTypes}
                selectedVehicleType={vehicleType}
                onSearch={handleSearch}
                selectedLocationValue={searchedPlace}
              />
            </div>
          </Col>
        </Row>

        <div className={styles.filterContainer}>
          <Row>
            {/* <Col>
              <div onClick={showPriceModal} className={styles.filtercontent}>
                <span className={styles.filterType}>Price</span>{' '}
                <span
                  className={styles.filterValueTxt}
                >{`$${startPrice} - $${endPrice}`}</span>{' '}
                <span className={styles.filterType}>
                  <DownOutlined />{' '}
                </span>{' '}
              </div>
            </Col> */}
            <Col>
              <Button
                isBordered={true}
                className={styles.btnStyle}
                onClick={() => setIsMoreFilterModalVisible(true)}
              >
                <DownOutlined className={styles.moreFilterIcon} />
                <b>More Filters</b>
              </Button>
            </Col>
          </Row>
        </div>
        <div className={styles.divider} />
        <div className={styles.cardContainer}>
          <Row gutter={[16, 16]}>
            {searchedVehicles?.length <= 0 && (
              <Col span={24}>
                <Empty description="Sorry no match found. Try again with different filter"></Empty>
              </Col>
            )}
            {searchedVehicles.map((vehicle, i) => (
              <Col key={i} xs={24} sm={24} md={12} lg={8} xl={6}>
                <VehicleCard
                  title={
                    vehicle.vehicle_make +
                    ' ' +
                    vehicle?.vehicle_model +
                    ' ' +
                    vehicle?.vehicle_year
                  }
                  cardImage={vehicle.featured_image}
                  rating={vehicle.rating}
                  tripCount={vehicle.trips}
                  perDayCost={vehicle.daily_price}
                  isNew={vehicle.new}
                  onClick={() => {
                    vehicle &&
                      navigate(
                        ROUTES.VEHICLE_DETAILS_ID(vehicle.id.toString())
                      );
                  }}
                />
              </Col>
            ))}
          </Row>
          {nextPage && (
            <div className={styles.loadMoreBtnWrapper}>
              <Button
                isBordered={true}
                onClick={handleLoadMore}
                className={styles.loadMore}
              >
                Load More
              </Button>
            </div>
          )}
        </div>

        {/* <Modal
          closable={true}
          destroyOnClose={true}
          visible={isPriceModalVisible}
          onCancel={handleCancel}
          mask={true}
          className={classNames({
            [styles.priceModal]: true,
            [styles['is-tab']]: isTab,
            [styles['is-mobile']]: isMobile,
          })}
          footer={[
            <Button
              type="ghost"
              isBordered={true}
              key="1"
              htmlType="submit"
              form="filterPriceForm"
              className={styles.applyFilterBtn}
            >
              Apply Filter
            </Button>,
          ]}
        >
          <Form
            onFinish={handlePricePerDay}
            id="filterPriceForm"
            initialValues={{
              daily_price_start: startPrice,
              daily_price_end: endPrice,
            }}
          >
            <Space>
              <Text strong>Price per day</Text>
            </Space>

            <div className={styles.priceSection}>
              <Space className={styles.priceInputs}>
                <Form.Item name="daily_price_start">
                  <InputNumber
                    addonBefore="Min"
                    addonAfter="$"
                    style={{ width: '80%' }}
                    value={startPrice}
                    onChange={(val: number | null) =>
                      val ? setStartPrice(val) : setStartPrice(0)
                    }
                    type="number"
                    min={0}
                  />
                </Form.Item>
                <Form.Item name="daily_price_end">
                  <InputNumber
                    addonBefore="Max"
                    addonAfter="$"
                    style={{ width: '80%' }}
                    value={endPrice}
                    onChange={(val: number | null) =>
                      val ? setEndPrice(val) : setEndPrice(0)
                    }
                    type="number"
                    min={0}
                  />
                </Form.Item>
              </Space>
            </div>
          </Form>
        </Modal> */}

        {/* <Modal
          visible={isVehicleTypeModalVisible}
          onCancel={handleCancel}
          mask={false}
          closable={false}
          className={classNames({
            [styles.vehicleTypeModal]: true,
            [styles['is-tab']]: isTab,
          })}
          footer={[
            <Button
              type="ghost"
              isBordered={true}
              key="1"
              onClick={handleVehicleTypeFilter}
              className={styles.applyFilterBtn}
            >
              Apply Filter
            </Button>,
          ]}
        >
          <SelectInput
            options={vehicleTypes}
            title="Vehicle type"
            onChange={(value: string) => {
              setVehicleType(value);
            }}
            value={vehicleType}
            className={styles.selectInput}
            placeholder="Select Type"
          />
        </Modal> */}

        <Modal
          title="More Filters"
          destroyOnClose={true}
          visible={isMoreFilterModalVisible}
          className={styles.moreFilterModal}
          //onOk={() => {}}
          onCancel={handleCancel}
          footer={[
            <Button
              type="ghost"
              isBordered={true}
              key="1"
              htmlType="submit"
              className={styles.applyFilterBtn}
              form="filterForm"
            >
              Apply Filter
            </Button>,
          ]}
        >
          <Form
            form={form}
            onFinish={handleMoreFilters}
            id="filterForm"
            initialValues={{
              vehicle_type_id: vehicleType,
              daily_price_start: startPrice,
              daily_price_end: endPrice,
              vehicle_make: vehicleMake,
              vehicle_model: vehicleModel,
              color: vehicleColor,
              vehicle_transmission: vehicleTransmission,
              fuel_type: vehicleFuel,
            }}
          >
            <Text strong>Price per day</Text>

            <div className={styles.priceSection}>
              <Space className={styles.priceInputs}>
                <Form.Item name="daily_price_start">
                  <InputNumber
                    addonBefore="Min"
                    addonAfter="$"
                    style={{ width: '80%' }}
                    value={startPrice}
                    onChange={(val: number | null) =>
                      val ? setStartPrice(val) : setStartPrice(0)
                    }
                    type="number"
                    min={0}
                  />
                </Form.Item>
                <Form.Item name="daily_price_end">
                  <InputNumber
                    addonBefore="Max"
                    addonAfter="$"
                    style={{ width: '80%' }}
                    value={endPrice}
                    onChange={(val: number | null) =>
                      val ? setEndPrice(val) : setEndPrice(0)
                    }
                    type="number"
                    min={0}
                  />
                </Form.Item>
              </Space>
            </div>

            <Form.Item name="vehicle_type_id">
              <SelectInput
                options={vehicleTypes}
                title="Vehicle Type"
                placeholder="Select"
                className={styles.selectInput}
                value={vehicleType}
                onChange={(value: string) => {
                  setVehicleType(value);
                }}
              />
            </Form.Item>
            <Form.Item name="vehicle_make">
              <SelectInput
                options={vehicleMakes}
                title="Make"
                placeholder="Select"
                className={styles.selectInput}
                value={vehicleMake}
                onChange={(value: string) => handleVehicleMakeChange(value)}
              />
            </Form.Item>
            <Form.Item name="vehicle_model">
              <SelectInput
                options={vehicleMake ? vehicleModels : []}
                title="Model"
                placeholder="Select"
                className={styles.selectInput}
                value={vehicleModel}
                onChange={(value: string) => setvehicleModel(value)}
              />
            </Form.Item>
            <Form.Item name="color">
              <IxInput
                label="Color"
                placeholder="Select"
                className={styles.selectInput}
                value={vehicleColor}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setVehicleColor(e.target.value)
                }
              />
            </Form.Item>
            <Text strong>Transmission</Text>
            <Form.Item name="vehicle_transmission">
              <AntCheckbox.Group
                options={[
                  { label: 'Automatic', value: TRANSMISSION_TYPE.AUTOMATIC },
                  { label: 'Manual', value: TRANSMISSION_TYPE.MANUAL },
                ]}
                onChange={(checkedValues: CheckboxValueType[]) => {
                  setVehicleTransmission(checkedValues);
                }}
              />
            </Form.Item>
            <Text strong>Fuel Type</Text>
            <Form.Item name="fuel_type">
              <AntCheckbox.Group
                options={FUEL_TYPE.map((e) => {
                  return {
                    label: e.name,
                    value: e.value,
                  };
                })}
                onChange={(checkedValues: CheckboxValueType[]) => {
                  setVehicleFuel(checkedValues);
                }}
              />
            </Form.Item>
          </Form>
        </Modal>
      </Spin>
    </div>
  );
};

export default SearchedVehicles;
