import React, { FC, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Form, Input as AntInput, Spin } from 'antd';
import classNames from 'classnames';
import { RightOutlined } from '@ant-design/icons';

import Input from 'components/Input';
import Button from 'components/Button';
import SelectInput from 'components/SelectInput';

import TopSection from '../TopSection';
import LeftSection from '../LeftSection';

import { FUEL_TYPE } from 'uicontainers/_shared/constants';

import { useCarDetailsSelectors, useUpdateCarDetails } from './hooks';

import styles from '../vehicles.module.scss';
import { useGetVehicleDetails } from '../hooks';

const Title = Typography.Title;
const Text = Typography.Text;
const { TextArea } = AntInput;

const CarDetail: FC = () => {
  const [form] = Form.useForm();

  const { vehicleId } = useParams();
  const {
    carDetails,
    patchStatus,
    getVehicleStatus,
  } = useCarDetailsSelectors();
  const isLoading = patchStatus === 'started' || getVehicleStatus === 'started';

  const carDetailUpdate = useUpdateCarDetails(Number(vehicleId));
  const getVehicleDetails = useGetVehicleDetails();

  useEffect(() => {
    if (vehicleId) {
      getVehicleDetails(Number(vehicleId));
    }
  }, [vehicleId]);

  useEffect(() => {
    form.setFieldsValue(carDetails);
  }, [carDetails]);

  const isDisable = carDetails.status !== 'INACTIVE';

  return (
    <div
      className={classNames({
        [styles.container]: true,
      })}
    >
      <TopSection />
      <div className={styles.vehicle}>
        <LeftSection />

        <div
          className={classNames({
            [styles.formContainer]: true,
          })}
        >
          <Spin spinning={isLoading}>
            <Title level={2}>Detail Information</Title>
            <Form
              className={styles.form}
              initialValues={carDetails}
              onFinish={(values) => carDetailUpdate(values)}
              form={form}
            >
              <div>
                <div className={styles.areaTitle}>
                  <Text strong>Details</Text>
                </div>
                <Form.Item name="vehicle_details">
                  <TextArea rows={4} disabled={isDisable} />
                </Form.Item>
              </div>
              <div className={styles.inputGroup}>
                <Form.Item name="fuel_type">
                  <SelectInput
                    disabled={isDisable}
                    className={styles.inputGroupInput}
                    title="Fuel Type"
                    options={FUEL_TYPE}
                    defaultValue={carDetails.fuel_type}
                  />
                </Form.Item>
                <Form.Item className={styles.inputGroupInput} name="mileage">
                  <Input
                    addonAfter="MPG"
                    label="Mileage"
                    disabled={isDisable}
                  />
                </Form.Item>
              </div>

              <div className={styles.inputGroup}>
                <Form.Item className={styles.inputGroupInput} name="doors">
                  <Input label="Doors" disabled={isDisable} />
                </Form.Item>
                <Form.Item className={styles.inputGroupInput} name="passengers">
                  <Input label="Passengers" disabled={isDisable} />
                </Form.Item>
              </div>

              <Form.Item className={styles.inputBaggage} name="baggage">
                <Input label="Baggage" disabled={isDisable} />
              </Form.Item>

              {!isDisable && (
                <Button
                  className={styles.button}
                  type="default"
                  isBordered={true}
                  htmlType="submit"
                  disabled={isDisable}
                >
                  <div className={styles.textContainer}>
                    <span className={styles.buttonText}>Save and Continue</span>
                    <RightOutlined className={styles.rightIcon} />
                  </div>
                </Button>
              )}
            </Form>
          </Spin>
        </div>
      </div>
    </div>
  );
};

export default CarDetail;
