import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { VehicleRequestParams } from 'api/provider/vehicles/types';

import {
  getVehiclesAsync,
  createVehicleAsync,
  updateVehicleAsync,
  getVehicleDetailsAsync,
  getInsuranceCompanyAsync,
} from './thunks';
import { ProviderVehiclesState } from './types';

const initialState: ProviderVehiclesState = {
  vehicles: [],
  vehicle: {
    vehicle_year: 2000,
    license_no: '',
    color: '',
    vehicle_make: '',
    vehicle_model: '',
    vehicle_transmission: '',
    vehicle_name: '',
    pickup_address: '',
    drop_off_address: '',
    doors: 0,
    fuel_type: '',
    mileage: 0,
    vehicle_details: '',
    vehicle_type: '',
  },
  totalCount: 0,
  currentPage: 1,
  getVehiclesRequestStatus: 'initial',
  postVehicleRequestStatus: 'initial',
  patchVehicleRequestStatus: 'initial',
  getVehicleDetailsRequestStatus: 'initial',
  getInsuranceRequestStatus: 'initial',
  queryParams: {},
};

export const providerVehicleSlice = createSlice({
  name: 'vehicles',
  initialState,
  reducers: {
    setCurrentPage(state, action: PayloadAction<number>) {
      state.currentPage = action.payload;
    },
    setQueryParams(state, action: PayloadAction<VehicleRequestParams>) {
      state.queryParams = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getVehiclesAsync.pending, (state) => {
        state.getVehiclesRequestStatus = 'started';
      })
      .addCase(getVehiclesAsync.fulfilled, (state, action) => {
        state.vehicles = action.payload.results;
        state.totalCount = action.payload.count;
        state.getVehiclesRequestStatus = 'done';
      })
      .addCase(getVehiclesAsync.rejected, (state) => {
        state.getVehiclesRequestStatus = 'failed';
      })
      .addCase(createVehicleAsync.pending, (state) => {
        state.postVehicleRequestStatus = 'started';
      })
      .addCase(createVehicleAsync.fulfilled, (state, action) => {
        state.postVehicleRequestStatus = 'done';
        state.vehicle = action.payload;
      })
      .addCase(createVehicleAsync.rejected, (state) => {
        state.postVehicleRequestStatus = 'failed';
      })
      .addCase(updateVehicleAsync.pending, (state) => {
        state.patchVehicleRequestStatus = 'started';
      })
      .addCase(updateVehicleAsync.fulfilled, (state) => {
        state.patchVehicleRequestStatus = 'done';
      })
      .addCase(updateVehicleAsync.rejected, (state) => {
        state.patchVehicleRequestStatus = 'failed';
      })
      .addCase(getVehicleDetailsAsync.pending, (state) => {
        state.getVehicleDetailsRequestStatus = 'started';
      })
      .addCase(getVehicleDetailsAsync.fulfilled, (state, action) => {
        state.getVehicleDetailsRequestStatus = 'done';
        state.vehicle = action.payload;
      })
      .addCase(getVehicleDetailsAsync.rejected, (state) => {
        state.getVehicleDetailsRequestStatus = 'failed';
      })
      .addCase(getInsuranceCompanyAsync.pending, (state) => {
        state.getInsuranceRequestStatus = 'started';
      })
      .addCase(getInsuranceCompanyAsync.fulfilled, (state) => {
        state.getInsuranceRequestStatus = 'done';
      })
      .addCase(getInsuranceCompanyAsync.rejected, (state) => {
        state.getInsuranceRequestStatus = 'failed';
      });
  },
});

export default providerVehicleSlice;
