import { createAsyncThunk } from "@reduxjs/toolkit";
import { getVehicleDetails, bookCar } from "api/tripBooking/vehicleDetails";
import { PostCarBook } from "api/tripBooking/vehicleDetails/types";

export const getVehicleDetailsAsync = createAsyncThunk(
  "trip_booking/vehicle_details",
  async (id?: number | string) => {
    const response = await getVehicleDetails(id);
    return response;
  }
);

export const bookCarAsync = createAsyncThunk(
  `trip_booking/book_car`,
  async (data: PostCarBook, { rejectWithValue }) => {
    try {
      const response = await bookCar(data);
      return response;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      return rejectWithValue(e.response.data);
    }
  }
);
