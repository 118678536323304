import React, { useState, useEffect } from 'react';
import { Row, Col, Divider, Typography } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { useAppSelector } from 'store/hooks';
import { selectVehicleDetails } from 'store/tripBooking/vehicleDetails/selectors';
import { selectBooking } from 'store/customer/booking/selectors';
import iconInsurance from 'assets/images/insurance.png';
import Image from 'components/Image';
import styles from './book.module.scss';
import BookCard from './BookCard';

const { Text } = Typography;

const Book = () => {
  const vehicleDetails = useAppSelector(selectVehicleDetails);
  const bookingDetails = useAppSelector(selectBooking);
  const [pinkslip, setPinkSlip] = useState<string>('');
  const [greenSlip, setGreenSlip] = useState<string>('');
  const [insurance, setInsurance] = useState<string>('');

  useEffect(() => {
    if (vehicleDetails) {
      setPinkSlip(vehicleDetails.pink_slip);
      setGreenSlip(vehicleDetails.green_slip);
      setInsurance(vehicleDetails.insurance_by);
    }
    if (bookingDetails) {
      setPinkSlip(bookingDetails.vehicle.pink_slip);
      setGreenSlip(bookingDetails.vehicle.green_slip);
      setInsurance(bookingDetails.vehicle.insurance_by);
    }
  }, [vehicleDetails, bookingDetails]);

  return (
    <div className={styles.book_container}>
      <Row>
        <Col className={styles.fixWidth} sm={16} md={18} lg={24}>
          <Row>
            <Col span={24}>
              <BookCard />
            </Col>
            <Col span={24}>
              <Divider />
            </Col>
            <Col span={24}>
              <Row gutter={[0, 8]}>
                {pinkslip && (
                  <Col span={24}>
                    <span className={styles.slips_green}>
                      <CheckOutlined />{' '}
                      <Text strong>Vehicle Inspection Document</Text>
                    </span>
                  </Col>
                )}
                {greenSlip && (
                  <Col span={24}>
                    <span className={styles.slips_green}>
                      <CheckOutlined /> <Text strong>Green Slip</Text>
                    </span>
                  </Col>
                )}
                <Col span={24}>
                  <Divider />
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <div className={styles.insurance}>
                    <div>
                      <Image src={iconInsurance} alt="insurance" />
                    </div>
                    <div>
                      <Text strong>Insurance and Protection</Text>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col span={24} className={styles.text_padding}>
              <Text>Insurance via {insurance}</Text>
            </Col>
            <Col span={24} className={styles.text_padding}>
              <div>
                <Text className={styles.primary_color}>Learn more</Text>
              </div>
            </Col>
            <Col span={24}>
              <Divider />
            </Col>
            <Col span={24}>
              <div className={styles.center}>
                <Text className={styles.primary_color}>
                  Cancellation and no-show policy
                </Text>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Book;
