import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';

import { updateVehicleAsync } from 'store/provider/vehicles/actions';
import { selectVehicleId } from 'store/provider/vehicles/selectors';
import { useUnwrapAsyncThunk, useAppSelector } from 'store/hooks';
import { getVehicleSettingsSidebarMenu } from '../constants';
import { selectCarPricing, selectGetVehicleDetailsStatus } from './selectors';
import { CreateVehicleData } from 'api/provider/vehicles/types';

/*export const useCarPricingActions = () => {
  return useActions({
    updateVehicleAsync,
  });
};*/

export const useCarPricingSelectors = () => {
  return useAppSelector((state) => {
    return {
      carPricing: selectCarPricing(state),
      id: selectVehicleId(state),
      getVehicleRequestStatus: selectGetVehicleDetailsStatus(state),
    };
  });
};

export const useUpdateCarPricing = (id?: number) => {
  const unwrap = useUnwrapAsyncThunk();
  const navigate = useNavigate();

  return useCallback(
    (pricing: Partial<CreateVehicleData>) => {
      if (id) {
        unwrap(updateVehicleAsync({ ...pricing, vehicleId: id }))
          .then(() => {
            message.success('Vehicle information saved successfully', 0.5).then(() => {
              navigate(getVehicleSettingsSidebarMenu(id.toString())[3].link);
            });
          })
          .catch((e) => {
            message.error(e.message);
          });
      }
    },
    [id],
  );
};
