import { createAsyncThunk } from '@reduxjs/toolkit';
import { getBookings } from 'api/customer/bookings';
import { BookingsRequestParams } from 'api/customer/bookings/types';
import { changeBookingStatus } from 'api/provider/tripHistory';
import { StatusRequestParams } from 'api/provider/tripHistory/types';

export const getBookingsAsync = createAsyncThunk(
  'customer/bookings',
  async (params?: BookingsRequestParams) => {
    const response = await getBookings(params);
    return response;
  }
);


export const changeBookingStatusAsync = createAsyncThunk(
  'provider/tripHistory/change_status',
  async (data: StatusRequestParams, { dispatch }) => {
    try {
      const response = await changeBookingStatus(data);
      dispatch(getBookingsAsync());
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);
