import { createSlice } from '@reduxjs/toolkit';

import { getVehicleDetailsAsync, bookCarAsync } from './thunks';
import { TripBookingVehicleDetailsState } from './types';

const initialState: TripBookingVehicleDetailsState = {
  vehicleDetails: {
    id: 0,
    vehicle_make: '',
    vehicle_model: '',
    vehicle_year: '',
    vehicle_type: '',
    featured_image: '',
    vehicle_images: [],
    rating: 0,
    trips: 0,
    daily_price: 0,
    est_total: 0,
    new: false,
    mileage: 0,
    passengers: 0,
    baggage: 0,
    fuel_type: '',
    doors: 0,
    vehicle_transmission: '',
    discount_percent: 0,
    discount_type: '',
    provider_id: null,
    vehicle_details: '',
    pink_slip: '',
    green_slip: '',
    insurance_by: '',
  },
  getVehicleDetailsRequestStatus: 'initial',
  getBookCarRequestStatus: 'initial',
  carBookingDetails: {
    unit: '',
    unit_price: 0,
    total_units: 0,
    total_amount: 0,
    total_discount: 0,
    surcharge: 0,
    net_amount: 0,
    net_amount_with_surcharge_per_installment: 0,
    net_amount_per_unit: 0,
    no_of_installments: 0,
    payment_per_installment: 0,
    net_payment_per_installment: 0,
    security_money: 0,
    start_date: '',
    end_date: '',
    drop_off_address: '',
    pickup_address: '',
    net_payable: 0,
  },
};

export const tripBookingVehicleDetailsSlice = createSlice({
  name: 'vehicleDetails',
  initialState,
  reducers: {
    resetCarBookingDetails: (state) => {
      state.carBookingDetails = initialState.carBookingDetails;
    },
    resetVehicleDetails: (state) => {
      state.vehicleDetails = initialState.vehicleDetails;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getVehicleDetailsAsync.pending, (state) => {
        state.getVehicleDetailsRequestStatus = 'started';
      })
      .addCase(getVehicleDetailsAsync.fulfilled, (state, action) => {
        state.vehicleDetails = action.payload;
        state.getVehicleDetailsRequestStatus = 'done';
      })
      .addCase(getVehicleDetailsAsync.rejected, (state) => {
        state.getVehicleDetailsRequestStatus = 'failed';
      })
      .addCase(bookCarAsync.pending, (state) => {
        state.getBookCarRequestStatus = 'started';
      })
      .addCase(bookCarAsync.fulfilled, (state, action) => {
        state.carBookingDetails = action.payload.results;
        state.getBookCarRequestStatus = 'done';
      })
      .addCase(bookCarAsync.rejected, (state) => {
        state.getBookCarRequestStatus = 'failed';
      });
  },
});

export const {
  resetCarBookingDetails, resetVehicleDetails
} = tripBookingVehicleDetailsSlice.actions;

export default tripBookingVehicleDetailsSlice;
