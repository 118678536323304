import { Ticket } from 'api/provider/tickets/types';
import { dateGenerator } from 'shared/helpers/helperFunctions';
import { TOLL_TICKET_STATUS_TAG_PROPS } from '../Tolls/constants';

import { TicketTableData } from './types';

export const transformTicketsToTableData = (tickets: Ticket[]): TicketTableData[] => {
  return tickets.map((ticket) => ({
    key: ticket.id,
    ticket_no: {
      rows: [
        {
          text: ticket.id,
        },
      ],
    },
    vehicle: {
      text: ticket.vehicle,
    },
    reg_no: {
      text: ticket.license_no,
    },
    violation: {
      text: ticket.violation,
    },
    driver: {
      text: ticket.driver,
    },
    date: {
      text: dateGenerator(ticket.toll_date),
    },
    status: {
      tags: [
        {
          name: TOLL_TICKET_STATUS_TAG_PROPS[ticket.status].name,
          color: TOLL_TICKET_STATUS_TAG_PROPS[ticket.status].color,
        },
      ],
    },
    fine_amount: {
      text: `$${ticket.fine_amount}`,
    },
  }));
};
