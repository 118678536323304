import { providerAPI } from 'api/base';

import { GetSummary, GetEarning, GetRecentBooking, GetVehicleWiseEarning } from './types';

export const getDashboard: GetSummary = () => {
  return providerAPI.get('/dashboard/summary/');
};

export const getEarning: GetEarning = () => {
  return providerAPI.get('/dashboard/earnings/');
};

export const getRecentBooking: GetRecentBooking = () => {
  return providerAPI.get('/dashboard/recent-bookings/');
};

export const getVehicleWiseEarning: GetVehicleWiseEarning = () => {
  return providerAPI.get('/dashboard/vehicle-wise-earnings/');
};
