import { RootState } from 'store/types';

export const selectIsAuthenticated = (state: RootState) => state.ui.auth.isAuthenticated;

export const selectLoginData = (state: RootState) => state.ui.auth.loginData;

export const selectLoginStatus = (state: RootState) => state.ui.auth.loginStatus;

export const selectSignupData = (state: RootState) => state.ui.auth.signupData;

export const selectSignUpRequestStatus = (state: RootState) => state.ui.auth.signupStatus;

export const selectForgotPasswordRequestStatus = (state: RootState) =>
  state.ui.auth.forgotPasswordStatus;

export const selectActivateAccountRequestStatus = (state: RootState) =>
  state.ui.auth.activateAccountStatus;

export const selectVerifyTokenRequestStatus = (state: RootState) => state.ui.auth.verifyTokenStatus;

export const selectResetPasswordRequestStatus = (state: RootState) =>
  state.ui.auth.resetPasswordStatus;

  export const selectUserMode = (state: RootState) => state.ui.auth.userMode;