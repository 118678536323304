import React from 'react';
import { Paragraph, Title } from 'components/misc/helpers';

import styles from './privacyPolicy.module.scss';

const PrivacyPolicy = () => {
  return (
    <div className={styles.container}>
      <Title level={2}>Privacy Policy</Title>
      <Paragraph>
        Jaunt (“Jaunt”) is committed to protecting your personal information and
        complying with privacy laws applicable to its business, including the
        Australian Privacy Principles. This privacy policy (“Privacy Policy”)
        sets out our policy with respect to the way we manage (collect, use and
        disclose) personal information about you (“you”).
      </Paragraph>
      <Title level={4}>Collection of Personal Information</Title>
      <Paragraph>
        “Personal Information” is information about an identified individual or
        information from which your identity is apparent or can be reasonably
        ascertained
      </Paragraph>
      <Paragraph>
        During the conduct of our business, the types of information generally
        collected by us include your name, date of birth, residential and/or
        postal address, telephone number, email address, payment details,
        details of your current driver licence, details of your driving history,
        your employer’s name and position and contact details for who to contact
        in the event of an emergency. We may also request additional
        documentation to assist in verifying your identity or eligibility for a
        particular membership plan or promotion.
      </Paragraph>
      <Title level={4}>How we collect your personal information</Title>
      <Paragraph>
        We may collect personal information through different methods,
        including:
        <ul>
          Directly
          <li>
            if you input any personal information into our website or apps,
            including filling in an application form to create an account or use
            of our services or entering a competition;
          </li>
          <li>
            if you contact us in relation to the services (including by
            telephone, fax, e-mail, online chats and social media).
          </li>
        </ul>
        <ul>
          Indirectly/ from third parties:
          <li>
            service providers including (without limitation) fraud protection
            agencies, insurance providers, maintenance providers, accident
            management providers;
          </li>
          <li>your employers (for our corporate program);</li>
          <li>publicly available resources;</li>
          <li>government agencies;</li>
          <li>credit reporting bodies and other credit providers.</li>
        </ul>
        We also collect information about your usage of our vehicles, including
        location, time, dates, driving behaviour which is gathered via vehicle
        on-board technology monitoring systems. In addition, we also collect and
        use cookies (as described below). Some provision of personal information
        is optional. If you decide not to provide some of your personal
        information, we may not be able to accept your membership application or
        we may be prevented from providing all or some of our services to you.
      </Paragraph>
      <Title level={4}>Use of Personal Information</Title>
      <Paragraph>
        We may use and disclose your Personal Information in the following ways:
        <ol>
          <li>
            to deliver services you have requested and do all things required to
            manage those services;
          </li>
          <li>
            to contact you about any incidents involving your vehicle, our
            contract or dealings with you and other matters, including
            investigating any accidents, claims, breaches to our terms, unlawful
            activities or fraud;
          </li>
          <li>
            verify the information you provide us, including to confirm your
            identity;
          </li>
          <li>
            as required for the purposes of debt recoveries and consumer credit
            rating;{' '}
          </li>
          <li>
            to improve and develop our services, including performing research
            and customer satisfaction surveys; and
          </li>
          <li>
            for direct marketing purposes (such as newsletters or email alerts),
            including updating you about changes to our services or providing
            information about special offers or promotions.
          </li>
        </ol>
        For direct marketing purposes, your Personal Information will be added
        to our database. The database may be used for ongoing marketing,
        promotional and/or educational purposes. The type of marketing and
        educational activities that we undertake includes forwarding material to
        you so that you are kept updated in relation to our services and various
        issues. Our communications may be sent in various forms such as mail,
        SMS, fax, in app notifications, website notifications and email. You
        consent to us sending you marketing communications. If at any time you
        do not wish to continue receiving marketing communications, we provide
        an “opt-out” procedure in each communication to you or you can opt out
        by contacting us directly.
      </Paragraph>
      <Title level={4}>Disclosure of Personal Information</Title>
      <Paragraph>
        <ol>
          <li>
            our service providers who assist us to provide the services
            including (without limitation), mailing houses, call centre
            operators, data processing, market research firms, consultants,
            insurance providers, accident management, roadside assistance
            providers or for personal information verification;
          </li>
          <li>
            finance providers, including banks, debit and credit card providers;
          </li>
          <li>
            credit reporting, fraud checking agencies and debt collection
            agencies{' '}
          </li>
          <li>
            government authorities, councils, regulatory and licensing bodies
            (including relevant road traffic authorities) and law enforcement
            bodies (where required){' '}
          </li>
          <li>
            third parties for processing and handling traffic related
            infringements, toll roads and car park matters;
          </li>
          <li>
            if you are an additional driver, accessing the vehicles under a
            corporate account, or accessing the vehicles under a membership deal
            provided to you via your employer, to the account holder company or
            entity; and
          </li>
          <li>
            third parties in the event we sell any or all our business and/or
            our assets.
          </li>
          <li>
            Vehicle Collection/Repossession agencies if you breach the terms of
            our membership agreement{' '}
          </li>
        </ol>
        You consent to us disclosing your Personal Information to our overseas
        service providers, who may be located in the United States, India,
        Philippines, or other countries and which may not meet the standard set
        by clause 8.1 of the Australian Privacy Principles.
      </Paragraph>
      <Title level={4}>
        Accessing, Updating and Correcting your Personal Information
      </Title>
      <Paragraph>
        You may request from us in writing to provide you with details of the
        Personal Information we hold about you. We will endeavour to process
        your request as soon as practicable. If we deny your request, we will
        provide reasons in writing as to our decision. To complain about or
        contest our decision, please contact our Privacy Officer.
      </Paragraph>
      <Paragraph>
        Please ensure you update any personal information you have provided us
        as soon as reasonably practicable to ensure we have up to date records.
      </Paragraph>
      <Paragraph>
        If you wish to amend information that is out of date or incorrect, or if
        you wish for your Personal Information to be removed from our database,
        please contact our Privacy Officer (see contact details below).
      </Paragraph>
      <Title level={4}>Security</Title>
      <Paragraph>
        We store Personal Information in hard copy and electronic format. We
        will use reasonable endeavours to secure any Personal Information we
        hold about you from misuse, loss, disclosure and unauthorised access.
        Your Personal Information is stored behind industry standard firewalls
        and where applicable, protected by usernames and passwords. However,
        there are risks involved in transmitting information across the Internet
        and we cannot provide any guarantee (nor accept any liability) with
        respect to the security of your data transmission over the internet.
        Once we receive the personal information, we will ensure it is protected
        in accordance with this Privacy Policy.
      </Paragraph>
      <Title level={4}>Cookies</Title>
      <Paragraph>
        A cookie is a small piece of data sent from our website (or app) and
        stored on your computer or device to provide functionality critical to
        use our services and help us serve you better. They collect information
        relating to your use of our website and your internet browsing. In
        general, cookies are used to improve the services and information we
        provide to users to enhance their experience and as well as potentially
        serve ads during your internet browsing.
      </Paragraph>
      <Paragraph>
        We use authentication cookies to record that you are authorised to
        access your account and without this we cannot provide you access to our
        services as our servers cannot be sure you are authorised to access.
      </Paragraph>
      <Paragraph>
        Cookies generally collect and store non-personal information including:
        (a) the date and time of your visit; (b) your internet protocol (IP)
        address; (c) the pages you have accessed and links clicked; and (d) the
        type of browser you were using.
      </Paragraph>
      <Paragraph>
        Jaunt may read these cookies as you use our services so that we can
        identify you and learn how to improve our service. Cookies may also
        collect personal information. Any personal information collected by
        cookies will be dealt with in accordance with this policy.
      </Paragraph>
      <Paragraph>
        Jaunt permits third parties, including Google Analytics to collect
        statistical data from our website using their cookies to help us measure
        how people use our website and other statistical information, such as
        website usage and traffic. These cookies do not collect Personal
        Information, however, may be used in conjunction with ad-serving
        networks to serve ads to you while you are browsing the Internet. You
        may opt of Google Analytics by visiting the Google Analytics Opt-out
        Browser Add-on webpage. This can be done via the “How Google uses data
        when you use our partners’ sites or apps” webpage, located at
        www.google.com/policies/privacy/partners/ (or any other URL Google may
        provide from time to time).
      </Paragraph>
      <Title level={4}>Links</Title>
      <Paragraph>
        Our website may link to other websites. Please note that we are not
        responsible for the privacy practices of these sites. When you leave our
        website, you should familiarise yourself with the privacy statement of
        that website prior to providing your Personal Information, as this
        Privacy Policy only applies to Personal Information collected by us or
        through our website.
      </Paragraph>
      <Title level={4}>Changes to this Privacy Policy</Title>
      <Paragraph>
        We may, from time to time, review, amend and update this Privacy Policy.
        When this occurs, we will post details of this update on the website. We
        encourage you to periodically review this Privacy Policy so you are
        aware of our latest practices with respect to Personal Information.
      </Paragraph>
      <Paragraph>
        Hardcopies of this Privacy Policy are available upon request.
      </Paragraph>
      <Title level={4}>Contact Information</Title>
      <Paragraph>
        If you wish to exercise any of your rights under this Privacy Policy,
        have any questions, comments or complaints regarding our practices, or
        if you are of the view that we have not adhered to this Privacy Policy,
        you may write to our Privacy Officer by emailing us at
        privacy@Jaunt.com.au.
      </Paragraph>
      <Paragraph>
        Upon receipt of a complaint, we will work with you to resolve your
        complaint. If we are unable to resolve your complaint to your
        satisfaction within a reasonable time frame, you may refer your
        complaint to the Office of the Australian Information Commissioner
        (OAIC).
      </Paragraph>
    </div>
  );
};

export default PrivacyPolicy;
