import React, { useEffect } from 'react';

import { useUnwrapAsyncThunk } from 'store/hooks';
import { useProfileInfoSelectors } from './hooks';
import { getProfileAsync } from 'store/customer/account/actions';
import ProfileInfo from '../ProfileInfo';
import PersonalInfo from '../PersonalInfo';
import BusinessInfo from '../BusinessInfo';

import styles from './home.module.scss';

function Home() {
  const unwrap = useUnwrapAsyncThunk();
  const { profileInfo, status } = useProfileInfoSelectors();

  useEffect(() => {
    unwrap(getProfileAsync());
  }, []);

  return (
    <div className={styles.root}>
      <p className={styles.title}>
        <b>Profile</b>
      </p>

      <ProfileInfo profileInfo={profileInfo} status={status} />
      <div className={styles.divider} />
      <PersonalInfo />
      <div className={styles.divider} />
      <BusinessInfo />
    </div>
  );
}

export default Home;
