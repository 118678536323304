import customerAPI, { paymentAPI } from 'api/base';

import { GetBookingDetails, UpdateBookingDetails } from './types';

export const getBookingDetails: GetBookingDetails = (id) => {
  return customerAPI.get(`/bookings/${id}/`);
};

export const updateBookingDetails: UpdateBookingDetails = (data, id) => {
  return paymentAPI.patch(`/booking/${id}/`, data);
};
