import React, { FC } from "react";
import { Menu } from "antd";

import { useUpdateVehicleBasicInfo } from "./hooks";
import { MenuActionsProps } from "./types";

const MenuActions: FC<MenuActionsProps> = (props) => {
  const { vehicle, setVisible } = props;
  const updateVehicle = useUpdateVehicleBasicInfo();

  // @ts-ignore
  const handleStatusChange = (info: MenuInfo) => {
    setVisible(false);
    if (info.key === "active") {
      updateVehicle({ status: "ACTIVE" }, vehicle.id);
    }
    if (info.key === "inactive") {
      updateVehicle({ status: "INACTIVE" }, vehicle.id);
    }
  };
  return (
    <Menu onClick={handleStatusChange}>
      {vehicle?.status === "REQUEST_FOR_APPROVAL" && (
        <>
          <Menu.Item key="active">Approve</Menu.Item>
          <Menu.Item key="inactive">Cancel</Menu.Item>
        </>
      )}
    </Menu>
  );
};

export default MenuActions;
