import React, { useEffect } from 'react';
import classNames from 'classnames';
import styles from '../Home/account.module.scss';
import Button from 'components/Button';
import { Text, Title } from 'components/misc/helpers';
import {
  useAppDispatch,
  useAppSelector,
  useUnwrapAsyncThunk,
} from 'store/hooks';
import {
  getCurrentBalanceAsync,
  handlePayOutAsync,
} from 'store/provider/billings/actions';
import {
  selectCurrentBalance,
  selectPayOutRequestStatus,
} from 'store/provider/billings/selectors';
import { isProvider } from 'uicontainers/_shared/helpers/auth';
import { selectLoginData } from 'store/shared/auth/selectors';
import { message, Spin } from 'antd';
import { selectStripeOnboardingLink } from 'uicontainers/_shared/SetupPayment/selectors';
import { getStripeOnboardingLinkAsync } from 'store/shared/auth/actions';

const Download = () => {
  const dispatch = useAppDispatch();
  const unwrap = useUnwrapAsyncThunk();
  const loginData = useAppSelector(selectLoginData);
  const availableBalance = useAppSelector(selectCurrentBalance);
  const stripeOnboardingLink = useAppSelector(selectStripeOnboardingLink);
  const isLoading = useAppSelector(selectPayOutRequestStatus) === 'started';
  const isOnboardingCompleted = loginData && loginData.is_onboarding_completed;

  useEffect(() => {
    if (loginData && isProvider(loginData.user_role)) {
      dispatch(getCurrentBalanceAsync());
    }
    if (!isOnboardingCompleted) {
      dispatch(getStripeOnboardingLinkAsync());
    }
  }, []);

  const handlePayOut = () => {
    unwrap(handlePayOutAsync({ amount: availableBalance.available[0].amount }))
      .then(() => {
        dispatch(getCurrentBalanceAsync());
        message.success('payout is successful');
      })
      .catch((e) => {
        message.error('Payout failed');
      });
  };

  const handleSetUpPayment = () => {
    window.location.href = stripeOnboardingLink;
  };

  return (
    <div className={styles.moreOptions}>
      {isOnboardingCompleted ? (
        <div>
          <div className={styles.titleContainer}>
            <Title level={2}>Pay out</Title>
          </div>

          <Spin spinning={isLoading}>
            <div className={styles.downloadContainer}>
              {/* <Text strong>Pay out</Text> */}
              <Text
                className={classNames({
                  [styles.moreOptions_info]: true,
                  [styles.availableAmountText]: true,
                })}
              >
                Available balance:{' '}
                <span className={styles.availableAmount}>
                  {availableBalance.available[0].amount}
                </span>
              </Text>

              <Button
                className={styles.button}
                type="default"
                isBordered={true}
                disabled={availableBalance.available[0].amount ? false : true}
                onClick={handlePayOut}
              >
                <div className={styles.textContainer}>
                  <span>Pay Out</span>
                </div>
              </Button>
              <br></br>
              <br></br>
            </div>
          </Spin>

          {/* <div className={styles.downloadContainer}>
        <Text strong>Close account</Text>
        <Text className={styles.moreOptions_info}>
          If closed, all data related to the account may be lost and can’t be
          recovered later.
        </Text>

        <Button className={styles.button} type="default" isBordered={true}>
          <div className={styles.textContainer}>
            <span>Close Account</span>
          </div>
        </Button>
      </div> */}
        </div>
      ) : (
        <div>
          {' '}
          <Title level={2}>Connect your account to Stripe</Title>
          <p>
            We use Stripe to make sure you get paid on time and keep your
            personal and bank details secure.
          </p>
          <Button
            className={styles.button}
            type="default"
            isBordered={true}
            onClick={handleSetUpPayment}
          >
            <div className={styles.textContainer}>
              <span>Set up payments</span>
            </div>
          </Button>
        </div>
      )}
    </div>
  );
};

export default Download;
