import { useSelector } from 'react-redux';
import moment from 'moment';

import {
  selectCustomerAccount,
  selectCustomerAccountStatus,
} from 'store/customer/account/selectors';
import { RootState } from 'store/types';

export const usePersonalInfoSelectors = () => {
  return useSelector((state: RootState) => {
    const {
      first_name,
      last_name,
      date_of_birth,
      email,
      phone_no,
      address,
    } = selectCustomerAccount(state);

    const status = selectCustomerAccountStatus(state);

    return {
      personalInfo: {
        first_name,
        last_name,
        date_of_birth: date_of_birth ? moment(date_of_birth).format('YYYY-MM-DD') : null,
        email,
        phone_no,
        address,
      },
      status,
    };
  });
};
