import { createSlice } from "@reduxjs/toolkit";

import {
  updateAccountAsync,
  getProfileAsync,
  uploadDataAsync,
} from "store/customer/account/actions";
import {
  isPendingActionMatcher,
  isFulfilledActionMatcher,
} from "store/matchers";
import { CUSTOMER_ACCOUNT_PERSONAL_INFO } from "./constants";
import { InfoState } from "./types";

const initialState: InfoState = {
  personalInfo: {
    address: "",
    date_of_birth: "",
    email: "",
    first_name: "",
    last_name: "",
    phone_no: "",
    profile_picture: "",
    bank_account: "",
  },
  status: "initial",
};

export const personalInfoSlice = createSlice({
  name: CUSTOMER_ACCOUNT_PERSONAL_INFO,
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(updateAccountAsync.fulfilled, (state, action) => {
        state.personalInfo = {
          ...state.personalInfo,
          ...action.payload,
        };
      })

      .addCase(updateAccountAsync.rejected, (state) => {
        state.status = "failed";
      })

      .addCase(getProfileAsync.fulfilled, (state, action) => {
        const {
          email,
          date_of_birth,
          profile_picture,
          address,
          first_name,
          last_name,
          phone_no,
          bank_account,
        } = action.payload.results[0];

        state.personalInfo = {
          email,
          date_of_birth,
          profile_picture,
          address,
          first_name,
          last_name,
          phone_no,
          bank_account,
        };
      })

      .addCase(getProfileAsync.rejected, (state) => {
        state.status = "failed";
      })

      .addCase(uploadDataAsync.fulfilled, (state, action) => {
        state.personalInfo.profile_picture = action.payload.profile_picture;
      })

      .addCase(uploadDataAsync.rejected, (state) => {
        state.status = "failed";
      })

      .addMatcher(isPendingActionMatcher, (state) => {
        state.status = "started";
      })
      .addMatcher(isFulfilledActionMatcher, (state) => {
        state.status = "done";
      }),
});

export default personalInfoSlice.reducer;
