import { nanoid } from '@reduxjs/toolkit';
import { UploadFile } from 'antd/lib/upload/interface';

//@ts-ignore
export const stringValidator = (rule, value) => {
  if (value === null || value === undefined || value === '') {
    return Promise.reject(rule.message);
  }
  return Promise.resolve();
};

export const getFileName = (url: string): string => {
  return url.substr(url.lastIndexOf('/') + 1).replace(/[#?].*$/, '');
};

export const transformToUploadFile = (data: string | null): UploadFile[] => {
  if (data) {
    return [
      {
        uid: nanoid(5),
        name: getFileName(data),
        status: 'done',
        url: data,
      },
    ];
  }
  return [];
};
