import { createSlice } from '@reduxjs/toolkit';

import {
  updateAccountAsync,
  getProfileAsync,
  uploadDataAsync,
} from 'store/customer/account/actions';
import {
  isPendingActionMatcher,
  isFulfilledActionMatcher,
} from '../../../../store/matchers';

import { CUSTOMER_ACCOUNT_BUSINESS_INFO } from './constants';
import { BusinessInfoState } from './types';

export const initialState: BusinessInfoState = {
  businessInfo: {
    abn: null,
    business_address: null,
    business_email: null,
    business_license: null,
    business_name: null,
    business_phone: null,
  },
  status: 'initial',
};

export const businessInfoSlice = createSlice({
  name: CUSTOMER_ACCOUNT_BUSINESS_INFO,
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(updateAccountAsync.fulfilled, (state, action) => {
        state.businessInfo = {
          ...state.businessInfo,
          ...action.payload,
        };
      })

      .addCase(updateAccountAsync.rejected, (state) => {
        state.status = 'failed';
      })

      .addCase(getProfileAsync.fulfilled, (state, action) => {
        const {
          business_name,
          business_phone,
          business_email,
          business_address,
          business_license,
          abn,
        } = action.payload.results[0];

        state.businessInfo = {
          business_name,
          business_phone,
          business_email,
          business_address,
          business_license,
          abn,
        };
      })

      .addCase(getProfileAsync.rejected, (state) => {
        state.status = 'failed';
      })

      .addCase(uploadDataAsync.fulfilled, (state, action) => {
        state.businessInfo.business_license = action.payload.business_license;
      })

      .addCase(uploadDataAsync.rejected, (state) => {
        state.status = 'failed';
      })

      .addMatcher(isPendingActionMatcher, (state) => {
        state.status = 'started';
      })

      .addMatcher(isFulfilledActionMatcher, (state) => {
        state.status = 'done';
      }),
});

export default businessInfoSlice.reducer;
