import React from 'react';
import { Row, Col, Divider, Typography } from 'antd';
import { CheckOutlined } from '@ant-design/icons';

import { useAppSelector } from 'store/hooks';
import { selectVehicleDetails } from 'store/tripBooking/vehicleDetails/selectors';
import iconInsurance from 'assets/images/insurance.png';
import Image from 'components/Image';
import styles from './vehicleDetails.module.scss';
import { selectAdminVehicleDetails } from 'store/admin/vehicles/selectors';

const { Title, Text } = Typography;

const InfoRow = (info: {
  name: string;
  value: string | number | undefined;
}) => {
  const { name, value } = info;
  return (
    <Col span={24}>
      <Row className={styles.border}>
        <Col span={12}>
          <Text>{name}</Text>
        </Col>
        <Col span={12}>
          <Text>{value}</Text>
        </Col>
      </Row>
    </Col>
  );
};

const CarInfo = () => {
  const vehicleDetails = useAppSelector(selectAdminVehicleDetails);
  return (
    <div className={styles.book_container}>
      <Row>
        <Col className={styles.fixWidth} sm={16} md={18} lg={24}>
          <Row>
            <Col span={24}>
              <div>
                <Title level={5}>vehicle Info</Title>
                <Row gutter={[0, 8]}>
                  <InfoRow
                    name={'Vehicle Type'}
                    value={vehicleDetails?.vehicle_type}
                  />
                  <InfoRow
                    name={'Pickup and Drop Off Address'}
                    value={vehicleDetails?.pickup_address}
                  />
                  <InfoRow
                    name={'License No.'}
                    value={vehicleDetails?.license_no}
                  />
                  <InfoRow name={'Color'} value={vehicleDetails?.color} />
                  <InfoRow
                    name={'Daily Price'}
                    value={vehicleDetails?.daily_price}
                  />
                  <InfoRow
                    name={'Security Money'}
                    value={vehicleDetails?.security_money}
                  />
                  <InfoRow name={'Discount'} value={vehicleDetails?.discount} />
                  {vehicleDetails?.discount_type && (
                    <InfoRow
                      name={'Discount Type'}
                      value={vehicleDetails?.discount_type}
                    />
                  )}
                  <InfoRow
                    name={'Insurance Number'}
                    value={vehicleDetails?.insurance_number}
                  />
                  <InfoRow
                    name={'Insurance Type'}
                    value={vehicleDetails?.insurance_type}
                  />
                </Row>
              </div>
            </Col>
            <Col span={24}>
              <Divider />
            </Col>
            <Col span={24}>
              <Row gutter={[0, 8]}>
                {vehicleDetails?.pink_slip && (
                  <Col span={24}>
                    <span className={styles.slips_green}>
                      <CheckOutlined /> <Text strong>Vehicle Inspection Document</Text>
                    </span>
                  </Col>
                )}
                {vehicleDetails?.green_slip && (
                  <Col span={24}>
                    <span className={styles.slips_green}>
                      <CheckOutlined /> <Text strong>Green Slip</Text>
                    </span>
                  </Col>
                )}
                {vehicleDetails?.insurance_paper && (
                  <Col span={24}>
                    <span className={styles.slips_green}>
                      <CheckOutlined /> <Text strong>Insurance Paper</Text>
                    </span>
                  </Col>
                )}
              </Row>
            </Col>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <div className={styles.insurance}>
                    <div>
                      <Image src={iconInsurance} alt="insurance" />
                    </div>
                    <div>
                      <Text strong>Insurance and Protection</Text>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col span={24} className={styles.text_padding}>
              <Text>Insurance via {vehicleDetails?.insurance_by}</Text>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default CarInfo;
