import React from 'react';
import { MoreOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';
import { Vehicle } from 'api/provider/vehicles/types';
import MenuActions from './MenuActions';

type Props = {
  vehicle: Vehicle;
};

const ActionButton = (props: Props) => {
  const { vehicle } = props;
  const [visible, setVisible] = React.useState(false);

  return (
    <Dropdown
      //@ts-ignore
      overlay={<MenuActions vehicle={vehicle} setVisible={setVisible} />}
      trigger={['click']}
      placement="bottomCenter"
      visible={visible}
      onVisibleChange={(visible) => setVisible(visible)}
    >
      <MoreOutlined />
    </Dropdown>
  );
};

export default ActionButton;
