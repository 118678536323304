import { createSlice } from '@reduxjs/toolkit';

import {
  handleRentalContractAsync,
  getRentalContractAsync,
  updateRentalContractAsync,
} from './thunks';
import { RentalContractState } from './types';

const initialState: RentalContractState = {
  rentalContract: null,
  rentalContractCreateStatus: 'initial',
  rentalContractRequestStatus: 'initial',
  rentalContractUpdateStatus: 'initial',
};

export const rentalContractSlice = createSlice({
  name: 'rental-contract',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(handleRentalContractAsync.pending, (state) => {
        state.rentalContractCreateStatus = 'started';
      })
      .addCase(handleRentalContractAsync.fulfilled, (state, action) => {
        state.rentalContractCreateStatus = 'done';
        state.rentalContract = action.payload;
      })
      .addCase(handleRentalContractAsync.rejected, (state) => {
        state.rentalContractCreateStatus = 'failed';
      })
      .addCase(getRentalContractAsync.pending, (state) => {
        state.rentalContractRequestStatus = 'started';
      })
      .addCase(getRentalContractAsync.fulfilled, (state, action) => {
        state.rentalContractRequestStatus = 'done';
        state.rentalContract = action.payload.results[0];
      })
      .addCase(getRentalContractAsync.rejected, (state) => {
        state.rentalContractRequestStatus = 'failed';
      })
      .addCase(updateRentalContractAsync.pending, (state) => {
        state.rentalContractUpdateStatus = 'started';
      })
      .addCase(updateRentalContractAsync.fulfilled, (state, action) => {
        state.rentalContractUpdateStatus = 'done';
        state.rentalContract = action.payload;
      })
      .addCase(updateRentalContractAsync.rejected, (state) => {
        state.rentalContractUpdateStatus = 'failed';
      });
  },
});

export default rentalContractSlice;
