import { providerAPI } from 'api/base';

import { GetTripHistory, ChangeBookingStatus } from './types';

export const getTripHistory: GetTripHistory = (params) => {
  return providerAPI.get('/trip-histories/', { params });
};
export const changeBookingStatus: ChangeBookingStatus = (data) => {
  return providerAPI.post(`/status-change/`, data);
};
