import { Col, Result, Row, Spin, Typography } from 'antd';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from 'components/Button';
import { ROUTES } from 'shared/routes/constants';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectActivateAccountRequestStatus } from 'store/shared/auth/selectors';
import { activateAccountAsync } from 'store/shared/auth/thunks';

import styles from './accountActivation.module.scss';
import { useQuery } from 'uicontainers/_shared/hooks';

const { Title } = Typography;

const AccountActivation: FC = () => {
  const query = useQuery();
  const securityCode = query.get('securityCode');
  const token = query.get('token');
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isActivationLoading =
    useAppSelector(selectActivateAccountRequestStatus) === 'started';
  const isActivationSuccess =
    useAppSelector(selectActivateAccountRequestStatus) === 'done';
  const isActivationFailed =
    useAppSelector(selectActivateAccountRequestStatus) === 'failed';

  React.useEffect(() => {
    // Call the api to activate
    dispatch(activateAccountAsync({ securityCode, token }));
  }, []);

  const onGoToLogin = () => {
    navigate(ROUTES.PATHS.LOGIN);
  };

  return (
    <Row align="middle">
      <Col xs={20} sm={20} md={20} lg={7} className={styles.container}>
        {isActivationSuccess && !isActivationFailed && (
          <>
            <Result
              status="success"
              title="Congratulations! Your have successfully activated your account"
            />
            <Button
              onClick={onGoToLogin}
              isBordered={true}
              style={{ width: '100%' }}
              htmlType="submit"
            >
              Go back to Login
            </Button>
          </>
        )}
        {!isActivationSuccess && isActivationFailed && (
          <Result
            status="error"
            title="Failed to activate your account"
            subTitle="Please try after some time or contact Jaunt"
          />
        )}
        {!isActivationSuccess && !isActivationFailed && (
          <Spin spinning={isActivationLoading}>
            <Title level={3} className={styles.title}>
              Activating your account
              <div className="ant-result-subtitle">
                Please wait for few moments
              </div>
            </Title>
          </Spin>
        )}
      </Col>
    </Row>
  );
};

export default AccountActivation;
