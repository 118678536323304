import { PlusOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from 'components/Button';
import IXTable from 'components/tables/IXTable';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getVehiclesAsync, setCurrentPage, setQueryParams } from 'store/provider/vehicles/actions';
import TableHeader from 'uicontainers/_shared/TableHeader';

import { COLUMNS } from './constants';
import {
  createSelectVehicleTableData,
  selectVehiclesRequestStatus,
  selectCurrentPage,
  selectTotalCount,
} from './selectors';
import styles from './vehicles.module.scss';
import { VehiclesTableData } from './types';
import { selectVehicles } from 'store/provider/vehicles/selectors';
import { notification } from 'shared/helpers/notifications';
import { ROUTES } from 'shared/routes/constants';

const Vehicles: FC = () => {
  const [searchValue, setSearchValue] = useState('');
  const [selectedValue, setSelectedValue] = useState('');
  const navigate = useNavigate();

  const vehiclesTableData = useAppSelector(createSelectVehicleTableData);
  const vehicles = useAppSelector(selectVehicles);
  const totalCount = useAppSelector(selectTotalCount);
  const currentPage = useAppSelector(selectCurrentPage);

  const isVehiclesLoading = useAppSelector(selectVehiclesRequestStatus) === 'started';
  const dispatch = useAppDispatch();

  const options = [
    { name: 'Active', value: 'ACTIVE' },
    { name: 'Inactive', value: 'INACTIVE' },
    { name: 'Request for approval', value: 'REQUEST_FOR_APPROVAL' },
  ];

  React.useEffect(() => {
    let searchParams = {};
    if (searchValue) {
      searchParams = { ...searchParams, search: searchValue };
    }
    if (selectedValue) {
      searchParams = { ...searchParams, status: selectedValue };
    }
    if (currentPage) {
      searchParams = { ...searchParams, page: currentPage };
    }
    dispatch(setQueryParams(searchParams));
    dispatch(getVehiclesAsync(searchParams));
  }, [searchValue, selectedValue, currentPage, getVehiclesAsync]);

  const onChange = (page: number) => {
    dispatch(setCurrentPage(page));
  };

  const handleSearch = (value: string) => {
    setSearchValue(value);
    dispatch(setCurrentPage(1));
  };
  const handleSelect = (value: string) => {
    setSelectedValue(value);
    dispatch(setCurrentPage(1));
  };

  const goToVehicleDetails = (record: VehiclesTableData) => {
    const selectedVehicle = vehicles.find(item => item.id.toString() === record.key)
    if (selectedVehicle) {
      if (selectedVehicle.status !== 'ACTIVE') {
        navigate(ROUTES.PROVIDER_VEHICLES_SETTINGS_BASIC_INFO_ID(record.key));
      } else {
        notification({
          type: 'info',
          title: 'Not allowed',
          description: "You can not edit an active vehicle.",
        });
      }
    }
  };

  const button = (
    <Button
      onClick={() => navigate(ROUTES.PROVIDER_VEHICLES_SETTINGS_BASIC_INFO_ID('new'))}
      type="ghost"
      isBordered={true}
      icon={<PlusOutlined />}
      className={styles.buttonIcon}
    >
      <span className={styles.buttonText}>Add Vehicles</span>
    </Button>
  );

  return (
    <div className={styles.root}>
      <Spin spinning={isVehiclesLoading}>
        <TableHeader
          button={button}
          title="Vehicles"
          onSearch={handleSearch}
          onSelectChange={handleSelect}
          dropdownTitle="Status"
          options={options}
        />
        <IXTable
          onRowClick={goToVehicleDetails}
          data={vehiclesTableData}
          columns={COLUMNS}
          pagination={{ currentPage: currentPage, count: totalCount, onChange }}
        />
      </Spin>
    </div>
  );
};

export default Vehicles;
