import { Empty, Radio, RadioChangeEvent, Spin } from 'antd';
import React from 'react';

import BarChart from 'components/Barchart';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getEarningAsync } from 'store/admin/dashboard/actions';

import { selectCategory } from './actions';
import styles from './earning.module.scss';
import {
  selectEarning,
  selectEarningCategory,
  selectEarningRequestStatus,
} from './selectors';
import { prepareChartData } from './helpers';
import NXCard from 'components/NXCard';

const Earning = () => {
  const earning = useAppSelector(selectEarning);
  const isEarningLoading =
    useAppSelector(selectEarningRequestStatus) === 'started';
  const earningCategory = useAppSelector(selectEarningCategory);

  const dispatch = useAppDispatch();
  React.useEffect(() => {
    dispatch(getEarningAsync());
  }, []);

  const onChange = (e: RadioChangeEvent) => {
    dispatch(selectCategory(e.target.value));
  };

  const data = prepareChartData(earning, earningCategory);

  const renderCategorySelector = () => (
    <Radio.Group onChange={onChange} defaultValue="all_year">
      <Radio.Button value="this_month">This month</Radio.Button>
      <Radio.Button value="last_month">Last month</Radio.Button>
      <Radio.Button value="all_year">All year</Radio.Button>
    </Radio.Group>
  );

  return (
    <Spin spinning={isEarningLoading}>
      <NXCard
        headerTitle="Earnings"
        headerActions={renderCategorySelector()}
        cssClassNames={styles.adminDashboardEarning}
      >
        {data?.length > 0 ?
          <BarChart
            data={data}
            xField={earningCategory === 'all_year' ? 'month' : 'date'}
            yField="earning"
          />
          : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      </NXCard>
    </Spin>
  );
};

export default Earning;
