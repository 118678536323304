import React from 'react';
import { Paragraph, Title } from 'components/misc/helpers';

import styles from './termsAndConditions.module.scss';
import { Divider } from 'antd';

const PrivacyPolicy = () => {
  return (
    <div className={styles.container}>
      <ol className={styles.decimal}>
        <li className={styles.title}>
          PARTIES
          <Divider></Divider>
          <Paragraph className={styles.body}>
            <Title level={5}>This Agreement is between:</Title>
            <Title level={4}>Jaunt Pty Ltd (“we”, “us”);</Title>
            <Title level={5}>and</Title>
            <Title level={4}>The Member (“you”).</Title>
          </Paragraph>
        </li>
        <li className={styles.title}>
          APPLYING FOR MEMBERSHIP
          <Divider></Divider>
          <Paragraph className={styles.body}>
            <ol className={styles.decimal}>
              <li>
                You can apply for Membership using the online application form
                on our Website. By submitting a Membership Application, you
                agree that:
                <ol className={styles.alpha}>
                  <li>
                    you meet the Eligibility Requirements set out at clause 3.1
                    when you submit the Membership Application; and
                  </li>
                  <li>
                    we will provide credit to you to make use of the Service and
                    as such, you consent to us communicating with a credit
                    reporting agency in the manner described in Schedule 1.
                  </li>
                </ol>
              </li>
              <li>
                You must inform us immediately if you cease to meet any of the
                Eligibility Requirements.
              </li>
              <li>
                We may accept or reject any Membership Application at our
                discretion.
              </li>
              <li>
                You warrant that all information provided by you to us in your
                Membership Application or at any time while you are a Member,
                including without limitation your name, address, email address,
                and information about your Driving History or credit record, is
                true and correct and is all the information we could reasonably
                require in relation to your Membership and use of the Service.
                You must ensure that all information provided by you to us is
                current and up to date. You permit us to verify your identity,
                including using the Document Verification Service (‘DVS’) to
                confirm that the personal information you provide to us in your
                identity documents as evidence of your identity match the
                information held by the agency which issued that document (also
                known as the official record holder). Our use of DVS to verify
                your identity may involve the use of third party systems and
                services. You can find more information about DVS by visiting
                the DVS website, or by phone or email as follows: Website:
                http://www.dvs.gov.au/Pages/default.aspx; Phone: (02) 6141 6666;
                Email: DVS.Manager@ag.gov.au. You must indemnify us for any
                claim or loss that we suffer as a result of any information that
                you provide to us in relation to this Agreement, your Membership
                or your use of the Service being incorrect or misleading in any
                way, whether intentionally or otherwise.
              </li>
              <li>
                If you agree to these terms on behalf of a company or other
                legal entity, you represent and warrant that you have the
                authority to bind that company or other legal entity to these
                terms and, in such event, “you” and “your” will refer and apply
                to that company or other legal entity.
              </li>
              <li>
                This clause 2 and clause 3 apply from the time you submit a
                membership application to us. The rest of this Agreement starts
                when we give you notice that we accept your Membership
                Application.
              </li>
            </ol>
          </Paragraph>
        </li>
        <li className={styles.title}>
          MEMBER ELIGIBILITY CRITERIA
          <Divider></Divider>
          <Paragraph className={styles.body}>
            <ol className={styles.decimal}>
              <li>
                To become a Member and to continue to be a Member, you must:
                <ol className={styles.alpha}>
                  <li>
                    be at least 18 years old and no more than 85 years old
                    (noting, however, that Members who are under 21 years of age
                    may only drive a restricted range of vehicles with a minimum
                    driver age less than 21);
                  </li>
                  <li>
                    have no licence suspensions or disqualifications, major
                    violations or alcohol/drug related incidents in the past 5
                    years;
                  </li>
                  <li>
                    not have been convicted of, or have any pending convictions
                    for, a criminal offence in any jurisdiction
                  </li>
                  <li>
                    hold a full unrestricted driver licence (unless, if you are
                    an Owner, we agree otherwise in writing);
                  </li>
                  <li>
                    have an appropriate credit or debit card with sufficient
                    funds to cover any Fees and Charges that you may incur in
                    relation to your use of the Service; and
                  </li>
                  <li>
                    have a satisfactory credit history as reported on your
                    credit record.
                  </li>
                </ol>
              </li>
              <li>
                You must immediately disclose to us any traffic incidents or
                driving violations that might affect your eligibility for
                Membership. If you do not wish to disclose any such incident,
                you must withdraw your Membership Application or cancel your
                Membership immediately.
              </li>
              <li>
                We can suspend or cancel your membership if you are not eligible
                to continue as a Member.
              </li>
            </ol>
          </Paragraph>
        </li>
        <li className={styles.title}>
          INCORPORATION OF POLICIES; CHANGES TO THIS AGREEMENT
          <Divider></Divider>
          <Paragraph className={styles.body}>
            <ol className={styles.decimal}>
              <li>
                These terms, together with the Policies and the Fee Schedule,
                form your written agreement with us. you must be familiar with
                the Policies and comply with them at all times while using the
                Service. you may obtain the latest version of the Policies and
                Fee Schedule from us or from our Website.
              </li>
              <li>
                If you are an Owner, the terms contained in the section titled
                ‘Owner Agreement’ and in the Owner Guarantee also form part of
                your written agreement with us.
              </li>
              <li>
                If there is any inconsistency between the terms of the Agreement
                and the Policies, the Fee Schedule or your Membership
                Application, the terms of the Agreement will prevail to the
                extent of any inconsistency.
              </li>
              <li>
                We may change the terms of this Agreement, the Policies, the Fee
                Schedule or the Owner Guarantee at any time by giving you notice
                by email. Changes will also be posted on our Website. If you do
                not agree to the changes, you may cancel your Membership in
                accordance with clause 24 and if you do so within 30 days we
                will refund any membership fees that you have paid that relate
                to the period after the cancellation date.
              </li>
            </ol>
          </Paragraph>
        </li>
        <li className={styles.title}>
          USE OF THE SERVICE
          <Divider></Divider>
          <Paragraph className={styles.body}>
            <ol className={styles.decimal}>
              <li>
                We provide the Service to facilitate the sharing of vehicles.
                Vehicle hire or rental services are provided by Owners to
                Borrowers under a separate contract, the terms of which are
                informed by these terms. We may act as an agent of Owners or
                Borrowers under that separate contract, as provided for in these
                terms and the Owner Agreement.
              </li>
              <li>
                We do not control the condition of any Vehicle, the actions of
                any Member, or the information provided to us by you or other
                Members and included in any Member Profiles or otherwise made
                available to you. You are solely responsible for your actions
                and inactions in relation to your Use of the Service and your
                interactions with other Members.
              </li>
              <li>
                We are not liable for, and disclaim any liability related to,
                your interaction with other Members, your use of other Members’
                Vehicles or other Members’ use of your Vehicle, or any Member’s
                action or inaction, with respect to the Service. You use any
                Vehicle made available through the Service at your own risk. To
                the maximum extent permitted by law, we owe you no duty of care,
                and disclaim all responsibility or liability to you, any
                passenger or third party (howsoever arising) resulting from any
                Accident, breakdown or any other failure of a Vehicle.
              </li>
              <li>
                By using the Service, to the maximum extent permitted by law,
                you agree that any legal claim or remedy that you seek to make
                or obtain under a contract for Vehicle rental services, or for
                actions or omissions of other Members or third parties will be
                limited to the particular Member or third party and you agree
                not to attempt to claim against or impose liability on or seek
                any legal remedy from us with respect to such actions or
                omissions. If you have a dispute with or claim against one or
                more Members, you release us (and our officers, directors,
                agents, and employees) from liability (howsoever arising,
                whether under contract, tort, stature or otherwise) in any way
                connected with such disputes or claims.
              </li>
              <li>
                The Service, including the Booking System, comes with consumer
                guarantees under the Australian Consumer Law in the Consumer and
                Competition Act 2010 (Cth) that cannot be excluded by this
                Agreement. Nothing in this Agreement affects your statutory
                rights as a consumer. We give no warranties beyond the consumer
                guarantees except where they are expressly set out in this
                Agreement. In particular, to the extent not prohibited by law:
                <ol className={styles.alpha}>
                  <li>
                    You acknowledge that with the exception of a major failure
                    that continues un- remedied for 48 hours, the delivery of
                    the Services (including the availability of the Booking
                    System) is not time critical. We will supply the Services
                    within a reasonable time, and we give no other warranty or
                    guarantee in relation to the availability of the Service
                    (including the Booking System), or that access to the
                    Service (including the Booking System) will be free from
                    interruptions or errors.
                  </li>
                  <li>
                    The Services depend upon telecommunications, cloud storage
                    and other services delivered to us and Members by third
                    parties, the availability of which we cannot control.
                  </li>
                  <li>
                    If we fail to supply the Services within a reasonable time,
                    or otherwise fail to comply with a consumer guarantee, we
                    will not be liable for any damage, loss or cost, including
                    without limitation loss of expected revenue, loss of profit
                    or loss of opportunity, that is not reasonably foreseeable.
                  </li>
                </ol>
              </li>
              <li>
                We may offer to issue discretionary risk protection products to
                Members in relation to the car-sharing activities. Any financial
                services including protection products in relation to the
                Service are offered by us as an authorized representative of an
                Australian financial services license holder. We act as a
                manager to Mobility Mutual Limited, the issuer of discretionary
                risk products including Trip Cover and Between- Booking Cover.
                We have authority from the product issuer to manage claims for
                damage to Vehicles but we are not financially liable for payment
                of claims. The terms and conditions for membership of Mobility
                Mutual and the terms and conditions for the discretionary risk
                product will be provided to you separately when an offer of
                membership and protection is made by us.
              </li>
            </ol>
          </Paragraph>
        </li>
        <li className={styles.title}>
          USE OF LOCKBOX SYSTEM AND VEHICLE KEYS
          <Divider></Divider>
          <Paragraph className={styles.body}>
            <ol className={styles.decimal}>
              <li>
                For each Booking you make for an Instant Keys Vehicle, we will
                issue you with a PIN that enables you to access the keys from
                the Lockbox or enable you to unlock the Vehicle using your
                phone. You must:
                <ol className={styles.alpha}>
                  <li>keep the PIN and your phone secure;</li>
                  <li>
                    keep the keys secure throughout the booking until you return
                    them to the Lockbox at the end of the Booking;
                  </li>
                  <li>
                    not allow any person other than a Member to use a PIN or
                    your phone to access the Vehicle without our written
                    consent; and
                  </li>
                  <li>
                    use the PIN or your phone only to access a Vehicle for which
                    you have a valid Booking.
                  </li>
                </ol>
              </li>
              <li>
                For each Booking you make for a Key Handover Vehicle, you must
                arrange to collect the keys as instructed by the Owner. You
                must:
                <ol className={styles.alpha}>
                  <li>
                    keep the keys secure throughout the Booking until you return
                    them as directed by the Owner at the end of the Booking; and
                  </li>
                  <li>
                    not allow any person other than a Member to access the
                    Vehicle without our written consent.
                  </li>
                </ol>
              </li>
            </ol>
          </Paragraph>
        </li>
        <li className={styles.title}>
          MEMBER PROFILES; USE OF OUR WEBSITE
          <Divider></Divider>
          <Paragraph className={styles.body}>
            <ol className={styles.decimal}>
              <li>
                We will create a Member Profile for you using the information
                that you provide to us in your Membership Application or by any
                other method. This Member Profile will be accessible to you via
                the Member-only section of our Website. Some information from
                your Member Profile, such as your profile photo, first name,
                contact details and reviews, may be made available to other
                Members if you make a Booking for their Vehicle.
              </li>
              <li>
                You are responsible for updating and maintaining the currency of
                your own Member Profile on our Website. You must ensure that any
                information posted in your Member Profile is correct and
                complete and not misleading.
              </li>
              <li>
                You warrant that any text, images or other content that would
                constitute intellectual property of any nature (“Materials”)
                that you include in your member profile or otherwise provide to
                us does not infringe on the intellectual property rights of any
                third party. You grant us a non-exclusive, irrevocable,
                worldwide, perpetual and royalty-free licence to use any
                Materials that you produce or provide, for the purpose of
                operating and marketing the Service.
              </li>
              <li>
                We will take reasonable steps to verify, at the time that a
                Member is accepted for Membership, that he or she has a current
                driver licence and that any Vehicle that he or she makes
                available for sharing is appropriately registered. However, we
                do not guarantee the completeness or correctness of this or any
                other information that Members provide to us at the time of
                submitting a Membership Application or afterwards. We will not
                under any circumstances be liable for any loss or damage
                resulting from or arising in connection with any errors,
                inaccuracies or misrepresentations in your Member Profile or any
                other Member’s Member Profile.
              </li>
              <li>
                We will issue you with a Member ID for accessing the Member-only
                areas of our Website. you must keep your Member ID and password
                confidential and secure. You must use the Website and the
                Booking System only for the intended purpose of making valid
                Bookings for the Service, and you will be liable for any use of
                our Website, including the Booking System, that is made using
                your Member ID.
              </li>
            </ol>
          </Paragraph>
        </li>
        <li className={styles.title}>
          ACCEPTANCE AND CANCELLATION OF BOOKINGS
          <Divider></Divider>
          <Paragraph className={styles.body}>
            <ol className={styles.decimal}>
              <li>
                You agree to make arrangements for sharing another Member’s
                Vehicle only through the Booking System.
              </li>
              <li>
                The minimum Booking Period is one hour. For Vehicles that are
                parked in marked ‘car share parking’ bays, the maximum Booking
                period is 4 days.
              </li>
              <li>
                You must, for the benefit of any Member whose Vehicle you Book
                through the Service, other Members and us:
                <ol className={styles.alpha}>
                  <li>
                    confirm the beginning and end times of any Booking that you
                    make before you begin using a Vehicle;
                  </li>
                  <li>
                    only use another Member’s Vehicle during the period for
                    which you have a confirmed Booking;
                  </li>
                  <li>
                    pay the Booking Fee and any usage fee or other fees set out
                    in the Fee Schedule in connection with your Booking (even if
                    you collect the Vehicle after the start of the Booking
                    Period, return it before the end of the Booking Period, or
                    do not use it at all during the Booking Period) unless you
                    cancel the Booking, in which case clause 8.5 applies; and
                  </li>
                  <li>
                    {' '}
                    pay the charges set out in the Fee Schedule if you use the
                    Vehicle outside of the period for which you have Booked the
                    Vehicle or fail to return the Vehicle and its key to the
                    proper location at the agreed time.
                  </li>
                </ol>
              </li>
              <li>
                We may, on behalf of a Member whose Vehicle you have Booked,
                cancel that Booking (either before the Booking commences or
                during the Booking Period), if we are unable to take a
                pre-payment or deposit on your Card, or to collect payment
                instalments that are payable for a long Booking in accordance
                with clause 17.3.
              </li>
              <li>
                If you cancel a Booking, you may be required to pay charges for
                part or all of the Booking, as set out on our Website.
              </li>
            </ol>
          </Paragraph>
        </li>
        <li className={styles.title}>
          LIABILITY FOR DAMAGE AND LOSS
          <Divider></Divider>
          <Paragraph className={styles.body}>
            <ol className={styles.decimal}>
              <li>
                If you Borrow a Vehicle, you must return it to the Owner in the
                same condition that it was in at the start of the Booking.
              </li>
              <li>
                Borrowers are responsible to the Owner and to us for all Damage
                and Loss that occurs while you have possession of the Vehicle
                (regardless of whether you caused that Damage and Loss) and for
                related costs and fees as set out in the Damage Policy and Fee
                Schedule.
              </li>
              <li>
                If you Borrow a Vehicle, you must take and provide to us
                photographs of the Vehicle when you take possession of the
                Vehicle and when you cease to have possession of the Vehicle, in
                accordance with the Damage Policy. Failure to do so may mean
                that you will be deemed under the Damage Policy to have caused,
                and be liable to the Owner and us for, any Damage and Loss or
                untidiness to the Vehicle, even if that Damage and Loss or
                untidiness may have occurred before or after you took possession
                of the Vehicle. You acknowledge and agree that this system of
                recording the condition of shared Vehicles and attributing
                responsibility for damage is a reasonable and necessary
                condition of your participation in the Service. You agree that
                it is your responsibility to prove that the Vehicle was not
                damaged whilst in your possession, and not the Owner’s or our
                responsibility to prove that it was damaged whilst in your
                possession. You agree to take and upload a complete and clear
                set of photos showing all exterior surfaces of the Vehicle for
                each Booking in accordance with the requirements of this clause
                and of the damage policy.
              </li>
              <li>
                If you are an Owner, you must inspect the Vehicle and report to
                us any damage to the Vehicle, in accordance with the Damage
                Policy. Failure to do so may mean that you will be deemed under
                the Damage Policy to have caused, and be liable to us for, any
                Damage and Loss or untidiness to the Vehicle, even if that
                Damage and Loss or untidiness may have occurred before you
                re-took possession of the Vehicle.
              </li>
              <li>
                Subject to clause 10:
                <ol className={styles.alpha}>
                  <li>
                    If Damage and Loss occurs during a Borrower’s possession of
                    the Vehicle, then, in respect of each single Event (being an
                    event that occurs at the same address and time), the
                    Borrower is liable to the Owner for, and will be charged:
                    <ol className={styles.roman}>
                      <li>
                        the costs of rectifying the Damage and Loss (which
                        includes the loss and damage to the Vehicle and any
                        assessment, towing, storage and recovery charges)
                      </li>
                      <li>
                        a loss-of-use payment to the Owner for each day that
                        their car is unavailable, calculated on the basis set
                        out in the Damage Policy; and
                      </li>
                      <li>
                        other losses or expenses related to the Accident, for
                        each single Event.
                      </li>
                    </ol>
                  </li>
                  <li>
                    if Damage and Loss is identified or reported to us in
                    connection with your possession of the Vehicle, we may
                    charge your Card for our account for the amount that we
                    estimate, in good faith, will be sufficient to cover your
                    Damage and Loss Liability
                  </li>
                </ol>
              </li>
              <li>
                If you are a Borrower who has Trip Cover for the Booking Period:
                <ol className={styles.alpha}>
                  <li>
                    you must make a claim to Mobility Mutual for protection
                    under the Trip Cover PDS and protection wording if you are
                    liable for Damage and Loss under this Agreement.
                  </li>
                  <li>
                    If you fail to make a claim under Trip Cover within 3
                    business days of Damage and Loss being reported to us, then
                    you authorise us to act as your attorney and to make a claim
                    in your name.
                  </li>
                  <li>
                    If your claim for protection under Trip Cover is approved by
                    Mobility Mutual, then we will charge your Card on behalf of
                    Mobility Mutual to pay your Excess, and Mobility Mutual will
                    pay the remaining amount of your Damage and Loss Liability
                    up to the Protection Limit in accordance with the terms and
                    conditions contained in the TripCover PDS.
                  </li>
                  <li>
                    You must comply with the important conditions in the
                    TripCover PDS and you report Damage and Loss to the Vehicle
                    from hail, flood, fire, storm, cyclone or other natural
                    disaster to us as soon as possible and no more than 7 days
                    after it occurs.
                  </li>
                  <li>
                    If it appears to us that the Damage and Loss Liability may
                    equal or exceed your Excess, we will charge your Card for
                    our account with your full Damage and Loss Liability amount.
                  </li>
                  <li>
                    If the claim for protection under Trip Cover is denied by
                    Mobility Mutual, or if it appears to us that one or more of
                    the exclusions to Trip Cover applies, we will charge your
                    Card, for the Owner’s account, for the full amount of your
                    Damage and Loss Liability amount. We will promptly notify
                    you of the details of the Damage and Loss and the amount to
                    be charged. With the exception of any fees relating to our
                    handling of the matter, we collect these amounts as the
                    Owner’s agent.
                  </li>
                  <li>
                    If you are required to pay the full amount for your Damage
                    and Loss Liability and you dispute the Damage and Loss
                    Liability or the amount charged, you must still pay the
                    amount determined under clause 9.5b) and contact us. We will
                    address or assist you to resolve the dispute in accordance
                    with our Complaints and Dispute Resolution Policy. We may do
                    so on behalf of an Owner if a disputed amount is owed to or
                    has been paid to the Owner, or we may ask you to attempt to
                    resolve the dispute directly with the Owner. If the dispute
                    is resolved in your favour, we will return the disputed
                    amount to you.
                  </li>
                  <li>
                    If are required to pay the full amount for your Damage and
                    Loss Liability and the actual costs of, or associated with,
                    the Damage and Loss are less than the amount that you have
                    been charged, or if we subsequently recover the costs from
                    Mobility Mutual or from any third parties who may have been
                    at fault, then we will reimburse the difference to your Card
                    within a reasonable time after all costs have been fully
                    quantified and forward to you an invoice for the final
                    amount. Alternatively, if we discover that the actual costs
                    are not covered under this Agreement, if an amount claimed
                    from Mobility Mutual is rejected, or if the actual costs
                    exceed the amount that you have been charged, then we may
                    charge your Card, either for our account or for the Owner’s
                    account, with the outstanding amount.
                  </li>
                </ol>
              </li>
              <li>
                If Damage and Loss to the Vehicle from hail, flood, fire, storm,
                cyclone or other natural disaster occurs during a period when
                you have possession of the Vehicle, you must report the Loss or
                Damage to us via our Website or app as soon as possible and no
                more than 7 days after it occurs
              </li>
              <li>
                You must inform us immediately if any of your Driving History
                details change during the course of your Membership, including
                (but not limited to) any vehicular accidents that you are
                involved in (note that these are not limited to “Accidents” as
                defined)
              </li>
              <li>
                You (whether as Owner or Borrower) agree to indemnify us for any
                claim made against us for loss of or damage to any personal
                property that is connected with your use or possession of a
                Vehicle, or the use or possession of your Vehicle, including
                personal property left in the Vehicle.
              </li>
            </ol>
          </Paragraph>
        </li>
        <li className={styles.title}>
          USE OF VEHICLES
          <Divider></Divider>
          <Paragraph className={styles.body}>
            <ol className={styles.decimal}>
              <li>
                If you are a Borrower, then at the start of a Booking Period,
                and before driving the Vehicle, you must:
                <ol className={styles.alpha}>
                  <li>
                    check that the Tolling Device is in the Vehicle (for Instant
                    Keys Vehicles listed in NSW, VIC and QLD only);
                  </li>
                  <li>
                    check that the fuel gauge shows at least ¼ full (and, if it
                    is not, take a photo of the fuel gauge and submit it to us
                    by email or through our app or Website;
                  </li>
                  <li>
                    follow any instructions given in our app, Website or by
                    email; and
                  </li>
                  <li>
                    take photographs that clearly show all exterior surfaces of
                    the vehicle (and upload these photos in accordance with the
                    Damage Policy); and
                  </li>
                  <li>
                    for Key Handover Bookings, take and upload photos of the
                    Vehicle’s odometer.
                  </li>
                </ol>
              </li>
              <li>
                If you are a Borrower:
                <ol className={styles.alpha}>
                  <li>
                    you must not, for our benefit and the benefit of the Owner,
                    attempt to access a Vehicle without a valid Booking for that
                    Vehicle; and
                  </li>
                  <li>
                    you must not permit any person other than a Member to drive
                    a Vehicle that you have booked.
                  </li>
                </ol>
              </li>
              <li>
                You must not, for our benefit and the benefit of the Owner,
                operate the Vehicle if your driver licence has expired, has been
                suspended or is of a category that does not permit you to drive
                the Vehicle.
              </li>
              <li>
                You must not tamper or interfere with any Lockbox System or
                Tolling Device (and must not permit or suffer any other person
                to do so).
              </li>
              <li>
                You must not (and must not permit or suffer any other person
                to), for our benefit and the benefit of the Owner:
                <ol className={styles.alpha}>
                  <li>
                    operate any device that requires the use of one or both
                    hands while driving, including but not limited to mobile
                    phones, mobile computers, or devices that allow texting;
                  </li>
                  <li>
                    carry cargo or baggage that damages the Vehicle or exceeds
                    the Vehicle’s carrying capacity;
                  </li>
                  <li>
                    carry a number of passengers that exceeds the number that
                    the Vehicle is designed to carry or the number of available
                    seatbelts;
                  </li>
                  <li>
                    carry passengers or property for hire, fare or reward,
                    unless you are the Owner of the Vehicle (in which case, you
                    must obtain separate insurance to cover that activity);
                  </li>
                  <li>pick up hitchhikers or persons unknown to you;</li>
                  <li>
                    transport any inflammable, hazardous, toxic, volatile,
                    poisonous, dangerous or illegal substances or items;
                  </li>
                  <li>
                    drive the Vehicle while under the influence of alcohol or
                    drugs or both to the extent that you are incapable of having
                    proper control of the Vehicle or while your blood alcohol
                    content or the level of drugs present in your blood exceeds
                    the limit specified by the law of the state or territory in
                    which the Vehicle is driven;
                  </li>
                  <li>
                    use the Vehicle for or in connection with any illegal
                    activity;
                  </li>
                  <li>
                    use the Vehicle in contravention of any traffic regulations;
                  </li>
                  <li>
                    use the Vehicle in an unsafe or unroadworthy condition;
                  </li>
                  <li>
                    engage in any motor sport, time trial, competitive driving
                    or racing or off-road driving;
                  </li>
                  <li>
                    use the Vehicle to push, propel or tow another car, trailer
                    or any other thing, unless you are the Owner of the Vehicle
                    or an Additional Driver;
                  </li>
                  <li>
                    use the Vehicle in any way that is reckless, illegal,
                    abnormal or likely to damage the Vehicle or cause a danger
                    to persons or property;
                  </li>
                  <li>
                    sell, rent or dispose of the Vehicle or any of its parts, or
                    attempt to give anyone legal rights over the Vehicle or any
                    of its parts, unless you are the Owner;
                  </li>
                  <li>
                    refuse to undergo any breath or blood test or drug
                    impairment assessment when reasonably requested by the
                    police;
                  </li>
                  <li>smoke in a Vehicle;</li>
                  <li>
                    allow any animals (other than certified assistance animals)
                    to enter a Vehicle that is not designated pet-friendly, or
                    carry an animal in pet-friendly car without an appropriate
                    carrier; or
                  </li>
                  <li>
                    place stickers, signs, symbols, or other devices,
                    modifications, advertisements or publicity materials on the
                    interior or exterior of the Vehicle without our permission.
                  </li>
                </ol>
              </li>
            </ol>
          </Paragraph>
        </li>
        <li className={styles.title}>
          ACCIDENTS, THEFT AND BREAKDOWNS
          <Divider></Divider>
          <Paragraph className={styles.body}>
            <ol className={styles.decimal}>
              <li>
                If you are a Borrower and a Vehicle breaks down or is involved
                in an Accident or is stolen during your Booking Period, you must
                follow the reporting, claims processing, and repair procedure
                set out in the Damage Policy and in this clause 11.
              </li>
              <li>
                You must report any Accident or theft to us as soon as possible
                after the Accident or theft occurs, even if you are not sure
                whether the Vehicle or other property was damaged. Reports
                should be made through the Help Centre on our Website unless we
                tell you to report it in another way. You must also provide all
                information we reasonably request concerning the Accident or
                theft within 48 hours of any request, and deliver a correctly
                completed Collision or Damage Report Form, including any police
                witness statements or reports, to us within 48 hours after the
                Accident or theft.
              </li>
              <li>
                You must assist us (at our cost and direction) in respect of any
                claim or action brought in respect of any Accident or theft of
                the Vehicle, including attending court to give evidence, and
                tell us about any other insurance or other claim that may be
                relevant to the claim.
              </li>
              <li>
                You must not make any admission of liability, offer of
                compromise, payment, settlement, waiver, release, indemnity or
                any other admission (other than a true statement made under
                compulsion of law) in relation to an Accident without our prior
                written consent. If a claim has been approved by Mobility
                Mutual, you must comply with the important conditions in the
                TripCover PDS or Between-Booking Cover PDS and in such cases,
                our rights under this clause to consent to compromises,
                settlements, payments and the like which are made by you, will
                only apply to the extent that any part of a claim is not paid by
                Mobility Mutual.
              </li>
              <li>
                You must promptly give us any papers or other documents that you
                receive concerning an Accident and take reasonable steps to
                ensure that any other person who receives any such papers or
                documents concerning the Accident provides those papers and
                documents promptly to us.
              </li>
              <li>
                By entering into this Agreement, you consent to and authorise us
                to obtain copies of any police witness statements or reports
                made or able to be obtained by you in relation to the Accident,
                theft of the Vehicle or police charges against you.
              </li>
              <li>
                If a claim is lodged with Mobility Mutual for TripCover or
                Between-Booking Cover, you must report to Mobility Mutual in
                accordance with important conditions in the TripCover PDS or
                Between-Booking Cover PDS (as relevant). We are permitted to
                seek information about an Accident on behalf of Mobility Mutual
                if a claim is lodged with Mobility Mutual. Where we request
                information acting on behalf of Mobility Mutual, you agree to
                share it with us for the purposes of assessing a claim under
                your TripCover or Between-Booking Cover.
              </li>
              <li>
                Upon any warning lights or messages becoming illuminated or
                displayed in the Vehicle, or if you become aware of any
                mechanical fault with the Vehicle, you must as soon as
                practicable stop driving, park the Vehicle where safe to do so
                and contact us or the Roadside Assistance service. You must not,
                for our benefit and the benefit of the Owner, recommence driving
                the Vehicle unless directed to do so by us, the Owner of the
                Vehicle, or the Roadside Assistance service. If there is a
                dispute between a Borrower and the Owner as to whether Damage
                and Loss was caused by the Borrower’s failure to properly attend
                to warning lights or by an underlying mechanical issue, we may
                (but are not obliged to) assist with investigating the cause of
                the issue, and allocate the costs of such investigation, in
                accordance with the Damage Policy.
              </li>
              <li>
                If a Vehicle suffers a mechanical fault or breakdown while you
                are driving it, you must:
                <ol className={styles.alpha}>
                  <li>
                    report it to us by phone and also call the Roadside
                    Assistance provider; and
                  </li>
                  <li>
                    wait with the Vehicle until Roadside Assistance attends the
                    Vehicle.
                  </li>
                </ol>
              </li>
              <li>
                If there is a mechanical issue at the start of a Booking prior
                to you commencing driving, you must call us to report the issue.
              </li>
              <li>
                You must not, for the benefit of the Owner, attempt to jump
                start the Vehicle, or use the Vehicle to jump start any other
                vehicle.
              </li>
              <li>
                Roadside Assistance charges as set out in the Fee Schedule will
                be passed through to the Member who, in our reasonable opinion,
                is responsible for the need for the callout.
              </li>
              <li>
                The cost of replacing a battery that cannot be recharged will be
                charged to the Owner’s account.
              </li>
              <li>
                If the Vehicle needs to be towed, the cost will be charged to
                the party who, in our reasonable opinion, is responsible for the
                need for towing, except where the Borrower has travelled further
                than 500 kilometres from the Vehicle’s Booking location without
                the express permission of the Owner and us, in which case the
                Borrower will be responsible for the cost of towing, regardless
                of fault.
              </li>
              <li>
                The Borrower is responsible for returning the Vehicle to its
                Booking location following any tow.
              </li>
              <li>
                Neither we nor the Vehicle’s Owner will provide any compensation
                or reimbursement for costs or losses you incur as a result of a
                Vehicle being inoperable or unavailable for any reason. We
                recommend that you ensure that you have suitable private travel
                insurance in place to cover such eventualities.
              </li>
            </ol>
          </Paragraph>
        </li>

        <li className={styles.title}>
          REFUELLING
          <Divider></Divider>
          <Paragraph className={styles.body}>
            <ol className={styles.decimal}>
              <li>
                If the Vehicle needs fuel during your trip or in order to return
                the Vehicle with the fuel tank at least ¼ full, you must pay for
                the fuel and then seek reimbursement from us by uploading or
                emailing us a tax invoice for the fuel purchase. We will charge
                the Owner and pass the reimbursement through to you.
              </li>
              <li>
                {' '}
                If you do not submit a valid tax invoice within three months of
                the date of the fuel purchase, then we will not charge the Owner
                or reimburse you.
              </li>
              <li>
                You must check whether the Vehicle takes diesel or petrol fuel
                and the fuel octane number specified by the Owner before
                refuelling, and fill the fuel tank with the fuel specified in
                the Owner’s instructions. You will be fully liable to the Owner
                for the costs of removing wrong fuel from the Vehicle and any
                damage caused by using the wrong fuel type in the. Additional
                fees may apply under the Fee Schedule.
              </li>
            </ol>
          </Paragraph>
        </li>
        <li className={styles.title}>
          RETURNING THE VEHICLE
          <Divider></Divider>
          <Paragraph className={styles.body}>
            <ol className={styles.decimal}>
              <li>
                At the end of the Booking Period, you must, for our benefit and
                the benefit of the Owner and other Members:
                <ol className={styles.alpha}>
                  <li>
                    return the Vehicle to the same parking space from which it
                    was collected (if the Vehicle has a dedicated parking
                    space), or to an unrestricted parking space as close as
                    possible to its specified home location for Vehicles
                    collected from a public space, and follow any specific
                    parking instructions given for the Vehicle;
                  </li>
                  <li>ensure that the fuel tank is at least ¼ full;</li>
                  <li>
                    ensure that the Vehicle is no less clean and tidy than when
                    you started the Booking;
                  </li>
                  <li>close all of the windows;</li>
                  <li>
                    ensure that all lights and accessories are turned off;
                  </li>
                  <li>
                    check that you have removed any of your personal possessions
                    from the Vehicle;
                  </li>
                  <li>
                    if a Lockbox is usually located on the Vehicle, put it back
                    in place;
                  </li>
                  <li>
                    lock the Vehicle with the key then secure the key in the
                    Lockbox (for Instant Keys Vehicles) or return it as directed
                    by the Owner (for Key Handover Vehicles) - the Vehicle is
                    not considered to be returned until and unless the key is
                    also correctly returned;
                  </li>
                  <li>
                    take photographs that clearly show all exterior surfaces and
                    the interior of the Vehicle and upload them in accordance
                    with the Damage Policy; and
                  </li>
                  <li>
                    if the Vehicle is a Key Handover Vehicle, take and upload
                    photos of the odometer; and
                  </li>
                  <li>
                    comply with any other requests or instructions given by the
                    Owner or us.
                  </li>
                </ol>
              </li>
              <li>
                If you do not do all of these things, you may be held
                responsible for Damage and Loss to the Vehicle and/or charged an
                amount in accordance with the Fee Schedule. These amounts may be
                payable to us for our account or payable to us on behalf of the
                Owner.
              </li>
              <li>
                If you use a Vehicle outside of a valid Booking Period,
                including if you fail to return the Vehicle and its key within 6
                hours after the end of the Booking Period, this will be taken to
                be a theft of the Vehicle and we or the Owner may:
                <ol className={styles.alpha}>
                  <li>report the Vehicle as being stolen; and/or</li>
                  <li>
                    take steps to locate, recover and repossess the Vehicle, or
                    engage a third party to recover the Vehicle, without further
                    notice to you.
                  </li>
                </ol>
              </li>
              <li>
                If the Vehicle is found illegally parked, apparently abandoned
                or is used or obtained as prohibited under this Agreement, we
                may, after making reasonable attempts to contact you, recover
                the Vehicle or engage a third party to recover the Vehicle. To
                the extent permitted by law, you waive any right to any hearing
                or to receive any notice or legal process as a precondition of
                our recovering the Vehicle in accordance with this clause 13.4.
                Nothing in this clause is intended to limit an Owner’s legal
                rights against a Borrower.
              </li>
              <li>
                You must pay to us immediately on demand any costs we reasonably
                incur in recovering a Vehicle under clause 13.3 or 13.4.
              </li>
            </ol>
          </Paragraph>
        </li>

        <li className={styles.title}>
          REVIEWS
          <Divider></Divider>
          <Paragraph className={styles.body}>
            <ol className={styles.decimal}>
              <li>
                Providing reviews about other Members and Vehicles is important
                to give other Members as much information about the Service as
                possible. Both Borrowers and Owners are requested to submit
                reviews following the completion of each Booking.
              </li>
              <li>
                {' '}
                You must abide by the Review Policy published on our Website
                when providing feedback, including by not posting any abusive,
                defamatory, obscene or intimidating material or statements.
              </li>
            </ol>
          </Paragraph>
        </li>

        <li className={styles.title}>
          LIABILITY FOR PARKING AND DRIVING INFRINGEMENTS
          <Divider></Divider>
          <Paragraph className={styles.body}>
            <ol className={styles.decimal}>
              <li>
                If an Infringement Notice is issued in relation to an
                infringement occurring during a time which you have possession
                of a Vehicle, or as a result of your failure to leave the
                Vehicle in a legal, unrestricted parking space at the end of a
                Booking Period, then you are responsible to the Owner for
                payment of the Infringement Notice and any demerit points will
                accrue to your driver licence.
              </li>
              <li>
                You must pay, for the benefit of the Owner, any Infringement
                Notice that you are responsible for in full on or before the
                date that payment is due. If either we, the Owner or the Owner’s
                authorised representative receives an Infringement Notice that
                you are responsible for, then:
                <ol className={styles.alpha}>
                  <li>
                    we, the Owner or the Owner’s authorised representative will
                    inform the relevant authority that you were the driver of
                    the Vehicle at the time of the Infringement and the
                    authority will then issue the Infringement Notice to you;
                  </li>
                  <li>
                    you consent to us providing your personal information to the
                    Owner or their authorised representative for the purpose of
                    informing the relevant authority, or providing your personal
                    information directly to the relevant authority; and
                  </li>
                  <li>
                    we may charge you an administration fee for each
                    Infringement Notice received, in accordance with the Fee
                    Schedule.
                  </li>
                </ol>
              </li>
              <li>
                You will be responsible to the Owner for the full cost of
                recovery and any damage to the Vehicle should the Vehicle be
                damaged, seized, towed or impounded whilst in your possession or
                as a result of your failure to leave the Vehicle in a legal,
                unrestricted parking space at the end of a Booking Period. These
                costs will be charged in addition to any additional late fees
                and/or extended Booking fees resulting from a late return of the
                Vehicle.
              </li>
              <li>
                If you dispute your liability for an Infringement Notice, then
                you may raise the dispute with us and we will deal with it in
                accordance with the Complaints and Dispute Resolution Policy.
              </li>
            </ol>
          </Paragraph>
        </li>

        <li className={styles.title}>
          LIABILITY FOR TOLLS
          <Divider></Divider>
          <Paragraph className={styles.body}>
            <ol className={styles.decimal}>
              <li>
                You will be liable for payment of any Tolls incurred in relation
                to the use of a Vehicle during the period for which you have use
                of that Vehicle.
              </li>
              <li>
                Your liability for the Toll will commence at the time the Toll
                is incurred. For Instant Keys Vehicles, we will provide credit
                to you for the amount of the Toll from that time until the time
                that payment for the Toll falls due under your monthly invoice.
                For Key Handover Vehicles you will be liable to the Owner for
                any Tolls that the Owner’s toll statement shows were incurred
                while you had possession of the Vehicle.
              </li>
              <li>
                If you dispute a Toll that has been charged to you or for which
                we have attributed responsibility to you, then you can ask us to
                review our records to reassess liability for the Toll.
              </li>
            </ol>
          </Paragraph>
        </li>
        <li className={styles.title}>
          FEES AND COSTS
          <Divider></Divider>
          <Paragraph className={styles.body}>
            <ol className={styles.decimal}>
              <li>
                You agree to pay to us (for our account or in certain cases as
                payment collection agent for Owners or for Mobility Mutual):
                <ol className={styles.alpha}>
                  <li>
                    any Membership Fee applicable to the Membership Plan that
                    you have selected;
                  </li>
                  <li>
                    any additional fees, charges and penalties in accordance
                    with this Agreement and the Policies (or notified by us in
                    accordance with the Agreement from time to time), including
                    without limitation costs relating to any Infringements,
                    Tolls, fuel charges for which you are responsible, any
                    amounts owing for Damage and Loss Liability or other
                    liabilities arising from your use of the Service and from
                    your use of Vehicles;
                  </li>
                  <li>
                    all fees and charges that are incurred in relation to the
                    use of the Service using your Member ID, even if you did not
                    authorise the use of your Member ID; and
                  </li>
                  <li>
                    GST and all other taxes or levies on any of the amounts
                    payable under this Agreement.
                  </li>
                </ol>
              </li>
              <li>
                To calculate Fees and Charges, we will use billing information
                generated or received by us, which may include:
                <ol className={styles.alpha}>
                  <li>information collected by the Booking System;</li>
                  <li>
                    information collected in connection with the use of a
                    Tolling Device;
                  </li>
                  <li>
                    information recorded in photographs of the odometer of a
                    Vehicle;
                  </li>
                  <li>receipts submitted to us; and</li>
                  <li>
                    any other information that is relevant to determining the
                    Fees and Charges applicable to your use of the Service.
                  </li>
                </ol>
              </li>
              <li>
                We may bill you in advance or arrears for some or all of the
                Fees and Charges payable by you under this Agreement. We will
                try to include all Fees and Charges for the relevant billing
                period on your invoice. However, this is not always possible and
                we may include these unbilled Fees and Charges in one or more
                later Invoices.
              </li>
              <li>
                If you Book a Key Handover Vehicle and do not take and upload
                valid photos of the odometer at the start and end of your
                Booking, then we may bill you for distance charges based on the
                Vehicle Owner’s photos or our estimate.
              </li>
            </ol>
          </Paragraph>
        </li>
        <li className={styles.title}>
          DRIVING CREDITS
          <Divider></Divider>
          <Paragraph className={styles.body}>
            <ol className={styles.decimal}>
              <li>
                From time to time, you may be issued with Driving Credits, which
                can be used to cover the cost of time and distance Booking
                charges. Driving Credits cannot be used to pay for other
                expenses such as Membership Fees, Fines, or Damage and Loss
                Liability.
              </li>
              <li>
                Driving Credits may have an expiry date and are not transferable
                or redeemable for cash.
              </li>
            </ol>
          </Paragraph>
        </li>
        <li className={styles.title}>
          DEPOSITS, INVOICING AND PAYMENT
          <Divider></Divider>
          <Paragraph className={styles.body}>
            <ol className={styles.decimal}>
              <li>
                We may charge a deposit to your Card before a Booking Period
                commences.
              </li>
              <li>
                We reserve the right to charge you for all or part of the
                estimated charges relating to your Booking Period, including
                amounts that we collect on behalf of Mobility Mutual, prior to
                commencement of the Booking Period and/or in instalments during
                the Booking Period.
              </li>
              <li>
                We will issue Invoices to you on a regular basis showing your
                Membership Fees and itemised Toll charges payable by you in
                accordance with this Agreement as well as a summary of all
                Booking charges and payments for the relevant period.
              </li>
              <li>
                If the Invoice shows an amount owing by you to us or to an
                Owner, we will charge your Card for that amount on the date
                shown in the Invoice.
              </li>
              <li>
                If you fail to pay the amounts that you owe under this Agreement
                within the time allowed for payment (including if the Card that
                you have provided to us for payment purposes is declined by the
                Card issuer or bank or if you request a chargeback from your
                Card issuer or bank), then at our discretion, we may:
                <ol className={styles.alpha}>
                  <li>charge a reasonable handling fee;</li>
                  <li>
                    charge interest on the outstanding amount, calculated daily
                    at the rate equal to Westpac Banking Corporation standard
                    business overdraft rate plus 2%;
                  </li>
                  <li>
                    suspend provision of the Service to you, including your
                    membership of Mobility Mutual and your access to TripCover
                    or Between-Booking Cover, by any means including by
                    restricting or disabling your access to our Website and
                    preventing you from making or accepting Bookings;
                  </li>
                  <li>
                    {' '}
                    in our absolute discretion, pay to an Owner the amount that
                    you owe to them in advance of collecting such amount from
                    you, in which case such amount will be owed by you to us
                    instead of to the Owner, and/or
                  </li>
                  <li>cancel your Membership in accordance with clause 24.1</li>
                </ol>
              </li>
              <li>
                If you are in default of any obligation to pay money to us or to
                an Owner or to Mobility Mutual under this Agreement, you must
                indemnify us for any costs that we pay to any person on your
                behalf or that we incur on our behalf or on behalf of the Owner
                or Mobility Mutual in taking action against you to recover that
                amount.
              </li>
              <li>
                If you wish to query or dispute the amounts shown on an Invoice,
                you may do so in accordance with the procedure set out in the
                Complaints and Dispute Resolution Policy.
              </li>
            </ol>
          </Paragraph>
        </li>
        <li className={styles.title}>
          CREDIT AND DEBIT CARD CHARGES
          <Divider></Divider>
          <Paragraph className={styles.body}>
            <ol className={styles.decimal}>
              <li>
                You authorise us to charge the Card in respect of all Fees and
                Charges due and payable under this Agreement, including amounts
                that we collect on behalf of Mobility Mutual.
              </li>
              <li>
                Where the Card is in your name, you warrant that the Card is
                Yours and you (whether alone or with another person or other
                people) are responsible for all amounts charged or debited to
                that Card. Where the Card is not in your name, you warrant that
                you are authorised to permit and authorise us to charge the Card
                in accordance with this Agreement.
              </li>
            </ol>
          </Paragraph>
        </li>
        <li className={styles.title}>
          COMMUNICATION BETWEEN Jaunt AND MEMBERS AND BETWEEN MEMBERS
          <Divider></Divider>
          <Paragraph className={styles.body}>
            <ol className={styles.decimal}>
              <li>
                We may send notices and Invoices under this Agreement to you by
                email or post. You must ensure that the email and postal address
                that you have provided to us are correct at all times, and you
                must check your email regularly.
              </li>
              <li>
                A notice or bill sent under this Agreement is taken to have been
                received by you or by us (as relevant):
                <ol className={styles.alpha}>
                  <li>
                    if sent by ordinary post, on the date two business days
                    after it is posted; or
                  </li>
                  <li>
                    if sent by email, four working hours after the time it is
                    sent (unless the sender receives an automated notice that
                    delivery did not occur or has been delayed).
                  </li>
                </ol>
              </li>
              <li>
                You agree that we may provide your name, email address and
                telephone number to another Member if you have made a Booking to
                use their Vehicle or if they have made a Booking to use your
                Vehicle, so that they can communicate directly with you in
                relation to that Booking. You must not use the contact details
                of other Members for marketing or any other unauthorised
                purpose.
              </li>
              <li>
                If you have any questions about this Agreement or the use of the
                Service (including financial services provided by us), you can
                contact us on (02) 8035 8000 or by chat from our Website, or
                send an email to members@jaunt.com.au.
              </li>
            </ol>
          </Paragraph>
        </li>
        <li className={styles.title}>
          PRIVACY
          <Divider></Divider>
          <Paragraph className={styles.body}>
            <ol className={styles.decimal}>
              <li>
                We will comply with all relevant privacy legislation and our
                Privacy Policy in relation to your personal information. You can
                find our Privacy Policy on our Website.
              </li>
              <li>
                The terms of our Privacy Policy form part of this Agreement. Our
                Privacy Policy sets out how we collect, use, store and disclose
                your personal information.
              </li>
              <li>
                If we do not collect personal information from you, we will not
                be able to provide our Services to you and if any of the
                personal information you provide is incomplete or inaccurate,
                the quality of our services may be compromised.
              </li>
              <li>
                You consent to us providing your personal information to other
                Members and to the relevant authorities as set out in clauses
                15.2 and 21.3.
              </li>
              <li>
                You can tell us if you do not consent to our use of such
                information, or if you do not wish to receive such information,
                or if you have any questions about our Privacy Policy, by
                calling us on (02) 1800 xxx xxx or by sending an email to
                members@jaunt.com.au.
              </li>
              <li>
                By entering into this Agreement and by providing us with
                personal information, you represent to us that you have read,
                and agree to, the terms of our Privacy Policy. When we collect
                personal information in relation to the discretionary risk
                products offered by Mobility Mutual, we do so in accordance with
                the Mobility Mutual Privacy Policy.
              </li>
            </ol>
          </Paragraph>
        </li>
        <li className={styles.title}>
          DISPUTE RESOLUTION
          <Divider></Divider>
          <Paragraph className={styles.body}>
            <ol className={styles.decimal}>
              <li>
                If you have a complaint or dispute in relation to this
                Agreement, you can ask us to consider the complaint in
                accordance with our Complaints and Dispute Resolution Policy,
                which is available on our Website.
              </li>
              <li>
                We reserve the right, but have no obligation, to monitor or
                assist the resolution of disputes between you and other Members.
              </li>
              <li>
                Complaints in relation to Trip Cover or Between-Booking Cover
                will be handled in accordance with the complaints handling
                process in the relevant TripCover PDS or Between-Booking Cover
                PDS.
              </li>
            </ol>
          </Paragraph>
        </li>
        <li className={styles.title}>
          CANCELLATION AND SUSPENSION OF MEMBERSHIP
          <Divider></Divider>
          <Paragraph className={styles.body}>
            <ol className={styles.decimal}>
              <li>
                We may immediately cancel or suspend your Membership at any time
                by notice by email if you:
                <ol className={styles.alpha}>
                  <li>
                    breach the terms of this Agreement or any of the Policies
                    and the breach cannot be remedied or, if the breach can be
                    remedied, you have not remedied the breach within 5 business
                    days after we give you notice of the breach;
                  </li>
                  <li>
                    in our reasonable opinion, commit any illegal, dishonest or
                    wrongful act, abuse or harass our staff or other Members, or
                    pose a threat to the safety or wellbeing of our staff or
                    other Members or to any Vehicle; or
                  </li>
                  <li>
                    fail to maintain your eligibility for membership under
                    clause 3 or your membership with Mobility Mutual is
                    cancelled;
                  </li>
                  <li>
                    fail to make a payment that is due under this Agreement
                    within 2 business days after we give you written notice of
                    the payment default.
                  </li>
                </ol>
              </li>
              <li>
                We may cancel your Membership with no less than 30 days’ notice
                for any reason not listed in clause 24.1.
              </li>
              <li>
                You may cancel your Membership at any time to take effect at the
                end of the current month.
              </li>
              <li>
                You may cancel your Membership with immediate effect in
                exceptional circumstances with our agreement.
              </li>
              <li>
                If your Membership is cancelled, either by you or by us, then:
                <ol className={styles.alpha}>
                  <li>
                    you must not use the Service on or after the date of
                    cancellation;
                  </li>
                  <li>
                    Mobility Mutual may cancel your membership of the mutual and
                    your discretionary protection;
                  </li>
                  <li>
                    we will issue a final Invoice within 60 business days of the
                    cancellation of your Membership and either make payment to
                    you or charge your Card for amounts owing by you, in
                    accordance with this Agreement; and
                  </li>
                  <li>
                    this Agreement will terminate on the date that we have
                    received final payment for all amounts owing by you to us
                    under this Agreement.
                  </li>
                </ol>
              </li>
              <li>
                Termination of this Agreement does not affect any accrued rights
                or liabilities up to (and including) the date of termination.
              </li>
            </ol>
          </Paragraph>
        </li>
        <li className={styles.title}>
          GOVERNING LAW, OTHER
          <Divider></Divider>
          <Paragraph className={styles.body}>
            <ol className={styles.decimal}>
              <li>
                This Agreement is governed by the laws of New South Wales, and
                each party submits to the non-exclusive jurisdiction of the
                courts of that state.
              </li>
              <li>
                you may only assign this Agreement or a right under this
                Agreement with our prior written consent. we may assign this
                Agreement or a right under this Agreement by written notice to
                you.
              </li>
              <li>
                If the whole or any part of a provision of this Agreement is
                invalid or unenforceable in a jurisdiction it must, if possible,
                be read down for the purposes of that jurisdiction so as to be
                valid and enforceable. If however, the whole or any part of a
                provision of this Agreement is not capable of being read down,
                it is severed to the extent of the invalidity or
                unenforceability without affecting the remaining provisions of
                this Agreement or affecting the validity or enforceability of
                that provision in any other jurisdiction.
              </li>
              <li>
                Except where this Agreement expressly states otherwise, this
                Agreement does not create a relationship of employment, trust,
                agency or partnership between the parties.
              </li>
            </ol>
          </Paragraph>
        </li>
        <li className={styles.title}>
          DEFINITIONS AND INTERPRETATION
          <Divider></Divider>
          <Paragraph className={styles.body}>
            <ol className={styles.decimal}>
              <li>
                The following definitions apply to this Agreement: “Accident”
                means any collision between the Vehicle and any other object,
                including another vehicle, or any other incident or occurrence
                that results in the Vehicle being damaged, lost or destroyed.
                “Agreement” means the agreement comprising the Member Agreement,
                the Owner Agreement (for Members who are Car Owners), the
                Policies and the Fee Schedule. “Authorised Driver” means a
                Member who drives a Vehicle during a Booking. “Availability
                Schedule” means the times that an Owner has defined during which
                their Vehicle is available to other Members to Book.
                “Between-Booking Cover” means the discretionary risk protection
                product issued by Mobility Mutual to Owners who are also members
                of Mobility Mutual to protect them for Damage and Loss when a
                Vehicle is used incidentally outside of a Booking.
                “Between-Booking Cover PDS” means the combined Product
                Disclosure Statement, Protection Wording and Financial Services
                Guide issued by Mobility Mutual for Between- Booking Cover, and
                includes any supplementary or replacement disclosures applicable
                from time to time. “Booking” means an instance where a Borrower
                uses the Service to reserve a Vehicle belonging to an Owner and,
                in the case of Key Handover Vehicles only, where the Owner of
                the Vehicle has confirmed the Booking. “Booking Fee” means the
                amount charged by us to process each Booking. “Booking Period”
                means the period of a Booking (including any amendments to that
                period that are made and accepted through the Booking System).
                “Booking System” means the technology that coordinates Bookings
                and payments and, in the case of Instant Keys Vehicles, performs
                the remote management of the Lockbox System. “Borrower” means a
                Member who borrows, or makes a Booking to borrow, a Vehicle.
                “Borrowing Plan” means a Membership Plan for borrowing Vehicles
                through the Service. “Jaunt” (also “we”, “us” or “our”) means
                Jaunt Australia Pty Ltd ABN 53 163 596 530. “Card” means the
                credit or debit card that a Member has selected as their primary
                method of payment to us for amounts owing to us or that we
                collect as agent for Owners. “Damage and Loss” means:
                <ol className={styles.alpha}>
                  <li>
                    damage to the Vehicle that requires repair or replacement
                    (excluding Wear and Tear);
                  </li>
                  <li>
                    loss arising from theft of the Vehicle or fire damage to the
                    Vehicle;
                  </li>
                  <li>towing, storage and recovery charges;</li>
                  <li>
                    fees and charges relating to the assessment or
                    quantification of the loss or damage; and
                  </li>
                  <li>Third Party Loss,</li>
                  <li>including any applicable taxes.</li>
                </ol>
                Damage and Loss Liability” means the amount you must pay if you
                are responsible for Damage and Loss. “Delisted” means, in
                respect of a Vehicle, that the Vehicle is made permanently
                unavailable for Borrowers to Book through the Service (whether
                by the Owner or by us). “Driving Credit” means credit provided
                to Members that can be used to pay for Bookings. “Driving
                History” means the driving-related events in your past that we
                will use to help assess your suitability for the Service.
                “Eligibility Requirements” means, in relation to Members, the
                minimum requirements set out in clause 3 that Members must meet
                in order to obtain and maintain Membership in the Service; and
                in relation to Vehicles, means the minimum requirements set out
                in clause 27 that Vehicles must meet in order to be part of the
                Service. “Event” means an event where Damage and Loss occurs at
                the same address and time. For example, once you have picked up
                a Vehicle, if you scrape it when reversing out of the driveway
                it will be one Event. If, later during the Booking Period, you
                have a collision with a third-party driver, it will be a second
                Event. “Excess” means the amount that Mobility Mutual, or Jaunt
                as its manager, will charge your Card if a claim is approved by
                Mobility Mutual, as set out in the terms and conditions for the
                discretionary protection (TripCover or Between-Booking Cover)
                and as stated in your Protection Schedule. “Fee Schedule” means
                the pages on our Website setting out the fees or charges we may
                charge you in connection with your Membership or your use of the
                Service, as updated, published on our Website and notified to
                Members from time to time. “Fees and Charges” means the set of
                charges levied on Members arising from their Membership in, and
                use of the Service. “GPS” means Global Positioning System.
                “Infringement Notice” means the notification of any driving or
                parking offence. “Invoice” means the itemised report of a
                Member’s use of the Service and will include details of any Fees
                and Charges incurred and any payments made. “Instant Keys
                Vehicle” means a Vehicle that is fitted with the Lockbox System
                or can be unlocked and used by a Borrower using our technology
                and the Vehicle’s technology without the need for the Owner or a
                person authorised by the Owner to deliver to the Borrower the
                car keys. “Key Handover Vehicle” means a Vehicle that is not
                fitted with the Lockbox System. “Listed” means, in respect of a
                Vehicle, that the Vehicle is available for Borrowers to Book
                through the Service. “Lockbox System” means the combination of
                the digital lockbox device that holds the car keys (the
                “Lockbox”), the GPS technology and other peripheral equipment
                installed in a Vehicle to enable it to participate in the
                Service. “Member” means any person whose Membership Application
                has been accepted by us and whose Membership has not been
                cancelled, and may be a Borrower or an Owner. “Member Profile”
                means the information about you that is stored on our systems.
                “Membership” means the state of being an active Member of the
                Service. “Membership Application” means an application for
                Membership, which may be made electronically, and includes all
                of the information provided by the prospective Member in that
                form or in connection with that application; “Membership Fee”
                means the monthly amount payable by a Member to us according to
                the Member’s selected Membership Plan(s). “Membership Plan”
                means a Sharing Plan or Borrowing Plan. Different plans are
                available to Borrowers and Owners and their costs and inclusions
                can be viewed on our Website. “Mobility Mutual” means Mobility
                Mutual Limited (ACN 653 040 647). “Owner” means a Member who
                makes their Vehicle available to other Members for sharing
                through the Service. “Owner Agreement” means the additional set
                of terms and conditions specific to Owners within this
                Agreement. “Owner Guarantee” means the guarantee given by us to
                Owners who List their Vehicles as part of the Services, as
                published and amended by us from time to time. “Policies” means
                each of the policies as published or made available on our
                Website from time to time relating to the use of the Service by
                Members, and includes:
                <ol className={styles.alpha}>
                  <li>the Complaints and Dispute Resolution Policy;</li>
                  <li>the Damage Policy;</li>
                  <li>the Privacy Policy; and</li>
                  <li>the Website Terms of Use</li>
                </ol>
                “Roadside Assistance” means the 24/7 breakdown service provided
                to Vehicles enrolled in the Service. “Service” means the service
                of facilitating peer-to-peer car sharing. “Sharing Plan” means a
                Membership Plan for Owners sharing a Vehicle through the
                Service. “Third Party Loss” means:
                <ol className={styles.alpha}>
                  <li>
                    legal liability to pay compensation for accidental loss or
                    damage to someone else’s property arising out of an Accident
                    where you or an Authorised Driver were at fault and the
                    legal liability arises out of the use of a Vehicle;
                  </li>
                  <li>
                    legal liability arising out of the use of a Vehicle that
                    results in death or bodily injury to another person (not
                    including any person who is driving or in charge of the
                    Vehicle or a member of your family), provided that the event
                    or series of related events that gave rise to the legal
                    liability, or any part of it, is not covered or indemnified
                    in any way by any statutory or compulsory insurance or any
                    compensation scheme or fund (except where the lack of
                    coverage or indemnification results from your failure to
                    insure or register your Vehicle or to comply with the
                    requirements of such a policy, fund or scheme);
                  </li>
                  <li>
                    costs of defending a legal claim for compensation for loss
                    or damage described in (a), provided that we have approved
                    the costs and expenses prior to them being incurred.
                  </li>
                </ol>
                “Toll” means an amount charged by the owner or operator of a
                toll road, and includes any penalties, fees or other amounts
                charged in relation to the late payment of a toll. “Tolling
                Device” means any tag or device installed in a Vehicle for
                recording electronic Tolls. “Trip Cover” means the discretionary
                risk protection product issued by Mobility Mutual to Borrowers
                who are also members of Mobility Mutual to protect them for
                Damage and Loss when they borrow or hire a Vehicle. “TripCover
                PDS” means the combined Product Disclosure Statement, Protection
                Wording and Financial Services Guide issued by Mobility Mutual
                for TripCover, and includes any supplementary or replacement
                disclosures applicable from time to time. “Website” means the
                website www.carnextdoor.com.au and any associated mobile sites
                and sub-domains, and mobile apps. “Vehicle” means a vehicle
                including its standard modifications and accessories as supplied
                by the manufacturer, keys, remote opening devices and Tolling
                Device, made available for sharing through the Service. “Vehicle
                Profile” means the photographs and other information maintained
                by the Owner about a Vehicle that is available to other Members
                through our Website. A subset of the information will also be
                available to the general public through our Website. “Your
                Vehicle” means a Vehicle that an Owner Lists for sharing through
                the Service. “Wear and Tear” means the deterioration in the
                condition of a Vehicle that occurs under normal car-sharing use
                and conditions, as defined in the Jaunt Damage Policy.
              </li>
              <Title level={4}>OWNER AGREEMENT</Title>
              <li>
                This section applies to you as additional terms and conditions
                and forms part of your Member Agreement, if you are an Owner and
                you apply to List a Vehicle for Sharing through the Service. The
                definitions contained on the Member Agreement apply to this
                Owners Agreement.
              </li>
            </ol>
          </Paragraph>
        </li>
        <li className={styles.title}>
          VEHICLE ELIGIBILITY CRITERIA
          <Divider></Divider>
          <Paragraph className={styles.body}>
            <ol className={styles.decimal}>
              <li>
                A Vehicle that is made available at any time for sharing through
                the Service must (unless we agree otherwise):
                <ol className={styles.alpha}>
                  <li>
                    be registered in the state or territory in which it is made
                    available for sharing;
                  </li>
                  <li>
                    be in a roadworthy condition at all times, and be submitted
                    for roadworthiness inspections in accordance with any
                    requirements of a regulator in your state or territory;
                  </li>
                  <li>
                    be well maintained, with maintenance properly scheduled and
                    recorded using the applicable manufacturer’s maintenance
                    schedule as a guide;
                  </li>
                  <li>be manufactured during or after the year 2001;</li>
                  <li>have been driven fewer than 400,000 kilometres;</li>
                  <li>
                    not have been altered in a way that materially changes the
                    performance, appearance or purpose of the Vehicle, except
                    with our written permission;
                  </li>
                  <li>
                    have a power rating of less than 200 kilowatts (250
                    kilowatts for 4WDs);
                  </li>
                  <li>have seating for a maximum of 12 passengers;</li>
                  <li>be a private passenger vehicle with four wheels; and</li>
                  <li>
                    have a market value less than $40,000 if listed in Victoria
                    and $50,000 in other states (noting that we may also decline
                    or remove Vehicles with a lower market value threshold from
                    the Service, or require them to implement additional
                    security measures, based on our security and risk management
                    requirements).
                  </li>
                </ol>
              </li>
              <li>
                You agree that:
                <ol className={styles.alpha}>
                  <li>
                    we may independently verify your Vehicle’s registration
                    details;
                  </li>
                  <li>
                    your Vehicle meets all of the Eligibility Requirements set
                    out at clause 27.1; and
                  </li>
                  <li>
                    if you are not the registered owner of your Vehicle, the
                    registered owner has given you permission to make your
                    Vehicle available for sharing through the Service. You are
                    fully responsible for securing the owner’s permission to
                    list the Vehicle with Jaunt and complying with the scope of
                    any permission granted.
                  </li>
                </ol>
              </li>
              <li>
                You may, with our express permission, List a Vehicle while you
                hold a restricted, learner’s or provisional driver licence or
                while you are not licenced to drive. You must disclose to us any
                changes or restrictions to your driver licence, including if at
                any time you hold a learner’s or provisional driver licence, and
                must not use the Service to book or drive any Vehicle other than
                your own Vehicle while you hold a restricted, learner’s or
                provisional driver licence or while you are not licenced to
                drive.
              </li>
            </ol>
          </Paragraph>
        </li>
        <li className={styles.title}>
          OWNER RESPONSIBILITIES
          <Divider></Divider>
          <Paragraph className={styles.body}>
            <ol className={styles.decimal}>
              <li>
                As an Owner of a Vehicle that is offered for sharing through
                Jaunt, you must:
                <ol className={styles.alpha}>
                  <li>
                    ensure that the Vehicle is maintained, serviced and repaired
                    so that it is in a roadworthy condition at all times. If we
                    have any concerns about the safety or maintenance of your
                    car, we may require you to provide proof of maintenance or
                    roadworthiness, and may suspend your Vehicle from the
                    Service until acceptable proof is received;
                  </li>
                  <li>
                    ensure that the Vehicle is safely operable by any Borrower,
                    and not place in the Vehicle any modifications, devices,
                    equipment or other items that may interfere with the safe
                    operation of the Vehicle;
                  </li>
                  <li>
                    check the amount of engine oil and coolant in the Vehicle at
                    appropriate intervals and maintain them at the levels
                    recommended in the Vehicle manufacturer’s specifications or
                    otherwise as required to maintain the Vehicle’s efficient
                    performance;
                  </li>
                  <li>
                    ensure there is a serviceable spare tyre available at all
                    times along with the necessary tools to change the wheel
                    (unless the Vehicle is fitted with runflat tyres);
                  </li>
                  <li>
                    maintain the correct registration for the Vehicle and comply
                    with any laws or regulations applicable to Vehicles that are
                    hired out without a driver in your state or territory;
                  </li>
                  <li>
                    park your Vehicle in a publicly accessible, legal parking
                    space at all times when it is made available for sharing;
                  </li>
                  <li>
                    follow any reasonable direction given by us in relation to
                    sharing your vehicle that, in our reasonable view, is
                    necessary for to avoid harm to our reputation and brand;
                  </li>
                  <li>
                    ensure that the fuel tank is more than 1/4 full at the start
                    of any Booking Period;
                  </li>
                  <li>
                    ensure that the Vehicle is clean at the start of each
                    Booking Period, and not smoke in the Vehicle;
                  </li>
                  <li>
                    ensure that if the Vehicle is fitted with child
                    restraint(s), these are undamaged and correctly installed in
                    accordance with the manufacturer’s instructions;
                  </li>
                  <li>
                    if your Vehicle is an Instant Keys Vehicle, ensure that the
                    Lockbox is accessible by Borrowers at all times when it is
                    made available for sharing,
                  </li>
                  <li>
                    if your Vehicle is an Instant Keys Vehicle and listed in
                    NSW, QLD or VIC, ensure that the Tolling Device is in the
                    Vehicle and in the correct location at all times; and
                  </li>
                  <li>
                    ensure that any dashcams or other recording or tracking
                    devices are operated in accordance with any applicable laws
                    and that their presence is clearly notified to Borrowers in
                    the Vehicle’s public description.
                  </li>
                </ol>
              </li>
              <li>
                You must be contactable by phone or email and able to respond to
                queries at all times during a Booking Period.
              </li>
              <li>
                If you will not be contactable during a Booking Period, then you
                must:
                <ol className={styles.alpha}>
                  <li>
                    provide us with the mobile phone number and email address of
                    a person who is responsible for your Vehicle (your nominated
                    representative); or
                  </li>
                  <li>
                    ensure that your Vehicle is not made available for sharing
                    during that time.
                  </li>
                </ol>
              </li>
              <li>
                You agree that:
                <ol className={styles.alpha}>
                  <li>
                    we may provide your mobile phone number and email address to
                    other Members so that they can contact you directly with any
                    queries relating to sharing your Vehicle; and
                  </li>
                  <li>
                    you will inform us as soon as possible of any changes to
                    your mobile phone number or email address.
                  </li>
                </ol>
              </li>
            </ol>
          </Paragraph>
        </li>
        <li className={styles.title}>
          VEHICLE PROFILES
          <Divider></Divider>
          <Paragraph className={styles.body}>
            <ol className={styles.decimal}>
              <li>
                You will be required to maintain a Vehicle Profile for any
                Vehicle that you have Listed for the Service. This information
                will be available to other Members. The information includes:
                <ol className={styles.alpha}>
                  <li>the Vehicle nickname;</li>
                  <li>the Vehicle description;</li>
                  <li>the Vehicle sharing location;</li>
                  <li>photograph(s) of the Vehicle;</li>
                  <li>the hourly and daily sharing rates; and</li>
                  <li>the Availability Schedule.</li>
                </ol>
              </li>
              <li>
                You will be able to provide special instructions to Borrowers
                concerning access to, or the operation of, the Vehicle. This
                information may be made available on our Website, or
                communicated to Borrowers by in-app message, email or SMS prior
                to the start of a Booking Period.
              </li>
            </ol>
          </Paragraph>
        </li>
        <li className={styles.title}>
          ACCEPTANCE AND CANCELLATION OF BOOKINGS
          <Divider></Divider>
          <Paragraph className={styles.body}>
            <ol className={styles.decimal}>
              <li>
                You must:
                <ol className={styles.alpha}>
                  <li>
                    review the Availability Schedule for your Vehicle regularly;
                  </li>
                  <li>
                    ensure that your Vehicle is available at the times and
                    locations for which it has been Booked and, if your Vehicle
                    is an Instant Keys Vehicle, that the Lockbox containing the
                    Vehicle key is accessible by Borrowers; and
                  </li>
                  <li>
                    pay the charges set out in the Fee Schedule if your Vehicle
                    is not available to any Borrower who has made a Booking for
                    its use.
                  </li>
                </ol>
              </li>
              <li>
                All Bookings are automatically confirmed if the Vehicle is shown
                as available in the Availability Schedule.
              </li>
              <li>
                If you cancel a Borrower’s Booking, you will incur a fee as set
                out in the Fee Schedule.
              </li>
            </ol>
          </Paragraph>
        </li>
        <li className={styles.title}>
          FEES AND INCOME
          <Divider></Divider>
          <Paragraph className={styles.body}>
            <ol className={styles.decimal}>
              <li>
                With respect to the income generated from your Vehicle:
                <ol className={styles.alpha}>
                  <li>
                    All amounts quoted and charged or paid are in Australian
                    Dollars (AUD$);
                  </li>
                  <li>You may set the rates for the use of your Vehicle.</li>
                  <li>
                    You authorise us, as your payment collection agent, to
                    collect amounts owing to you by Borrowers.
                  </li>
                  <li>
                    We will pass through to you a portion of the time and
                    distance fees that Borrowers pay to us for using your
                    Vehicle, and you will owe to us, and we will retain, the
                    remainder of those fees as part of the consideration payable
                    to us in connection with supplying the Service (and the
                    amount retained will include the GST that we must charge you
                    for such supply);
                  </li>
                  <li>
                    The portion that we pass through to you will be determined
                    by your Sharing Plan.
                  </li>
                  <li>
                    We may set off any amounts that you owe to us in connection
                    with this Owner Agreement against amounts that we owe to
                    you.
                  </li>
                  <li>
                    If your Vehicle is unregistered or unroadworthy, not fit for
                    purpose or does not match the Vehicle Profile at any time
                    when it is made available for sharing through the Service,
                    we may refund any amounts paid by the Borrower to you on
                    your behalf and charge you for our lost revenue from
                    Bookings that are cancelled or refunded as a result.
                  </li>
                  <li>
                    In the event that we do not pass through to you any time and
                    distance fees held by us and owing to you in accordance with
                    this Owner Agreement for whatever reason, you acknowledge
                    and agree that you will have recourse only against us, and
                    no claim or action against the Member who used your Vehicle.
                  </li>
                  <li>
                    We will not be required to make any payment to you until we
                    have first received payment of those amounts from the Member
                    or Members who incurred fees or charges in relation to the
                    use of your Vehicle during the relevant billing period.
                  </li>
                  <li>
                    We will make any payments owing to you to the bank account
                    that you nominate in your account via our Website.
                  </li>
                  <li>
                    We will issue invoices to Members on your behalf for amounts
                    owing to you. you must provide us with all information we
                    ask for so we can prepare the invoice. That might include,
                    for example, your ABN.
                  </li>
                </ol>
              </li>
              <li>
                You authorise us to enter into, vary, waive, release and
                terminate agreements with Borrowers to the extent that we do so
                as your payment collection agent, or in any other agency
                capacity for you, without requiring your prior approval.
              </li>
              <li>
                You acknowledge that we may also collect payments from Borrowers
                on behalf of Mobility Mutual in connection with the Borrower’s
                use of your Vehicle, and that these payments are not payable to
                you.
              </li>
            </ol>
          </Paragraph>
        </li>
        <li className={styles.title}>
          INVOICING AND PAYMENT
          <Divider></Divider>
          <Paragraph className={styles.body}>
            <ol className={styles.decimal}>
              <li>
                We will issue Invoices to you on a regular basis showing your
                Membership Fees, Sharing Plan fees, , fees for Between-Booking
                Cover (where you have it) and any Toll charges or fuel charges
                payable by you in accordance with the Agreement, as well as the
                amounts payable to you by Borrowers for the use of your Vehicle
                and the percentage of these amounts owing by you to us.
              </li>
              <li>
                If the Invoice shows a net amount owing by you to us, we will
                charge your Card for that amount on the date shown in the
                Invoice. If the Invoice shows a net amount owing by us to you,
                we will pay the amounts shown in the Invoice by the due date
                shown on the Invoice, in accordance with clause 31.1.
              </li>
              <li>
                Amounts payable under the Fee Schedule are inclusive of GST.
                Where GST is payable on any “taxable supply” as defined for GST
                purposes made to you by us or another person (Supplier) under or
                in connection with the Agreement and the consideration for that
                supply is not stated to be GST inclusive:
                <ol className={styles.alpha}>
                  <li>
                    you must pay an additional amount to the Supplier equal to
                    the amount of the GST payable on that supply (GST Amount),
                    at the same time as any other consideration is to be first
                    provided for that supply; and
                  </li>
                  <li>
                    the Supplier must provide a tax invoice to you for that
                    supply, no later than the time at which the GST Amount for
                    that supply is to be paid in accordance with clause 32.3a).
                  </li>
                </ol>
              </li>
              <li>
                If you are required to quote an Australian Business Number (ABN)
                relating to the income generated from your Vehicle, you must
                notify us. we will supply your ABN to Borrowers on your behalf
                at the time of issuing to Borrowers a receipt for the use of
                your Vehicle.
              </li>
            </ol>
          </Paragraph>
        </li>
        <li className={styles.title}>
          LOCKBOX SYSTEM AND TOLLING DEVICE (INSTANT KEYS VEHICLES ONLY)
          <Divider></Divider>
          <Paragraph className={styles.body}>
            <ol className={styles.decimal}>
              <li>
                If your Vehicle is an Instant Keys Vehicle, we will either
                arrange for installation of the Lockbox System in your Vehicle
                (defined to mean the combination of the digital lockbox device
                that fixes to a car window and holds the car keys (the
                “Lockbox”), the GPS technology and other peripheral equipment
                installed in a Vehicle to enable it to participate in the
                Service), or send the Lockbox System to you to install in your
                Vehicle.
              </li>

              <li>
                If we arrange for installation of the Lockbox System in your
                Vehicle, we will:
                <ol className={styles.alpha}>
                  <li>
                    ensure that, if we carry out the installation, it is carried
                    out by an appropriately trained and qualified person;
                  </li>
                  <li>
                    agree with you on a time and place for the installation of
                    the Lockbox System to take place.
                  </li>
                </ol>
              </li>
              <li>
                If you do not make your Vehicle available and accessible for a
                scheduled appointment for installation of the Lockbox System,
                and have not cancelled or rescheduled the appointment more than
                24 hours in advance, then we may charge you a rescheduling fee
                in respect of the missed appointment.
              </li>
              <li>
                We may arrange for a Tolling Device to be provided for your
                Vehicle. Upon receiving the Tolling Device, you must:
                <ol className={styles.alpha}>
                  <li>
                    remove any other Tolling Device from your Vehicle prior to
                    the Vehicle being driven;
                  </li>
                  <li>install the Tolling Device as per the instructions;</li>
                  <li>
                    ensure that the Tolling Device is in your Vehicle prior to
                    the commencement of each Booking; and
                  </li>
                  <li>
                    pay all Tolls associated with the Tolling Device, other than
                    Tolls incurred by Borrowers while your Vehicle is in their
                    possession.
                  </li>
                </ol>
              </li>
              <li>
                You must not, and must not permit or suffer any other person to,
                tamper with, interfere with, or remove any part of the Lockbox
                System or the Tolling Device that has been installed in your
                Vehicle or any other Member’s Vehicle by or on behalf of us.
              </li>
              <li>
                You must inform us immediately if any part of the Lockbox System
                or the Tolling Device is lost, stolen, damaged or not working
                properly.
              </li>
              <li>
                {' '}
                If any part of the Lockbox System or Tolling Device that we
                provide to you is lost, stolen or damaged, we may charge you a
                fee, as set out in the Fee Schedule.
              </li>
            </ol>
          </Paragraph>
        </li>
        <li className={styles.title}>
          CHECKING THE VEHICLE
          <Divider></Divider>
          <Paragraph className={styles.body}>
            <ol className={styles.decimal}>
              <li>
                You are responsible for checking the Borrower’s photographs of
                your Vehicle from the beginning and end of their possession of
                the Vehicle and for checking your Vehicle as soon as possible
                after you re-take possession of the Vehicle and at a minimum,
                once each week. If you as an Owner find any damage, or if the
                Tolling Device or Lockbox System are missing or damaged, you
                must report it to us promptly.
              </li>
              <li>
                You must report any new damage that you identify in a Borrower’s
                photographs, or submit photographs of any new damage you find
                that is not recorded in a Borrower’s photographs, to us as soon
                as possible after the relevant Booking Period (and, in any
                event, within 42 days of the end of the relevant Booking
                Period).
              </li>
              <li>
                If you report damage to your Vehicle, we will assist with the
                investigation and collection of payment from any liable
                Borrowers who have used your Vehicle within the 42 days prior to
                your report. If the damage occurred outside that period then you
                will be responsible for communicating directly with Borrowers to
                ascertain liability.
              </li>
            </ol>
          </Paragraph>
        </li>
        <li className={styles.title}>
          DAMAGE AND LOSS TO YOUR VEHICLE
          <Divider></Divider>
          <Paragraph className={styles.body}>
            <ol className={styles.decimal}>
              <li>
                Each Borrower must have Trip Cover for each Booking they make to
                cover Damage and Loss when they use your Vehicle. If a Borrower
                is responsible for Damage and Loss under the Member Agreement,
                the amount that they will be required to pay to you will be:
                <ol className={styles.alpha}>
                  <li>
                    the reasonable cost of repairing your Vehicle (which may
                    include the use of used parts or parts that were not made by
                    the original equipment manufacturer); or
                  </li>
                  <li>
                    if your Vehicle is:
                    <ol className={styles.roman}>
                      <li>damaged beyond economic repair; or</li>
                      <li>stolen and not recovered,</li>
                      <li></li>
                    </ol>
                  </li>
                </ol>
                the market value of your Vehicle at the date of the Accident or
                theft, as determined by an independent valuer appointed by us or
                by Mobility Mutual when assessing your claim. If Mobility Mutual
                approves a claim, we will pay for the repairs to Vehicle, on
                behalf of Mobility Mutual in accordance with the terms of the
                TripCover PDS.
              </li>
              <li>
                If you do not agree with the valuation made by the independent
                valuer then you may provide us with evidence to support your
                alternative valuation. The final determination of the market
                value of your Vehicle will be made by us or by Mobility Mutual.
              </li>
              <li>
                If a Borrower fails to lodge a claim with Mobility Mutual when
                the Borrower is liable for Damage and Loss to your Vehicle, we
                will exercise our rights under the Member Agreement to lodge
                that claim as their attorney. If a claim for Damage and Loss to
                Your Vehicle is not approved by Mobility Mutual, the Borrower
                remains liable to you in accordance with the Member Agreement.
              </li>
              <li>
                Where you experience loss of use of your Vehicle as a direct
                result of Damage and Loss caused to the Vehicle by a Borrower,
                the Borrower may be required to make a payment to you to
                compensate you for that loss of use, calculated as set out in
                the Damage Policy in accordance with the Member Agreement.
              </li>
              <li>
                You will not be entitled to any compensation or payment in
                relation to any actual or anticipated loss of income generated
                from your Vehicle.
              </li>
              <li>
                Borrowers will not be responsible for, and the Owner Guarantee
                will not cover::
                <ol className={styles.alpha}>
                  <li>
                    loss of use, depreciation, Wear and Tear, deterioration
                    resulting from existing damage, rust or corrosion or
                    reduction in value;
                  </li>
                  <li>
                    mechanical, electrical or computer breakdowns, failures or
                    breakages;
                  </li>
                  <li>
                    Damage and Loss as a result of lawful seizure or impounding
                    (however the Borrower will be responsible for these costs if
                    the seizure or impounding results from his or her actions or
                    inaction);
                  </li>
                  <li>repairs carried out without our consent;</li>
                  <li>
                    the cost of repair of old damage, faulty workmanship or
                    incomplete repairs that were in existence prior to the
                    Accident;
                  </li>
                  <li>
                    Damage and Loss to any non-standard modifications or
                    accessories that were not supplied by the manufacturer; or
                  </li>
                  <li>
                    Damage and Loss if you make your Vehicle available for
                    Bookings, or drive it Yourself, when it is in an unsafe or
                    un-roadworthy condition.
                  </li>
                </ol>
              </li>
              <li>
                We will ensure that whilst your Vehicle is in the possession of
                a Borrower, as described in the Damage Policy: the Borrower has
                current membership of Mobility Mutual and discretionary risk
                protection under Trip Cover for the Booking. If the Borrower is
                for any reason unable to access Trip Cover, or if their claim
                for protection under Trip Cover is declined by Mobility Mutual,
                then You may make a claim under the Owner Guarantee.
              </li>
              <li>
                There are limits to the Borrower’s level of cover under Trip
                Cover and to the amount that may be paid under the Owner
                Guarantee.
              </li>
            </ol>
          </Paragraph>
        </li>
        <li className={styles.title}>
          CLAIMS FOR DAMAGE AND LOSS
          <Divider></Divider>
          <Paragraph className={styles.body}>
            <ol className={styles.decimal}>
              <li>
                You are deemed to be in possession of the Vehicle for all times
                other than when a Borrower is in possession of the Vehicle. The
                deemed periods when of Borrower possession occurs are specified
                in the Damage Policy under the heading “Who is responsible for
                damage?”
              </li>
              <li>
                You will be fully responsible for Damage and Loss that occurs
                whilst the Vehicle is in your possession (as opposed to being in
                the possession of a Borrower). We are not liable to you or any
                third party in relation to any such Damage and Loss, and you
                agree to indemnify us for any claims against, or damage or loss
                suffered by, us as a result of or in connection with any such
                Damage and Loss.
              </li>
              <li>
                If your Vehicle meets the eligibility criteria for
                Between-Booking Cover, you may choose to purchase
                Between-Booking Cover from Mobility Mutual to cover your Vehicle
                for damage and Loss which occurs between Bookings and is
                protected under the terms and conditions of the Between-Booking
                Cover PDS.
              </li>
              <li>
                If there is Damage and Loss that results from the use or
                possession of your Vehicle by a Borrower then we will:
                <ol className={styles.alpha}>
                  <li>
                    Determine the quantification of the Damage and Loss in
                    accordance with clause 35, which may be through quotations
                    for the repairs to your Vehicle and where appropriate an
                    assessment of the damage;
                  </li>
                  <li>
                    arrange for the completion of our Collision or Damage Report
                    Form;
                  </li>
                  <li>
                    in accordance with the Member Agreement, collect:
                    <ol className={styles.roman}>
                      <li>
                        any Excess from the Borrower if Mobility Mutual approves
                        the claim and pay the Excess to Mobility Mutual on the
                        Borrower’s behalf; or
                      </li>
                      <li>
                        if the Borrower is not able to access Trip Cover or
                        Mobility Mutual does not approve the claim, the amounts
                        owing to you by the Borrower in relation to the Damage
                        and Loss from the Borrower on your behalf; and
                      </li>
                    </ol>
                  </li>
                  <li>
                    pay:
                    <ol className={styles.roman}>
                      <li>the Trip Cover Excess to Mobility Mutual; or</li>
                      <li>
                        the applicable repair costs and other amounts collected
                        from the Borrower or Mobility Mutual (including any
                        claim settlement) on your behalf, either to you or
                        directly to the repairer on your behalf or to the person
                        you direct us to pay.
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li>
                We may, in accordance with the Owner Guarantee or otherwise in
                our absolute discretion pay you in advance of collecting such
                amount from the Borrower or Mobility Mutual, in which case such
                amount will be owed by way of reimbursement for the payments we
                have made, instead of to you.
              </li>
            </ol>
          </Paragraph>
        </li>
        <li className={styles.title}>
          LIABILITY FOR PARKING AND DRIVING INFRINGEMENTS
          <Divider></Divider>
          <Paragraph className={styles.body}>
            <ol className={styles.decimal}>
              <li>
                Subject to clause 16.2 and clause 17, If an Infringement Notice
                is issued in relation to an infringement occurring during any
                time other than:
                <ol className={styles.alpha}>
                  <li>while Borrower has possession of the Vehicle, or</li>
                  <li>
                    when your Vehicle incurs a parking fine in the place where
                    it was left by a Borrower, then you are responsible for
                    payment of the Infringement Notice. Between-Booking Cover
                    does not provide protection for fines, penalties and
                    infringements.
                  </li>
                </ol>
              </li>
              <li>
                You are responsible for ensuring that your Vehicle’s home
                location as listed in the Vehicle Profile is a place where the
                Vehicle can be left indefinitely without incurring a parking
                fine. If a Borrower leaves the Vehicle in its specified home
                location, then you will be responsible for the payment of any
                Infringement Notice issued after the Borrower ceases to have
                possession of the Vehicle.
              </li>
              <li>
                If you receive an Infringement Notice that you believe is the
                responsibility of a Borrower, you should notify us and cooperate
                with us to ensure the Infringement Notice is assigned to the
                appropriate Member for resolution. Trip Cover does not provide
                protection for fines, penalties and infringements.
              </li>
              <li>
                If you dispute your liability for an Infringement Notice, then
                you may raise the dispute with us and we will deal with it in
                accordance with the Complaints and Dispute Resolution Policy.
              </li>
            </ol>
          </Paragraph>
        </li>
        <li className={styles.title}>
          OWNERSHIP AND RETURN OF TOLLING DEVICE AND LOCKBOX SYSTEM (INSTANT
          KEYS VEHICLES ONLY)
          <Divider></Divider>
          <Paragraph className={styles.body}>
            <ol className={styles.decimal}>
              <li>
                If your Vehicle is approved to be an Instant Keys Vehicle then
                we may provide a Lockbox System to you to facilitate your
                participation in the Service. Any Lockbox System provided to you
                will remain our property at all times. The Tolling Device will
                remain the property of the authority or company that provides
                them.
              </li>
              <li>
                You must facilitate the removal of, and return, any Tolling
                Device or Lockbox System in your possession to us:
                <ol className={styles.alpha}>
                  <li>
                    before you sell or transfer possession of the vehicle to
                    another person or move it to a location outside of our
                    normal operating area;
                  </li>
                  <li>
                    if we have sent them to you to install in your Vehicle and
                    you have not installed them within 20 business days of
                    receipt (or another period agreed with us);
                  </li>
                  <li>upon termination of your Agreement; or</li>
                  <li>at any other time if we request that you do so.</li>
                </ol>
              </li>
              <li>
                If we do not receive the Tolling Device or Lockbox System within
                20 business days after our request, or if you transfer ownership
                or possession of the vehicle to another person without returning
                these items to us, then you agree to pay us the replacement cost
                of those items and a reasonable handling fee (as set out in the
                Fee Schedule) and authorise us to charge these costs to your
                account.
              </li>
            </ol>
          </Paragraph>
        </li>
        <li className={styles.title}>
          USING THE LOCKBOX
          <Divider></Divider>
          <Paragraph className={styles.body}>
            <ol className={styles.decimal}>
              <li>
                If your Vehicle is equipped with a Lockbox, you may use the
                Website to generate a personal identification number (PIN) that
                provides you access to unlock the Lockbox for your Vehicle at
                any time.
              </li>
              <li>
                you must keep each PIN secure at all times and only disclose it
                to your Additional Drivers if they need to access the Vehicle.
              </li>
            </ol>
          </Paragraph>
        </li>
        <li className={styles.title}>
          APPLICATION AND REMOVAL OF STICKERS
          <Divider></Divider>
          <Paragraph className={styles.body}>
            <ol className={styles.decimal}>
              <li>
                We may apply stickers to your Vehicle with your consent, or send
                you stickers to apply.
              </li>
              <li>
                If you remove the stickers and do not follow the instructions
                set out in our Help Centre, then you may damage the paintwork on
                your Vehicle. we will not be responsible for the costs of
                repairing any damage caused by your removal of the stickers.
              </li>
            </ol>
          </Paragraph>
        </li>
        <li className={styles.title}>
          LIABILITY FOR PENALTIES, LOSS OR DAMAGE RESULTING FROM FAILURE TO
          REGISTER YOUR VEHICLE OR ENSURE ITS ROADWORTHINESS
          <Divider></Divider>
          <Paragraph className={styles.body}>
            <ol className={styles.decimal}>
              <li>
                If your Vehicle is unregistered, unroadworthy or does not meet
                the Vehicle Eligibility criteria set out above in clause 4.1 at
                any time when it is made available for sharing through the
                Service, you will be liable, and agree to indemnify and hold
                harmless us and any Borrowers, for any cost, loss, damage,
                fines, penalties, claims or liability arising in relation to the
                use of your Vehicle. Between-Booking Cover does not provide
                protection for these costs, losses, damage, fines, penalties,
                claims or liability.
              </li>
            </ol>
          </Paragraph>
        </li>
        <li className={styles.title}>
          SUSPENDING YOUR VEHICLE FROM THE SERVICE
          <Divider></Divider>
          <Paragraph className={styles.body}>
            <ol className={styles.decimal}>
              <li>
                We may suspend your Vehicle from the Service by making it
                unavailable for sharing at any time at our discretion:
                <ol className={styles.alpha}>
                  <li>
                    if we consider that you have not complied with the terms of
                    the Agreement, including this Owner Agreement;
                  </li>
                  <li>
                    if there is a problem with the Lockbox System or Booking
                    System that affects your Vehicle; or
                  </li>
                  <li>
                    for any other legitimate reason to protect the integrity of
                    the Service or our business.
                  </li>
                </ol>
              </li>
              <li>
                We will not be liable for any direct or indirect loss or cost,
                including without limitation any loss of income or loss of
                opportunity, that you suffer as a result of our suspending your
                Vehicle from the Service or our failure to suspend your Vehicle
                for the Service.
              </li>
              <li>
                If we suspend your Vehicle from the Service for cause, to the
                extent not prohibited by law you will not be entitled to a
                refund of your Membership Fee or of any payments for the Lockbox
                System.
              </li>
            </ol>
          </Paragraph>
        </li>
        <li className={styles.title}>
          CANCELLATION AND SUSPENSION OF MEMBERSHIP
          <Divider></Divider>
          <Paragraph className={styles.body}>
            <ol className={styles.decimal}>
              <li>
                If your Membership is cancelled, either by you or by us, then in
                addition to any other terms in the Member Agreement:
                <ol className={styles.alpha}>
                  <li>
                    you must not use the Service or otherwise share your Vehicle
                    with Members on or after the date of cancellation;
                  </li>
                  <li>
                    your Between-Booking Cover for Your Vehicle will end, your
                    membership of Mobility Mutual will also end and your Vehicle
                    will no longer be protected by Mobility Mutual; and
                  </li>
                  <li>
                    you must facilitate the removal of any Tolling Device or
                    Lockbox System (where applicable) that has been issued to
                    you under this Owner Agreement.
                  </li>
                </ol>
              </li>
              <li>
                As soon as possible after your Membership is cancelled, you
                must:
                <ol className={styles.alpha}>
                  <li>
                    return the Lockbox System to us in accordance with our
                    postage instructions; and
                  </li>
                  <li>
                    remove any Jaunt stickers from your vehicle, in accordance
                    with clause 40 (Application and removal of stickers).
                  </li>
                </ol>
              </li>
            </ol>
          </Paragraph>
        </li>
      </ol>
      <Title level={2}>Schedule 1</Title>
      <Title level={4}>Credit Information and Reporting</Title>
      <Divider></Divider>
      <Paragraph>
        (SECTION 18(E)(1) PRIVACY ACT 1988) NOTICE OF DISCLOSURE OF YOUR CREDIT
        INFORMATION TO A CREDIT REPORTING AGENCY
      </Paragraph>
      <Paragraph>We may provide credit to you.</Paragraph>
      <Paragraph>
        We collect, hold and use, information related to your commercial and
        consumer creditworthiness from credit reporting agencies including
        Equifax (https://www.equifax.com.au/contact), for all purposes permitted
        by law. we also disclose information to them. This activity is conducted
        for the purpose of assessing your credit capacity, eligibility or
        history in connection with an application or an obligation as a
        guarantor, collecting payments from you, and managing our credit
        relationship. Creditworthiness information includes information that is
        both positive (like payment information) and negative (like defaults or
        serious credit infringements that we may disclose to credit reporting
        agencies if you fail to pay us). our privacy policy and the credit
        reporting agencies’ privacy policy (see our websites) have more
        information on how we, and the credit reporting agencies, manage
        personal information, including creditworthiness information. The
        policies also include how you can access, correct, and make complaints
        about personal information, request that your information is not used
        for credit pre-screening, and request a ban on use of credit information
        where you have been a victim of fraud.
      </Paragraph>
      <Title level={4}>PERIOD TO WHICH THIS UNDERSTANDING APPLIES</Title>
      <Paragraph>
        This information may be given before, during or after the provision of
        credit to you.
      </Paragraph>
      <Title level={4}>STATEMENT BY APPLICANT(S) FOR CREDIT</Title>
      <Paragraph>
        You agree to our exchange of creditworthiness information with credit
        reporting agencies, and agree that we will obtain (and to the extent
        permitted by law, provide) information about both consumer and
        commercial credit to credit reporting agencies as stated above.
      </Paragraph>
      <Paragraph>
        Your ticking of the check box on the online application form constitutes
        a binding statement equivalent to your signature under ss.8 and 9 of the
        Electronic Transactions Act 1999 (Cth).
      </Paragraph>
    </div>
  );
};

export default PrivacyPolicy;
