import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getBookingDetailsAsync , changeBookingDetailsAsync } from './thunks';
import { BookingDetailsState } from './types';

const initialState: BookingDetailsState = {
  booking: null,
  getBookingRequestStatus: 'initial',
  patchBookingRequestStatus: 'initial',
};

export const customerBookingSlice = createSlice({
  name: 'booking',
  initialState,
  reducers: {
    resetBooking: (state) => {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(getBookingDetailsAsync.pending, (state, action) => {
      state.getBookingRequestStatus = 'started';
    })
    .addCase(getBookingDetailsAsync.fulfilled, (state, action) => {
      state.booking = action.payload;
      state.getBookingRequestStatus = 'done';
    })
    .addCase(getBookingDetailsAsync.rejected, (state, action) => {
      state.getBookingRequestStatus = 'failed';
    })
    .addCase(changeBookingDetailsAsync.pending, (state, action) => {
      state.patchBookingRequestStatus = 'started';
    })
    .addCase(changeBookingDetailsAsync.fulfilled, (state, action) => {
      state.patchBookingRequestStatus = 'done';
    })
    .addCase(changeBookingDetailsAsync.rejected, (state, action) => {
      state.patchBookingRequestStatus = 'failed';
    })
  },
});

export const {
  resetBooking,
} = customerBookingSlice.actions;

export default customerBookingSlice;
