import { useCallback } from 'react';

import { useUnwrapAsyncThunk } from 'store/hooks';
import { getVehicleDetailsAsync } from 'store/provider/vehicles/thunks';

export const useGetVehicleDetails = () => {
  const unwrap = useUnwrapAsyncThunk();

  return useCallback((id: number) => {
    return unwrap(getVehicleDetailsAsync(id));
  }, []);
};
