import { Account } from './../../../../api/customer/account/types';
import { useSelector } from 'react-redux';

import {
  selectCustomerAccount,
  selectCustomerAccountStatus,
} from 'store/customer/account/selectors';
import { RootState } from 'store/types';

export const useProfileInfoSelectors = () => {
  return useSelector((state: RootState) => {
    const {
      first_name,
      last_name,
      address,
      email,
      '100_points': points,
      approve_to_drive,
      rating,
      trips,
      date_joined,
      profile_picture,
      is_active,
      user_documents,
    } = selectCustomerAccount(state);

    const status = selectCustomerAccountStatus(state);

    return {
      profileInfo: {
        first_name,
        last_name,
        address,
        email,
        '100_points': points,
        approve_to_drive,
        rating,
        trips,
        date_joined,
        profile_picture,
        is_active,
        user_documents,
      } as Partial<Account>,
      status,
    };
  });
};
