import { UserRole } from 'api/auth/types';

export const isCustomer = (roles: UserRole[]): boolean => {
  return roles.some((role) => role.user_role.toUpperCase() === 'CUSTOMER');
};

export const isProvider = (roles: UserRole[]): boolean => {
  return roles.some((role) => role.user_role.toUpperCase() === 'PROVIDER');
};

export const isAdmin = (roles: UserRole[]): boolean => {
  return roles.some((role) => role.user_role.toUpperCase() === 'ADMIN');
};
