import { createSelector } from 'reselect';

import { selectTrips } from 'store/admin/tripHistory/selectors';
import { RootState } from 'store/types';

import { transformTripsToTableData } from './helpers';

export const createSelectTripHistoryTableData = createSelector(
  selectTrips,
  transformTripsToTableData
);

export const selectTripHistoryRequestStatus = (state: RootState) =>
  state.resources.admin.trips.getTripHistoryRequestStatus;

export const selectTotalCount = (state: RootState) =>
  state.resources.admin.trips.totalCount;

export const selectCurrentPage = (state: RootState) =>
  state.resources.admin.trips.currentPage;
