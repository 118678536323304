import React from 'react';
import { Navigate, Outlet, Route } from 'react-router-dom';

import { ROLE } from 'api/auth/types';
import Sidebar from 'components/Sidebar';
import PrivateRoute from 'pages/PrivateRoute';
import { ROUTES } from 'shared/routes/constants';
import Content from 'uicontainers/_shared/layout/Content';
import Billing from 'uicontainers/provider/Billings';
import Calendar from 'uicontainers/provider/Calendars';
import Customers from 'uicontainers/provider/Customers';
import Dashboard from 'uicontainers/provider/dashboard/Home';
import Instructions from 'uicontainers/provider/RentalAgreement/Instructions';
import RentalContract from 'uicontainers/provider/RentalAgreement/RentalContract';
import TermsAndConditions from 'uicontainers/provider/RentalAgreement/TermsAndConditions';
import TollAndTickets from 'uicontainers/provider/tollAndTickets/Home';
import TripHistory from 'uicontainers/provider/TripHistory';
import BasicInfo from 'uicontainers/provider/vehicles/details/BasicInfo';
import CarDetail from 'uicontainers/provider/vehicles/details/CarDetail';
import Documents from 'uicontainers/provider/vehicles/details/Documents';
import Photos from 'uicontainers/provider/vehicles/details/Photos';
import Pricing from 'uicontainers/provider/vehicles/details/Pricing';
import Protection from 'uicontainers/provider/vehicles/details/Protection';
import Vehicles from 'uicontainers/provider/vehicles/Home/Vehicles';

import { getSideBarMenu } from './helpers';

const RentalAgreementDetails = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

const VehicleDetails = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

const ProviderLayout = () => {
  return (
    <Content leftSection={<Sidebar menuItems={getSideBarMenu()} />}>
      <Outlet />
    </Content>
  );
};

const Provider = () => {
  return [
    <Route
      path={ROUTES.PATHS.PROVIDER.PROVIDER_HOME}
      element={
        <PrivateRoute roles={[ROLE.PROVIDER]}>
          <ProviderLayout />
        </PrivateRoute>
      }
    >
      <Route index element={<Navigate replace to={ROUTES.PATHS.PROVIDER.PROVIDER_DASHBOARD} />} />
      <Route path={ROUTES.PATHS.PROVIDER.PROVIDER_DASHBOARD} element={<Dashboard />} />
      <Route path={ROUTES.PATHS.PROVIDER.PROVIDER_CALENDAR} element={<Calendar />} />
      <Route path={ROUTES.PATHS.PROVIDER.PROVIDER_VEHICLES} element={<Vehicles />} />
      <Route path={ROUTES.PATHS.PROVIDER.PROVIDER_VEHICLES_SETTINGS} element={<VehicleDetails />}>
        <Route
          path={ROUTES.PATHS.PROVIDER.PROVIDER_VEHICLES_SETTINGS_BASIC_INFO}
          element={<BasicInfo />}
        />
        <Route
          path={ROUTES.PATHS.PROVIDER.PROVIDER_VEHICLES_SETTINGS_DETAILS}
          element={<CarDetail />}
        />
        <Route
          path={ROUTES.PATHS.PROVIDER.PROVIDER_VEHICLES_SETTINGS_PRICING}
          element={<Pricing />}
        />
        <Route
          path={ROUTES.PATHS.PROVIDER.PROVIDER_VEHICLES_SETTINGS_PHOTOS}
          element={<Photos />}
        />
        <Route
          path={ROUTES.PATHS.PROVIDER.PROVIDER_VEHICLES_SETTINGS_DOCUMENTS}
          element={<Documents />}
        />
        <Route
          path={ROUTES.PATHS.PROVIDER.PROVIDER_VEHICLES_SETTINGS_PROTECTION}
          element={<Protection />}
        />
        <Route index element={<BasicInfo />} />
      </Route>
      <Route path={ROUTES.PATHS.PROVIDER.PROVIDER_CUSTOMERS} element={<Customers />} />
      <Route path={ROUTES.PATHS.PROVIDER.PROVIDER_TRIP_HISTORY} element={<TripHistory />} />
      <Route path={ROUTES.PATHS.PROVIDER.PROVIDER_BILLING} element={<Billing />} />
      <Route path={ROUTES.PATHS.PROVIDER.PROVIDER_TOLL_AND_TICKETS} element={<TollAndTickets />} />
      <Route
        path={ROUTES.PATHS.PROVIDER.PROVIDER_RENTAL_AGREEMENT}
        element={<RentalAgreementDetails />}
      >
        <Route
          index
          element={
            <Navigate
              replace
              to={ROUTES.PATHS.PROVIDER.PROVIDER_RENTAL_AGREEMENT_TERMS_AND_CONDITIONS}
            />
          }
        />
        <Route
          path={ROUTES.PATHS.PROVIDER.PROVIDER_RENTAL_AGREEMENT_TERMS_AND_CONDITIONS}
          element={<TermsAndConditions />}
        />
        <Route
          path={ROUTES.PATHS.PROVIDER.PROVIDER_RENTAL_AGREEMENT_INSTRUCTIONS}
          element={<Instructions />}
        />
        <Route
          path={ROUTES.PATHS.PROVIDER.PROVIDER_RENTAL_AGREEMENT_RENTAL_CONTRACT}
          element={<RentalContract />}
        />
      </Route>
    </Route>,
  ];
};

export default Provider;
