import { adminAPI } from 'api/base';

import { PostRentalContract, GetRentalContract, UpdateRentalContract } from './types';

export const handleRentalContract: PostRentalContract = (data) => {
  return adminAPI.post(`/rental-agreements/`, data);
};

export const getRentalContract: GetRentalContract = () => {
  return adminAPI.get(`/rental-agreements/`);
};

export const updateRentalContract: UpdateRentalContract = (data, id) => {
  return adminAPI.patch(`/rental-agreements/${id}/`, data);
};
