import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import IXTable from 'components/tables/IXTable';
import { notification } from 'shared/helpers/notifications';
import { ROUTES } from 'shared/routes/constants';
import { getProfileAsync } from 'store/customer/account/actions';
import { setCurrentPage } from 'store/customer/bookings/actions';
import { selectCurrentPage } from 'store/customer/bookings/selectors';
import { selectBookings } from 'store/customer/bookings/selectors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import BookingsHeader from 'uicontainers/Customer/BookingsHeader';
import { BookingsTableData } from 'uicontainers/Customer/Bookings/types';
import { useIsMobile } from 'shared/hooks';
import styles from './bookings.module.scss';
import { COLUMNS } from './constants';
import { createSelectBookingsTableData, selectTotalCount } from './selectors';
import classNames from 'classnames';

const Bookings: FC = () => {
  const navigate = useNavigate();
  const bookingsTableData = useAppSelector(createSelectBookingsTableData);
  const bookings = useAppSelector(selectBookings);
  const totalCount = useAppSelector(selectTotalCount);
  const currentPage = useAppSelector(selectCurrentPage);
  const dispatch = useAppDispatch();
  const isMobile = useIsMobile();

  useEffect(() => {
    dispatch(getProfileAsync());
  }, []);

  const onChange = (page: number) => {
    dispatch(setCurrentPage(page));
  };

  const goToBookingDetails = (record: BookingsTableData) => {
    const selectedBooking = bookings.find(
      (item) => item.id.toString() === record.key
    );
    if (selectedBooking) {
      if (
        selectedBooking.request_status === 'BOOKED' &&
        !selectedBooking.subscription_id
      ) {
        navigate(ROUTES.CUSTOMER_BOOKING_DETAILS_ID(record.key));
      } else {
        notification({
          type: 'info',
          title: 'Not allowed',
          description: 'You can only edit unpaid booking.',
        });
      }
    }
  };

  return (
    <div
      className={classNames({
        [styles.root]: true,
        [styles['is-mobile']]: !isMobile,
      })}
    >
      <BookingsHeader currentPage={currentPage} />
      <IXTable
        onRowClick={goToBookingDetails}
        data={bookingsTableData}
        columns={COLUMNS}
        pagination={{ currentPage: currentPage, count: totalCount, onChange }}
      />
    </div>
  );
};

export default Bookings;
