import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import {
  updateVehicleAsync,
  getVehicleDetailsAsync,
} from 'store/provider/vehicles/actions';
import { CAR_PRICING_SLICE } from './constants';
import { State } from './types';
import { DISCOUNT_TYPE } from '../constants';

const initialState: State = {
  carPricing: {
    daily_price: 0,
    discount_type: '',
    discount: 0,
    has_discount: false,
  },
};

export const carPricingSlice = createSlice({
  name: CAR_PRICING_SLICE,
  initialState,
  reducers: {
    resetCarPricing: () => initialState,
  },
  extraReducers: (builder) =>
    builder.addMatcher(
      isAnyOf(updateVehicleAsync.fulfilled, getVehicleDetailsAsync.fulfilled),
      (state, action) => {
        const { has_discount: discount, discount_type, ...others } = action.payload;
        state.carPricing = {
          ...state.carPricing,
          has_discount: !!discount,
          discount_type: discount_type || DISCOUNT_TYPE.PERCENTAGE,
          ...others,
        };
      }
    ),
});

export default carPricingSlice;
