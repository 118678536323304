import React, { FC } from 'react';
import { Props } from './types';
import styles from './imageAndTextCell.module.scss';
import Image from 'components/Image'; 
export const ImageAndTextCell: FC<Props> = (props) => {
  const { rows } = props;

  return (
    <div>
      {rows?.map(({text,imageSrc}, index) => (
        <div key={`${index}`} className={styles.root}>
          {imageSrc && <Image src={imageSrc} alt="image" className={styles.image} />}
          <p className={styles.title}>{text}</p>
        </div>
      ))}
    </div>
  );
};

export default ImageAndTextCell;
