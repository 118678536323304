import React, { FC, useState } from 'react';

import IXTable from 'components/tables/IXTable';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getTripsAsync, setCurrentPage } from 'store/admin/tripHistory/actions';
import TableHeader from 'uicontainers/_shared/TableHeader';

import { COLUMNS } from './constants';
import {
  createSelectTripHistoryTableData,
  selectTripHistoryRequestStatus,
  selectCurrentPage,
  selectTotalCount,
} from './selectors';
import styles from './tripHistory.module.scss';
import { Spin } from 'antd';
import { TripStatusOptions } from 'uicontainers/_shared/constants';

const TripHistory: FC = () => {
  const [searchValue, setSearchValue] = useState('');
  const [selectedValue, setSelectedValue] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const totalCount = useAppSelector(selectTotalCount);
  const currentPage = useAppSelector(selectCurrentPage);
  const isTripHistoryLoading =
    useAppSelector(selectTripHistoryRequestStatus) === 'started';

  const tripHistoryTableData = useAppSelector(createSelectTripHistoryTableData);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    let searchParams = {};
    if (searchValue) {
      searchParams = { ...searchParams, search: searchValue };
    }
    if (selectedValue) {
      searchParams = { ...searchParams, status: selectedValue };
    }
    if (startDate) {
      searchParams = { ...searchParams, start_time: startDate };
    }
    if (endDate) {
      searchParams = { ...searchParams, end_time: endDate };
    }
    if (currentPage) {
      searchParams = { ...searchParams, page: currentPage };
    }
    dispatch(getTripsAsync(searchParams));
  }, [searchValue, selectedValue, currentPage, getTripsAsync, endDate]);

  const onChange = (page: number) => {
    dispatch(setCurrentPage(page));
  };

  const handleSearch = (value: string) => {
    setSearchValue(value);
    dispatch(setCurrentPage(1));
  };
  const handleDateRangeChange = (date: string, dateString: string) => {
    setStartDate(dateString[0]);
    setEndDate(dateString[1]);
    dispatch(setCurrentPage(1));
  };

  const handleSelect = (value: string) => {
    setSelectedValue(value);
    dispatch(setCurrentPage(1));
  };

  const options = TripStatusOptions;

  return (
    <div className={styles.root}>
      <Spin spinning={isTripHistoryLoading}>
        <TableHeader
          title="Trip History"
          onChange={handleDateRangeChange}
          onSearch={handleSearch}
          onSelectChange={handleSelect}
          dropdownTitle="Status"
          placeholder="Trip status"
          options={options}
          extraOptions={options}
        />
        <IXTable
          data={tripHistoryTableData}
          columns={COLUMNS}
          pagination={{ currentPage: currentPage, count: totalCount, onChange }}
        />
      </Spin>
    </div>
  );
};

export default TripHistory;
