import { ArrowLeftOutlined } from "@ant-design/icons";
import { Col, Grid, Row, Space, Typography, Spin } from "antd";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Button from 'components/Button';
import { useAppDispatch, useAppSelector } from "store/hooks";
import { selectVehicleDetails, selectVehicleDetailsRequestStatus } from "store/tripBooking/vehicleDetails/selectors";
import { getVehicleDetailsAsync } from "store/tripBooking/vehicleDetails/thunks";
import Details from "uicontainers/tripBooking/vehicleDetails/Details/Details";
import SimilarCars from "uicontainers/tripBooking/vehicleDetails/SimilarCars/SimilarCars";
import { resetVehicleDetails } from 'store/tripBooking/vehicleDetails/actions';

import styles from "./preBook.module.scss";
import { ROUTES } from "shared/routes/constants";

const { Title } = Typography;

const { useBreakpoint } = Grid;

const PreBook = () => {
  const screenMap = useBreakpoint();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const vehicleDetails = useAppSelector(selectVehicleDetails);
  const { vehicleId } = useParams();
  const vehicleDetailsRequestStatus = useAppSelector(selectVehicleDetailsRequestStatus);

  const isLoading = vehicleDetailsRequestStatus === 'started';


  useEffect(()=>{
    return () => {
      dispatch(resetVehicleDetails());  
        }
  },[])

  useEffect(() => {
    dispatch(getVehicleDetailsAsync(vehicleId));
    window.scrollTo(0, 0);
  }, [vehicleId]);

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <Spin spinning={isLoading}>
          <div className={styles.navigation}>
          <ArrowLeftOutlined onClick={() => navigate(ROUTES.PATHS.SEARCH)} />
          <Title level={5}>All Vehicles</Title>
        </div>

        <Row gutter={[40, 16]}>
          <Col xs={24} md={16}>
            <Space size="large" direction="horizontal">
              <Title level={4}>{`$${vehicleDetails?.daily_price * 1}/day`}</Title>
              <Button type="ghost" isBordered={true} onClick={() => vehicleId && navigate(ROUTES.CUSTOMER_CREATE_BOOKING_VEHICLE_ID(vehicleId))}>
                Book Now
              </Button>
            </Space>
          </Col>
          <Col xs={24} md={16}>
            <Details vehicleDetails={vehicleDetails} />
          </Col>

          {screenMap.md || screenMap.lg || screenMap.xl ? (
            <Col span={24}>
              <SimilarCars />
            </Col>
          ) : null}
        </Row>
        </Spin>
      </div>
    </div>
  );
};

export default PreBook;
