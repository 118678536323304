import { providerAPI } from 'api/base';

import {
  CreateVehicle,
  UpdateVehicle,
  UploadImage,
  GetVehicles,
  DeleteUploadedImage,
  GetVehicleDetails,
  GetInsuranceCompany,
  GetVehicleTypes,
  GetVehicleMake,
  GetVehicleModel,
} from './types';

export const createVehicle: CreateVehicle = (data) => {
  return providerAPI.post('/vehicles/', data);
};

export const updateVehicle: UpdateVehicle = (data, id) => {
  return providerAPI.patch(`/vehicles/${id}/`, data);
};

export const uploadImage: UploadImage = (data, id) => {
  return providerAPI.patch(`/vehicles/${id}/`, data);
};

export const getVehicles: GetVehicles = (params) => {
  return providerAPI.get('/vehicle-views/', { params });
};

export const deleteUploadedImage: DeleteUploadedImage = (image_id) => {
  return providerAPI.post(`/vehicle-image-delete/`, { image_id: parseInt(image_id) });
};

export const getVehicleDetails: GetVehicleDetails = (id) => {
  return providerAPI.get(`/vehicles/${id}/`);
};

export const getInsuranceCompany: GetInsuranceCompany = () => {
  return providerAPI.get(`/insurance-company/`);
};

export const getVehicleTypes: GetVehicleTypes = () => {
  return providerAPI.get(`/vehicle-types/`);
};

export const getVehicleMake: GetVehicleMake = () => {
  return providerAPI.get(`/vehicle-makes/`);
};

export const getVehicleModel: GetVehicleModel = (makeId) => {
  return providerAPI.get(`/vehicle-models/?make_id=${makeId}`);
};
