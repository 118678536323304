import { authAPI, silentAPI, userAPI } from 'api/base';

import {
  AccountActivationData,
  ActivateAccount,
  PostPasswordReset,
  PostLogin,
  PostTokenVerify,
  GetStripeOnboardingLink,
  AcknowledgeOnboard,
} from './types';

export const login: PostLogin = (loginData) => {
  return authAPI.post('/token/', loginData);
};

export const signup: PostLogin = (signupData) => {
  return authAPI.post('/registration/', signupData);
};

export const passwordResetRequest: PostPasswordReset = (email: string) => {
  return authAPI.post('/password-reset/', email);
};

export const resetPassword: ActivateAccount = (
  activationData: AccountActivationData
) => {
  return authAPI.post(`/password-reset-done/`, activationData);
};

export const activateAccount: ActivateAccount = (
  activationData: AccountActivationData
) => {
  const { securityCode, token } = activationData;
  return authAPI.get(`/activate/${securityCode}/${token}/`);
};

export const verifyToken: PostTokenVerify = (verificationData) => {
  return silentAPI.post('/token-verify/', { token: verificationData });
};

export const getStripeOnboardingLink: GetStripeOnboardingLink = () => {
  return userAPI.get(`/create_account_links/`);
};

export const acknowledgeOnboarding: AcknowledgeOnboard = () => {
  return userAPI.patch('/acknowledge-onboarding/');
}
