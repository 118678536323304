import React, { Fragment } from 'react';
import { Card, Typography, Row, Col } from 'antd';

import { InfoCardProps } from './types';
import styles from './infoCard.module.scss';
import Button from '../../../../components/Button';
import NXCard from 'components/NXCard';

const { Text } = Typography;

const InfoCard = (props: InfoCardProps) => {
  const { titleText, contents, onEditClick, noContentsData, ...others } = props;

  const colSpanText = 6;
  const colSpanValue = 24 - colSpanText;

  return (
    <NXCard
      headerTitle={<Text strong>{titleText}</Text>}
      headerActions={
        noContentsData ? null : (
          <div>
            <Button
              type="primary"
              isBordered={true}
              onClick={onEditClick}
              className={styles.editBtn}
            >
              Edit
            </Button>
          </div>
        )
      }
    >
      {noContentsData ? (
        noContentsData
      ) : (
        <Row gutter={[0, 16]} className={styles.infoContainer}>
          {contents.map((content) => (
            <Fragment key={content.text}>
              <Col span={colSpanText}>
                <Text> {content.text}</Text>
              </Col>
              <Col span={colSpanValue}>
                <Text strong>{content.value || '-'}</Text>
              </Col>
            </Fragment>
          ))}
        </Row>
      )}
    </NXCard>
  );
};

export default InfoCard;
