import { Empty, Spin } from 'antd';
import React from 'react';

import PieChart from 'components/Piechart';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getVehicleWiseEarningAsync } from 'store/provider/dashboard/actions';

import { selectVehicleWiseEarning, selectVehicleWiseEarningRequestStatus } from './selectors';
import NXCard from 'components/NXCard';

const VehicleWiseEarning = () => {
  const vehicleWiseEarning = useAppSelector(selectVehicleWiseEarning);
  const isVehicleWiseEarningLoading =
    useAppSelector(selectVehicleWiseEarningRequestStatus) === 'started';

  const vehicleWiseEarningData = vehicleWiseEarning.map((e) => {
    return {
      vehicle: e?.vehicle_name,
      earning: e?.revenue,
    };
  });

  const dispatch = useAppDispatch();
  React.useEffect(() => {
    dispatch(getVehicleWiseEarningAsync());
  }, []);

  return (
    <Spin spinning={isVehicleWiseEarningLoading}>
      <NXCard headerTitle="Vehicle wise Earning">
        {vehicleWiseEarningData?.length > 0 ? (
          <PieChart data={vehicleWiseEarningData} angleField="earning" colorField="vehicle" />
        ) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      </NXCard>
    </Spin>
  );
};

export default VehicleWiseEarning;
