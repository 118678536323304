import { Account } from './../../../api/customer/account/types';
import { useSelector } from 'react-redux';

import {
  selectProviderDetails,
  selectProviderDetailsStatus,
} from 'store/tripBooking/providerDetails/selectors';
import { RootState } from 'store/types';

export const useProviderInfoSelectors = () => {
  return useSelector((state: RootState) => {
    const {
      first_name,
      last_name,
      address,
      email,
      '100_points': points,
      approve_to_drive,
      rating,
      trips,
      joined_date,
      profile_picture,
      is_active,
      user_documents,
    } = selectProviderDetails(state);

    const status = selectProviderDetailsStatus(state);

    return {
      profileInfo: {
        first_name,
        last_name,
        address,
        email,
        '100_points': points,
        approve_to_drive,
        rating,
        trips,
        joined_date,
        profile_picture,
        is_active,
        user_documents
      } as Partial<Account>,
      status,
    };
  });
};
