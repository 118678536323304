/* eslint-disable @typescript-eslint/no-explicit-any */
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Col, Row, Space, Spin } from 'antd';
import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import Button from 'components/Button';
import { notification } from 'shared/helpers/notifications';
import { changeBookingStatusAsync } from 'store/customer/bookings/thunks';
import { useUnwrapAsyncThunk } from 'store/hooks';

import styles from './checkoutForm.module.scss';
import { Props } from './types';

export default function CheckoutForm(props: Props) {
  const { vehicleId } = props;
  const stripe = useStripe();
  const navigate = useNavigate();
  const unwrap = useUnwrapAsyncThunk();
  const elements = useElements();
  const [searchParams] = useSearchParams();
  const bookId: string | null = searchParams.get('bookId');
  const [isLoading, setIsLoading] = useState(false);

  // @ts-ignore
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      // @ts-ignore
      card: elements.getElement(CardElement),
    });

    if (error) {
      notification({
        type: 'error',
        title: 'Error occurred',
        description: 'Invalid payment method. Please try again later.',
      });

      setIsLoading(false);
    } else {
      if (paymentMethod && bookId) {
        try {
          unwrap(
            changeBookingStatusAsync({
              id: parseInt(bookId),
              status: 'PICKUP_REQUESTED',
              payment_method_id: paymentMethod.id,
            })
          )
            .then((response: any) => {
              if (response.results.subscription_id) {
                navigate(
                  `/customer/booking/vehicle/${vehicleId}/confirmation/${response?.results?.subscription_id}`
                );
              } else {
                setIsLoading(false);
              }
            })
            .catch((error) => {
              console.log(error);
              setIsLoading(false);
            });
        } catch (error) {
          setIsLoading(false);
        }
      }
    }
  };

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };

  return (
    <Spin spinning={isLoading}>
      <form id="payment-form" onSubmit={handleSubmit}>
        <Row>
          <Col xs={24} sm={24} md={{ span: 23 }} lg={{ span: 23 }}>
            <Space direction="vertical" className={styles.checkoutForm}>
              Enter your card details
              <CardElement options={CARD_ELEMENT_OPTIONS} />
              <Button
                id="submit"
                type="ghost"
                isBordered={true}
                style={{ width: '100%' }}
                htmlType="submit"
                loading={isLoading}
                disabled={isLoading || !stripe || !elements}
              >
                Pay now
              </Button>
            </Space>
          </Col>
        </Row>
      </form>
    </Spin>
  );
}
