import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getTripsAsync } from './thunks';
import { ProviderTripHistoryState } from './types';

const initialState: ProviderTripHistoryState = {
  trips: [],
  getTripHistoryRequestStatus: 'initial',
  totalCount: 0,
  currentPage: 1,
};

export const providerTripHistorySlice = createSlice({
  name: 'trips',
  initialState,
  reducers: {
    setCurrentPage(state, action: PayloadAction<number>) {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTripsAsync.pending, (state) => {
      state.getTripHistoryRequestStatus = 'started';
    });
    builder.addCase(getTripsAsync.fulfilled, (state, action) => {
      state.trips = action.payload.results;
      state.totalCount = action.payload.count;
      state.getTripHistoryRequestStatus = 'done';
    });
    builder.addCase(getTripsAsync.rejected, (state) => {
      state.getTripHistoryRequestStatus = 'failed';
    });
  },
});

export default providerTripHistorySlice;
