import React from "react";
import { Row, Col } from "antd";

import BgImageCard from "components/BgImageCard";

import styles from "./bottomLargeCard.module.scss";

function BottomLargeCard() {
  return (
    <div className={styles.root}>
      <Row gutter={[0, 0]}>
        <Col className="gutter-row" xs={24} sm={24} md={24} lg={24}>
          <BgImageCard
            title={"List your car in Jaunt and start earning"}
            className={styles.secondCardImg}
            buttonText=""
            handleClick={() => {}}
          />
        </Col>
      </Row>
    </div>
  );
}

export default BottomLargeCard;
