import { Col, Row } from 'antd';
import classNames from 'classnames';
import React, { FC, useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import { useNavigate, useLocation } from 'react-router-dom';

import Button from 'components/Button';
import SelectInput from 'components/SelectInput';
import SidebarNavigation from 'components/SideNavigation';
import { ROUTES } from 'shared/routes/constants';
import { useAppDispatch } from 'store/hooks';
import {
  handleRentalContractAsync,
  updateRentalContractAsync,
} from 'store/provider/rentalAgreement/actions';

import styles from './rentalAgreement.module.scss';
import { Props } from './types';

import 'react-quill/dist/quill.snow.css';

const sidebarMenu = [
  {
    title: 'Terms and Conditions',
    link: `${ROUTES.PATHS.PROVIDER.PROVIDER_RENTAL_AGREEMENT_TERMS_AND_CONDITIONS}`,
    routeName: 'terms-and-conditions',
  },
  {
    title: 'Instructions',
    link: `${ROUTES.PATHS.PROVIDER.PROVIDER_RENTAL_AGREEMENT_INSTRUCTIONS}`,
    routeName: 'instructions',
  },
  {
    title: 'Rental Contract',
    link: `${ROUTES.PATHS.PROVIDER.PROVIDER_RENTAL_AGREEMENT_RENTAL_CONTRACT}`,
    routeName: 'rental-contract',
  },
];

const selectOptions = [{ name: 'Default Template', value: 'Default Template' }];

const RentalAgreement: FC<Props> = (props) => {
  const {
    secondaryTitle,
    paragraph1,
    richTextEditorLabel,
    footerText,
    isReadOnly,
    template,
    agreementDetail,
    id = 0,
    handleRentalContractPrint,
  } = props;
  const [agreementContract, setAgreementContract] = useState(agreementDetail);
  const [templateType, setTemplateType] = useState('Default Template');
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  const lastItem = location.pathname.substring(
    location.pathname.lastIndexOf('/') + 1
  );
  const found = sidebarMenu.find((item) => item.routeName === lastItem);
  const [activeTab] = useState(found?.title);

  useEffect(() => {
    setAgreementContract(agreementDetail);
  }, [agreementDetail]);

  const onClick = (link: string) => {
    navigate(link);
  };
  const onSelectChange = (value: string) => {
    setTemplateType(value);
  };

  const handleSave = () => {
    const rentalAgrementdata = {
      rental_contacts_template_type: templateType ? templateType : '',
      rental_contacts_agreement: agreementContract ? agreementContract : '',
    };

    if (!agreementDetail && !template) {
      dispatch(handleRentalContractAsync(rentalAgrementdata));
    } else {
      dispatch(
        updateRentalContractAsync({
          data: rentalAgrementdata,
          id: id,
        })
      );
    }
  };

  const toolbarOptions = isReadOnly
    ? false
    : [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline'],
        [{ color: [] }, { background: [] }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ align: [] }],
      ];
  return (
    <div className={styles.root}>
      <h1 className={styles.containerTitle}>
        {' '}
        <b>Rental Agreement</b>
      </h1>
      <Row>
        <Col sm={6}>
          <SidebarNavigation
            onClick={onClick}
            menuItems={sidebarMenu}
            selectedTab={activeTab}
          />{' '}
        </Col>

        <Col sm={18}>
          <div className={styles.container}>
            <h1>
              <b>{secondaryTitle}</b>
            </h1>
            {activeTab !== 'Rental Contract' && (
              <span className={styles.agreementParagraph}>{paragraph1}</span>
            )}
            {activeTab === 'Rental Contract' && (
              <SelectInput
                onChange={onSelectChange}
                options={selectOptions}
                title={'Base Template'}
                defaultValue={'Default Template'}
                className={styles.selectInput}
                allowClear={false}
              />
            )}
            <p className={styles.textAreaTitle}>
              <b>{richTextEditorLabel}</b>
            </p>
            <div className={styles.rentalQuill}>
              <ReactQuill
                modules={{ toolbar: toolbarOptions }}
                theme="snow"
                value={agreementContract || ''}
                onChange={setAgreementContract}
                className={styles.richTextEditor}
                readOnly={isReadOnly}
              />
            </div>

            <span className={styles.agreementParagraph}>{footerText}</span>

            {!isReadOnly && (
              <Button
                type="ghost"
                isBordered={true}
                className={classNames({
                  [styles.buttonContainer]: true,
                })}
                onClick={handleSave}
              >
                <span className={styles.buttonText}>Save</span>
              </Button>
            )}
            {activeTab === 'Rental Contract' && (
              <Button
                type="default"
                isBordered={true}
                className={classNames({
                  [styles.buttonContainer]: true,
                })}
                onClick={handleRentalContractPrint}
              >
                <span className={styles.buttonText}>Print</span>
              </Button>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default RentalAgreement;
