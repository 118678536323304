import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TripBookingState } from './types';

export const initialState: TripBookingState = {
  end_time: '',
  start_time: '',
  location: '',
  vehicle_type_id: '',
};

const tripBookingSlice = createSlice({
  name: 'tripBooking',
  initialState,
  reducers: {
    setTripBooking: (
      state,
      action: PayloadAction<Partial<TripBookingState>>
    ) => {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
  extraReducers: {},
});

export const { setTripBooking } = tripBookingSlice.actions;

export default tripBookingSlice.reducer;
