import React, { useEffect, useState } from 'react';
import { Col, Row, Spin } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import { UploadOutlined } from '@ant-design/icons';
import moment from 'moment';

import Input from 'components/Input';
import FileUploader from 'components/FileUploader';
import Button from 'components/Button';

import styles from '../Home/account.module.scss';
import { transformToUploadFile } from 'uicontainers/provider/vehicles/details/helpers';
import {
  DatePicker,
  Form,
  FormItem,
  Text,
  Title,
  useForm,
} from 'components/misc/helpers';
import {
  usePersonalInfoSelectors,
  useUpdatePersonalInfo,
  useUploadData,
} from './hooks';
import { PersonalInfoType } from './types';

const PersonalInfo = () => {
  const updateAccount = useUpdatePersonalInfo();
  const upload = useUploadData();
  const { personalInfo, isLoading } = usePersonalInfoSelectors();

  const [profilePicture, setProfilePicture] = useState<UploadFile[]>([]);
  const [changedValues, setChangedValues] = useState<
    PersonalInfoType | undefined
  >(undefined);

  const [form] = useForm();

  useEffect(() => {
    const tempValues = {
      address: changedValues?.address || personalInfo.address,
      date_of_birth: changedValues?.date_of_birth
        ? moment(changedValues?.date_of_birth)
        : personalInfo.date_of_birth
        ? moment(personalInfo.date_of_birth)
        : '',
      email: changedValues?.email || personalInfo.email,
      first_name: changedValues?.first_name || personalInfo.first_name,
      last_name: changedValues?.last_name || personalInfo.last_name,
      phone_no: changedValues?.phone_no || personalInfo.phone_no,
      bank_account: changedValues?.bank_account || personalInfo.bank_account,
    };
    form.setFieldsValue(tempValues);
    setProfilePicture(transformToUploadFile(personalInfo.profile_picture));
  }, [personalInfo]);

  return (
    <div>
      <div className={styles.titleContainer}>
        <Title className={styles.title} level={2}>
          Personal Information
        </Title>
      </div>

      <Form
        className={styles.form}
        onFinish={(values: PersonalInfoType) => {
          const { date_of_birth, ...others } = values;
          updateAccount({
            ...others,
            date_of_birth: moment(date_of_birth).format(),
          });
          setChangedValues(undefined);
        }}
        onValuesChange={(changedValues) => {
          setChangedValues((prevState) => {
            return { ...prevState, ...changedValues };
          });
        }}
        form={form}
      >
        <Spin spinning={isLoading}>
          <div className={styles.nameContainer}>
            <Row gutter={[24, 0]}>
              <Col xs={24} lg={12}>
                <FormItem
                  name="first_name"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your first name!',
                    },
                  ]}
                >
                  <Input label="First Name" />
                </FormItem>
              </Col>
              <Col xs={24} lg={12}>
                <FormItem
                  name="last_name"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your last name!',
                    },
                  ]}
                >
                  <Input label="Last Name" />
                </FormItem>
              </Col>
            </Row>
            <span>Enter your name as it appears on your driver license</span>
          </div>

          <div className={styles.profileContainer}>
            <div className={styles.upperTitleContainer}>
              <Text strong>Profile Picture</Text>
              <br />
              <Text>Please provide a clear photo of your face</Text>
            </div>
            <FormItem
              rules={[
                {
                  required: true,
                  message: 'Image is required!',
                },
              ]}
            >
              <FileUploader
                fileType="image"
                name="profile_picture"
                multiple={false}
                maxCount={1}
                onUpload={upload}
                fileList={profilePicture}
                listType="picture-card"
                onRemove={() => {
                  updateAccount({
                    profile_picture: null,
                  });
                }}
                isDragger={false}
                className={styles.uploader}
              >
                <div className={styles.uploadButton}>
                  <UploadOutlined />
                  <span>Click To Upload</span>
                </div>
              </FileUploader>
            </FormItem>
          </div>

          <div>
            <div className={styles.areaTitle}>
              <Text strong>Date of Birth</Text>
            </div>
            <FormItem
              name="date_of_birth"
              rules={[
                {
                  required: true,
                  message: 'Please input your date of birth!',
                },
              ]}
            >
              <DatePicker />
            </FormItem>
          </div>
          <FormItem
            name="email"
            rules={[
              {
                required: true,
                message: 'Please input your email!',
              },
            ]}
          >
            <Input label="Email" disabled />
          </FormItem>
          <FormItem
            name="phone_no"
            rules={[
              {
                required: true,
                message: 'Please input your phone number!',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (/[a-zA-Z]/.test(value)) {
                    return Promise.reject('Invalid phone number');
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input label="Phone" />
          </FormItem>
          <FormItem
            name="address"
            rules={[
              {
                required: true,
                message: 'Please input your address!',
              },
            ]}
          >
            <Input label="Address" />
          </FormItem>

          <FormItem
            name="bank_account"
            rules={[
              {
                required: true,
                message: 'Please input your bank account!',
              },
            ]}
          >
            <Input label="Bank Account" />
          </FormItem>

          <Button
            className={styles.button}
            type="ghost"
            isBordered={true}
            htmlType="submit"
          >
            <div className={styles.textContainer}>
              <span className={styles.buttonTextGhost}>Save</span>
            </div>
          </Button>
        </Spin>
      </Form>
    </div>
  );
};

export default PersonalInfo;
