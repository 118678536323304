import { Col, Row, Typography } from 'antd';
import React, { FC } from 'react';

import Button from 'components/Button';
import { Alert, Spin } from 'components/misc/helpers';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getStripeOnboardingLinkAsync } from 'store/shared/auth/actions';

import { selectGetStripeOnboardingLinkStatus, selectStripeOnboardingLink } from './selectors';
import styles from './setupPayment.module.scss';
import { Props } from './types';
import { getProfileAsync } from 'store/customer/account/actions';

const { Title } = Typography;

const SetupPayment: FC<Props> = (props) => {
  const { isRedirected } = props;
  const isLoading = useAppSelector(selectGetStripeOnboardingLinkStatus) === 'started';
  const stripeOnboardingLink = useAppSelector(selectStripeOnboardingLink);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(getStripeOnboardingLinkAsync());
    dispatch(getProfileAsync());
  }, []);

  const handleOnClick = () => {
    window.location.href = stripeOnboardingLink;
  }

  return (
    <Spin spinning={isLoading}>
      <Row align="middle">
        <Col xs={20} sm={20} md={20} lg={7} className={styles.container}>
          {isRedirected && <Alert message="You were redirected to this page because the link is not valid anymore. Please continue onboarding
            by clicking the button below." type="info" showIcon />}          
          <Title level={3} className={styles.title}>
            Connect your account to Stripe
          </Title>
          <Col className="gutter-row" span={24}>
            <p className={styles.paragraph}>
              We use Stripe to make sure you get paid on time and keep your
              personal and bank details secure.
            </p>
          </Col>
          <Row gutter={[16, 8]}>
            <Col className="gutter-row" span={24}>
              <Button onClick={handleOnClick} type="ghost" isBordered={true} style={{ width: '100%' }}>
                SET UP PAYMENTS
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Spin>
  );
};

export default SetupPayment;
