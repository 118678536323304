import { Col, Row, Typography } from 'antd';
import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import VehicleCard from 'components/VehicleCard';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectSearchedVehicles } from 'store/tripBooking/home/selectors';
import { getSearchedVehiclesAsync } from 'store/tripBooking/home/thunks';
import { ROUTES } from 'shared/routes/constants';

const { Text } = Typography;

const SimilarCars = () => {
  const { vehicleId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const vehicles = useAppSelector(selectSearchedVehicles);
  React.useEffect(() => {
    dispatch(getSearchedVehiclesAsync());
  }, []);
  return (
    <Row gutter={[0, 16]}>
      <Col span={24}>
        <Text strong>You might also like</Text>
      </Col>
      <Col span={24}>
        <Row gutter={[16, 0]}>
          {vehicles.length > 0 &&
            vehicles
              .filter((item) => item.id.toString() !== vehicleId)
              .slice(0, 3)
              .map((vehicle) => (
                <Col key={vehicle.id} xs={24} sm={24} md={8} lg={6} xl={6}>
                  <VehicleCard
                    title={
                      vehicle.vehicle_make +
                      ' ' +
                      vehicle?.vehicle_model +
                      ' ' +
                      vehicle?.vehicle_year
                    }
                    isNew={vehicle.new}
                    cardImage={vehicle.featured_image}
                    rating={vehicle.rating}
                    tripCount={vehicle.trips}
                    perDayCost={vehicle.daily_price}
                    onClick={() => {
                      navigate(
                        ROUTES.VEHICLE_DETAILS_ID(vehicle.id.toString())
                      );
                    }}
                  />
                </Col>
              ))}
        </Row>
      </Col>
    </Row>
  );
};

export default SimilarCars;
