import { createAsyncThunk } from '@reduxjs/toolkit';
import { updateAccount, getProfileDetails, uploadData, changePassword, createVerification } from 'api/customer/account';
import { RecursivePartial, PostChangePasswordData, PostCreateVerification } from 'api/customer/account/types';
import { Account } from 'api/customer/account/types';
import { CUSTOMER_ACCOUNT } from './constants';

export const updateAccountAsync = createAsyncThunk(
  `${CUSTOMER_ACCOUNT}/updateAccount`,
  async ({ data, id }: { data: RecursivePartial<Account>; id: number }) => {
    const response = await updateAccount(data, id);
    return response;
  },
);

export const getProfileAsync = createAsyncThunk(`${CUSTOMER_ACCOUNT}/getProfile`, async () => {
  const response = await getProfileDetails();
  return response;
});

export const uploadDataAsync = createAsyncThunk(
  `${CUSTOMER_ACCOUNT}/uploadData`,
  async ({ data, id }: { data: FormData; id: number }) => {
    const response = await uploadData(data, id);
    return response;
  },
);

export const changePasswordAsync = createAsyncThunk(
  `${CUSTOMER_ACCOUNT}/changePassword`,
  async (data: PostChangePasswordData, { rejectWithValue }) => {
    try {
      const response = await changePassword(data);
      return response;
    } catch (e) {
      return rejectWithValue(e.error_data);
    }
  },
);

//create verification session
export const createVerificationAsync = createAsyncThunk(
  `${CUSTOMER_ACCOUNT}/create-verification-session`,
  async (data: PostCreateVerification) => {
    const response = await createVerification(data);
    return response;
  }
);
