import { combineReducers as _ } from 'redux';

import earningReducer from './dashboard/Earning/reducer';
import { vehicleReducer } from './vehicles/vehicleReducer';

export const adminReducer = _({
  vehicle: vehicleReducer,
  dashboard: _({
    earning: earningReducer,
  }),
});

export default adminReducer;
