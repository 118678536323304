import {
  CarOutlined,
  DashboardOutlined,
  DollarOutlined,
  HistoryOutlined,
  SolutionOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import React from 'react';

import { ROUTES } from 'shared/routes/constants';

export const getSideBarMenu = () => [
  {
    title: 'Dashboard',
    icon: <DashboardOutlined />,
    link: ROUTES.PATHS.ADMIN.ADMIN_DASHBOARD,
  },
  {
    title: 'Vehicles',
    icon: <CarOutlined />,
    link: ROUTES.PATHS.ADMIN.ADMIN_VEHICLES,
  },
  {
    title: 'Providers',
    icon: <SolutionOutlined />,
    link: ROUTES.PATHS.ADMIN.ADMIN_PROVIDERS,
  },
  {
    title: 'Customers',
    icon: <TeamOutlined />,
    link: ROUTES.PATHS.ADMIN.ADMIN_CUSTOMERS,
  },
  {
    title: 'Trip History',
    icon: <HistoryOutlined />,
    link: ROUTES.PATHS.ADMIN.ADMIN_TRIP_HISTORY,
  },
  {
    title: 'Billing',
    icon: <DollarOutlined />,
    link: ROUTES.PATHS.ADMIN.ADMIN_BILLING,
  },
  // {
  //   title: 'Toll and Tickets',
  //   icon: <FileDoneOutlined />,
  //   link: ROUTES.PATHS.ADMIN.ADMIN_TOLL_AND_TICKETS,
  // },
  // {
  //   title: 'Rental Agreement',
  //   icon: <AuditOutlined />,
  //   link: ROUTES.PATHS.ADMIN.ADMIN_RENTAL_AGREEMENT,
  // },
];
