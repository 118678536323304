import { createAsyncThunk } from '@reduxjs/toolkit';

import { getBillings, getCurrentBalance, payOut } from 'api/provider/billing';
import { BillingRequestParams, PayOutAmount } from 'api/provider/billing/types';

export const getBillingsAsync = createAsyncThunk(
  'provider/billing/get_billings',
  async (params?: BillingRequestParams) => {
    const response = await getBillings(params);
    return response;
  },
);

export const getCurrentBalanceAsync = createAsyncThunk(`provider/billing/get_current_balance`, async () => {
  const response = await getCurrentBalance();
 return response;
});

export const handlePayOutAsync = createAsyncThunk(
  `provider/billing/pay_out`,
  async (data: PayOutAmount) => {
    const response = await payOut(data);
    return response;
  }
);
