import React, { FC } from "react";
import { useNavigate } from "react-router-dom";

import NXCarousel from "components/NXCarousel";
import VehicleCard from "components/VehicleCard";
import { ROUTES } from "shared/routes/constants";

import Section from "../Section";
import styles from "./featuredVehicles.module.scss";
import { Props } from "./types";

const FeaturedVehicles: FC<Props> = (props) => {
  const { vehicles } = props;
  const navigate = useNavigate();

  return (
    <Section title="Featured Vehicles">
      <NXCarousel totalCount={vehicles.length}>
        {vehicles.map((vehicle, i) => (
          <div key={vehicle.id} className={styles.carouselItem}>
            <VehicleCard
              title={
                vehicle.vehicle_make +
                " " +
                vehicle?.vehicle_model +
                " " +
                vehicle?.vehicle_year
              }
              cardImage={vehicle.featured_image}
              rating={vehicle.rating}
              tripCount={vehicle.trips}
              perDayCost={vehicle.daily_price}
              isNew={vehicle.new}
              onClick={() => {
                vehicle && navigate(ROUTES.VEHICLE_DETAILS_ID(vehicle.id.toString()));
              }}
            />
          </div>
        ))}
      </NXCarousel>
    </Section>
  );
};

export default FeaturedVehicles;
