import { Spin, Typography } from 'antd';
import React from 'react';

import Calendar from 'components/Calendar';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getCalendarsAsync } from 'store/provider/calendars/actions';

import styles from './calendar.module.scss';
import { selectCalendars, selectCalendarsRequestStatus } from './selectors';
import { transformCalendarToEvents } from './helpers';

const CalendarContainer = () => {
  const Title = Typography.Title;
  const calendars = useAppSelector(selectCalendars);
  const isCalendarsLoading = useAppSelector(selectCalendarsRequestStatus) === 'started';
  const events = transformCalendarToEvents(calendars);

  const dispatch = useAppDispatch();
  React.useEffect(() => {
    dispatch(getCalendarsAsync());
  }, []);

  return (
    <div className={styles.calendarBody}>
      <Title level={3}>Calendar</Title>
      <Spin spinning={isCalendarsLoading}>
        <Calendar events={events} />
      </Spin>
    </div>
  );
};

export default CalendarContainer;
