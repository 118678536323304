import React, { FC } from "react";
import { Menu } from "antd";

import { useUpdateVehicleBasicInfo } from "./hooks";
import { MenuActionsProps } from "./types";

const MenuActions: FC<MenuActionsProps> = (props) => {
  const { vehicle, setVisible } = props;
  const updateVehicle = useUpdateVehicleBasicInfo();

  // @ts-ignore
  const handleStatusChange = (info: MenuInfo) => {
    setVisible(false);
    if (info.key === "request_for_approval") {
      updateVehicle({ status: "REQUEST_FOR_APPROVAL" }, vehicle.id);
    }    
    if (info.key === "inactive") {
      updateVehicle({ status: "INACTIVE" }, vehicle.id);
    }
  };
  return (
    <Menu onClick={handleStatusChange}>
      {vehicle?.status === "INACTIVE" && (
        <Menu.Item key="request_for_approval">Request for approval</Menu.Item>
      )}      
      {vehicle?.status === "ACTIVE" && (
        <Menu.Item key="inactive">Inactive</Menu.Item>
      )}
    </Menu>
  );
};

export default MenuActions;
