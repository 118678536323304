import { combineReducers as _ } from 'redux';
import personalInfoReducer from './PersonalInfo/slice';
import businessInfoReducer from './BusinessInfo/slice';
import pointIdentificationReducer from './PointIdentification/slice';

const accountReducer = _({
  personal: personalInfoReducer,
  business: businessInfoReducer,
  pointIdentification: pointIdentificationReducer,
});

export default accountReducer;
