import React, { FC, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { RightOutlined } from '@ant-design/icons';

import {
  Title,
  Form,
  Spin,
  FormItem,
  useForm,
  Alert,
} from 'components/misc/helpers';
import Input from 'components/Input';
import Switch from 'components/Switch';
import RadioButton from 'components/RadioButton';
import Button from 'components/Button';

import TopSection from '../TopSection';
import LeftSection from '../LeftSection';
import { useUpdateCarPricing, useCarPricingSelectors } from './hooks';

import styles from '../vehicles.module.scss';
import { DISCOUNT_TYPE } from '../constants';
import { useGetVehicleDetails } from '../hooks';

const Pricing: FC = () => {
  const [form] = useForm();
  const { vehicleId } = useParams();

  const { carPricing, id, getVehicleRequestStatus } = useCarPricingSelectors();
  const updatePrice = useUpdateCarPricing(id);

  const [provideDiscount, setProvideDiscount] = useState(
    carPricing.has_discount
  );
  const [discountType, setDiscountType] = useState(carPricing?.discount_type);
  const isLoading = getVehicleRequestStatus === 'started';

  const getVehicleDetails = useGetVehicleDetails();

  useEffect(() => {
    if (vehicleId) {
      getVehicleDetails(Number(vehicleId));
    }
  }, [vehicleId]);

  useEffect(() => {
    form.setFieldsValue(carPricing);
    setProvideDiscount(carPricing.has_discount);
    setDiscountType(carPricing?.discount_type);
  }, [carPricing]);

  const isDisable = carPricing.status !== 'INACTIVE';

  return (
    <div
      className={classNames({
        [styles.container]: true,
      })}
    >
      <TopSection />
      <Alert
        message="Jaunt will take 20% from the payment and 5% surcharge will be added on total cost. So please set your price based on that"
        type="info"
      />
      <div className={styles.vehicle}>
        <LeftSection />
        <div
          className={classNames({
            [styles.formContainer]: true,
          })}
        >
          <Spin spinning={isLoading}>
            <Title level={2}>Pricing</Title>
            <Form
              form={form}
              className={styles.form}
              initialValues={carPricing}
              onFinish={(value) => {
                const { discount, ...others } = value;
                updatePrice({
                  ...others,
                  discount: provideDiscount ? discount : 0,
                  has_discount: provideDiscount,
                });
              }}
            >
              <FormItem
                name="daily_price"
                rules={[{ required: true, message: 'Price is required' }]}
              >
                <Input
                  addonBefore="$"
                  addonAfter="/day"
                  label="Daily Price"
                  type="number"
                  min="1"
                  disabled={isDisable}
                />
              </FormItem>
              <FormItem
                name="security_money"
                rules={[
                  { required: true, message: 'Security money is required' },
                ]}
              >
                <Input
                  addonBefore="$"
                  label="Security Money"
                  type="number"
                  disabled={isDisable}
                />
              </FormItem>

              <FormItem name="has_discount">
                <Switch
                  title="Discount"
                  checked={provideDiscount}
                  onChange={(checked) => setProvideDiscount(checked)}
                  disabled={isDisable}
                />
              </FormItem>
              {provideDiscount && (
                <FormItem name="discount_type">
                  <RadioButton
                    disabled={isDisable}
                    title="Discount Type"
                    options={[
                      { label: 'Percentage', value: DISCOUNT_TYPE.PERCENTAGE },
                      { label: 'Fixed Amount', value: DISCOUNT_TYPE.FIXED },
                    ]}
                    defaultValue={discountType}
                    onChange={(e) => setDiscountType(e.target.value)}
                  />
                </FormItem>
              )}
              {provideDiscount && (
                <FormItem name="discount">
                  <Input
                    disabled={isDisable}
                    label={
                      discountType === 'PERCENTAGE'
                        ? 'Percentage'
                        : 'Fixed Amount'
                    }
                    addonAfter={discountType === 'PERCENTAGE' ? '%' : ''}
                    className={styles.percentageInput}
                  />
                </FormItem>
              )}

              {!isDisable && (
                <Button
                  className={styles.button}
                  type="default"
                  isBordered={true}
                  htmlType="submit"
                  disabled={isDisable}
                >
                  <div className={styles.textContainer}>
                    <span className={styles.buttonText}>Save and Continue</span>
                    <RightOutlined className={styles.rightIcon} />
                  </div>
                </Button>
              )}
            </Form>
          </Spin>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
