import { publicAPI } from "api/base";

import { GetVehicleDetails, BookCar } from "./types";

export const getVehicleDetails: GetVehicleDetails = (id) => {
  return publicAPI.get(`/vehicle-details/${id}/`);
};

export const bookCar: BookCar = (data) => {
  return publicAPI.post("/booking/", data);
};
