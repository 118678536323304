import { Spin } from 'antd';
import React from 'react';

import IXTable from 'components/tables/IXTable';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getRecentBookingAsync } from 'store/provider/dashboard/actions';

import { COLUMNS } from './constants';
import { getRecentBookingTableData } from './helpers';
import { selectRecentBooking, selectRecentBookingRequestStatus } from './selectors';
import NXCard from 'components/NXCard';
import styles from './recentBooking.module.scss';

const RecentBooking = () => {
  const recentBookingData = getRecentBookingTableData(useAppSelector(selectRecentBooking));
  const isRecentBookingLoading = useAppSelector(selectRecentBookingRequestStatus) === 'started';

  const dispatch = useAppDispatch();
  React.useEffect(() => {
    dispatch(getRecentBookingAsync());
  }, []);

  return (
    <Spin spinning={isRecentBookingLoading}>
      <NXCard headerTitle="Recent Bookings" cssClassNames={styles.providerDashboardRecentBooking}>
        <IXTable data={recentBookingData} columns={COLUMNS} />
      </NXCard>
    </Spin>
  );
};

export default RecentBooking;
