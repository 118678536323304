import React from 'react';
import message from 'antd/lib/message';
import {
  useUnwrapAsyncThunk,
  useAppDispatch,
  useAppSelector,
} from 'store/hooks';
import {
  updateVehicleAsync,
  getVehiclesAsync,
} from 'store/provider/vehicles/actions';

import { BasicInfo } from './types';
import { selectQueryParams } from 'store/provider/vehicles/selectors';

export const useUpdateVehicleBasicInfo = () => {
  const unwrap = useUnwrapAsyncThunk();
  const dispatch = useAppDispatch();
  const queryParams = useAppSelector(selectQueryParams);

  return React.useCallback(
    async (basicInfo: BasicInfo, id: number) => {
      try {
        await unwrap(updateVehicleAsync({ ...basicInfo, vehicleId: id }));

        await dispatch(getVehiclesAsync(queryParams));

        message.success('Vehicle information saved successfully');
      } catch (e) {
        //@ts-ignore
        message.error(e.message);
      }
    },
    [updateVehicleAsync]
  );
};
