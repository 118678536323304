import {
  createVehicleAsync,
  deleteVehicleImageAsync,
  getVehiclesAsync,
  updateVehicleAsync,
  upload,
  getVehicleDetailsAsync,
  getInsuranceCompanyAsync,
  getVehicleTypesAsync,
  getVehicleMakeAsync,
  getVehicleModelAsync,
} from 'store/provider/vehicles/thunks';

import slice from './slice';

const { setCurrentPage, setQueryParams } = slice.actions;

export {
  getVehiclesAsync,
  createVehicleAsync,
  updateVehicleAsync,
  upload,
  deleteVehicleImageAsync,
  setCurrentPage,
  getVehicleDetailsAsync,
  getInsuranceCompanyAsync,
  getVehicleTypesAsync,
  getVehicleModelAsync,
  getVehicleMakeAsync,
  setQueryParams,
};
