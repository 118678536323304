import axios, { AxiosRequestConfig } from 'axios';

import { InterceptErrorResponse, InterceptSuccessRequest, InterceptSuccessResponse } from './types';

export const DEFAULT_API_TIMEOUT = 60000;

export const axiosCreate = (params: AxiosRequestConfig = {}) => {
  const { baseURL } = params;

  return (
    successResponse: InterceptSuccessResponse,
    errorResponse?: InterceptErrorResponse,
    successRequest?: InterceptSuccessRequest,
  ) => {
    const instance = axios.create({
      baseURL,
      timeout: DEFAULT_API_TIMEOUT,
      withCredentials: false,
    });

    instance.interceptors.request.use(successRequest);
    instance.interceptors.response.use(successResponse, errorResponse);

    return instance;
  };
};
