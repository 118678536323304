import { createAsyncThunk } from '@reduxjs/toolkit';

import { getTickets } from 'api/provider/tickets';
import { TicketsRequestParams } from 'api/provider/tickets/types';

export const getTicketsAsync = createAsyncThunk(
  'provider/ticket/get_tickets',
  async (params?: TicketsRequestParams) => {
    const response = await getTickets(params);
    return response;
  },
);
