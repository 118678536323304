import { createAction } from '@reduxjs/toolkit';

import { CarDetail } from './types';
import carDetailSlice from './slice';

const { resetCarDetails } = carDetailSlice.actions;

export const updateCarDetail = createAction<CarDetail>('form/vehicle/update_car_detail');

export { resetCarDetails };
