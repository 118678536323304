import { createSelector } from 'reselect';

import { selectBookings } from 'store/customer/bookings/selectors';
import { RootState } from 'store/types';

import { transformBookingsToTableData } from './helpers';

export const createSelectBookingsTableData = createSelector(
  selectBookings,
  transformBookingsToTableData,
);
export const selectTotalCount = (state: RootState) => state.resources.customer.bookings.totalCount;