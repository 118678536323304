import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import isEqual from 'lodash/isEqual';

import { RootState } from 'store/types';
import { selectPersonalInfo, selectStatus } from './selectors';
import { useUnwrapAsyncThunk } from 'store/hooks';
import { USER_ID } from 'api/base/constants';
import { updateAccountAsync, uploadDataAsync } from 'store/customer/account/actions';
import { PersonalInfoType } from './types';
import { message } from 'antd';

export const usePersonalInfoSelectors = () => {
  return useSelector((state: RootState) => {
    return {
      personalInfo: selectPersonalInfo(state),
      isLoading: isEqual(selectStatus(state), 'started'),
    };
  });
};

export const useUpdatePersonalInfo = () => {
  const unwrap = useUnwrapAsyncThunk();

  return useCallback((personalInfo: Partial<PersonalInfoType>) => {
    const userId = localStorage.getItem(USER_ID);

    if (userId) {
      unwrap(updateAccountAsync({ data: personalInfo, id: parseInt(userId, 10) }))
      .then(() => {
        message.success('Personal info saved successfully');
      })
      .catch((e) => {
        message.error('update failed');
      });
    }
  }, []);
};

export const useUploadData = () => {
  const unwrap = useUnwrapAsyncThunk();

  return useCallback(async (data: FormData) => {
    const userId = localStorage.getItem(USER_ID);

    if (userId) {
      unwrap(uploadDataAsync({ data, id: parseInt(userId, 10) }));
    }
  }, []);
};
