import { combineReducers as _ } from 'redux';
import basicInfo from './details/BasicInfo/slice';
import carDetail from './details/CarDetail/slice';
import carPricingSlice from './details/Pricing/slice';
import photoSlice from './details/Photos/slice';
import carProtectionSlice from './details/Protection/slice';
import carDocumentSlice from './details/Documents/slice';

export const vehicleReducer = _({
  basicInfo: basicInfo.reducer,
  detail: carDetail.reducer,
  pricing: carPricingSlice.reducer,
  photos: photoSlice.reducer,
  protection: carProtectionSlice.reducer,
  documents: carDocumentSlice.reducer,
});
