import { EnvironmentOutlined, SearchOutlined } from '@ant-design/icons';
import { Col, Input, Row } from 'antd';
import classNames from 'classnames';
import React from 'react';

import Button from 'components/Button';
import SelectInput from 'components/SelectInput';
import { useIsMobile } from 'shared/hooks';

import styles from './searchBar.module.scss';
import { Props } from './types';

const SearchBar = (props: Props) => {
  const {
    onLocationChange,
    onVehicleTypeChange,
    vehicleTypesOptions: vehicleTypes,
    selectedLocationValue,
    selectedVehicleType,
    onSearch,
  } = props;
  const isMobile = useIsMobile();

  return (
    <Row className={styles.searchContainer} align="middle">
      <Col xs={20} sm={20} md={16} lg={16}>
        <Input
          size="large"
          className={classNames({
            [styles.searchButton]: false,
            [styles.borderLess]: true,
          })}
          prefix={<EnvironmentOutlined />}
          onChange={onLocationChange}
          value={selectedLocationValue}
          placeholder="Type pickup location"
        ></Input>
      </Col>
      <Col xs={0} sm={0} md={6} lg={6}>
        <SelectInput
          size="large"
          className={classNames({
            [styles.borderLess]: true,
          })}
          options={vehicleTypes || []}
          bordered={false}
          value={selectedVehicleType || undefined}
          placeholder="Select vehicle type"
          onChange={onVehicleTypeChange}
          showArrow={false}
        />
      </Col>
      <Col xs={4} sm={4} md={2} lg={2} className={styles.alignRight}>
        <Button
          shape="circle"
          type="ghost"
          size="large"
          icon={<SearchOutlined />}
          onClick={onSearch}
          className={classNames({
            [styles.searchButton]: true,
            [styles['is-mobile']]: isMobile,
          })}
        ></Button>
      </Col>
    </Row>
  );
};

export default SearchBar;
