import { Column } from 'components/tables/IXTable/types';

export const COLUMNS: Column[] = [
  {
    name: 'Provider',
    key: 'provider',
    type: 'text',
  },
  {
    name: 'Earnings',
    key: 'earnings',
    type: 'text',
  },
];
