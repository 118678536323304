import { createAsyncThunk } from '@reduxjs/toolkit';
import { getProviderInfo } from 'api/tripBooking/providerDetails/index';

export const getProviderInfoAsync = createAsyncThunk(
  'customer/trip_booking/vehicle_provider_details',
  async (id?: number | null) => {
    const response = await getProviderInfo(id);
    return response;
  },
);
