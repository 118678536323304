import React from 'react';
import { Tabs } from 'antd';
import EligibilityCriteria from './EligibilityCriteria';
import BookingCar from './BookingCar';
import TakingTrip from './TakingTrip';
import RentCar from './RentCar';
import ManageCar from './ManageCar';
import DamageCover from './DamageCover';
import FuelTollsFines from './FuelTollsFines';
import YourAccount from './YourAccount';
import { useNavigate, useParams } from 'react-router-dom';
import { useIsMobile } from 'shared/hooks';
import styles from './faqDetails.module.scss';
import { ROUTES } from 'shared/routes/constants';

const { TabPane } = Tabs;

function FaqDetails() {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const { key } = useParams();
  return (
    <div>
      <Tabs
        tabPosition={isMobile ? 'top' : 'left'}
        defaultActiveKey={'1'}
        activeKey={key}
        onChange={(key) => {
          navigate(ROUTES.FAQ_DETAILS_KEY(key));
        }}
        className={styles.tabContainer}
      >
        <TabPane tab="Check the eligibility criteria" key="1">
          <EligibilityCriteria />
        </TabPane>
        <TabPane tab="Booking a Car" key="2">
          <BookingCar />
        </TabPane>
        <TabPane tab="Taking a trip" key="3">
          <TakingTrip />
        </TabPane>
        <TabPane tab="How to rent out your car?" key="4">
          <RentCar />
        </TabPane>
        <TabPane tab="Managing your car" key="5">
          <ManageCar />
        </TabPane>
        <TabPane tab="Damage cover and repairs" key="6">
          <DamageCover />
        </TabPane>
        <TabPane tab="Fuel, tolls and fines" key="7">
          <FuelTollsFines />
        </TabPane>
        <TabPane tab="Your account" key="8">
          <YourAccount />
        </TabPane>
      </Tabs>
    </div>
  );
}

export default FaqDetails;
