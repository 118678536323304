import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { message } from 'antd';

import { useUnwrapAsyncThunk, useAppSelector, useActions } from 'store/hooks';

import { createVehicleAsync, updateVehicleAsync } from 'store/provider/vehicles/actions';
import {
  selectBasicInfo,
  selectPostRequestStatus,
  selectGetVehicleDetailsStatus,
  selectVehicleTypes,
  selectVehicleMake,
  selectVehicleModel,
} from './selectors';

import { getVehicleSettingsSidebarMenu } from '../constants';
import { BasicInfo } from './types';
import { resetBasicInfo } from './actions';

export const useBasicInfoActions = () => {
  return useActions({
    resetBasicInfo,
  });
};

export const useVehicleInfoSelectors = () =>
  useAppSelector((state) => {
    return {
      basicInfo: selectBasicInfo(state),
      postBasicInfoRequestStatus: selectPostRequestStatus(state),
      getVehicleDetailsRequestStatus: selectGetVehicleDetailsStatus(state),
      vehicleTypes: selectVehicleTypes(state),
      vehicleMakes: selectVehicleMake(state),
      vehicleModels: selectVehicleModel(state),
    };
  });

export const useVehicleBasicInfoCreate = () => {
  const unwrap = useUnwrapAsyncThunk();
  const navigate = useNavigate();

  return useCallback(
    (basicInfo: BasicInfo) => {
      unwrap(createVehicleAsync(basicInfo))
        .then((response) => {
          message.success('Vehicle information saved successfully', 0.5).then(() => {
            navigate(getVehicleSettingsSidebarMenu(response.id.toString())[1].link);
          });
        })
        .catch((e) => {
          message.error('Request Failed');
        });
    },
    [createVehicleAsync],
  );
};

export const useUpdateVehicleBasicInfo = () => {
  const unwrap = useUnwrapAsyncThunk();
  const navigate = useNavigate();

  return useCallback(
    (basicInfo: BasicInfo, id: number) => {
      return unwrap(updateVehicleAsync({ ...basicInfo, vehicleId: id }))
        .then((response) => {
          message.success('Vehicle information saved successfully', 0.5).then(() => {
            navigate(getVehicleSettingsSidebarMenu(response.id.toString())[1].link);
          });
        })
        .catch((e) => {
          message.error(e.message);
        });
    },
    [updateVehicleAsync],
  );
};
