import { useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { selectVehicleId } from 'store/provider/vehicles/selectors';
import { updateVehicleAsync, upload } from 'store/provider/vehicles/actions';
import { useActions, useUnwrapAsyncThunk, useAppSelector } from 'store/hooks';
import {
  selectCarProtection,
  selectGetVehicleDetailsStatus,
  selectInsuranceCompanyOptions,
  selectGetInsurancesStatus,
} from './selectors';
import { resetCarProtection } from './actions';
import { Protection } from './types';
import { resetBasicInfo } from '../BasicInfo/actions';
import { resetCarDetails } from '../CarDetail/actions';
import { resetCarDocuments } from '../Documents/actions';
import { resetCarPhotos } from '../Photos/actions';
import { resetCarPricing } from '../Pricing/actions';
import { message } from 'antd';

export const useCarProtectionActions = () => {
  return useActions({
    updateVehicleAsync,
    upload,
    resetBasicInfo,
    resetCarDetails,
    resetCarDocuments,
    resetCarPhotos,
    resetCarPricing,
    resetCarProtection,
  });
};

export const useCarProtectionSelectors = () => {
  return useAppSelector((state) => {
    return {
      protectionPlan: selectCarProtection(state),
      id: selectVehicleId(state),
      getVehicleRequestStatus: selectGetVehicleDetailsStatus(state),
      insuranceCompanies: selectInsuranceCompanyOptions(state),
      getInsuranceRequestStatus: selectGetInsurancesStatus(state),
    };
  });
};

export const useUpdateCarProtectionPlan = (id?: number) => {
  const {
    resetBasicInfo,
    resetCarDetails,
    resetCarDocuments,
    resetCarPhotos,
    resetCarPricing,
    resetCarProtection,
  } = useCarProtectionActions();

  const unwrap = useUnwrapAsyncThunk();
  const navigate = useNavigate();
  const location = useLocation();


  return useCallback(
    (protectionPlan: Protection) => {
      if (id) {
        unwrap(
          updateVehicleAsync({
            ...protectionPlan,
            vehicleId: id,
          }),
        )
          .then(() => {
            message.success('Vehicle information saved successfully', 0.5).then(() => {
              resetCarProtection();
              resetBasicInfo();
              resetCarDetails();
              resetCarDocuments();
              resetCarPhotos();
              resetCarPricing();
              //check if it is the last tab to submit
              if(location.pathname.includes('protection')){
                navigate('/provider/vehicles');
              }
            });
          })
          .catch((e) => {
            console.log('error', e);
          });
      }
    },
    [id],
  );
};

export const useUpdateCaInsurancePaper = (id?: number) => {
  const { upload } = useCarProtectionActions();

  return useCallback(
    async (formData: FormData) => {
      if (id) {
        await upload({ data: formData, vehicleId: id });
      }
    },
    [upload],
  );
};

export const useDeleteInsurancePaper = (id?: number) => {
  const { updateVehicleAsync } = useCarProtectionActions();

  return useCallback(
    (data: Partial<Protection>) => {
      if (id) {
        updateVehicleAsync({ ...data, vehicleId: id });
      }
    },
    [updateVehicleAsync, id],
  );
};
