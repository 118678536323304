import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getTicketsAsync } from './thunks';
import { AdminTicketsState } from './types';

const initialState: AdminTicketsState = {
  tickets: [],
  getTicketsRequestStatus: 'initial',
  totalCount: 0,
  currentPage: 1,
};

export const adminTicketSlice = createSlice({
  name: 'admin-tickets',
  initialState,
  reducers: {
    setCurrentPage(state, action: PayloadAction<number>) {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTicketsAsync.pending, (state) => {
      state.getTicketsRequestStatus = 'started';
    });
    builder.addCase(getTicketsAsync.fulfilled, (state, action) => {
      state.tickets = action.payload.results;
      state.getTicketsRequestStatus = 'done';
    });
    builder.addCase(getTicketsAsync.rejected, (state) => {
      state.getTicketsRequestStatus = 'failed';
    });
  },
});

export default adminTicketSlice;
