import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Layout } from 'antd';
import Admin from 'pages/Admin';
import Customer from 'pages/Customer';
import Provider from 'pages/Provider';
import Public from 'pages/Public';
import User from 'pages/User';
import styles from './app.module.scss';
import NotFound from 'uicontainers/NotFound';
import { useAppDispatch } from 'store/hooks';
import { ACCESS_TOKEN_NAME } from 'api/base/constants';
import { verifyTokenAsync } from 'store/shared/auth/thunks';

const App = () => {
  const dispatch = useAppDispatch();
  const token = localStorage.getItem(ACCESS_TOKEN_NAME) || '';
  React.useEffect(() => {
    dispatch(verifyTokenAsync(token));
  }, []);
  return (
    <Layout className={styles.layout}>
      <Routes>
        {Public()}
        {Provider()}
        {Customer()}
        {Admin()}
        {User()}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Layout>
  );
};

export default App;
