import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Typography, Row, Col, Grid, Spin } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useUnwrapAsyncThunk } from 'store/hooks';
import { resetBooking } from "store/customer/booking/actions";
import { resetVehicleDetails } from 'store/tripBooking/vehicleDetails/actions';


import { useAppSelector, useAppDispatch } from "store/hooks";
import { getVehicleDetailsAsync } from "store/tripBooking/vehicleDetails/thunks";
import { getBookingDetailsAsync } from "store/customer/booking/thunks";
import { selectVehicleDetails, selectVehicleDetailsRequestStatus } from 'store/tripBooking/vehicleDetails/selectors';
import { selectBooking ,selectBookingRequestStatus} from "store/customer/booking/selectors";

import styles from "./home.module.scss";
import Details from "uicontainers/tripBooking/vehicleDetails/Details/Details";
import Book from "uicontainers/tripBooking/vehicleDetails/Book/Book";
import { ROUTES } from "shared/routes/constants";

const { Title } = Typography;


const Home = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const unwrap = useUnwrapAsyncThunk();
  const vehicleDetails = useAppSelector(selectVehicleDetails);
  const bookingDetails = useAppSelector(selectBooking);
  const bookingDetailsRequestStatus = useAppSelector(selectBookingRequestStatus);
  const vehicleDetailsRequestStatus = useAppSelector(selectVehicleDetailsRequestStatus);

  const isLoading =
    vehicleDetailsRequestStatus === 'started' ||
    bookingDetailsRequestStatus === 'started';

  const { vehicleId } = useParams();
  const { bookingId } =  useParams();
  const [routeToGo, setrouteTogo] = useState<string>(ROUTES.PATHS.SEARCH);

  useEffect(()=>{
    return () => {
      dispatch(resetBooking());
      dispatch(resetVehicleDetails());  
        }
  },[])

  useEffect(() => {
    if(vehicleId){
      unwrap(getVehicleDetailsAsync(vehicleId)).then(() => {
            //reset booking details
            dispatch(resetBooking());
          })
          .catch((e) => {
            console.log('error', e);
          });
      setrouteTogo(ROUTES.PATHS.SEARCH);
      window.scrollTo(0, 0);
    }
    if(bookingId){
      unwrap(getBookingDetailsAsync(parseInt(bookingId))).then(() => {
            //reset vehicle details
            dispatch(resetVehicleDetails());
          })
          .catch((e) => {
            console.log('error', e);
          });
      setrouteTogo("/customer/bookings");
      window.scrollTo(0, 0);
    }
  }, [vehicleId, bookingId]);

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <Spin spinning={isLoading}>
          <div className={styles.navigation}>
          <ArrowLeftOutlined onClick={() => {navigate(routeToGo)}} />
          <Title level={5}>{vehicleId ? "All Vehicles" : "All Bookings"}</Title>
        </div>
        <Row gutter={[40, 16]}>
          <Col xs={24} md={16}>
            <Details vehicleDetails={ bookingDetails ? bookingDetails.vehicle :vehicleDetails} />
          </Col>
          <Col xs={24} md={8}>
            <Book />
          </Col>
        </Row>
        </Spin>
      </div>
    </div>
  );
};

export default Home;
