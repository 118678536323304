import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography } from 'antd';

import { useBusinessInfoSelectors } from './hooks';
import { ROUTES } from 'shared/routes/constants';
import { ContentType } from '../_shared/types';
import InfoCard from '../_shared';
import Button from 'components/Button';

const { Title } = Typography;

const BusinessInfo = () => {
  const navigate = useNavigate();

  const { businessInfo, hasBusinessInfo, status } = useBusinessInfoSelectors();

  const [contents, setContents] = useState<ContentType[]>([]);

  useEffect(() => {
    setContents([
      {
        text: 'Business Name',
        value: businessInfo.business_name,
      },
      {
        text: 'ABN No.',
        value: businessInfo.abn,
      },
      {
        text: 'Address',
        value: businessInfo.business_address,
      },
      {
        text: 'Phone',
        value: businessInfo.business_phone,
      },
      {
        text: 'Email',
        value: businessInfo.business_email,
      },
    ]);
  }, [status]);

  const goToBusinessInfoPage = () => {
    navigate(ROUTES.PATHS.USER.USER_ACCOUNT_POINT_BUSINESS_INFO);
  };

  return (
    <>
      <InfoCard
        titleText="Business Information"
        contents={contents}
        onEditClick={goToBusinessInfoPage}
        noContentsData={
          hasBusinessInfo ? null : (
            <>
              <Title level={3}>List your car in Jaunt and start earning</Title>
              <Button
                type="ghost"
                isBordered={true}
                style={{ width: '270px' }}
                onClick={goToBusinessInfoPage}
              >
                Add Business Information
              </Button>
            </>
          )
        }
        loading={status === 'started'}
      />
    </>
  );
};

export default BusinessInfo;
