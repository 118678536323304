import React from 'react';
import { useNavigate } from 'react-router-dom';
import { message, Tooltip } from 'antd';

import Button from 'components/Button';
import Input from 'components/Input';
import { Form, FormItem, Title, useForm } from 'components/misc/helpers';
import styles from '../Home/account.module.scss';
import passStyles from './changePassword.module.scss';
import { InfoCircleOutlined } from '@ant-design/icons';

import { ChangePasswordFormValues } from './types';
import { useAppDispatch, useUnwrapAsyncThunk } from 'store/hooks';
import { changePasswordAsync } from 'store/customer/account/actions';
import { ACCESS_TOKEN_NAME } from 'api/base/constants';
import { setIsAuthenticated, setUserData } from 'store/shared/auth/actions';
import { ROUTES } from 'shared/routes/constants';

const ChangePassword = () => {
  const unwrap = useUnwrapAsyncThunk();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [form] = useForm();

  // const pattern = /^(?=.*[A-Za-z])(?=.*\d?)(?=.*[#@$!%*?&]?)[A-Za-z\d#@$!%*?&]{8,}$/;

  const changePassword = (values: ChangePasswordFormValues) => {
    unwrap(
      changePasswordAsync({
        new_password: values.new_password,
        old_password: values.current_password,
      })
    )
      .then(() => {
        message.success('Password changed successfully').then(() => {
          localStorage.removeItem(ACCESS_TOKEN_NAME);
          dispatch(setIsAuthenticated(false));
          dispatch(setUserData(undefined));
          navigate(ROUTES.PATHS.LOGIN, { replace: true });
        });
      })
      .catch((e) => {
        message.error('Password change failed');
        form.setFields([
          {
            name: 'new_password',
            errors: e.new_password ? [...e.new_password] : [],
          },
          {
            name: 'current_password',
            errors: e.old_password ? [...e.old_password] : [],
          },
        ]);
      });
  };

  return (
    <div>
      <div className={styles.titleContainer}>
        <Title level={2}>Change Password</Title>
      </div>

      <Form className={styles.form} onFinish={changePassword} form={form}>
        <div className={styles.currentPassword}>
          <FormItem
            name="current_password"
            rules={[
              { required: true, message: 'Please input your current password' },
              { min: 8, message: 'Password must be at least 8 characters' },
            ]}
          >
            <Input inputType="password" label="Current Password" />
          </FormItem>
          <div
            className={styles.forgetPassword}
            onClick={() => {
              navigate(ROUTES.PATHS.FORGOT_PASSWORD);
            }}
          >
            Forget Password
          </div>
        </div>
        <FormItem
          name="new_password"
          rules={[
            { required: true, message: 'Please input your password' },
            {
              min: 8,
              max: 32,
              message: 'Password must be 8-32 characters long',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (value && getFieldValue('current_password') === value) {
                  return Promise.reject(
                    new Error(
                      'New password must be different from current password'
                    )
                  );
                }

                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input
            inputType="password"
            label="New Password"
            prefix={
              <div>
                <Tooltip title="Password must contain 8 characters, at least one Uppercase letter, one lower case letter, one number, one special character">
                  <InfoCircleOutlined
                    style={{
                      color: 'rgba(0,0,0,.45)',
                      marginRight: '.5rem',
                    }}
                  />
                </Tooltip>
              </div>
            }
          />
        </FormItem>
        <FormItem
          className={passStyles.main}
          name="confirm_new_password"
          dependencies={['new_password']}
          hasFeedback
          rules={[
            { required: true, message: 'Please confirm your password' },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('new_password') === value) {
                  return Promise.resolve();
                }

                return Promise.reject(
                  new Error('The two passwords that you entered do not match!')
                );
              },
            }),
          ]}
        >
          <Input inputType="password" label="Confirm New Password" />
        </FormItem>

        <Button
          className={styles.button}
          type="ghost"
          isBordered={true}
          htmlType="submit"
        >
          <div className={styles.textContainer}>
            <span className={styles.buttonTextGhost}>Save</span>
          </div>
        </Button>
      </Form>
    </div>
  );
};

export default ChangePassword;
