import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col, Typography, Spin } from 'antd';
import { useParams } from 'react-router-dom';
import ProfileInfo from 'uicontainers/Customer/profile/ProfileInfo';
import { getSearchedVehiclesAsync } from 'store/tripBooking/home/thunks';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectSearchedVehicles, selectRequest, } from 'store/tripBooking/home/selectors';
import { getProviderInfoAsync } from 'store/tripBooking/providerDetails/thunks';
import VehicleCard from 'components/VehicleCard';

import { useProviderInfoSelectors } from './hooks';
import styles from './providerInfo.module.scss';
import { ROUTES } from 'shared/routes/constants';

const { Text } = Typography;

function ProviderInfo() {
  const dispatch = useAppDispatch();
  const { providerId } = useParams();
  const navigate = useNavigate();
  const vehicles = useAppSelector(selectSearchedVehicles);
  const { profileInfo, status } = useProviderInfoSelectors();
  const isLoading = useAppSelector(selectRequest) === 'started';

  React.useEffect(() => {
    if (providerId) {
      dispatch(getProviderInfoAsync(parseInt(providerId)));
      dispatch(getSearchedVehiclesAsync({ provider: providerId }));
    }
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <ProfileInfo profileInfo={profileInfo} status={status} />
      <Text
        strong
        className={styles.title}
      >{`${profileInfo?.first_name} ${profileInfo?.last_name}'s Vehicles`}</Text>
      <Row gutter={[16, 16]} className={styles.cardContainer}>
        {
          isLoading ? 
          <Col span={24}>
            <Spin spinning></Spin>
          </Col> : 
          vehicles.map((vehicle) => (
            <Col xs={24} sm={24} md={8} lg={6}>
              <VehicleCard
                key={vehicle.id}
                title={
                  vehicle.vehicle_make +
                  ' ' +
                  vehicle?.vehicle_model +
                  ' ' +
                  vehicle?.vehicle_year
                }
                cardImage={vehicle.featured_image}
                rating={vehicle.rating}
                tripCount={vehicle.trips}
                perDayCost={vehicle.daily_price}
                isNew={vehicle.new}
                onClick={() => {
                    vehicle && navigate(ROUTES.VEHICLE_DETAILS_ID(vehicle.id.toString()));
                }}
              />
            </Col>
          ))
        }
      </Row>
    </div>
  );
}

export default ProviderInfo;
