import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  getSummary,
  getEarning,
  getTopEarningProviders,
  getVehicleStatus,
} from 'api/admin/dashboard';

export const getSummaryAsync = createAsyncThunk(
  'admin/dashboard/get_summary',
  async () => {
    const response = await getSummary();
    return response;
  }
);

export const getEarningAsync = createAsyncThunk(
  'admin/dashboard/get_earning',
  async () => {
    const response = await getEarning();
    return response;
  }
);

export const getTopEarningProvidersAsync = createAsyncThunk(
  'admin/dashboard/get_top_earning_providers',
  async () => {
    const response = await getTopEarningProviders();
    return response;
  }
);

export const getVehicleStatusAsync = createAsyncThunk(
  'admin/dashboard/get_vehicle_status',
  async () => {
    const response = await getVehicleStatus();
    return response;
  }
);
