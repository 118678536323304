import { RootState } from "store/types";

export const selectSearchedVehicles = (state: RootState) =>
  state.resources.tripBooking.searchedVehicles.searchedVehicles;

export const selectVehicleTypes = (state: RootState) =>
  state.resources.tripBooking.searchedVehicles.vehicle_types;

export const selectSearchParams = (state: RootState) =>
  state.resources.tripBooking.searchedVehicles.searchParams;

export const selectTotalCount = (state: RootState) =>
  state.resources.tripBooking.searchedVehicles.totalCount;
export const selectCurrentPage = (state: RootState) =>
  state.resources.tripBooking.searchedVehicles.currentPage;
export const selectNextPage = (state: RootState) =>
  state.resources.tripBooking.searchedVehicles.next;
export const selectRequest = (state: RootState) =>
  state.resources.tripBooking.searchedVehicles.getVehiclesRequestStatus;

export const selectPopularProviders = (state: RootState) =>
  state.resources.tripBooking.searchedVehicles.popularProviders;
