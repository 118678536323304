import { message } from 'antd';
import { useCallback } from 'react';

import { changeBookingStatusAsync } from 'store/customer/bookings/actions';
import { useUnwrapAsyncThunk } from 'store/hooks';

import { BookingInfo } from './types';

export const useChangeBookingStatus = () => {
  const unwrap = useUnwrapAsyncThunk();

  return useCallback(
    (bookingInfo: BookingInfo, id: number) => {
      return unwrap(changeBookingStatusAsync({ status: bookingInfo.request_status, id }))
        .then((response) => {
          message.success('Booking information saved successfully', 0.5);
        })
        .catch((e) => {
          message.error(e.message);
        });
    },
    [changeBookingStatusAsync]
  );
};
