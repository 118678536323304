import { providerAPI, paymentAPI } from 'api/base';

import {
  ConfirmPayment,
  PostPaymentIntent,
  PostSubscriptionData,
} from './types';

export const handlePayment: ConfirmPayment = (data) => {
  return providerAPI.post('/bookings/', data);
};

export const createPaymentIntent: PostPaymentIntent = (data) => {
  return paymentAPI.post('/create-payment-intent/', data);
};

// for payment
export const handleCheckout: PostSubscriptionData = (data) => {
    return paymentAPI.post('/booking/', data);
    };
