import { createSlice, isAnyOf } from '@reduxjs/toolkit';
import { CAR_PROTECTION_SLICE } from './constants';
import { State } from './types';
import { INSURANCE_TYPE } from '../constants';
import {
  upload,
  updateVehicleAsync,
  getVehicleDetailsAsync,
  getInsuranceCompanyAsync,
} from 'store/provider/vehicles/actions';

const initialState: State = {
  carProtection: {
    coverage_overview: '',
    insurance_number: '',
    insurance_type: INSURANCE_TYPE.OWNER,
    insurance_by: '',
    insurance_paper: null,
    insurance_by_id: 0,
  },
  insurance_company_options: [],
};

export const carProtectionSlice = createSlice({
  name: CAR_PROTECTION_SLICE,
  initialState,
  reducers: {
    resetCarProtection: () => initialState,
  },
  extraReducers: (builder) =>
    builder
      .addCase(upload.fulfilled, (state, action) => {
        if (action.payload.insurance_paper) {
          state.carProtection.insurance_paper = action.payload.insurance_paper;
        }
      })
      .addCase(getInsuranceCompanyAsync.fulfilled, (state, action) => {
        const { results } = action.payload;
        state.insurance_company_options = results.map(({ id, name }) => ({
          value: id.toString(),
          name,
        }));
      })
      .addMatcher(
        isAnyOf(updateVehicleAsync.fulfilled, getVehicleDetailsAsync.fulfilled),
        (state, action) => {
          const { insurance_type, ...others } = action.payload;
          state.carProtection = {
            ...state.carProtection,
            ...others,
            insurance_type: insurance_type
              ? insurance_type
              : INSURANCE_TYPE.OWNER,
          };
        }
      ),
});

export default carProtectionSlice;
