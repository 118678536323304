import React, { FC } from 'react';
import { Typography, Row, Col, Divider } from 'antd';

import iconMpg from 'assets/images/mpg.png';
import iconPassengers from 'assets/images/passengers.png';
import iconLuggage from 'assets/images/luggage.png';
import iconGas from 'assets/images/gas.png';
import iconDoors from 'assets/images/doors.png';
import iconTransmission from 'assets/images/transmission.png';

import styles from '../details.module.scss';

import Info from './Info';
import { OverviewProps } from './types';

const { Text } = Typography;

const Overview: FC<OverviewProps> = (props) => {
  const { mileage, passengers, baggage, fuelType, doors, vehicleTransmission } = props;
  return (
    <Row gutter={[0, 16]}>
      <Col span={24}>
        <Text strong>Overview</Text>
      </Col>
      <Col span={24}>
        <Row>
          <Col span={12}>
            <Row justify="start" className={styles.baseline} gutter={[0, 8]}>
              <Info iconUrl={iconMpg} value={`${mileage} MPG`} />
              <Info iconUrl={iconPassengers} value={`${passengers} Passengers`} />
              <Info iconUrl={iconLuggage} value={`${baggage} Bags`} />
            </Row>
          </Col>
          <Col span={12}>
            <Row justify="start" className={styles.baseline} gutter={[0, 8]}>
              <Info iconUrl={iconGas} value={fuelType} />
              <Info iconUrl={iconDoors} value={`${doors} Doors`} />
              <Info iconUrl={iconTransmission} value={vehicleTransmission} />
            </Row>
          </Col>
        </Row>
      </Col>
      <Divider />
    </Row>
  );
};

export default Overview;
