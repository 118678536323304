import { Trip } from 'api/admin/tripHistory/types';
import { dateGenerator } from 'shared/helpers/helperFunctions';
import { TRIP_STATUS_TAG_PROPS } from 'uicontainers/_shared/constants';

import { TripHistoryTableData } from './types';

export const transformTripsToTableData = (
  trips: Trip[]
): TripHistoryTableData[] => {
  return trips.map((trip) => ({
    key: trip.id.toString(),
    trip_id: {
      text: trip.id,
    },
    customer: {
      text: trip.customer,
    },
    trip_duration: {
      text: `${dateGenerator(trip?.start_time)}---${dateGenerator(
        trip?.end_time
      )}`,
    },
    vehicle: {
      text: trip.vehicle,
    },
    provider: {
      text: trip.provider,
    },
    booked: {
      text: dateGenerator(trip?.created_at),
    },
    trip_status: {
      tags: [
        {
          name: TRIP_STATUS_TAG_PROPS[trip.request_status].name,
          color: TRIP_STATUS_TAG_PROPS[trip.request_status].color,
        },
      ],
    },
  }));
};
