import { createSlice } from '@reduxjs/toolkit';
import filter from 'lodash/filter';

import { PHOTOS_SLICE } from './constants';
import { PhotoState } from './types';
import {
  upload,
  deleteVehicleImageAsync,
  getVehicleDetailsAsync,
} from 'store/provider/vehicles/actions';

const initialState: PhotoState = {
  vehicleImages: [],
  featuredImage: null,
};

export const photoSlice = createSlice({
  name: PHOTOS_SLICE,
  initialState,
  reducers: {
    resetCarPhotos: () => initialState,
  },
  extraReducers: (builder) =>
    builder
      .addCase(upload.fulfilled, (state, action) => {
        state.vehicleImages = action.payload.vehicle_image || [];
        state.featuredImage = action.payload.featured_image || null;
      })
      .addCase(getVehicleDetailsAsync.fulfilled, (state, action) => {
        state.vehicleImages = action.payload.vehicle_image || [];
        state.featuredImage = action.payload.featured_image || null;
        state.status = action.payload.status;
      })
      .addCase(deleteVehicleImageAsync.fulfilled, (state, action) => {
        state.vehicleImages = filter(
          state.vehicleImages,
          (image) => image.id.toString() !== action.payload.image_id,
        );
      }),
});

export default photoSlice;
