import { createSlice } from '@reduxjs/toolkit';

import {
  getDashboardAsync,
  getEarningAsync,
  getRecentBookingAsync,
  getVehicleWiseEarningAsync,
} from './thunks';
import { ProviderDashboardState } from './types';

const initialState: ProviderDashboardState = {
  summary: {
    booking_count: 0,
    net_income: 0,
    one_go_fee: 0,
    total_income: 0,
  },
  summaryRequestStatus: 'initial',
  earning: {
    all_year: [],
    this_month: [],
    last_month: [],
  },
  earningRequestStatus: 'initial',
  recentBooking: [],
  recentBookingRequestStatus: 'initial',
  vehicleWiseEarning: [],
  vehicleWiseEarningRequestStatus: 'initial',
};

export const providerDashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getDashboardAsync.pending, (state) => {
        state.summaryRequestStatus = 'started';
      })
      .addCase(getDashboardAsync.fulfilled, (state, action) => {
        state.summaryRequestStatus = 'done';
        state.summary = action.payload.results;
      })
      .addCase(getDashboardAsync.rejected, (state) => {
        state.summaryRequestStatus = 'failed';
      })
      .addCase(getEarningAsync.pending, (state) => {
        state.earningRequestStatus = 'started';
      })
      .addCase(getEarningAsync.fulfilled, (state, action) => {
        state.earningRequestStatus = 'done';
        state.earning = action.payload.results;
      })
      .addCase(getEarningAsync.rejected, (state) => {
        state.earningRequestStatus = 'failed';
      })
      .addCase(getRecentBookingAsync.pending, (state) => {
        state.recentBookingRequestStatus = 'started';
      })
      .addCase(getRecentBookingAsync.fulfilled, (state, action) => {
        state.recentBookingRequestStatus = 'done';
        state.recentBooking = action.payload.results;
      })
      .addCase(getRecentBookingAsync.rejected, (state) => {
        state.recentBookingRequestStatus = 'failed';
      })
      .addCase(getVehicleWiseEarningAsync.pending, (state) => {
        state.vehicleWiseEarningRequestStatus = 'started';
      })
      .addCase(getVehicleWiseEarningAsync.fulfilled, (state, action) => {
        state.vehicleWiseEarningRequestStatus = 'done';
        state.vehicleWiseEarning = action.payload.results;
      })
      .addCase(getVehicleWiseEarningAsync.rejected, (state) => {
        state.vehicleWiseEarningRequestStatus = 'failed';
      });
  },
});

export default providerDashboardSlice;
