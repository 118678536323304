import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ROUTES } from "shared/routes/constants";
import { useAppDispatch, useAppSelector, useUnwrapAsyncThunk } from "store/hooks";
import { getVehicleTypesAsync } from "store/tripBooking/home/actions";
import { selectVehicleTypes } from "store/tripBooking/home/selectors";
import SearchBar from "uicontainers/tripBooking/SearchBar";

import { setTripBooking } from "../../actions";
import styles from "./topSection.module.scss";

const TopSection = () => {
  const [searchedPlace, setSearchedPlace] = useState("");
  const [vehicleType, setVehicleType] = useState<string | undefined>(undefined);

  const navigate = useNavigate();
  const unwrap = useUnwrapAsyncThunk();
  const dispatch = useAppDispatch();

  const vehicleTypes = useAppSelector(selectVehicleTypes);

  useEffect(() => {
    unwrap(getVehicleTypesAsync());
  }, []);

  const handleLocationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchedPlace(e.target.value);
  };

  const handleSearch = () => {
    dispatch(
      setTripBooking({
        location: searchedPlace,
        vehicle_type_id: vehicleType,
      })
    );
    navigate(ROUTES.PATHS.SEARCH);
  };
  return (
    <div className={styles.container}>
      <div className={styles.searchContainer}>
        <SearchBar
          onLocationChange={handleLocationChange}
          onVehicleTypeChange={(value: string) => {
            setVehicleType(value);
          }}
          vehicleTypesOptions={vehicleTypes}
          selectedVehicleType={vehicleType}
          onSearch={handleSearch}
        />
      </div>
    </div>
  );
};

export default TopSection;
