import React, { FC, useEffect } from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import styles from './vehicleDetails.module.scss';
import { Col, Row, Typography } from 'antd';
import Details from 'uicontainers/tripBooking/vehicleDetails/Details';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

import { getAdminVehicleDetailsAsync } from 'store/admin/vehicles/actions';
import { selectAdminVehicleDetails } from 'store/admin/vehicles/selectors';
import { selectVehicleDetails } from 'store/tripBooking/vehicleDetails/selectors';
import { getVehicleDetailsAsync } from 'store/tripBooking/vehicleDetails/actions';
import CarInfo from './CarInfo';

const { Title } = Typography;

const BookingDetails: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isAdmin = location.pathname.includes('/admin');
  const adminVehicleDetails = useAppSelector(selectAdminVehicleDetails);
  const vehicleDetails = useAppSelector(selectVehicleDetails);
  const { vehicleId } = useParams();

  useEffect(() => {
    if (vehicleId && isAdmin) {
      dispatch(getAdminVehicleDetailsAsync(parseInt(vehicleId)));
    }

    if (vehicleId && !isAdmin) {
      dispatch(getVehicleDetailsAsync(parseInt(vehicleId)));
    }

    window.scrollTo(0, 0);
  }, [vehicleId]);

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <div className={styles.navigation}>
          <ArrowLeftOutlined onClick={() => navigate(-1)} />
          <Title level={5}>All Vehicles</Title>
        </div>

        <Row gutter={[40, 16]}>
          <Col xs={24} md={16}>
            <Details
              //@ts-ignore
              vehicleDetails={isAdmin ? adminVehicleDetails : vehicleDetails}
            />
          </Col>
          <Col xs={24} md={8}>
            <CarInfo />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default BookingDetails;
