import { createSelector } from 'reselect';

import { selectTolls } from 'store/provider/tolls/selectors';
import { RootState } from 'store/types';

import { transformTollsToTableData } from './helpers';

export const createSelectTollTableData = createSelector(selectTolls, transformTollsToTableData);

export const selectGetTollsRequestStatus = (state: RootState) =>
  state.resources.provider.tolls.getTollsRequestStatus;

export const selectTotalCount = (state: RootState) => state.resources.provider.tolls.totalCount;

export const selectCurrentPage = (state: RootState) => state.resources.provider.tolls.currentPage;
