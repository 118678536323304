import { adminAPI } from 'api/base';

import {
  GetRegoPaper,
  GetVehicleDetails,
  GetVehicles,
  UpdateVehicle,
} from './types';

export const getVehicles: GetVehicles = (params) => {
  return adminAPI.get('/vehicle-views/', { params });
};

export const getVehicleDetails: GetVehicleDetails = (id) => {
  return adminAPI.get(`/vehicle-views/${id}/`);
};

export const getRegoPaper: GetRegoPaper = async (id: number) => {
  return adminAPI.get(`/vehicles/${id}/download-rego-paper/`, {
    responseType: 'arraybuffer',
  });
};

export const updateVehicle: UpdateVehicle = (data, id) => {
  return adminAPI.patch(`/vehicle-views/${id}/`, data);
};
