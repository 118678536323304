import { Col, Row } from 'antd';
import React from 'react';

import Summary from 'uicontainers/provider/dashboard/Summary';

import Earning from '../Earning';
import RecentBooking from '../RecentBooking';
import VehicleWiseEarning from '../VehicleWiseEarning';
import styles from './home.module.scss';

const Home = () => {
  return (
    <div className={styles.bodyContainer}>
      <Row gutter={[0, { xs: 8, sm: 8, md: 16, lg: 16 }]}>
        <Col span={24}>
          <Summary></Summary>
        </Col>
        <Col span={24}>
          <Earning></Earning>
        </Col>
        <Col span={24}>
          <Row gutter={[{ xs: 8, sm: 8, md: 16, lg: 32 }, 0]}>
            <Col xs={24} sm={24} md={12} lg={12}>
              <RecentBooking></RecentBooking>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <VehicleWiseEarning></VehicleWiseEarning>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Home;
