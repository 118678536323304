import { userAPI } from 'api/base';

import { UpdateAccount, GetProfileDetails, UploadData, PostChangePassword, CreateVerification } from './types';

export const updateAccount: UpdateAccount = (data, id) => {
  return userAPI.patch(`/profile-update/${id}/`, data);
};

export const getProfileDetails: GetProfileDetails = () => {
  return userAPI.get(`/profile-details/`);
};

export const uploadData: UploadData = (data, id) => {
  return userAPI.patch(`/profile-update/${id}/`, data);
};

export const changePassword: PostChangePassword = (data) => {
  return userAPI.post(`/change-password/`, data);
};

export const createVerification: CreateVerification = (data) => {
  return userAPI.post(`/verify-user/`, data);
};
