import { createSelector } from 'reselect';

import { selectVehicles } from 'store/admin/vehicles/selectors';
import { RootState } from 'store/types';

import { transformVehiclesToTableData } from './helpers';

export const createSelectVehicleTableData = createSelector(
  selectVehicles,
  transformVehiclesToTableData,
);

export const selectVehiclesRequestStatus = (state: RootState) =>
  state.resources.admin.vehicles.getVehiclesRequestStatus;

export const selectTotalCount = (state: RootState) => state.resources.admin.vehicles.totalCount;

export const selectCurrentPage = (state: RootState) => state.resources.admin.vehicles.currentPage;

export const selectQueryParams = (state: RootState) => state.resources.admin.vehicles.queryParams;
