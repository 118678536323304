import {
  getVehiclesAsync,
  getAdminVehicleDetailsAsync,
  getRegoPaperAsync,
  updateVehicleAsync,
} from 'store/admin/vehicles/thunks';

import slice from './slice';

const { setCurrentPage, setQueryParams } = slice.actions;

export {
  getVehiclesAsync,
  getAdminVehicleDetailsAsync,
  setCurrentPage,
  getRegoPaperAsync,
  updateVehicleAsync,
  setQueryParams,
};
